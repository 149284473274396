import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BuyerModel } from 'src/app/shared/models/buyer.model';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { environment } from 'src/environments/environment';
import { ActivityLog } from '../../../campaign-management/campaign-tracking.model';
import { ConfigureSMTPModel } from '../../../campaign-management/email-inbox-management/configure-smtp-server/configure-smtp-server.component';
import { UserModel } from '../../../user-management/user-model';
import { ProfileDetailModel } from '../../profile-detail.model';
import { EmailCampaignTemplate, SingleMailBodyResponse, SingleMailBodyTemplate } from './campaign-linkscope-email.model';
@Injectable({
  providedIn: 'root'
})
export class CampaignLinkscopeService {
  basaeUrl = environment.apiCampaign;
  profileUrl = environment.apiProfileManagement;
  buyerUrl = environment.apiBuyer;
  linkscopeEmailTemplate$: ReplaySubject<EmailCampaignTemplate> = new ReplaySubject<EmailCampaignTemplate>(1);
  editEmailResponse: ReplaySubject<ReturnResult<SingleMailBodyResponse>> = new ReplaySubject<ReturnResult<SingleMailBodyResponse>>(1);

  constructor(private http: HttpClient) { }
  getLinkscopeEmailTemplate(): Observable<EmailCampaignTemplate> {
    return this.linkscopeEmailTemplate$.asObservable();
  }
  refreshGetLinkscopeEmailTemplate(requirePermission: boolean = true) {
    return this.http.post<ReturnResult<EmailCampaignTemplate>>(`${this.basaeUrl}/GetLinkScopeEmailTemplate`, { RequirePermission: requirePermission }).subscribe(resp => {
      if (resp.result) {
        this.linkscopeEmailTemplate$.next(resp.result);
      }
    });
  }
  sendCampaign(profile: ProfileDetailModel, emailTemplateId: number, titleEmail: string, bodyEmail: string, senderId: string, entityType = "profile"): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.basaeUrl}/SendCampaign`, {
      profile,
      emailTemplateId,
      titleEmail,
      bodyEmail,
      senderId,
      entityType

    });
  }
  makeACall(url, profile, type, userName, taskId, profileId: string = null) {
    return this.http.post<any>(`${this.profileUrl}/MakeACall`, { url, profile, type, userName, taskId, profileId });
  }
  makeAMDCall(url, profile, type, userName, taskId) {
    return this.http.post<any>(`${this.profileUrl}/MakeAMDCall`, { url, profile, type, userName, taskId });
  }
  // 2021-12-29 tienlm add start
  GetLastSendEmail(profileId: string): Observable<ReturnResult<ActivityLog[]>> {
    return this.http.get<ReturnResult<ActivityLog[]>>(`${this.basaeUrl}/GetLastSendEmail/${profileId}`);
  }
  // 2021-12-29 tienlm add end
  makeAMDCallAltProfileId(url, profileId, type, userName, taskId) {
    return this.http.post<any>(`${this.profileUrl}/MakeAMDCall`, { url, profileId, type, userName, taskId });
  }

  sendWithEditorEmail(profile: ProfileDetailModel, title: string, body: string, senderId: string, entityType = 'profile'): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.basaeUrl}/SendWithEditorEmail`, {
      Profile: profile,
      Title: title,
      Body: body,
      SenderId: senderId,
      EntityType: entityType,
    });
  }

  updateCallEventActivityLog(activityLogId: number, callEvent: string) {
    return this.http.post<ReturnResult<boolean>>(`${this.profileUrl}/UpdateCallEventActivityLog?activityLogId=${activityLogId}&callEvent=${callEvent}`, {});
  }
  makeAMDCallAltBuyerId(url, buyerId, type, userName, taskId) {
    return this.http.post<any>(`${this.buyerUrl}/MakeAMDCall`, { url, buyerId, type, userName, taskId });
  }
  GetLastSendEmailByBuyerId(buyerId: string): Observable<ReturnResult<ActivityLog[]>> {
    return this.http.get<ReturnResult<ActivityLog[]>>(`${this.basaeUrl}/GetLastSendEmailByBuyerId/${buyerId}`);
  }
  sendCampaignByBuyerId(buyer: BuyerModel, emailTemplateId: number, titleEmail: string, bodyEmail: string, entityType = "buyer"): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.basaeUrl}/SendCampaign`, {
      buyer,
      emailTemplateId,
      titleEmail,
      bodyEmail,
      entityType,
    });
  }
  sendWithEditorEmailByBuyerId(buyer: BuyerModel, title: string, body: string, entityType = 'buyer'): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.basaeUrl}/SendWithEditorEmail`, {
      Buyer: buyer,
      Title: title,
      Body: body,
      EntityType: entityType,
    });
  }
  buyerMakeAMDCall(url, buyer, type, userName, taskId) {
    return this.http.post<any>(`${this.buyerUrl}/MakeAMDCall`, { url, buyer, type, userName, taskId });
  }

  sendSupportTicketEmail(profile: ProfileDetailModel, userModel: UserModel, title: string, body: string, senderId: string = ''): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.basaeUrl}/SendSupportTicketEmail`, {
      Profile: profile,
      UserModel: userModel,
      Title: title,
      Body: body,
      senderId,
      EntityType: 'profile',
    });
  }
  sendEmailIndividual(titleEmail: string, bodyEmail: string, targetEmail: string): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.basaeUrl}/SendEmailIndividual`, {
      Title: titleEmail,
      Body: bodyEmail,
      TargetEmail: targetEmail
    });
  }

  editEmailTemplateLinkScope(model: SingleMailBodyTemplate): Observable<ReturnResult<SingleMailBodyResponse>> {
    return this.http.post<ReturnResult<SingleMailBodyResponse>>(`${this.basaeUrl}/EditEmailTemplateLinkScope`, model).pipe(
      tap(resp => this.editEmailResponse.next(resp))
    )
  }

  responseEditEmailTemplate(): Observable<ReturnResult<SingleMailBodyResponse>> {
    return this.editEmailResponse.asObservable();
  }

  testSMTP(model: ConfigureSMTPModel): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.basaeUrl}/TestSMTP`, model);
  }

  connectSMTPByCX(model: ConfigureSMTPModel): Observable<ReturnResult<string>> {
    return this.http.post<ReturnResult<string>>(`${this.basaeUrl}/ConnectSMTPByCX`, model);
  }

  disconnectSMTPByCX(model: string): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.basaeUrl}/DisconnectSMTPByCX`, model);
  }

  deleteEmailTemplate(id: number): Observable<ReturnResult<boolean>> {
    return this.http.delete<ReturnResult<boolean>>(`${this.basaeUrl}/DeleteEmailTemplate?id=${id}`);
  }

  getEmailTemplateCX(requirePermission: boolean = true) {
    return this.http.post<ReturnResult<EmailCampaignTemplate>>(`${this.basaeUrl}/GetLinkScopeEmailTemplate`, { RequirePermission: requirePermission })
      .pipe(tap((resp) => this.linkscopeEmailTemplate$.next(resp.result)));
  }
}
