import { maxLength, prop, required } from "@rxweb/reactive-form-validators";

export class UserUpload {
  userUploadId: number;
  @prop()
  fileName: string;
  @prop()
  physicalPath: string;
  @prop()
  @required()
  owners: string;
  @prop()
  @maxLength({ value: 255 })
  referenceType: string;
  @prop()
  @maxLength({ value: 36 })
  referenceId: string;
  deleted: boolean;
  dateDeleted: string | null;
  dateCreated: string | null;
  dateModified: string | null;
  userUploadHistories: UserUploadHistory[];
  historyFiles: number;
  dataType:string;
  isURL: boolean = false;
}

export class UserUploadHistory {
  userUploadHistoryId: string;
  userUploadId: number;
  deleted: boolean;
  dateDeleted: Date | string | null;
  dateCreated: Date | string | null;
  dateModified: Date | string | null;
  userUpload: UserUpload | null;
}

export class UserUploadMultipleModel {
  @prop()
  fileName: string;
  @prop()
  physicalPath: string;
  @prop()
  @required()
  owners: string;
  @prop()
  @maxLength({ value: 255 })
  referenceType: string;
  @prop()
  @maxLength({ value: 36 })
  referenceId: string;

  @prop()
  file: File;


  userUploadHistories: UserUploadHistory[];
}

// folder tree class and enum
export class FolderTree {
  userUploadHistoryId: string;
  type: FolderType;
  name: string;
  physicalPath: string;
  children?: FolderTree[];
}
export class changeURLUpload{
  userUploadHistoryId:string;
  newURL:string;
}

export enum FolderType {
  Folder,
  File
}
