<!-- <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/baguettebox.js/1.11.1/baguetteBox.css"
  integrity="sha512-3eoKq7cU6gdVeT+6eL40YcJLD8dxzQmOK54qxUWVjg7H4NN3u5AA5k5ywrqLV15hOZDBBgdQH/GK5CA9MwDVwg=="
  crossorigin="anonymous" referrerpolicy="no-referrer" />
<script src="https://cdnjs.cloudflare.com/ajax/libs/baguettebox.js/1.11.1/baguetteBox.min.js"
  integrity="sha512-7KzSt4AJ9bLchXCRllnyYUDjfhO2IFEWSa+a5/3kPGQbr+swRTorHQfyADAhSlVHCs1bpFdB1447ZRzFyiiXsg=="
  crossorigin="anonymous" referrerpolicy="no-referrer"></script> -->
<script src="/src/app/shared/utility/battleguet.min.js"
  integrity="sha512-7KzSt4AJ9bLchXCRllnyYUDjfhO2IFEWSa+a5/3kPGQbr+swRTorHQfyADAhSlVHCs1bpFdB1447ZRzFyiiXsg=="
  crossorigin="anonymous" referrerpolicy="no-referrer"></script>
<div class="card" style="border:none;" #wrapperContainer>
  <div class="card-body">
    <div [ngSwitch]="dataField">
      <div *ngSwitchCase="displayComponentMultimedia.All">
        <ng-container [ngTemplateOutlet]="photosGroup"></ng-container>
        <ng-container [ngTemplateOutlet]="videosGroup"></ng-container>
      </div>
      <div *ngSwitchCase="displayComponentMultimedia.Photos">
        <ng-container [ngTemplateOutlet]="photosGroup"></ng-container>
      </div>
      <div *ngSwitchCase="displayComponentMultimedia.Videos">
        <ng-container [ngTemplateOutlet]="videosGroup"></ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #uploadImg>
  <h2 mat-dialog-title>Add new image</h2>
  <div class="form-group">
    <form [formGroup]="imageInput">
      <div class="img-space">
        <input type="file" id="file" (change)="handleFileInput($event.target.files, $event)" formControlName="input"
          accept="image/png, image/gif, image/jpeg" class="fileInputProfile">
        <ngx-dropzone-label *ngIf="imgSrc == imgSrcFlag">
          <p style="text-align: center; width: 100% ; margin-top: 60px;">Drag your files here or click in this area.</p>
        </ngx-dropzone-label>
        <ngx-dropzone-image-preview [removable]="false" [file]="imgSrc" *ngIf="imgSrc != imgSrcFlag">
        </ngx-dropzone-image-preview>
      </div>
      <mat-error *ngIf="blockInput">
        {{videoValidateMessage}}
      </mat-error>
    </form>
  </div>
  <div class="d-flex flex-row justify-content-between">
    <button mat-button color="warn" (click)="closeAddImg()"> Cancel</button>
    <button mat-button color="primary" (click)="onUploadClick()"
      [disabled]="!this.imageInput.get('input').valid || !this.imageInput.get('input').dirty || blockInput">
      Upload</button>
  </div>
</ng-template>

<ng-template #imageActions>
  <button mat-button color="primary"> Add</button>
  <button mat-button color="primary"> Upload</button>
</ng-template>

<ng-template #uploadVideo>
  <h2 mat-dialog-title>Add video Url</h2>
  <div class="container">
    <form [formGroup]="videoInput">
      <mat-form-field appearance="standard">
        <mat-label>Your video Url</mat-label>
        <input matInput autocomplete="off" required formControlName="videoUrlInput" multiple>
        <mat-error *ngIf="videoInput.controls.videoUrlInput.hasError('required')">
          Url required
        </mat-error>
        <mat-error *ngIf="videoInput.controls.videoUrlInput.hasError('pattern')">
          Url Pattern Invalid
        </mat-error>
      </mat-form-field>
    </form>
  </div>
  <div class="d-flex flex-row justify-content-between">
    <button mat-button color="warn" (click)="closeVideoDialogRef()"> Cancel</button>
    <button mat-button color="primary" (click)="onManageVideoClick()" [nbSpinner]="isUploadVideoURL"
      [disabled]="!videoInput.valid || isUploadVideoURL"> Upload</button>
  </div>
</ng-template>

<ng-template #setPrimaryDialog>
  <button mat-flat-button>
    set primary
  </button>
</ng-template>
<!-- menu image -->
<mat-menu #rightMenu="matMenu">
  <ng-template matMenuContent let-photo="item" let-table="table" let-type="type">
    <button mat-menu-item (click)="copyUrlMedia(photo, type)">
      Copy url
    </button>
    <button mat-menu-item (click)="SetPrimaryMedia(photo,table,type)" [disabled]="readonlyMultiMedia">
      Set primary
    </button>
    <button mat-menu-item (click)="onMoveUp(photo,table,type)" [disabled]="readonlyMultiMedia">
      Move up
    </button>
    <button mat-menu-item (click)="onMoveDown(photo,table,type)" [disabled]="readonlyMultiMedia">
      Move down
    </button>
    <button mat-menu-item (click)="onToTop(photo,table,type)" [disabled]="readonlyMultiMedia">
      Move to top
    </button>
    <button mat-menu-item (click)="onToBottom(photo,table,type)" [disabled]="readonlyMultiMedia">
      Move to the bottom
    </button>
    <button *ngIf="type == 'Image'" mat-menu-item [disabled]="disableResizeGif(photo)" (click)="openResizeModal(photo)">
      Resize
    </button>
    <!-- <button *ngIf="type == 'Image'" mat-menu-item (click)="uploadAndResizeMedia(photo)" [disabled]="readonlyMultiMedia">
      Sync to S3
    </button> -->
    <button *ngIf="type == 'Image'" mat-menu-item (click)="uploadToBunny(photo)" [disabled]="readonlyMultiMedia">
      Sync to Bunny
    </button>
  </ng-template>
</mat-menu>
<!-- <ngx-thumbnail-video url="{{video.media1}}"></ngx-thumbnail-video> -->


<!--2022-03-29 vuonglqn add start-->
<ng-template #menuAddVideo class="column d-flex flex-column align-items-start">
  <button mat-flat-button class="col float-start" (click)="openVideoDialogRef()">
    <mat-icon style="color: #007100;">add</mat-icon> Add Video URL
  </button>
  <hr class="custom-divider">
  <button mat-flat-button class="col d-flex flex-row" [disabled]="(obsUploadVideo | async)" (click)="openUploadVideo()">
    <mat-icon style="color: #007100;">add</mat-icon> Upload Video
  </button>
</ng-template>
<!--2022-03-29 vuonglqn add end-->

<!--#region photo-->
<ng-template #photosGroup>
  <div class="container">
    <div style="visibility: hidden; position: fixed;" [style.left]="menuTopLeftPosition.x"
      [style.top]="menuTopLeftPosition.y" [matMenuTriggerFor]="rightMenu"></div>
    <div class="row d-flex flex-row justify-content-between align-items-center">
      <h4>Photos
        <mat-icon
          matTooltip="To set primary image, user can right click on the image then set primary. If user want to add MULTIPLE IMAGES, just drag and drop all images that user wants to upload to the fields">
          help_outline
        </mat-icon>
      </h4>
      <div *ngIf="!readonlyMultiMedia">
        <div *ngIf="!isShowDragArea; else dropZoneActions" class="manage d-flex flex-row justify-content-between">
          <button *ngIf="isShowDragArea" mat-raised-button color="warn" (click)="cancelDragBox()">
            <mat-icon>close</mat-icon> Cancel
          </button>
          <div style="padding-left: 10px;"></div>
          <button *ngIf="!isDeleteMode; else deleteBtn" mat-raised-button color="primary" (click)="onSelectClick()"
            [nbSpinner]="loading" [disabled]="loading" matTooltip="Select images" matTooltipPosition="below">
            <mat-icon>library_add_check</mat-icon>
          </button>
          <ng-template #deleteBtn>
            <button mat-raised-button color="warn" (click)="onDeleteImagesClick()" matTooltip="Delete images"
              matTooltipPosition="below">
              <mat-icon>delete</mat-icon>
            </button>
          </ng-template>
          <div style="padding-left: 10px;"></div>
          <button mat-raised-button color="primary" (click)="onManageClick()" [nbSpinner]="loading" [disabled]="loading"
            matTooltip="New image" matTooltipPosition="below">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>

      <!-- Dropzone actions -->
      <ng-template #dropZoneActions>
        <div class="manage d-flex flex-row justify-content-between">
          <button mat-raised-button color="warn" (click)="cancelDragBox()" [nbSpinner]="loading" [disabled]="loading">
            <mat-icon>close</mat-icon> Cancel
          </button>
          <div style="padding-left: 10px;"></div>
          <button mat-raised-button color="primary" [nbSpinner]="loading" (click)="uploadMultipleImage()"
            [disabled]="files.length == 0 || loading">
            <mat-icon>upload</mat-icon>
            Upload
          </button>
        </div>
      </ng-template>
    </div>
    <br>
    <div [nbSpinner]="loading">
      <mat-grid-list *ngIf="!isShowDragArea;else showDragImage" cols="4" rowHeight="4:3" class="container" fxLayoutWrap
        fxLayout="row" [gutterSize]="'30px'" class="gallery">
        <mat-grid-tile *ngFor="let photo of photos;index as i" [matTooltip]="photo.originalMedia"
          matTooltipPosition="above" style="background-color: #f2f2f2;">
          <a *ngIf="!isDeleteMode;else selectMode" href="{{photo.originalMedia}}" (click)="showImage($event,i)"
            style="height: 100%;" [nbSpinner]="photo.isLoading">
            <nb-badge text="Primary" position="top left" style="top: 3%; left: 3%; z-index: 4;" status="success"
              *ngIf="primaryImage === photo.mediaId">
            </nb-badge>
            <button *ngIf="photo.published" mat-icon-button
              class="material-icons-outlined middle-align published-button" (click)="changePublishedMedia(photo)"
              style="color: green" matTooltip="Click to cancel public" matTooltipPosition="below">
              <mat-icon>check_circle</mat-icon>
            </button>
            <mat-radio-button *ngIf="!photo.published" (click)="changePublishedMedia(photo)"
              class="material-icons-outlined middle-align not-published-button" matTooltip="Click to public"
              matTooltipPosition="below">
            </mat-radio-button>
            <img src="{{photo.thumbnailMedia}}" (error)="photo.thumbnailMedia = 'assets/images/error-photo.jpg'"
              class="img-responsive normal" alt="{{photo.mediaTypeId}}"
              (contextmenu)="onRightClick($event,photo,'Profile','Image')" layout-fill
              (load)="changeSizeImage(photo.thumbnailMedia,i)" [ngClass]="!photo.published ? 'faded' : ''"
              style="height: 100%; object-fit: cover;">
          </a>
          <ng-template #selectMode>
            <mat-checkbox class="select-absolute" (change)="onImgChecked($event.checked,i)"
              [(ngModel)]="imageCheckLst[i].value">
              <div style="width:inherit;height:inherit"></div>
            </mat-checkbox>

            <img src="{{photo.thumbnailMedia}}" (error)="photo.thumbnailMedia = 'assets/images/error-photo.jpg'"
              class="img-responsive normal faded" alt="{{photo.mediaTypeId}}" (click)="onImgChecked(true,i,true)"
              (load)="changeSizeImage(photo.thumbnailMedia,i)" style="height: 100%; object-fit: cover;">
          </ng-template>
        </mat-grid-tile>
      </mat-grid-list>
      <br>
      <ng-template #showDragImage>
        <ngx-dropzone #drop accept="image/png, image/gif, image/jpeg" (change)="onSelect($event)">
          <ngx-dropzone-label>
            <div>
              <h2>Drop your images here</h2>
            </div>
          </ngx-dropzone-label>
          <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [removable]="true"
            [file]="f" (removed)="onRemove(f)" [removable]="true">
            <ngx-dropzone-label></ngx-dropzone-label>
          </ngx-dropzone-image-preview>
        </ngx-dropzone>
      </ng-template>
    </div>
  </div>
</ng-template>
<!--#endregion-->

<!--#region video-->
<ng-template #videosGroup>
  <div class="container">
    <div class="row d-flex flex-row justify-content-between align-items-center">
      <h4>Videos
        <mat-icon matTooltip="To set primary video, user can right click on the video then set primary.">help_outline
        </mat-icon>
      </h4>
      <div *ngIf="!readonlyMultiMedia">
        <div class="manage d-flex flex-row justify-content-between">
          <button *ngIf="videoDeleteMode == false; else videoDeleteModeTemp" mat-raised-button color="primary"
            (click)="onVideoSelectMode()" [nbSpinner]="loading" [disabled]="loading" matTooltip="Select videos"
            matTooltipPosition="below">
            <mat-icon>library_add_check</mat-icon>
          </button>
          <ng-template #videoDeleteModeTemp>
            <button mat-raised-button color="warn" (click)="onDeleteVideoClick()" matTooltip="Delete videos"
              matTooltipPosition="below">
              <mat-icon>delete</mat-icon>
            </button>
          </ng-template>
          <div style="padding-left: 10px;"></div>
          <button mat-raised-button color="primary" class="manage" [nbPopover]="menuAddVideo" nbPopoverPlacement="left"
            [nbSpinner]="loading" [disabled]="loading" matTooltip="New video" matTooltipPosition="below">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <!-- videos -->
    <br>
    <div>
      <!-- <mat-grid-list cols="4" rowHeight="4:3" class="container" fxLayoutWrap fxLayout="row" [gutterSize]="'30px'">
        <mat-grid-tile *ngFor="let video of videos">

          <iframe
            src="https://www.viddle.in/embed/WMY4B3Su/?title=true&share=true&controls=true&context=true&color=blue"
            style="position: absolute; top: 0; left: 0; bottom: 0; right: 0; width:100%; height: 100%; border: none; overflow: hidden;"
            allowfullscreen>
          </iframe>

        </mat-grid-tile>
      </mat-grid-list> -->
      <app-video-player-list [videos]="videos" (getDeletedVideos)="getDeletedVideos($event)"
        [selectedVideos]="videoCheckLst" [gallery]="videoGallery" [isDeleteMode]="videoDeleteMode"
        (rightClick)="handldeVideoRighClick($event)" [primaryVideo]="primaryVideo">
      </app-video-player-list>
    </div>
  </div>
</ng-template>
<!--#endregion-->
