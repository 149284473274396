import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
import { RxFormBuilder, RxwebValidators } from '@rxweb/reactive-form-validators';
import { filter } from 'rxjs/operators';
import { MediaManagementService } from 'src/app/modules/admin/media-management/media-management.service';
import { ProfileService } from 'src/app/modules/admin/profile-management/profile.service';
import { Helper } from 'src/app/shared/utility/Helper';
import { ArtistDetails, MediaOnBoardViewModel, TestimonialsForm } from '../onboarding-form-profile.model';

@Component({
  selector: 'app-act-details-tab',
  templateUrl: './act-details-tab.component.html',
  styleUrls: ['./act-details-tab.component.scss', '../onboarding-form.component.scss']
})
export class ActDetailsTabComponent implements OnInit, OnChanges {
  @Input() actDetails: ArtistDetails[] = [];
  @Output() validForm: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(
    private formBuilder: RxFormBuilder,
    private mediaService: MediaManagementService,
    private profileService: ProfileService,
    private toast: NbToastrService
  ) { }

  actDetailsForm: FormGroup[];

  ngOnInit(): void {
    console.log(this.actDetails);
    this.actDetailsForm = [];
    if (this.actDetails && this.actDetails.length > 0) {
      this.actDetails.forEach((act, index) => {
        this.createFormAct(JSON.parse(JSON.stringify(act)), index);
        this.actDetailsForm[index].markAllAsTouched();
      });
    } else this.clickAddAct();
    console.log(this.actDetailsForm);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const inputAct = changes['actDetails'];
    if (inputAct && JSON.stringify(inputAct.previousValue) !== JSON.stringify(inputAct.currentValue))
      this.ngOnInit();
  }

  //#region rider file 
  onSelectRider(event, index) {
    if (event) this.uploadRiderAttachment(event.addedFiles[0], index);
  }

  uploadRiderAttachment(file: File, index) {
    if (file) {
      this.actDetailsForm[index].patchValue({ FileRider: file });
      this.profileService.uploadRiderAttachmentOnboardingForm(file).subscribe(resp => {
        if (resp.result) this.actDetailsForm[index].patchValue({ RiderAttachment: resp.result });
      });
    }
  }

  onRemoveRider(file, index) {
    this.actDetailsForm[index].patchValue({ FileRider: null });
  }
  //#endregion rider file

  //#region photo
  onSelectPhoto(event, index) {
    if (event) this.uploadPhoto([...event.addedFiles], index);
  }

  uploadPhoto(files: File[], index) {
    if (files && files.length > 0 && index != null && index >= 0) {
      this.mediaService.uploadImageAsset(files).subscribe(resp => {
        if (resp.result && resp.result.length > 0) {
          // this.actDetailsForm[index].patchValue({ FilePhotos: files });
          this.actDetailsForm[index].patchValue({ FilePhotos: [...this.actDetailsForm[index]?.controls?.FilePhotos?.value || [], ...files] });
          var newPhotos = [];
          resp.result.forEach(str => {
            let photo = { MediaID: 0, PID: this.actDetailsForm[index].controls?.PID?.value, Media: str, MediaTypeID: 1 } as MediaOnBoardViewModel;
            newPhotos.push(photo);
          });

          this.actDetailsForm[index].patchValue({ PhotoLinks: [...this.actDetailsForm[index]?.controls?.PhotoLinks?.value || [], ...newPhotos] });
        }
      }, (err) => {
        if (files && files.length > 0) {
          this.toast.info("Reload upload images!", "Info");
          files.forEach(file => {
            if ((file.size / 1024 / 1024) > 20) {
              this.toast.danger("Image have size > 20MB", "Error");
              return;
            } else {
              this.mediaService.uploadImageAsset([file]).subscribe(resp => {
                if (resp.result && resp.result.length > 0) {
                  this.actDetailsForm[index].patchValue({ FilePhotos: [...this.actDetailsForm[index].controls.FilePhotos.value ?? [], ...[file]] });
                  let photo = { MediaID: 0, PID: this.actDetailsForm[index].controls?.PID?.value, Media: resp.result[0], MediaTypeID: 1 } as MediaOnBoardViewModel;
                  this.actDetailsForm[index].patchValue({ PhotoLinks: [...this.actDetailsForm[index].controls.PhotoLinks.value ?? [], ...[photo]] });
                }
              });
            }
          });
        }
      });
    }
  }

  onRemovePhoto(file, index) {
    if (file) {
      var indexFile = this.actDetailsForm[index].controls.FilePhotos.value.indexOf(file);
      if (indexFile >= 0) {
        this.actDetailsForm[index].controls.FilePhotos.value.splice(indexFile, 1);
        this.actDetailsForm[index].patchValue({
          PhotoLinks: [...this.actDetailsForm[index].controls.PhotoLinks.value
            .filter(x => x.MediaID == 0)
            .filter((x, i) => i != indexFile)]
        });
        // this.actDetailsForm[index].controls.PhotoLinks.value

      }

    }
  }
  //#endregion photo

  //#region video
  addLinkVideo(index) {
    if (index != null && index >= 0) {
      let currentValue = this.actDetailsForm[index].controls?.VideoLinks?.value;
      currentValue.push({
        Media: '',
        MediaID: 0,
        MediaTypeID: 2,
        PID: this.actDetailsForm[index].controls?.PID?.value
      } as MediaOnBoardViewModel);
      this.actDetailsForm[index].patchValue({ VideoLinks: [...currentValue] });
    }
  }

  removeLinkPhoto(videoLink, index) {
    if (videoLink && index != null && index >= 0)
      this.actDetailsForm[index].controls.VideoLinks.value
        .splice(this.actDetailsForm[index].controls.VideoLinks.value.indexOf(videoLink), 1)
  }
  //#endregion video

  //#region testimonial
  formatTestimonialForm(index) {
    if (index != null && index >= 0) {
      var data = this.actDetailsForm[index]?.controls?.Testimonials?.value
        ?? JSON.stringify([{ ClientNames: '', Testimonial: '' } as TestimonialsForm]);
      var loopStr = data.indexOf('[{');
      for (let index = 0; index <= loopStr - 1; index++) {
        data = JSON.parse(data);
      }

      var convertJson = JSON.parse(!Helper.isEmptyOrSpaces(data) && !Helper.isNullOrEmpty(data) ? data
        : JSON.stringify([{ ClientNames: '', Testimonial: '' } as TestimonialsForm])) as TestimonialsForm[];

      if (convertJson && convertJson.length > 0) this.actDetailsForm[index].patchValue({
        TestimonialsForm: convertJson.map(x => ({
          ClientNames: x.ClientNames.replace(/\\n/g, '\n'),
          Testimonial: x.Testimonial.replace(/\\n/g, '\n'),
        }))
      })
      else this.actDetailsForm[index].patchValue({
        TestimonialsForm: [{ ClientNames: '', Testimonial: '' } as TestimonialsForm]
      });
    }
  }

  formatInnerHTML(index) {
    if (index != null && index >= 0) {
      this.actDetailsForm[index].patchValue({ SetList: this.actDetailsForm[index].controls.SetList.value.replace(/\\n/g, '\n') });
      this.actDetailsForm[index].patchValue({ PreviousClients: this.actDetailsForm[index].controls.PreviousClients.value.replace(/\\n/g, '\n') });
      this.actDetailsForm[index].patchValue({ Rider: this.actDetailsForm[index].controls.Rider.value.replace(/\\n/g, '\n') });
    }
  }

  addTestimonial(index) {
    if (index != null && index >= 0) {
      let currentValue = this.actDetailsForm[index].controls?.TestimonialsForm?.value;
      currentValue.push({ ClientNames: '', Testimonial: '' } as TestimonialsForm);
      this.actDetailsForm[index].patchValue({ TestimonialsForm: [...currentValue] });
    }
  }

  removeTestimonial(testimonial, index) {
    if (testimonial && index != null && index >= 0)
      this.actDetailsForm[index].controls.TestimonialsForm.value
        .splice(this.actDetailsForm[index].controls.TestimonialsForm.value.indexOf(testimonial), 1)
  }
  //#endregion testimonial

  //#region act
  setValidatorsForms(index) {
    if (index != null && index >= 0) this.actDetailsForm[index]
      .controls.RealName.setValidators([RxwebValidators.required()]);
  }

  createFormAct(act: ArtistDetails, index: number) {
    this.actDetailsForm.push(this.formBuilder.formGroup(ArtistDetails, act));
    this.addLinkVideo(index);
    this.formatTestimonialForm(index);
    this.formatInnerHTML(index);
    this.setValidatorsForms(index);
    //this.eventStatusForm();
  }

  clickAddAct() {
    this.actDetailsForm.push(this.formBuilder.formGroup(ArtistDetails, Object.assign({})));
    var newForm = this.actDetailsForm[this.actDetailsForm.length - 1];
    if (newForm) {
      //this.validForm.emit(false);
      newForm.controls.RealName.setValidators([RxwebValidators.required()]);
      newForm.patchValue({
        VideoLinks: [], PhotoLinks: [], FilePhotos: [], TestimonialsForm: []
      })
      this.addLinkVideo(this.actDetailsForm.length - 1);
      this.formatTestimonialForm(this.actDetailsForm.length - 1);
      newForm.markAllAsTouched();
      //this.eventStatusForm();
    }
  }

  removeAct(actForm: FormGroup) {
    this.actDetailsForm.splice(this.actDetailsForm.indexOf(actForm), 1);
  }
  //#endregion act

  eventStatusForm() {
    this.actDetailsForm.forEach((item, index) => {
      if (item) {
        item.statusChanges.pipe(filter(() => this.actDetailsForm[index].valid))
          .subscribe(() => this.validForm.emit(true));

        item.statusChanges.pipe(filter(() => this.actDetailsForm[index].invalid))
          .subscribe(() => this.validForm.emit(false));
      }
    })
  }

  getActDetails(): ArtistDetails[] {
    if (this.actDetailsForm && this.actDetailsForm.length > 0) {
      let actModel: ArtistDetails[] = this.actDetailsForm.map(item => item.value);
      if (actModel && actModel.length > 0) {
        actModel.forEach(act => {
          if (act.TestimonialsForm && act.TestimonialsForm.length > 0) {
            var listIndex = [];
            act.TestimonialsForm.forEach((testimonial, index) => {
              if ((Helper.isNullOrEmpty(testimonial.ClientNames) || Helper.isEmptyOrSpaces(testimonial.ClientNames))
                && (Helper.isNullOrEmpty(testimonial.Testimonial) || Helper.isEmptyOrSpaces(testimonial.Testimonial)))
                listIndex.push(index);
            });

            act.TestimonialsForm = [...act.TestimonialsForm.filter((x, index) => !listIndex.includes(index))]
          }

          if (act.VideoLinks && act.VideoLinks.length > 0) {
            var listIndex = [];
            act.VideoLinks.forEach((video, index) => {
              if (Helper.isNullOrEmpty(video.Media) || Helper.isEmptyOrSpaces(video.Media))
                listIndex.push(index);
            });

            act.VideoLinks = [...act.VideoLinks.filter((x, index) => !listIndex.includes(index))]
          }
        });
      }

      actModel.map(item => item.Testimonials = JSON.stringify(item.TestimonialsForm) == '[]' ? '' : JSON.stringify(item.TestimonialsForm));
      return actModel;
    }
    return [];
  }
}
