export enum AttendanceStatus
{
    Nothing,
    ForgetPunchout,
    RequestAttendance,
}
export enum PunchinRequestStatus
{
    Nothing,
    PunchinApproved,
    PunchinRejected,
    PunchinWaiting
}
export enum PunchoutRequestStatus
{
    Nothing,
    PunchoutApproved,
    PunchoutRejected,
    PunchoutWaiting
}