import { element } from 'protractor';
import { RealtimeNotification } from './../../../shared/components/realtime-notification/responseRealtimeNotification.model';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { SupportTicket } from './../../../modules/admin/support-ticket-management/support-ticket.model';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuItem, NbMenuService, NbSidebarService, NbThemeService, NbToastrService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, mergeMap, takeLast, takeUntil } from 'rxjs/operators';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { NbTokenService, NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { ActivityLog } from 'src/app/modules/admin/campaign-management/campaign-tracking.model';
import { ActivityLogActionsType } from 'src/app/shared/contances/activity-log-actions';
import { TasksNotification } from './mockup-notifications';
import { TaskBoardService } from 'src/app/modules/admin/task-management/task-board/task-board.service';
import { Priority, TaskStatus, TaskType } from 'src/app/modules/admin/task-management/task-board/task-board-lane/task-status.model';
import { TaskPropertyName } from 'src/app/shared/contances/task-filter';
import { TasksNotificationService } from './tasks-notification.service';
import { EditInlineTaskComponent } from 'src/app/modules/admin/task-management/edit-inline-task/edit-inline-task.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TblActionType } from 'src/app/shared/enums/tbl-action-type.enum';
import { Task } from 'src/app/modules/admin/task-management/task-board/task-board-lane/task/task.model';
import dateFormat, { masks } from "dateformat";
import { AddEditUserComponent } from 'src/app/modules/admin/user-management/add-edit-user/add-edit-user.component';
import { UserService } from 'src/app/modules/admin/user-management/user.service';
import { UserModel } from 'src/app/modules/admin/user-management/user-model';
import { DomSanitizer } from '@angular/platform-browser';
import { SettingService } from 'src/app/shared/services/setting.service';
import { SettingCompanyViewModel } from 'src/app/modules/admin/setting-management/setting-company/setting-company';
import { ChangeLogService } from 'src/app/shared/services/change-log.service';
import { ChangeLog } from 'src/app/shared/models/request/change-log.model';
import { ChangeLogComponent } from 'src/app/shared/components/stand-alone-component/change-log/change-log.component';
import { Setting } from 'src/app/shared/models/setting.model';
import { isBuffer } from 'util';
import { SupportTicketManagementComponent } from 'src/app/modules/admin/support-ticket-management/support-ticket-management.component';
import { CallEventService } from 'src/app/modules/admin/call-event-management/call-event-service.service';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Helper } from 'src/app/shared/utility/Helper';
import { RealtimeNotificationDTO } from 'src/app/shared/components/realtime-notification/responseRealtimeNotification.model';
import { Page } from 'src/app/shared/models/paging/page';
import { MatMenuTrigger } from '@angular/material/menu';
import { AdminTabModeService } from 'src/app/shared/components/stand-alone-component/admin-tab-mode/admin-tab-mode.service';
import { UserTokenManagementService } from 'src/app/shared/services/user-token-management.service';
import { SettingPoolService } from 'src/app/shared/services/setting-pool.service';
import { SharedEventService } from 'src/app/modules/home/chat-box-ui/shared-event.service';
import { HotToastService } from '@ngneat/hot-toast';
import { RealtimeNotificationService } from 'src/app/shared/components/realtime-notification/realtime-notification.service';
import { UserStatusService } from 'src/app/shared/services/user-status.service';
import { UserStatusEnums } from 'src/app/shared/enums/user-status.enum';
import { environment } from 'src/environments/environment';
import { AddEditLeaveRequestComponent } from 'src/app/modules/admin/leave-request-management/add-edit-leave-request/add-edit-leave-request.component';
import { LeaveRequestManagementService } from 'src/app/modules/admin/leave-request-management/leave-request-management.service';
import { AttendanceManagementService } from 'src/app/modules/admin/attendance-management/attendance-management.service';
import { AttendanceModel } from 'src/app/modules/admin/attendance-management/attendance-management.model';
import { AttendanceDialogComponent } from 'src/app/modules/admin/attendance-management/attendance-dialog/attendance-dialog.component';
import { AddEditAttendanceComponent } from 'src/app/modules/admin/attendance-management/add-edit-attendance/add-edit-attendance.component';
import { AddEditAttendanceRequestComponent } from 'src/app/modules/admin/attendance-management/attendance-request-management/add-edit-attendance-request/add-edit-attendance-request.component';
import { HeaderRefreshService } from 'src/app/shared/services/refresh-header.service';
import { DailyReportService } from 'src/app/shared/services/daily-report.service';
import { is } from 'date-fns/locale';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        height: '30px',
        fontSize: '0.9375rem',
        opacity: 1,
      })),
      state('closed', style({
        height: '0px',
        fontSize: '0px',
        opacity: 1,
      })),
      transition('open => closed', [
        animate('0.5s')
      ]),
      transition('closed => open', [
        animate('0.25s')
      ]),
      transition('* => closed', [
        animate('0.5s')
      ]),
      transition('* => open', [
        animate('0.25s')
      ]),
      transition('open <=> closed', [
        animate('0.25s')
      ]),
      transition('* => open', [
        animate('0.5s',
          style({ opacity: '*' }),
        ),
      ]),
      transition('* => *', [
        animate('0.5s')
      ]),
    ]),
  ],
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() tabMode: boolean = false;
  @ViewChild('matMenuTrigger') trigger: MatMenuTrigger;
  @ViewChildren('elRefNotifications') set contentNotifications(contents: QueryList<ElementRef>) {
    if (contents && contents.length > 0) {
      var elems = contents.map(x => x.nativeElement);
      if (elems && elems.length > 0) {
        elems.forEach(x => {
          x.style.display = 'block';
          var height = x.offsetHeight;
          if (height > 69)
            x.getElementsByTagName('label')[0].style.display = 'inline-block';
          x.style.display = '-webkit-box';
        })
      }
    }
  }
  @ViewChild('messageToast', { static: true }) messageToast: TemplateRef<void>;

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly = false;
  isAllNotification: boolean = true;
  user: any;
  title = 'Altus';
  systemNotification: string;
  // count real time notification unread
  numbersOfNotification = 0;
  //2021-29-12 HMTien add start
  taskStatusLst: TaskStatus[] = [];
  taskPropertyName = TaskPropertyName;
  taskTypeNames: TaskType[] = [];
  prioritiesLst: Priority[] = [];
  tasksNotification: TasksNotification[] = [];
  backupNotification: TasksNotification[] = [];
  addEditComponent = EditInlineTaskComponent;
  dialogRef: MatDialogRef<EditInlineTaskComponent, any>;
  //2021-29-12 HMTien add end
  //2022-7th-03 HMTien add start
  maxNotiPerChunks: number = 10;
  maxNoti: number;
  throttle = 0;
  scrollDistance = 1;
  infiniteScrollUpDistance = 1.5
  //2022-7th-03 HMTien add end
  // 2022-01-06 gnguyen start add
  userModel: UserModel;
  isSwitchUserMode: boolean = false;
  orgInfo: string;
  loginAsInfo: string;
  versionCode: string;
  // 2022-01-06 gnguyen end add
  userMenu = [

    { title: 'Profile', id: 'profile' },
    { title: 'Log out', id: 'logout' }
  ];
  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'material-light';
  picturelUrlSafe = null;
  currentPicture = null;
  menuServiceObservable: Subscription = null;
  configCompany: SettingCompanyViewModel = new SettingCompanyViewModel();
  changelogs: ChangeLog[] = [];
  changelogLoading = false;
  showSupportTicket: string;
  isStatusCalling = null;
  isNotiStatePhoneCall = false;
  timeOutNotiStatePhoneCall = null;
  isEnableChangeStatePhoneCall = false;
  attendances: AttendanceModel[] = [];

  //2022-09-28 ducqm start add
  realtimeData: RealtimeNotificationDTO =
    {
      realtimeNotificationId: 1,
      title: 'a',
      body: 'a',
      type: 'success',
      userId: '1b3444ba-74b0-4721-b4a7-6ca7c99919ed',
      referenceId: null,
      referenceType: null,
      dateCreated: null,
      isClicked: false
    }
  realtimeNotifications: RealtimeNotificationDTO[] = [];
  realtimeNotificationExpandBtn: string[];
  markAllAsRead: string[];
  currentPage: number = 0;
  totalElement: number = 0;
  oldRealtimeNotificationLength: number = 0;
  loading: boolean = false;
  skeletonLoading: boolean = false;
  callRedirect: boolean = true;
  page: NotificationPage = {
    pageNumber: 0,
    size: 5,
    totalPages: 0,
    viewId: null,
    totalElements: 0,
    isUnread: false,
  };
  isBlinkingNotify: Boolean = false;
  statusBadge: StatusBadge = new StatusBadge();
  //2022-09-28 ducqm end add
  isChatReceiveNewMessage: boolean = false;

  // 2023-03-31 vunh add start
  originalTitle: string = document.title;
  notificationSoundPath: string = null;
  // interval blinking the title when receive new message
  intervalTitle: NodeJS.Timeout = null;
  // 2023-03-31 vunh add end

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private authService: NbAuthService,
    private layoutService: LayoutService,
    private tokenService: NbTokenService,
    private router: Router,
    private dialog: MatDialog,
    private userServiceBus: UserService,
    public sanitizer: DomSanitizer,
    private breakpointService: NbMediaBreakpointsService,
    // 2021-30-12 HMTien add start
    private tasksNotificationService: TasksNotificationService,
    private activatedRoute: ActivatedRoute,
    // 2021-30-12 HMTien add end
    private settingService: SettingService,
    private settingPoolService: SettingPoolService,
    private changelogService: ChangeLogService,
    private localStorage: LocalStorageService,
    private callEventService: CallEventService,
    private toast: NbToastrService,
    private _changeDetectorRef: ChangeDetectorRef,
    private tabModeService: AdminTabModeService,
    //vunh 2023-01-18 vunh add start
    private userTokenService: UserTokenManagementService,
    //vunh 2023-01-18 vunh add end
    public chatShareService: SharedEventService,
    private notificationService: RealtimeNotificationService,

    //2023-03-31 vunh add start
    private hotToastService: HotToastService,
     //2023-03-31 vunh add end
    private userStatusService: UserStatusService,
    private leaveRequestService: LeaveRequestManagementService,
    private attendanceService: AttendanceManagementService,
    private headerRefreshService: HeaderRefreshService,
    private dailyReportService: DailyReportService,
  ) {
    this.statusBadge.setStatus(UserStatusEnums.Busy);
    this.userServiceBus.pictureURLChange.subscribe((value) => {
      this.currentPicture = value;
    });
    // this.tasksNotificationService.getTasksNotificationList().subscribe(resp => {
    //   this.tasksNotification = resp;
    //   this.convertNotificationToMenuList();
    // });


    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.user = token.getPayload();
          // this.picturelUrlSafe = this.user.pictureURL?.replaceAll('\\', '/');
          this.userServiceBus.getUserById(this.user.nameid).subscribe((resp) => {
            if (resp.result != null) {
              this.userModel = resp.result;
              this.picturelUrlSafe = this.userModel.pictureURL?.replaceAll('\\', '/');
            }

          });
        }
      });
    // 2022-01-05 HMTien add start

    // this.settingService.getConfigCompany().subscribe(res => {
    //   if (res) this.configCompany = res;
    // });

    this.checkEnabledFeatureChangeStatePCall();
    this.tasksNotificationService.countRealtimeNotification(this.user.nameid).subscribe(resp => {
      if (resp.result) {
        this.numbersOfNotification = resp.result;
        window.localStorage.setItem('NumberOfNotificationUnread', `${this.numbersOfNotification}`)
      }
    })

    this.settingService.getSettingByKeyAndGroup("CHAT_NOTIFICATION_SOUND", "MESSAGE").subscribe(resp => {
      this.notificationSoundPath = resp?.result?.value;
    })

    let message: string = null
    this.chatShareService.newNotify.pipe(takeUntil(this.destroy$), mergeMap((value) => {
      if (value != null) {
        this.isChatReceiveNewMessage = !Helper.isNullOrEmpty(value);

        if (!Helper.isNullOrEmpty(value)) {
          message = value?.message;
          return this.userServiceBus.getUserById(value?.userId);
        }
      }
    })).pipe(takeUntil(this.destroy$)).subscribe(resp => {
        if (resp?.result) {
          this.blinkTitle(`${resp.result?.userName} messaged you`);
          this.playSound();
          this.hotToastService.show(this.messageToast, {
            data: {
              userName: resp.result?.userName,
              pictureURL: resp.result?.pictureURL,
              message: message
            },
          })
        }
    });
    this.headerRefreshService.onRefresh().subscribe(() => {
      this.ngOnInit();
    });
  }

  // 2023-03-31 vunh add start
  blinkTitle(newTitle) {
    const titles = [this.originalTitle, newTitle];
    let currentIndex = 0;

    clearInterval(this.intervalTitle)
    this.intervalTitle = setInterval(() => {
      document.title = titles[currentIndex];
      currentIndex = (currentIndex + 1) % 2;
    }, 1000);
  }
  stopBlinkTitle() {
    document.title = this.originalTitle;
    clearInterval(this.intervalTitle);
  }
  playSound() {
    const audio = new Audio();
    audio.src = this.notificationSoundPath;
    audio.play()
  }
  // 2023-03-31 vunh add end

  handleChatIconClick() {
    this.chatShareService.openChatIframe.emit()
    this.isChatReceiveNewMessage = false;
    this.stopBlinkTitle();
  }
  // listNotification: ActivityLog[] = mockupNotification;
  notificationMenu = [];
  convertNotificationToMenuList() {
    // 2021-30-12 HMTien add start
    const items = [];
    if (this.tasksNotification && this.tasksNotification.length > 0) {
      this.tasksNotification.forEach(act => {
        // get task type name
        this.taskTypeNames.forEach(item => {
          if (item.taskTypeId === act.tasksDetail.taskTypeId) {
            act.tasksDetail.taskTypeName = item.taskTypeName;
          }
        });
        switch (act.activityLogDetail.action) {
          case ActivityLogActionsType.Add:
            {
              items.push({
                firstPart: `${this.user?.userName}`,
                connector: ` has been assigned to `,
                secondPart: `${act.tasksDetail.taskTypeName}-${act.activityLogDetail.objId} - ${act.tasksDetail.taskName}`,
                thirdPart: null,
                fourthPart: null,
                fifthPart: null,
                sixthPart: null,
                icon: 'plus-circle-outline',
                notificationId: act.notificationId,
                task: act.tasksDetail,
                isSeen: act.isSeen,
                dateCreated: dateFormat(act.dateCreated, 'mm-dd-yyyy hh:mm')
              });
            }
            break;
          case ActivityLogActionsType.Edit:
            {
              if (act.activityLogDetail.propertyName === 'TaskPriority') {
                items.push({
                  firstPart: `${this.user?.userName}`,
                  connector: ` edit the task priority of `,
                  secondPart: `${act.tasksDetail.taskTypeName}-${act.activityLogDetail.objId} - ${act.tasksDetail.taskName} `,
                  thirdPart: `From: `,
                  fourthPart: `${this.prioritiesLst.find(x => x.priorityId == parseInt(act.activityLogDetail.logInfo1))?.priorityName ?? 'Unknown'}`,
                  fifthPart: `To: `,
                  sixthPart: `${this.prioritiesLst.find(x => x.priorityId == parseInt(act.activityLogDetail.logInfo2))?.priorityName ?? 'Unknown'}`,
                  icon: 'plus-circle-outline',
                  notificationId: act.notificationId,
                  task: act.tasksDetail,
                  isSeen: act.isSeen,
                  dateCreated: dateFormat(act.dateCreated, 'mm-dd-yyyy hh:mm')

                });
                break;
              }
              if (act.activityLogDetail.propertyName === 'TaskTypeId') {
                items.push({
                  firstPart: `${this.user?.userName}`,
                  connector: ` edit task type of `,
                  secondPart: `${act.tasksDetail.taskTypeName}-${act.activityLogDetail.objId} - ${act.tasksDetail.taskName}`,
                  thirdPart: `From: `,
                  fourthPart: `${this.taskTypeNames.find(x => x.taskTypeId == parseInt(act.activityLogDetail.logInfo1))?.taskTypeName ?? 'Unknown'}`,
                  fifthPart: `To: `,
                  sixthPart: `${this.taskTypeNames.find(x => x.taskTypeId == parseInt(act.activityLogDetail.logInfo2))?.taskTypeName ?? 'Unknown'}`,
                  icon: `edit-outline`,
                  notificationId: act.notificationId,
                  task: act.tasksDetail,
                  isSeen: act.isSeen,
                  dateCreated: dateFormat(act.dateCreated, 'mm-dd-yyyy hh:mm')
                });
                break;
              }
              if (act.activityLogDetail.propertyName === 'TaskName') {
                items.push({
                  firstPart: `${this.user?.userName}`,
                  connector: ` edit task name of `,
                  secondPart: `${act.tasksDetail.taskTypeName}-${act.activityLogDetail.objId} - ${act.tasksDetail.taskName}`,
                  thirdPart: `From: `,
                  fourthPart: `${act.activityLogDetail.logInfo1}`,
                  fifthPart: `To: `,
                  sixthPart: `${act.activityLogDetail.logInfo2}`,
                  icon: 'edit-outline',
                  notificationId: act.notificationId,
                  task: act.tasksDetail,
                  isSeen: act.isSeen,
                  dateCreated: dateFormat(act.dateCreated, 'mm-dd-yyyy hh:mm')

                });
                break;
              }
              if (act.activityLogDetail.propertyName === 'TaskStatusId') {
                items.push({
                  firstPart: `${this.user?.userName}`,
                  connector: ` edit task status of `,
                  secondPart: `${act.tasksDetail.taskTypeName}-${act.activityLogDetail.objId} - ${act.tasksDetail.taskName} `,
                  thirdPart: `From: `,
                  fourthPart: `${this.taskStatusLst.find(x => x.taskStatusId == parseInt(act.activityLogDetail.logInfo1))?.taskStatusName ?? 'Unknown'}`,
                  fifthPart: `To: `,
                  sixthPart: `${this.taskStatusLst.find(x => x.taskStatusId == parseInt(act.activityLogDetail.logInfo2))?.taskStatusName ?? 'Unknown'}`,
                  icon: 'plus-circle-outline',
                  notificationId: act.notificationId,
                  task: act.tasksDetail,
                  isSeen: act.isSeen,
                  dateCreated: dateFormat(act.dateCreated, 'mm-dd-yyyy hh:mm')

                });
                break;
              }
              if (act.activityLogDetail.propertyName === 'AssigneeTo') {
                items.push({
                  firstPart: `${this.user?.userName}`,
                  connector: ` has been assigned to `,
                  secondPart: `${act.tasksDetail.taskTypeName}-${act.activityLogDetail.objId} - ${act.tasksDetail.taskName}`,
                  thirdPart: null,
                  fourthPart: null,
                  fifthPart: null,
                  sixthPart: null,
                  icon: `edit-outline`,
                  notificationId: act.notificationId,
                  task: act.tasksDetail,
                  isSeen: act.isSeen,
                  dateCreated: dateFormat(act.dateCreated, 'mm-dd-yyyy hh:mm')
                });
                break;
              }
              if (act.activityLogDetail.propertyName === 'ResolutionCodeId') {
                items.push({
                  firstPart: `${this.user?.userName}`,
                  connector: ` edit resolution code of `,
                  secondPart: `${act.tasksDetail.taskTypeName}-${act.activityLogDetail.objId} - ${act.tasksDetail.taskName}`,
                  thirdPart: `From: `,
                  fourthPart: `${act.activityLogDetail.logInfo1}`,
                  fifthPart: `To: `,
                  sixthPart: `${act.activityLogDetail.logInfo2}`,
                  icon: `edit-outline`,
                  notificationId: act.notificationId,
                  task: act.tasksDetail,
                  isSeen: act.isSeen,
                  dateCreated: dateFormat(act.dateCreated, 'mm-dd-yyyy hh:mm')
                });
                break;
              }
              if (act.activityLogDetail.propertyName === 'Resolution') {
                items.push({
                  firstPart: `${this.user?.userName}`,
                  connector: ` edit resolution of `,
                  secondPart: `${act.tasksDetail.taskTypeName}-${act.activityLogDetail.objId} - ${act.tasksDetail.taskName}`,
                  thirdPart: null,
                  fourthPart: null,
                  fifthPart: null,
                  sixthPart: null,
                  icon: `edit-outline`,
                  notificationId: act.notificationId,
                  task: act.tasksDetail,
                  isSeen: act.isSeen,
                  dateCreated: dateFormat(act.dateCreated, 'mm-dd-yyyy hh:mm')
                });
                break;
              }
              if (act.activityLogDetail.propertyName === 'TaskStartDate') {
                items.push({
                  firstPart: `${this.user?.userName}`,
                  connector: ` edit task start date of `,
                  secondPart: `${act.tasksDetail.taskTypeName}-${act.activityLogDetail.objId} - ${act.tasksDetail.taskName}`,
                  thirdPart: `From: `,
                  fourthPart: `${dateFormat(act.activityLogDetail.logInfo1, 'mm-dd-yyyy hh:mm')}`,
                  fifthPart: `To: `,
                  sixthPart: `${dateFormat(act.activityLogDetail.logInfo2, 'mm-dd-yyyy hh:mm')}`,
                  icon: `edit-outline`,
                  notificationId: act.notificationId,
                  task: act.tasksDetail,
                  isSeen: act.isSeen,
                  dateCreated: dateFormat(act.dateCreated, 'mm-dd-yyyy hh:mm')
                });
                break;
              }
              if (act.activityLogDetail.propertyName === 'TaskEndDate') {
                items.push({
                  firstPart: `${this.user?.userName}`,
                  connector: ` edit task end date of `,
                  secondPart: `${act.tasksDetail.taskTypeName}-${act.activityLogDetail.objId} - ${act.tasksDetail.taskName}`,
                  thirdPart: `From: `,
                  fourthPart: `${dateFormat(act.activityLogDetail.logInfo1, 'mm-dd-yyyy hh:mm')}`,
                  fifthPart: `To: `,
                  sixthPart: `${dateFormat(act.activityLogDetail.logInfo2, 'mm-dd-yyyy hh:mm')}`,
                  icon: `edit-outline`,
                  notificationId: act.notificationId,
                  task: act.tasksDetail,
                  isSeen: act.isSeen,
                  dateCreated: dateFormat(act.dateCreated, 'mm-dd-yyyy hh:mm')
                });
                break;
              }
              if (act.activityLogDetail.propertyName === 'RelationshipId') {
                items.push({
                  firstPart: `${this.user?.userName}`,
                  connector: ` edit relationshipId of `,
                  secondPart: `${act.tasksDetail.taskTypeName}-${act.activityLogDetail.objId} - ${act.tasksDetail.taskName}`,
                  thirdPart: `From: `,
                  fourthPart: `${act.activityLogDetail.logInfo1}`,
                  fifthPart: `To: `,
                  sixthPart: `${act.activityLogDetail.logInfo2}`,
                  icon: `edit-outline`,
                  notificationId: act.notificationId,
                  task: act.tasksDetail,
                  isSeen: act.isSeen,
                  dateCreated: dateFormat(act.dateCreated, 'mm-dd-yyyy hh:mm')
                });
                break;
              }
              if (act.activityLogDetail.propertyName === 'RelationshipType') {
                items.push({
                  firstPart: `${this.user?.userName}`,
                  connector: ` edit relationship type of `,
                  secondPart: `${act.tasksDetail.taskTypeName}-${act.activityLogDetail.objId} - ${act.tasksDetail.taskName}`,
                  thirdPart: `From: `,
                  fourthPart: `${act.activityLogDetail.logInfo1}`,
                  fifthPart: `To: `,
                  sixthPart: `${act.activityLogDetail.logInfo2}`,
                  icon: `edit-outline`,
                  notificationId: act.notificationId,
                  task: act.tasksDetail,
                  isSeen: act.isSeen,
                  dateCreated: dateFormat(act.dateCreated, 'mm-dd-yyyy hh:mm')
                });
                break;
              }
              if (act.activityLogDetail.propertyName === 'TaskDescription') {
                items.push({
                  firstPart: `${this.user?.userName}`,
                  connector: ` edit task description of `,
                  secondPart: `${act.tasksDetail.taskTypeName}-${act.activityLogDetail.objId} - ${act.tasksDetail.taskName}`,
                  thirdPart: null,
                  fourthPart: null,
                  fifthPart: null,
                  sixthPart: null,
                  icon: `edit-outline`,
                  notificationId: act.notificationId,
                  task: act.tasksDetail,
                  isSeen: act.isSeen,
                  dateCreated: dateFormat(act.dateCreated, 'mm-dd-yyyy hh:mm')
                });
                break;
              }

              if (act.activityLogDetail.propertyName !== 'AssigneeTo') {
                items.push({
                  firstPart: `${act.tasksDetail.taskTypeName}-${act.activityLogDetail.objId}`,
                  connector: ` has been changed `,
                  secondPart: `${act.activityLogDetail.propertyName} by `,
                  thirdPart: `${act.activityLogDetail.userName}`,
                  fourthPart: null,
                  fifthPart: null,
                  sixthPart: null,
                  icon: `edit-outline`,
                  notificationId: act.notificationId,
                  task: act.tasksDetail,
                  isSeen: act.isSeen,
                  dateCreated: dateFormat(act.dateCreated, 'mm-dd-yyyy hh:mm')
                });
              } else {
                items.push({
                  firstPart: `${this.user?.userName}`,
                  connector: ` has been assigned to `,
                  secondPart: `${act.tasksDetail.taskTypeName}-${act.activityLogDetail.objId}`,
                  thirdPart: null,
                  fourthPart: null,
                  fifthPart: null,
                  sixthPart: null,
                  icon: `edit-outline`,
                  notificationId: act.notificationId,
                  task: act.tasksDetail,
                  isSeen: act.isSeen,
                  dateCreated: dateFormat(act.dateCreated, 'mm-dd-yyyy')
                });
              }
            }
            break;
          default:
            break;
        }
        this.notificationMenu = [];
        this.notificationMenu = items;
      });
    }
  }

  ngOnInit() {
    // 2021-30-12 HMTien add start
    this.changelogLoading = true;
    // this.activatedRoute.data.subscribe(data => {
    //   //this.tasksNotification = data.notification.result;
    //   this.prioritiesLst = data.priority.result;
    //   this.taskStatusLst = data.taskStatus.result;
    //   this.taskTypeNames = data.taskType.result;
    // });
    this.maxNoti = this.maxNotiPerChunks;
    //this.convertNotificationToMenuList();
    let orgToken = localStorage.getItem("org_token");
    if (orgToken) {
      this.isSwitchUserMode = true;
    }
    let orgInfo = localStorage.getItem("org_info");
    if (orgInfo) {
      this.orgInfo = orgInfo;
    }
    this.authService.onTokenChange().subscribe(e => {
      let payLoad = e.getPayload();
      if (payLoad) this.loginAsInfo = payLoad.userName;
    })
    // add end
    this.menuServiceObservable = this.menuService.onItemClick().subscribe((event) => {
      // tslint:disable-next-line: no-string-literal
      if (event.item['id'] === 'logout') {
        this.userStatusService.isLogout = true;
        this.userStatusService.setUserActive = UserStatusEnums.Offline;
        //vunh 2023-01-18 vunh add start
        this.userTokenService.removeToken().subscribe();
        //vunh 2023-01-18 vunh add end

        this.tokenService.clear();
        //clean org_token
        let org_token = localStorage.getItem("org_token");
        if (org_token)
          localStorage.removeItem("org_token");
        localStorage.removeItem("org_info");

        //clean all local storage
        localStorage.clear();
        this.router.navigate(['/auth']).then(() => {
        });
      } else if (event.item['id'] === 'profile') {
        this.userServiceBus.getUserById(this.user.nameid).subscribe((resp) => {
          if (resp.result != null) {
            const dialogRef = this.dialog.open(AddEditUserComponent, {
              disableClose: true,
              height: '100vh',
              width: '600px',
              panelClass: 'dialog-detail',
              autoFocus: false,
              data: {
                action: TblActionType.Profile,
                model: resp.result
              }
            });
            dialogRef.afterClosed().subscribe(response => {
              this.userServiceBus.getUserById(this.user.nameid).subscribe((resp) => {
                if (resp.result != null) {
                  this.userModel = resp.result;
                  if (!Helper.isEmptyOrSpaces(this.userModel.phoneNumber) && !Helper.isNullOrEmpty(this.userModel.phoneNumber)
                    && !Helper.isEmptyOrSpaces(this.userModel.outboundCallerId) && !Helper.isNullOrEmpty(this.userModel.outboundCallerId))
                    this.checkEnabledFeatureChangeStatePCall();
                  else this.isEnableChangeStatePhoneCall = false
                }

              }).add(() => {
                this.userServiceBus.modelChange.next();
              })
            });
          }

        })
      }

    });

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
    this.changelogService.getChangeLogsByUserId().subscribe(resp => {
      if (resp.result) {
        this.changelogs = resp.result;
        this.changelogLoading = false;
        // first show:
        if (this.changelogs && this.changelogs.length > 0) {
          this.showchangelogsDialog();
        }
      }
    });

    //2022-02-21 toanpq add logic testing noti
    // 2023-02-01 ducqm start add
    this.settingService.getNotificationByMode('MODE').pipe(takeUntil(this.destroy$)).subscribe(resp => {
      if (resp.result) {
        if (resp.result.value) {
          this.systemNotification = resp.result.value;
        }
      }
    });
    //2022-02-21 toanpq add logic testing noti - end

    this.getShowSupportTicketSetting();
    // this.settingService.getSettingByKeyAndGroup("BOF_IMG_SOURCE", "SYSTEM").subscribe(resp => {
    //   if (resp.result) window.localStorage.setItem(resp.result.key, btoa(resp.result.value));
    // });
    this.settingPoolService.getSettingByKeyAndGroup("BOF_IMG_SOURCE", "SYSTEM").subscribe(resp => {
      if (resp.result) window.localStorage.setItem(resp.result.key, btoa(resp.result.value));
    });

    // 2023-02-01 ducqm end add
    //get sersionCode setting
    this.getVersionCode();

    // set status badge:
    this.userStatusService.uniqueStatusChange.subscribe(res => {
      if (res != null) {
        this.statusBadge.setStatus(res);
      }
    })

    this.dailyReportService.isForgotReport(this.user?.nameid).pipe(takeUntil(this.destroy$)).subscribe(resp => {
      if (resp.result) {
        this.openDailyReport(true);
      }
    });

    // 2023-11-1 luongna start add
    // open punch in or punch out
    this.attendanceService.getTodayAttendanceByProfileId(this.user?.nameid, -new Date().getTimezoneOffset()/60).pipe(takeUntil(this.destroy$)).subscribe(resp => {
      if (resp.result) {
        this.attendances = resp.result;
      }
      this.showAttendanceDialog();
    });
    // 2023-11-1 luongna end add
  }

  ngOnDestroy() {
    if (this.menuServiceObservable != null) {
      this.menuServiceObservable.unsubscribe();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  openTaskDetail(task: Task) {
    console.log(this.router.url.search('/configuration/task'))
    if (this.router.url.search('/configuration/task') >= 0) {
      if (this.addEditComponent) {
        this.dialogRef = this.dialog.open(this.addEditComponent, {
          disableClose: true,
          height: '100vh',
          width: '1200px',
          panelClass: 'dialog-detail',
          autoFocus: false,
          data: {
            action: TblActionType.Edit,
            model: {
              task: task,
              //filterList: null,
              //isDisplayAsList: false
              //index: this.listTask.findIndex(x => x == this.task),
              //listTask: this.listTask,
            }
          }
        });
      }
    } else {
      this.router.navigate([`/configuration/task`], { queryParams: { action: TblActionType.Edit, taskId: task.taskId } });
    }

  }
  // 2021-12-31 HMTien add start
  openTask(task: Task, notification: TasksNotification): void {
    // check is Read
    if (!notification.isSeen) {
      this.tasksNotificationService.readNotification(notification.notificationId).subscribe(resp => {
        if (resp.result) {
          //   this.tasksNotificationService.getTaskNotification();
        }
      });
    }
    this.openTaskDetail(task);
  }
  // showDotNotification(taskNoti: TasksNotification[]) {
  //   if (taskNoti) {
  //     return taskNoti?.filter(x => !x.isSeen)?.length > 0 ? true : false;
  //   } else {
  //     return false;
  //   }
  // }
  // 2022-03-01 tienlm add start
  showchangelogsDialog() {
    let changelogRef = this.dialog.open(ChangeLogComponent, {
      disableClose: true,
      // maxHeight: '60vh',
      width: '600px',
      // panelClass: 'dialog-detail',
      autoFocus: false,
      data: {
        changelogs: this.changelogs
      }
    });
    changelogRef.afterClosed().subscribe(res => {
      if (res) {
        this.changelogService.markReadChangeLog(this.changelogs).subscribe(resp => {
          if (resp.result) {
            this.changelogLoading = true;
            this.changelogService.getChangeLogsByUserId().subscribe(resp => {
              if (resp.result) {
                this.changelogs = resp.result;
                this.changelogLoading = false;
              }
            })
          }
        })
      }
    })
  }

  // 2023-11-1 luongna add start
  async showAttendanceDialog(useIcon: boolean = false) {
    if (useIcon) {
      var listAttendance = await this.attendanceService.getTodayAttendanceByProfileId(this.user?.nameid, -new Date().getTimezoneOffset()/60).pipe(takeUntil(this.destroy$)).toPromise();
      if (listAttendance.result) {
        this.attendances = listAttendance.result;
      }
    }
    var toDay = new Date();
    var listLeaveRequest = await this.leaveRequestService.getToDayLeaveRequestByProfileId(this.user?.nameid).pipe(takeUntil(this.destroy$)).toPromise();
    if ((!listLeaveRequest.result && toDay.getHours() >= 8 && toDay.getDay() !== 0 && toDay.getDay() !== 6) || useIcon) {
      if (this.attendances.length > 0) {
        var findAttendanceHaveNotPunchOut = this.attendances?.find(attendance => !attendance?.punchoutDate);
        var getFirstPunchInToDay = this.attendances?.find(attendance => {
          var punchinDate = new Date(attendance?.punchinDate);
          if (punchinDate.getDate() === toDay.getDate() && 
              punchinDate.getMonth() === toDay.getMonth() && 
              punchinDate.getFullYear() === toDay.getFullYear()) {
            return attendance;
          }
        });
        if (findAttendanceHaveNotPunchOut) {
          var punchInDate = new Date(getFirstPunchInToDay?.punchinDate ? getFirstPunchInToDay?.punchinDate : findAttendanceHaveNotPunchOut?.punchinDate);
          var difference = Math.abs(toDay.getTime() - punchInDate.getTime()) / 3600000;
          if (difference >= 9.50 || useIcon) {
            let attendanceRef = this.dialog.open(AttendanceDialogComponent, {
              disableClose: true,
              width: '600px',
              autoFocus: false,
              data: {
                attendance: findAttendanceHaveNotPunchOut,
                user: this.user,
              }
            });
          }
        } else {
          if (getFirstPunchInToDay) {
            var punchInDate = new Date(getFirstPunchInToDay?.punchinDate);
            var difference = Math.abs(toDay.getTime() - punchInDate.getTime()) / 3600000;
            if (difference < 9.50  || useIcon) {
              let attendanceRef = this.dialog.open(AttendanceDialogComponent, {
                disableClose: true,
                width: '600px',
                autoFocus: false,
                data: {
                  user: this.user,
                }
              });
            }
          } else {
            let attendanceRef = this.dialog.open(AttendanceDialogComponent, {
              disableClose: true,
              width: '600px',
              autoFocus: false,
              data: {
                user: this.user,
              }
            });
          }
        }
      } else {
        let attendanceRef = this.dialog.open(AttendanceDialogComponent, {
          disableClose: true,
          width: '600px',
          autoFocus: false,
          data: {
            user: this.user,
          }
        });
      }
    }
  }
  // 2023-11-1 luongna add end

  // 2024-22-2 luongna add start
  async showRequestAttendance(attendanceId: string) {
    const respAttendance = await this.attendanceService.getAttendanceById(Number(attendanceId)).pipe(takeUntil(this.destroy$)).toPromise();
    if (respAttendance.result) {
      let attendanceRef = this.dialog.open(AddEditAttendanceRequestComponent, {
        disableClose: true,
        height: '100vh',
        width: '600px',
        panelClass: 'dialog-detail',
        autoFocus: false,
        data: {
          model: respAttendance.result,
          action: TblActionType.Edit
        }
      });
    }
  }
  // 2024-22-2 luongna add end

  // 2022-03-07 hmtien add start
  seeMore($event: any) {
    this.maxNoti += this.maxNotiPerChunks;
    $event.stopPropagation();
    //Another instructions

  }
  // 2022-03-07 hmtien add end
  backToOrgUser() {
    this.userStatusService.setUserActive = UserStatusEnums.Offline;
    const orgToken = localStorage.getItem("org_token");
    const orgAccessToken = localStorage.getItem("org_accessToken");

    if (orgToken) {
      localStorage.removeItem("org_token");
      localStorage.removeItem("org_accessToken");

      localStorage.removeItem("org_info");
      let nbAuth = JSON.parse(localStorage.getItem("auth_app_token"));
      nbAuth.value = orgToken;
      localStorage.setItem("auth_app_token", JSON.stringify(nbAuth));
      localStorage.setItem("accessToken", orgAccessToken);

      this.authService.getToken().subscribe(e => {
        let defaultScreen = e.getPayload()['defaultScreen'] ?? '/';
        this.router.navigate([defaultScreen]).then(() => {
          window.location.reload();
        });
      })
    }
  }
  onClickSupportAgentTicket() {
    this.dialog.open(SupportTicketManagementComponent, {
      disableClose: true,
      height: '95vh',
      width: '90vw',
      autoFocus: false,
      data: {
        isShowInAdmin: false
      }
    })
  }
  getShowSupportTicketSetting() {
    // this.localStorage.showSupportTicketIcon().subscribe(e => {
    //   this.showSupportTicket = e;
    // })
    this.showSupportTicket = 'true';
  }

  updateStatusCall(event) {
    if (event) {
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        data: {
          message: `${!this.isStatusCalling
            ? `Do you wish to enable receiving the callback on your cellphone?`
            : `Do you wish to disable receiving the callback on your cellphone?`}`
        }
      });

      dialogRef.afterClosed().subscribe(response => {
        var oldState = this.isStatusCalling;
        this.isStatusCalling = null;
        if (response) {
          this.callEventService.changeStatePhoneNumber().subscribe(resp => {
            if (resp.message == null) {
              if (resp.result)
                this.isStatusCalling = resp.result ?? oldState;
              else this.isStatusCalling = resp.result ?? oldState;
              this.toast.success("Change state phone call success!", "Success");
              this.notificationStatePhoneCall();
            } else this.isStatusCalling = oldState;
          }, err => {
            this.isStatusCalling = oldState;
          });
        } else setTimeout(() => this.isStatusCalling = oldState, 100);
      });
    }
  }

  notificationStatePhoneCall() {
    var duration = 3 * 1000;
    this.isNotiStatePhoneCall = true;
    if (this.timeOutNotiStatePhoneCall != null)
      clearTimeout(this.timeOutNotiStatePhoneCall);
    this.timeOutNotiStatePhoneCall = setTimeout(() => { this.isNotiStatePhoneCall = false }, duration);
  }

  checkEnabledFeatureChangeStatePCall() {
    this.callEventService.enableFeatureChangeStatePhoneCall().subscribe(resp => {
      if (resp.result) {
        this.isEnableChangeStatePhoneCall = resp.result;
        this.callEventService.checkStatePhoneNumber().subscribe(resp => {
          var firstLookState = window.localStorage.getItem("first_look_state_phone");
          if (resp.result) this.isStatusCalling = resp.result ?? false;
          else this.isStatusCalling = resp.result ?? false;
          if (firstLookState == "1") {
            setTimeout(() => this.notificationStatePhoneCall(), 500);
            window.localStorage.removeItem("first_look_state_phone");
          }
        })
      }
    });
  }
  async getVersionCode() {
    try {
      // 2023-02-01 ducqm start add
      // const versionCodeSetting = await this.settingService.getSettingByKeyAndGroup("SYSTEM_VERSION", "SYSTEM").toPromise();
      // this.versionCode = versionCodeSetting && versionCodeSetting.result ? versionCodeSetting.result.value : "";
      this.settingPoolService.getSettingByKeyAndGroup("SYSTEM_VERSION", "SYSTEM").subscribe(resp => {
        this.versionCode = (resp && resp.result) ? resp.result.value : "";
      })
      // 2023-02-01 ducqm end add
    }
    catch (ex) {
      console.log(ex);
    }
  }

  //2022-09-28 ducqm start add
  refreshData() {
    // this.skeletonLoading = true;
    this.page.totalElements = this.oldRealtimeNotificationLength;
    this.page.isUnread = !this.isAllNotification;

    this.loading = true;
    // if (this.isAllNotification) {
      this.tasksNotificationService
        .getRealtimeNotificationPaging(this.page, this.user.nameid)
        .subscribe((pageData) => {
          this.totalElement = pageData.result.page.totalElements;
          if (pageData.result.data.length !== 0 || (pageData.result.data.length === 0 && this.page.pageNumber === 0)) {
            this.oldRealtimeNotificationLength = pageData.result.page.totalElements;
            pageData.result.data.map((RN) => {
              if (!RN.type) RN.type = 'success';
              this.realtimeNotifications.push(RN);
              this.realtimeNotificationExpandBtn.push("more");
              RN.body = Helper.removeStyleHtml(RN.body);
            });
          }
          else {
            this.totalElement = this.oldRealtimeNotificationLength;
            this.page.pageNumber -= 1;
          }
          this.skeletonLoading = false;
          this.loading = false;
        });
    // }
    // else{
    //   this.tasksNotificationService.getNotificationUnreadByUserId(this.user.nameid).subscribe(resp=>{
    //     if(resp.result){
    //       resp.result.map((RN)=>{
    //         if (!RN.type)RN.type = 'success';

    //         this.realtimeNotifications.push(RN);
    //         this.realtimeNotificationExpandBtn.push("more");
    //         RN.body = Helper.removeStyleHtml(RN.body);
    //       })
    //     }
    //     this.skeletonLoading = false;
    //     this.loading = false;
    //   });
    // }
  }

  getListNotifications() {
    /*Reset data*/
    this.realtimeNotifications = [];
    this.realtimeNotificationExpandBtn = [];
    this.page.pageNumber = 0;
    this.totalElement = 0;
    this.isBlinkingNotify = false;
    /*Call API*/
    this.skeletonLoading = true;
    this.settingService
      .getSettingByKeyAndGroup("SIZE-PAGE", "TASK")
      .subscribe((resp) => {
        this.page.size = Number(resp.result.value);
        this.refreshData();
      });
  }

  clickExpandBtn(i: number, $event: any) {
    this.realtimeNotificationExpandBtn[i] = (this.realtimeNotificationExpandBtn[i] === 'more') ? 'less' : 'more';
    $event.stopImmediatePropagation();
    this.callRedirect = false; // Prevent redirect page

    /*Prevent call click event of parent */
    let btn = document.getElementById(`noti-${this.realtimeNotifications[i].realtimeNotificationId}`);
    btn.addEventListener("click", (e) => { e.stopPropagation() });

    //Update IsClicked property
    this.updateReadStatus(this.realtimeNotifications[i]);
    this.realtimeNotifications[i].isClicked = true;
  }

  onScroll() {
    // check current page is the final page
    // because first page is 0 so we had to plus 1 to page number
    if ((this.page.pageNumber + 1) * this.page.size < this.totalElement && !this.loading) {
      this.loading = true;
      this.page.pageNumber += 1;
      // this.isAllNotification = true;
      this.refreshData();
    }
  }

  callReference(notification: RealtimeNotificationDTO, $event) {
    /*if click expand button => this.callRedirect = false so cannot redirect */
    let TargetUrl = '';
    let tabName: string = null;
    if (this.callRedirect) {
      this.updateReadStatus(notification);
      if (notification.referenceId && notification.referenceType) {
        if (notification.referenceType === 'profile') {
          TargetUrl = `/configuration/${notification.referenceType}/${notification.referenceId}`;
          // this.router.navigate([`/configuration/${notification.referenceType}/${notification.referenceId}`]);
          // tabName = `Profile #${notification.referenceId.length > 10 ? notification.referenceId.substring(0, 5) + '..' : notification.referenceId}`
        }
        else if (notification.referenceType === 'task') {
          TargetUrl = `/configuration/${notification.referenceType}/${notification.referenceId}`;
          // console.log(`/configuration/${notification.referenceType}/?taskId=${notification.referenceId}`);
          // this.router.navigate([`/configuration/${notification.referenceType}`], { queryParams: { taskId: notification.referenceId } });
        }
        else if (notification.referenceType === 'sale-lead') {
          TargetUrl = `/configuration/${notification.referenceType}/${notification.referenceId}`;
          // console.log(`/configuration/${notification.referenceType}/?saleLeadId=${notification.referenceId}`);
          // this.router.navigate([`/configuration/${notification.referenceType}`], { queryParams: { saleLeadId: notification.referenceId } });
        }
        else if (notification.referenceType === 'sale-account') {
          TargetUrl = `/configuration/${notification.referenceType}/${notification.referenceId}`;
          // console.log(`/configuration/${notification.referenceType}/?saleAccountId=${notification.referenceId}`);
          // this.router.navigate([`/configuration/${notification.referenceType}`], { queryParams: { saleAccountId: notification.referenceId } });
        }
        else if (notification.referenceType === 'opportunity') {
          TargetUrl = `/configuration/${notification.referenceType}/${notification.referenceId}`;
          // console.log(`/configuration/${notification.referenceType}/?opportunityId=${notification.referenceId}`);
          // this.router.navigate([`/configuration/${notification.referenceType}`], { queryParams: { opportunityId: notification.referenceId } });
        }
        else if (notification.referenceType === 'indexing-history') {
          TargetUrl = `/configuration/indexing-tool`;
            tabName = `Indexing Tool`;
        }
        else if (notification.referenceType === 'leave-request') {
          TargetUrl = `/configuration/${notification.referenceType}/${notification.referenceId}`;
          tabName = `Leave Request`;
        }
        else if (notification.referenceType === 'attendance-request') {
          TargetUrl = `/configuration/${notification.referenceType}/${notification.referenceId}`;
          tabName = `attendance-request`;
        }
        this.openNewTabMode(TargetUrl, tabName);
        // let getTabMode = window.localStorage.getItem("tabMode");
        // if (getTabMode === "false") {
        //   this.router.navigateByUrl(TargetUrl);
        // } else {
        //   this.tabModeService.CreateTabOtherwiseSetActive(TargetUrl, tabName, 'Link', true);
        // }
      }
      else if (!notification.referenceId && notification.referenceType) {
        switch (notification.referenceType) {
          case 'profile':
            TargetUrl = `/configuration/${notification.referenceType}`;
            tabName = `${environment.titleProfile} Profile`;
            break;
          case 'task':
            TargetUrl = `/configuration/${notification.referenceType}?myTask=true`;
            tabName = `My Task`;
            break;
          case 'sale-lead':
            TargetUrl = `/configuration/${notification.referenceType}`;
            tabName = `${environment.titleLead}`;
            break;
          case 'sale-account':
            TargetUrl = `/configuration/${notification.referenceType}`;
            tabName = `${environment.titleAccount}s`;
            break;
          case 'opportunity':
            TargetUrl = `/configuration/${notification.referenceType}`;
            tabName = `${environment.titleOpportunity}`;
            break;
          case 'leave-request':
            TargetUrl = `/configuration/${notification.referenceType}`;
            tabName = `Leave Request`;
            break;
          case 'attendance':
            TargetUrl = `/configuration/${notification.referenceType}`;
            tabName = `Attendance`;
            break;
          default:
            break;
        }
        this.openNewTabMode(TargetUrl, tabName);
      }
      this.trigger.closeMenu();
    }
    this.callRedirect = true;
  }
  openNewTabMode(targetUrl: string, tabName: string = null) {
    let getTabMode = window.localStorage.getItem("tabMode");
    if (getTabMode === "false") {
      this.router.navigateByUrl(targetUrl);
    } else if(this.router.url !== targetUrl){
      if (tabName) {
        if (targetUrl.includes('/configuration/leave-request/')) {
          var findIndex = targetUrl.lastIndexOf('/');
          this.openAddEditLeaveRequestById(targetUrl.substring(findIndex + 1));
        } else if (targetUrl.includes('/configuration/attendance')) {
          var findIndex = targetUrl.lastIndexOf('/');
          if (tabName === 'attendance-request') this.showRequestAttendance(targetUrl.substring(findIndex + 1));
          else this.showAttendanceDialog(true);
        } else {
          this.tabModeService.CreateTabOtherwiseSetActive(targetUrl, tabName, 'Link', true);
        }
      }
      else {
        this.router.navigate([targetUrl]);
      }
    }
  }

  openAddEditLeaveRequestById(data) {
    if (data) {
      var modelLeaveRequest;
      this.leaveRequestService.getLeaveRequestById(data).pipe(takeUntil(this.destroy$)).subscribe(resp => {
        if (resp.result) {
          modelLeaveRequest = resp.result;
        }
      }).add(() => {
        const dialogRef = this.dialog.open(AddEditLeaveRequestComponent, {
          disableClose: true,
          height: '100vh',
          width: '600px',
          panelClass: 'dialog-detail',
          autoFocus: false,
          data: {
            model: modelLeaveRequest,
            action: TblActionType.Edit
          }
        });
      });
    }
  }

  updateReadStatus(notification: RealtimeNotificationDTO) {
    if (!notification.isClicked) {
      this.tasksNotificationService.readRealtimeNotification(notification.realtimeNotificationId).subscribe(resp => { });
      this.numbersOfNotification--;
      window.localStorage.setItem('NumberOfNotificationUnread', `${this.numbersOfNotification}`)
    }
  }
  ngAfterContentChecked() {
    this._changeDetectorRef.detectChanges();
  }

  //2022-09-28 ducqm end add

  ngAfterViewInit(): void {
    if (this.notificationService && this.notificationService.notificationComponent) {
      this.notificationService.notificationComponent.onClickRedirect
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.isBlinkingNotify = false;
          this.numbersOfNotification--;
          window.localStorage.setItem('NumberOfNotificationUnread', `${this.numbersOfNotification}`)
        });
      this.notificationService.notificationComponent.realtimeNotificationId.pipe(takeUntil(this.destroy$))
        .subscribe(resp => {
          if (resp) {
            this.tasksNotificationService.readRealtimeNotification(resp).subscribe(resp => { });
          }
        })


      this.notificationService.notificationComponent.onReceiveNewNotify
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.isBlinkingNotify = true;
          this.numbersOfNotification++;
          window.localStorage.setItem('NumberOfNotificationUnread', `${this.numbersOfNotification}`)
        }
        );
    }
  }
  changeNotiToUnread() {
    this.isAllNotification = false;
    this.getListNotifications();
  }
  changeNotiToAll() {
    this.isAllNotification = true;
    this.getListNotifications();
  }
  @HostListener('window:storage', ['$event'])
  onStorageChange(event: StorageEvent) {
    if (event.key === 'NumberOfNotificationUnread') {
      this.numbersOfNotification = Number(window.localStorage.getItem('NumberOfNotificationUnread'));
    }
  }
  UpdateMarkAllAsRead() {
    if (this.numbersOfNotification != 0) {
      this.tasksNotificationService.updateMarkAllAsReadByUserId(this.user.nameid).subscribe(resp => {
        if (resp.result) {
          this.toast.success('Update Notification success', 'Success')
          this.numbersOfNotification = 0;
          this.getListNotifications();
        }
      })
    }
  }
  menuClosed() {
    this.isAllNotification = true;
  }

  openDailyReport(isForgot: boolean = false){
    let params = {};
    if(isForgot){
      const currentPath = this.router.url.includes("configuration/work-log/create-new?isForgot=true") ? "configuration/employee" : this.router.url;
      params = {isForgot: true, redirect: currentPath};
    }
    this.router.navigate(['/configuration/work-log/create-new'], { queryParams: params });
  }
}


class StatusBadge {
  className: string;
  color: string;
  position: string = "bottom start";
  status: string;
  setStatus(status: number) {
    switch (status) {
      case UserStatusEnums.Online:
        this.color = "#28a745";
        this.className = "success";
        this.status = "Online";
        break;
      case UserStatusEnums.Offline:
        this.color = "#f7f9fc";
        this.className = "control";
        this.status = "Offline";
        break;
      case UserStatusEnums.Idle:
        this.color = "#ffaa00";
        this.className = "warning";
        this.status = "Idle";
        break;
      default:
        this.color = "#ff3d71";
        this.className = "danger";
        this.status = "Unknown";
        break;
    }
  }
}

export class NotificationPage extends Page {
  isUnread: boolean = false;
}
