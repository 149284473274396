<h2 mat-dialog-title style="display: flex; align-items: center;">    
  {{titleAttendance}} 
  <span style="margin-left: 130px; color: green;">{{durationDisplay}}  Today</span> <mat-icon style="color: green;">alarm_on</mat-icon>
</h2>
<!-- <mat-divider></mat-divider> -->
<mat-dialog-content class="mat-typography">
  <form [formGroup]="frmAttendance" class="container"
    style="padding-top: 25px; padding-right: 50px; padding-left: 50px">
    <div class="row">
      <ng-container *ngIf="isPunchOut">
        <div class="col-12" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="12px">
          <mat-form-field>
            <mat-label>Punch In Date</mat-label>
            <input required #dueDateInput matInput [matDatepicker]="punchInPicker.datePicker"
                formControlName="punchinDate"
                (dateChange)="punchInPicker.dateChangeSubject.next($event)" readonly>
            <!-- primas -->
            <mat-datepicker-toggle id="punchInDate" matSuffix [for]="punchInPicker.datePicker" disabled></mat-datepicker-toggle>
            <app-primas-custom-date-time-picker #punchInPicker="PrimasCustomDateTimePicker"></app-primas-custom-date-time-picker>
          </mat-form-field>
          <mat-form-field [fxFlex]="20">
            <mat-label>Time Zone</mat-label>
            <input matInput autocomplete="off" [value]="timeZone">
          </mat-form-field>
        </div>
        <div class="col-12" style="height: fit-content">
          <mat-form-field appearance="standard">
            <mat-label style="color: #666666">Note</mat-label>
            <textarea matInput placeholder="Write note here..." formControlName="punchinNote" trim="blur"></textarea>
          </mat-form-field>
        </div>
      </ng-container>
      <ng-container *ngIf="!isPunchOut">
        <div class="col-12" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="12px">
          <mat-form-field>
            <mat-label>Punched In Time</mat-label>
            <input #dueDateInput matInput [matDatepicker]="punchInPicker.datePicker"
                formControlName="punchinDate"
                (dateChange)="punchInPicker.dateChangeSubject.next($event)" readonly>
            <!-- primas -->
            <mat-datepicker-toggle id="punchInDate" matSuffix [for]="punchInPicker.datePicker" disabled></mat-datepicker-toggle>
            <app-primas-custom-date-time-picker #punchInPicker="PrimasCustomDateTimePicker"></app-primas-custom-date-time-picker>
          </mat-form-field>
          <mat-form-field [fxFlex]="20">
            <mat-label>Time Zone</mat-label>
            <input matInput autocomplete="off" [value]="timeZone">
          </mat-form-field>
        </div>
        <div class="col-12" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="12px">
          <mat-form-field>
              <mat-label>Punch Out Date</mat-label>
              <input required #dueDateInput matInput [matDatepicker]="punchOutPicker.datePicker"
                  formControlName="punchoutDate"
                  (dateChange)="punchOutPicker.dateChangeSubject.next($event)" readonly>
              <!-- primas -->
              <mat-datepicker-toggle id="punchOutDate" matSuffix [for]="punchOutPicker.datePicker" disabled></mat-datepicker-toggle>
              <app-primas-custom-date-time-picker #punchOutPicker="PrimasCustomDateTimePicker"></app-primas-custom-date-time-picker>
          </mat-form-field>
          <mat-form-field [fxFlex]="20">
            <mat-label>Time Zone</mat-label>
            <input matInput autocomplete="off" [value]="timeZone">
          </mat-form-field>
        </div>
        <div class="col-12" style="height: fit-content">
          <mat-form-field appearance="standard">
            <mat-label style="color: #666666">Note</mat-label>
            <textarea matInput placeholder="Write note here..." formControlName="punchoutNote" trim="blur"></textarea>
          </mat-form-field>
        </div>
      </ng-container>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button color="warn" [disabled]="loading" [mat-dialog-close]="true" cdkFocusInitial>Cancel</button>
  <button mat-button color="primary" [nbSpinner]="loading" [disabled]="loading" (click)="confirmSaveAttendance()">{{titleAttendance}}</button>
</mat-dialog-actions>