import { AfterViewInit, Directive, ElementRef, Input, SimpleChanges, } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AdminTabModeService } from '../components/stand-alone-component/admin-tab-mode/admin-tab-mode.service';
import { Helper } from '../utility/Helper';

@Directive({
  selector: '[appDetectTabMode]'
})
export class DetectTabModeDirective implements AfterViewInit {

  @Input() typeElement: string = TypeElement[TypeElement.LabelScreen];
  @Input() includeSearchParams: boolean = false;
  constructor(
    private el: ElementRef,
    private tabMode: AdminTabModeService
  ) {
    if (TypeElement[TypeElement.TabName])
      Helper.validateTabSession(this.tabMode);
  }

  ngAfterViewInit(): void {
    if (Helper.checkTabMode()) {
      switch (this.typeElement.toString()) {
        case TypeElement[TypeElement.LabelScreen]:
          this.el.nativeElement.innerText = '';
          break;
        case TypeElement[TypeElement.BodyDetail]:
          var sHeight = parseInt(this.el.nativeElement.clientHeight, 10);
          if (sHeight > 100) this.el.nativeElement.style.height = (sHeight - 100) + 'px';
          break;
        case TypeElement[TypeElement.LoadingContent]:
          Helper.removeOverlay();
          break;
        case TypeElement[TypeElement.TabName]:
          this.updateTabName()
          break;
        case TypeElement[TypeElement.DetectHeader]:
          this.getHeightHeader();
          break;
      }
    }
  }

  updateTabName() {
    var currentUrl = window.location.pathname;
    if (currentUrl && currentUrl.includes('/view/')) {
      currentUrl = currentUrl.slice(0, currentUrl.indexOf('/view/'));
    }

    var title: string;

    switch (currentUrl) {
      case '/configuration/profile':
        title = `${environment.titleProfile} Profile`;
        break;
      case '/configuration/sale-lead':
        title = `${environment.titleLead}s`;
        break;
      case '/configuration/employee':
        title = `${environment.titleAccount}s`;
        break;
      case '/configuration/opportunity':
        title = `${environment.titleOpportunity}`;
        break;
      case '/configuration/task':
        title = 'Task';
        break;
      // case '/configuration/task-filter':
      //   if (this.includeSearchParams) {
      //     currentUrl += window.location.search;
      //   }
      //   title = 'Dashboard';
      //   break;
      case '/configuration/sales-report':
        title = 'Sale Report';
        break;
    }

    if (title && currentUrl) this.tabMode.editTab(title, currentUrl);
  }

  getHeightHeader() {
    setTimeout(() => {
      const hostEl = this.el.nativeElement?.closest("nb-layout-header.fixed")?.parentElement?.querySelector('.layout-container');
      const getHeightHeader = this.el.nativeElement?.parentElement?.offsetHeight;
      hostEl.style.paddingTop = `${getHeightHeader}px`;
    }, 300);

  }
}

export enum TypeElement {
  LabelScreen,
  BodyDetail,
  LoadingContent,
  TabName,
  DetectHeader
}
