import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { AutomateDatastateService } from 'src/app/modules/admin/profile-management/profile-detail/automate-datastate-log/automate-datastate.service';
import { DisplayComponentMultimediaTab } from 'src/app/modules/admin/profile-management/profile-detail/multi-media/multi-media.component';
import { Media, MediaType } from 'src/app/modules/admin/profile-management/profile-detail/multi-media/multi-media.model';
import { ProfileService } from 'src/app/modules/admin/profile-management/profile.service';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { environment } from 'src/environments/environment';
import { MediaViewModel } from '../models/media-view.model';
@Injectable({
  providedIn: 'root'
})
export class MutliMediaService {
  baseUrl = environment.mediaManagement;
  profileUrl = environment.apiProfileManagement;
  producerUrl = environment.apiProducer;
  multiMediaSubject = new Subject<MediaType[]>();
  constructor(
    private http: HttpClient,
    private profileService: ProfileService,
    private automateDataStateService: AutomateDatastateService
  ) { }
  getMediaType(): Observable<MediaType[]> {
    return this.multiMediaSubject.asObservable();
  }

  getMediaByProfileId(profileId: string, dataField: DisplayComponentMultimediaTab): void {
    if (dataField == DisplayComponentMultimediaTab.All)
      this.profileService.isAutomateDataState$.asObservable().pipe(first()).subscribe(resp => {
        if (resp) this.automateDataStateService.requestIsSatisfiedDataState(profileId);
      });

    this.http.get<ReturnResult<MediaType[]>>(`${this.baseUrl}/GetMediaByProfileId/${profileId}`).subscribe(item => {
      this.multiMediaSubject.next(item.result);
    });
  }

  uploadImage(profileId: string, image: any) {
    const bodyFormData = new FormData();
    bodyFormData.append('upload', image);
    return this.http.post(`${this.profileUrl}/UploadFile/${profileId}`, bodyFormData);
  }
  uploadVideoLink(profileId: string, media: Media) {
    return this.http.post(`${this.profileUrl}/UploadVideo`, {
      Media1: media.media1,
      ProfileId: media.profileId
    });
  }
  removeMedias(medias: Media[]): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.profileUrl}/RemoveMediasAsync`, medias);
  }
  changeAvatar(id: string, image: any, table: string): Observable<ReturnResult<Media>> {
    const bodyFormData = new FormData();
    bodyFormData.append('upload', image);
    if (table === 'Profile') {
      return this.http.post<ReturnResult<Media>>(`${this.profileUrl}/UploadAvatar/${id}`, bodyFormData);
    } else {
      return this.http.post<ReturnResult<Media>>(`${this.producerUrl}/UploadAvatar/${id}`, bodyFormData);
    }
  }
  SetPrimaryMediaAsync(media: Media, id: string, table: string, type: string): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SetPrimaryMediaAsync`, {
      media, id, table, type
    });
  }
  uploadMultipleMediaAsync(profileId: string, images: any[]): Observable<ReturnResult<boolean>> {
    const bodyFormData = new FormData();
    images.forEach(image => {
      bodyFormData.append('upload', image);
    });
    return this.http.post<ReturnResult<boolean>>(`${this.profileUrl}/UploadMultipleMediaAsync/${profileId}`, bodyFormData);
  }

  updatePublishedMedia(mediaId: number): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/UpdatePublishedMedia/${mediaId}`, {});
  }
  selfGetMediaById(profileId: string): Observable<ReturnResult<MediaType[]>> {
    return this.http.get<ReturnResult<MediaType[]>>(`${this.baseUrl}/GetMediaByProfileId/${profileId}`);
  }

  moveUpMedia(media: Media, id: string, table: string, type: string): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/MoveUpMedia`, { media, id, table, type });
  }

  moveDownMedia(media: Media, id: string, table: string, type: string): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/MoveDownMedia`, { media, id, table, type });
  }

  moveToTopMedia(media: Media, id: string, table: string, type: string): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/MoveToTopMedia`, { media, id, table, type });
  }

  moveToTheBottom(media: Media, id: string, table: string, type: string): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/MoveToTheBottom`, { media, id, table, type });
  }
  changeSelectedAvatar(mediaViewModel: MediaViewModel): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/ChangeSelectedAvatar`, mediaViewModel);
  }
  getImageFromServer(imgUrl: string) {
    return this.http.get(`${imgUrl}`, { responseType: 'blob' });
  }
  updateImage(profileId: string, image: any, mediaId: number): Observable<ReturnResult<boolean>> {
    const bodyFormData = new FormData();
    bodyFormData.append('upload', image);
    bodyFormData.append('profileId', profileId);
    bodyFormData.append('mediaId', mediaId.toString());
    return this.http.post<ReturnResult<boolean>>(`${this.profileUrl}/UpdateImage`, bodyFormData);
  }

  uploadAndResizeImage(mediaId: number, imageUrl: string = "", isForce: boolean = true): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/UploadAndResizeImage`, { mediaId, imageUrl, isForce });
  }

  uploadToBunny(mediaId: number): Observable<ReturnResult<Media>> {
    return this.http.post<ReturnResult<Media>>(`${this.baseUrl}/UploadImageToBunny/${mediaId}`, null);
  }
}
