import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { map } from 'rxjs/operators';
import { countriesArr } from 'src/app/shared/contances/country-constance';
import { ActionOnboardingFormEnums, ArtistDetails, EntityTypeOnboardingFormEnums, LocationProps, ObjectChangedModel } from '../onboarding-form-profile.model';

@Component({
  selector: 'app-artist-details-tab',
  templateUrl: './artist-details-tab.component.html',
  styleUrls: ['./artist-details-tab.component.scss', '../onboarding-form.component.scss']
})
export class ArtistDetailsTabComponent implements OnInit, OnChanges {
  @Input() artistDetails: ArtistDetails;
  @Input() contactID: number;
  artistDetailsForm: FormGroup;
  changesList: ObjectChangedModel[] = [];
  countriesArr = countriesArr;
  constructor(private formBuilder: RxFormBuilder) { }
  willTravelFlag = "1";
  initial = false;
  ngOnInit(): void {
    this.artistDetailsForm = this.formBuilder.formGroup(ArtistDetails, this.artistDetails);
    this.artistDetailsForm.markAllAsTouched();
    // add changes detect logic here:
    Object.entries(this.artistDetailsForm.controls).forEach(value => {
      const [key, control] = value;
      control.valueChanges.subscribe(
        (value) => {
          this.detectChangesArtistDetails(key, this.artistDetailsForm.controls.PID.value, value);
        },
      );
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    let dataChanges = changes?.artistDetails;
    let contactIDChanges = changes?.contactID;
    if (dataChanges?.previousValue && JSON.stringify(dataChanges?.previousValue) != JSON.stringify(dataChanges?.currentValue)) {
      this.ngOnInit();

    }
  }
  getArtistDetails(): ArtistDetails {
    if (this.artistDetailsForm.valid) {
      return this.artistDetailsForm.value;
    } else {
      return null;
    }
  }
  detectChangesArtistDetails(propName: string, PID: string, value: any) {

    let newPropName = propName;
    if (propName.toLowerCase() == 'Scarlettname'.toLowerCase())
      newPropName = 'DisplayName';
    if (PID) {
      // have PID then search if it has already added:
      let changedPropertyIndex = this.changesList.findIndex(x => x.PropertyName.toLocaleLowerCase() === newPropName.toLocaleLowerCase() && x.PID === PID)
      if (changedPropertyIndex != -1) {
        // has value:
        this.changesList[changedPropertyIndex].PropertyValue = value;
        this.changesList[changedPropertyIndex].DateModified = 0;
        this.changesList[changedPropertyIndex].DateModified = + new Date();
        console.log(this.changesList);
      } else {

        // add new 
        let newChanges = new ObjectChangedModel(
          PID,
          LocationProps.find(x => x.toLowerCase() === newPropName.toLowerCase()) === undefined ? EntityTypeOnboardingFormEnums.ARTIST_DETAILS : EntityTypeOnboardingFormEnums.LOCATION,
          ActionOnboardingFormEnums.MODIFIED,
          newPropName,
          LocationProps.find(x => x.toLowerCase() === newPropName.toLowerCase()) === undefined ? null : (this.contactID),
          value,
          null,
          + new Date());
        // special case 'Scarlettname' ==> RealName:
        // remove first track for how far:
        if (!this.initial && propName === 'HowFar') {
          this.initial = true;
          return;
        }
        this.changesList.push(newChanges);
        console.log(this.changesList);
      }
    } else {
      // no pid mean new artist:

    }
  }
  getChangesArtistDetails(): ObjectChangedModel[] {
    return this.changesList;
  }
}
