import { pattern, prop, required } from "@rxweb/reactive-form-validators";

export class SeoInfoFormControl {
    @prop()
    primarySeoInfo:boolean;
    @prop()
    displayName: string;
    @prop()
    title: string;
    @prop()
    description: string;
    @prop()
    keywords: string;

    @required()
    @pattern({ expression: { url: /^[a-zA-Z0-9]+([- ]?[a-zA-Z0-9]+)*$/ }, message: 'This field cannot have any char different than alphabet and number!' })
    friendlyUrl: string;
}
// 2021-10-01 tien add start
export class SeoInfoModel {
    // tslint:disable-next-line:no-inferrable-types
    seoInfoId: number = 0;
    title: string;
    description: string;
    keywords: string;
    friendlyUrl: string;
}
// 2021-10-01 tien add end
