<div *ngIf="!isLoading" appChangeUrlObjects [id]="id" [isOpenDialog]="openByDialog" [topic]="'profile'"
    [name]="profileModel?.displayName"></div>

<div class="d-flex flex-wrap align-items-space-between" mat-dialog-title
    style="margin: unset;padding: 1px 10px 1px 28px;" *ngIf="isShowButton">
    <div [hidden]="isLoading" class="d-flex flex-wrap" fxLayoutGap="10px">
        <div fxLayout="row" fxLayoutAlign="space-around center">
            <mat-icon style="transform: scale(1.5);" [ngStyle]="{'color':colorCode.Profile}">
                account_circle</mat-icon>
        </div>
        <div style="align-items: flex-start;" class="entity-name-id">
            <span class="entity-name" fxLayout="row" fxLayoutAlign="space-around center">
                {{dynamicName}} Profile
            </span>
            <span *ngIf="!isCopy;else copied" [matTooltip]="'Click to copy this profile ID: '+ id" class="entity-id"
                (click)="copyGUIDToClipboard()" class="underline-id">#{{id | truncateText:[6,
                '...']}}</span>
        </div>
        <mat-divider [vertical]="true" style="height:100%">
        </mat-divider>
    </div>
    <div class="ml-auto" fxLayout="row">

        <div fxLayout="row" fxLayoutAlign="start center">
            <div *nbIsGranted="['view', resourceItem.requestSEOAudit]">
                <button mat-icon-button [ngClass]="primarySeo?.seoInfo?.overallScore | seoColorScore: '100'"
                    class="ml-auto float-start" [matTooltip]="'Click to open SEO audit'" (click)="clickSEOAudit()">
                    <mat-icon svgIcon="seo-audit"></mat-icon>
                </button>
            </div>
            <div *nbIsGranted="['view', resourceItem.openProfileAndShowSensitive]">
                <!-- <button mat-icon-button class="ml-auto float-start" matTooltip="Open this profile in new tab"
          *ngIf="profileModel.profileId" matTooltipPosition="left" (click)="popProfileToNewPage()">
          <mat-icon>open_in_new</mat-icon>
        </button> -->
                <button mat-icon-button class="ml-auto float-start"
                    [matTooltip]="isHiddenData ? 'Click to hidden the internal data' : 'Click to show the internal data'"
                    *ngIf="profileModel.profileId" [color]="isHiddenData ? 'primary' : ''" matTooltipPosition="left"
                    (click)="toggleHiddenData()">
                    <mat-icon>{{isHiddenData ? 'visibility' : 'visibility_off'}}</mat-icon>
                </button>
            </div>
            <!-- <button mat-icon-button class="ml-auto float-start" [disabled]="!isSaveAllChange" color="primary"
        (click)="onConfirm(true)">
        <mat-icon>save</mat-icon>
      </button> -->
            <ng-container *nbIsGranted="['view', resourceItem.openProfileOnFrontFacing]">
                <button mat-icon-button class="ml-auto float-start"
                    matTooltip="Copy consent link this profile on front-facing" matTooltipPosition="left"
                    (click)="copyConsentLink()" [nbSpinner]="loadingSeoLinkBtn">
                    <mat-icon>copyright</mat-icon>
                </button>
            </ng-container>
            <div *nbIsGranted="['view', resourceItem.openProfileOnFrontFacing]">
                <button mat-icon-button class="ml-auto float-start" matTooltip="Open this profile on front-facing"
                    matTooltipPosition="left" (click)="openSeoLink()" [disabled]="loadingSeoLinkBtn || disableSeoLink"
                    [nbSpinner]="loadingSeoLinkBtn">
                    <mat-icon>open_in_browser</mat-icon>
                </button>
            </div>
            <button mat-icon-button class="ml-auto float-start"
                [matTooltip]="'Copy this ' + subjectProfile + ' profile url to clipboard'" matTooltipPosition="left"
                (click)="copyToClipboard()">
                <mat-icon style="transform: scale(0.9);">share</mat-icon>
            </button>
            <div *nbIsGranted="['view', resourceItem.copyPreviewPageLink]">
                <button mat-icon-button class="ml-auto float-start" matTooltip="Copy preview page to clipboard"
                    [disabled]="toggleReviewableState()" matTooltipPosition="left"
                    (click)="copyPreviewFrontFacingSite()">
                    <mat-icon style="transform: scale(0.9);">wysiwyg</mat-icon>
                </button>
            </div>
            <mat-divider [vertical]="true" style="height:100%">
            </mat-divider>
        </div>
        <app-next-or-previous-detail #btnNextOrPre [modelNextOrPre]="profileNextOrPre"
            (onClick)="clickNextOrPre($event[0] , $event[1])">
        </app-next-or-previous-detail>
        <button *ngIf="openByDialog" mat-icon-button matTooltip="Close" (click)="closeDialog()">
            <mat-icon style="color: red">close</mat-icon>
        </button>
    </div>
</div>
<mat-divider *ngIf="isShowButton"></mat-divider>

<div *ngIf="!isLoading && id == profileModel?.profileId; then content else skeleton"></div>

<ng-template #content>
    <mat-dialog-content [ngStyle]="{
    'display': !openByDialog?'contents':'block',
    'overflow' : isAutomateDataState && currentUrl.startsWith('/configuration/profile') ? 'hidden' : 'auto'
  }" *ngIf="profileModel.profileId; else empty" style="max-height: 100% !important;">
        <div
            *ngIf="isAutomateDataState && isHasDataField && currentUrl.startsWith('/configuration/profile'); else noAutomateBody">
            <div fxLayout="row" fxLayoutAlign="start start" style="overflow: hidden; ">
                <div fxFlex="15" appDetectTabMode [typeElement]="'BodyDetail'" class="scroll-automate-datastate"
                    style="overflow: hidden; height: 100vh;">
                    <ng-container [ngTemplateOutlet]="bodyAutoDataStateLog"></ng-container>
                </div>
                <mat-divider appDetectTabMode [typeElement]="'BodyDetail'" style="height: 100vh;" [vertical]="true">
                </mat-divider>
                <div fxFlex="85" appDetectTabMode [typeElement]="'BodyDetail'" style="overflow: auto; height: 100vh;"
                    class="full-height">
                    <ng-container [ngTemplateOutlet]="bodyProfile"></ng-container>
                </div>
            </div>
        </div>
    </mat-dialog-content>

    <ng-template #noContent>
        <app-inline-edit></app-inline-edit>
    </ng-template>
    <ng-template #notPublished>
        <span class="material-icons" style="font-size: 24px; color: red" matTooltip="Not published"
            matTooltipPosition="above">cancel</span>
    </ng-template>
</ng-template>

<ng-template #skeleton>
    <div class="fb-item">
        <div class="first-section-wrapper">
            <div class="gravatar">
                <ngx-skeleton-loader appearance="circle" [theme]="{ width: '80px', height: '80px' }">
                </ngx-skeleton-loader>
            </div>
            <div class="gravatar-title">
                <div>
                    <ngx-skeleton-loader [theme]="{
          width: '200px',
          'border-radius': '0',
          height: '15px',
          'margin-bottom': '10px'
        }"></ngx-skeleton-loader>
                </div>
                <div>
                    <ngx-skeleton-loader [theme]="{ width: '170px', 'border-radius': '0', height: '15px' }">
                    </ngx-skeleton-loader>
                </div>
            </div>
        </div>
        <div class="second-section-wrapper">
            <div class="wrapper">
                <ngx-skeleton-loader [theme]="{
        width: '80%',
        'border-radius': '0',
        height: '15px',
        'margin-bottom': '10px'
      }"></ngx-skeleton-loader>
            </div>
            <div class="wrapper">
                <ngx-skeleton-loader [theme]="{
        width: '90%',
        'border-radius': '0',
        height: '15px',
        'margin-bottom': '10px'
      }"></ngx-skeleton-loader>
            </div>
            <div class="wrapper">
                <ngx-skeleton-loader [theme]="{
        width: '60%',
        'border-radius': '0',
        height: '15px',
        'margin-bottom': '10px'
      }"></ngx-skeleton-loader>
            </div>
        </div>
    </div>
    <br>
    <br>
    <div class="item">
        <ngx-skeleton-loader count="6" animation="progress" [theme]="{
    height: '50px'
  }"></ngx-skeleton-loader>
    </div>
</ng-template>

<mat-menu class="mat-data-state-menu" #menu="matMenu" focusFirstItem="false"
    style="max-width: fit-content !important; ">
    <button mat-menu-item mat-chip *ngFor="let state of (isAutomateDataState ? autoDataState : dataState)"
        class="chip-menu"
        (click)="isAutomateDataState ? editAutoDataState(state.automateDataStateId) : editDataState(state.dataStateId)"
        [style.backgroundColor]="state.colorCode"><strong>{{state.dataStateName}}</strong> </button>
</mat-menu>

<button mat-fab color="primary"
    style="width: 50px;height: 50px;position: absolute;top: 90vh;z-index: 1000000000000;right: 6px;"
    *ngIf="!openByDialog1 && (theLastAsteriskCallStatus!=null && !this.isPopupAsteriskOpen)"
    (click)="onShowLastAsteriskCallStatus()">
    <mat-icon>phone_in_talk</mat-icon>
</button>
<ng-template #empty>
    <mat-dialog-content>
        <app-blank-page [message]="'The profile with id ' + id +  ' is not exist or deleted'"></app-blank-page>
    </mat-dialog-content>
</ng-template>

<ng-template #bodyProfile>
    <div class="col-12" *ngIf="readonlyProfile">
        <br>
        <div class="alert alert-warning" role="alert">
            This profile was created by the another user. You can read-only.
        </div>
    </div>
    <div class="col-12" *ngIf="bypassPermissions">
        <br>
        <div class="alert alert-info" role="alert">
            This profile was created by the another user. But the system allows you to modify
        </div>
    </div>
    <!-- show notification for  -->
    <div class="col-12" *ngIf="!readonlyProfile && isHasUpdateNew">
        <br>
        <div class="alert alert-info" role="alert">
            This profile has some changes from onboarding form. <a class="fake-a-link" (click)="showMergeChange()">Click
                here to accept or decline the changes.</a>
        </div>
    </div>
    <div class="col-12">
        <div class="row">
            <div class="col-md-7" style="padding-left: 50px; padding-top: 25px">
                <div class="row">
                    <!-- <div class="col-md-6 col-xl-3"><img class="rounded-circle img-fluid" src="assets/images/5.jpg"></div> -->
                    <mat-chip-list hidden>
                    </mat-chip-list>
                    <div class="col-md-6 col-xl-6">
                        <!--Avatar-->
                        <div>
                            <app-profile-avatar [medias]="profileModel.medias" [profileId]="profileModel.profileId"
                                [media]="getAvt()" [mediaId]="profileModel.primaryImage" [table]="'Profile'"
                                [readonlyAvatar]="readonlyProfile" [profile]="profileModel">
                            </app-profile-avatar>
                        </div>
                        <!--Favorite - Popular - Top-->
                        <div fxLayout="row" style="margin-top: 5px;" fxLayoutAlign="space-evenly center"
                            *nbIsGranted="['view', resourceItem.hotTrend]">
                            <button mat-icon-button matTooltip="Favorite" class="icon-top-page"
                                [color]="profileModel.favoriteArt ? 'warn' : ''"
                                (click)="readonlyProfile ? '' : changeArtToggle('favoriteArt')">
                                <mat-icon>{{profileModel.favoriteArt ? 'favorite' : 'favorite_border'}}</mat-icon>
                            </button>
                            <!-- <p class="button-icon-text-art">Favorite</p> -->
                            <button mat-icon-button matTooltip="Popular" class="icon-top-page"
                                [color]="profileModel.isPopularAct ? 'primary' : ''"
                                (click)="readonlyProfile ? '' : changeArtToggle('isPopularAct')">
                                <span *ngIf="!profileModel.isPopularAct" class="material-icons-outlined">
                                    thumb_up
                                </span>
                                <mat-icon *ngIf="profileModel.isPopularAct">thumb_up</mat-icon>
                            </button>
                            <!-- <p class="button-icon-text-art">Popular</p> -->
                            <button mat-icon-button matTooltip="Top" class="icon-top-page"
                                [color]="profileModel.isTopAct ? 'primary' : ''"
                                (click)="readonlyProfile ? '' : changeArtToggle('isTopAct')">
                                <span [ngStyle]="{'color': profileModel.isTopAct ? '#fd7e14' : ''}"
                                    class="material-icons-outlined" style="font-size: 24px;">
                                    local_fire_department
                                </span>
                            </button>
                            <!-- <p class="button-icon-text-art">Feature Act</p> -->
                            <button mat-icon-button matTooltip="Feature Act" class="icon-top-page"
                                [nbSpinner]="featureActHP?.homePageId == -1"
                                [color]="featureActHP?.homePageId > 0 ? 'primary' : ''"
                                [disabled]="featureActHP?.homePageId == -1"
                                (click)="readonlyProfile ? '' : saveFeatureOrSeasonalAct(featureActHP)">
                                <span [ngStyle]="{'color': featureActHP?.homePageId > 0 ? '#307ABD' : ''}"
                                    class="material-icons-outlined" style="font-size: 24px;">
                                    trending_up
                                </span>
                            </button>
                            <!-- <p class="button-icon-text-art">Seasonal Act</p> -->
                            <button mat-icon-button matTooltip="Seasonal Act" class="icon-top-page"
                                [nbSpinner]="seasonalActHP?.homePageId == -1"
                                [color]="seasonalActHP?.homePageId > 0 ? 'primary' : ''"
                                [disabled]="seasonalActHP?.homePageId == -1"
                                (click)="readonlyProfile ? '' : saveFeatureOrSeasonalAct(seasonalActHP)">
                                <span [ngStyle]="{'color': seasonalActHP?.homePageId > 0 ? '#f44336' : ''}"
                                    [ngClass]="seasonalActHP?.homePageId > 0 ? 'material-icons' : 'material-icons-outlined'"
                                    style="font-size: 24px;">
                                    celebration
                                </span>
                            </button>
                            <!-- <p class="button-icon-text-art">Top</p> -->
                        </div>
                        <!-- <div fxLayout="column" fxLayoutAlign="space-evenly end" class="col-md-6"
             style="padding-top: 25px;"> -->
                        <!--Social link-->
                        <div fxLayout="row" fxLayoutAlign="space-evenly center"
                            *nbIsGranted="['view', resourceItem.socialMedia]">
                            <app-inline-edit-socical icon="facebook" [socialAccount]="profileModel.facebook"
                                fieldName="facebook" (handleSave)=editSocialAccount($event)
                                [readonlyInlineSocial]="readonlyProfile"
                                (cacheInputChange)="dataChange($event, 'facebook')">
                            </app-inline-edit-socical>
                            <app-inline-edit-socical icon="youtube" [socialAccount]="profileModel.youtube"
                                fieldName="youtube" (handleSave)=editSocialAccount($event)
                                [readonlyInlineSocial]="readonlyProfile"
                                (cacheInputChange)="dataChange($event, 'youtube')">
                            </app-inline-edit-socical>
                            <app-inline-edit-socical icon="instagram" [socialAccount]="profileModel.instagram"
                                fieldName="instagram" (handleSave)=editSocialAccount($event)
                                [readonlyInlineSocial]="readonlyProfile"
                                (cacheInputChange)="dataChange($event, 'instagram')">
                            </app-inline-edit-socical>
                            <app-inline-edit-socical icon="skype" [socialAccount]="profileModel.skype" fieldName="skype"
                                (handleSave)=editSocialAccount($event) [readonlyInlineSocial]="readonlyProfile"
                                (cacheInputChange)="dataChange($event, 'skype')">
                            </app-inline-edit-socical>
                            <app-inline-edit-socical icon="twitter" [socialAccount]="profileModel.twitter"
                                fieldName="twitter" (handleSave)=editSocialAccount($event)
                                [readonlyInlineSocial]="readonlyProfile"
                                (cacheInputChange)="dataChange($event, 'twitter')">
                            </app-inline-edit-socical>
                            <app-inline-edit-socical icon="tiktok" [socialAccount]="profileModel.tiktok"
                                fieldName="tiktok" (handleSave)=editSocialAccount($event)
                                [readonlyInlineSocial]="readonlyProfile"
                                (cacheInputChange)="dataChange($event, 'tiktok')">
                            </app-inline-edit-socical>
                        </div>
                        <div class="layout-action-block">
                            <app-action-block [(readonlyProfile)]="readonlyProfile" [optionalTaskId]="optionalTaskId"
                                [(profileModel)]="profileModel" [creatableTask]="creatableTask"
                                [tooltipProp]="tooltipProp" [(contacts)]="totalContacts">
                            </app-action-block>
                        </div>

                    </div>
                    <div class="col-md-6 col-xl-6">
                        <!--Edit inline -->
                        <h5 #displayNameProp [matTooltip]="findItemByKey(tooltipProp, 'displayName') ?? 'Display name'"
                            matTooltipPosition="left" class="h5-custom">
                            <span>
                                <app-inline-edit [inputData]="profileModel?.displayName || 'Click to add content' "
                                    (handleSave)="editDisplayName($event); refreshTask.emit()"
                                    [readonlyInline]="readonlyProfile"
                                    (cacheInputChange)="dataChange($event, 'displayName')">
                                </app-inline-edit>
                                <mat-icon *ngIf="duplicateIconLoading"
                                    [nbSpinner]="duplicateIconLoading">error_outline</mat-icon>
                                <mat-icon class="duplicate-display-name"
                                    [matTooltip]="'The ' + (findItemByKey(tooltipProp, 'displayName') ?? 'display name') + ' is duplicated'"
                                    *ngIf="isDuplicate" matTooltipPosition="right">error_outline</mat-icon>

                                <mat-icon [nbPopover]="isShowPopover ? emailRelationship : null"
                                    [nbSpinner]="isLoadingDataDuplicated" class="duplicate-data"
                                    [ngStyle]="{'color': isLoadingDataDuplicated ? '': 'red'}" style="cursor: pointer;"
                                    [matTooltip]="'We discover potential duplicate data using the same contact information!'"
                                    *ngIf="isContactsRelationship" matTooltipPosition="right">warning_amber</mat-icon>
                            </span>
                        </h5>
                        <h6 [matTooltip]="findItemByKey(tooltipProp, 'realName') ?? 'Real name'"
                            matTooltipPosition="left" class="h6-custom">
                            <span class="material-icons-outlined blue-color middle-align"
                                style="font-size: 24px;">account_circle</span>
                            <span class="middle-align">
                                <app-inline-edit [inputData]="profileModel?.realName" (handleSave)=editRealName($event)
                                    [width]="'80%'" [display]="'inline-block'" [readonlyInline]="readonlyProfile"
                                    (cacheInputChange)="dataChange($event, 'realName')">
                                </app-inline-edit>
                            </span>
                        </h6>
                        <h6 [matTooltip]="findItemByKey(tooltipProp, 'producer.producerName') ?? 'Production company'"
                            matTooltipPosition="left" class="h6-custom">
                            <span class="material-icons-outlined blue-color middle-align"
                                style="font-size: 24px; ">apartment</span>
                            <span>
                                <app-add-producer-inline [inputData]="profileModel?.producer?.producerName"
                                    [id]="profileModel.profileId" [readonlyInlineProducer]="readonlyProfile">
                                </app-add-producer-inline>
                            </span>
                        </h6>
                        <h6 [matTooltip]="findItemByKey(tooltipProp, 'webPage') ?? 'Website'" matTooltipPosition="left"
                            style="margin-bottom: 0px;" class="h6-custom">
                            <span class="material-icons-outlined blue-color middle-align"
                                style="font-size: 24px;">language</span>
                            <span class="middle-align">
                                <app-inline-edit [inputData]="profileModel?.webPage" isAccess="true" [width]="'80%'"
                                    [display]="'inline-block'" (handleSave)="editWebPage($event)"
                                    [readonlyInline]="readonlyProfile"
                                    (cacheInputChange)="dataChange($event, 'webPage')">
                                </app-inline-edit>
                            </span>
                        </h6>
                        <!-- 2022-06-07 gnguyen start add -->

                        <h6 style="margin-bottom: 0px;" class="h6-custom" *ngIf="profileModel?.profileCode">
                            <span class="material-icons-outlined blue-color middle-align" style="font-size: 24px;"
                                matTooltip="Profile Code">qr_code_2</span>
                            <span class="middle-align">
                                {{profileModel?.profileCode}}
                            </span>
                        </h6>
                        <!-- 2022-06-07 gnguyen end add -->

                        <!-- <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px">
              <mat-divider fxFlex="1 0"></mat-divider>
              <div>Actions</div>
              <mat-divider fxFlex="1 0"></mat-divider>
            </div> -->
                        <!--Data state-->
                        <div class="data-state-editable" style="padding: 0.5rem 0 0.5rem 0"
                            [matMenuTriggerFor]="!readonlyProfile ? menu : ''">
                            <mat-chip class="chip-data-state"
                                [style.backgroundColor]="currentState?.colorCode || '#000'" selected
                                [disabled]="readonlyProfile">
                                {{currentState?.dataStateName || 'Unknown'}}
                            </mat-chip>
                        </div>
                    </div>
                </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="space-evenly center"
                [ngStyle]="{'padding-top': lastNote ? '1rem' : '4rem'}" class="col-md-5">
                <ng-container *ngIf="lastNote; else blankLastNote">
                    <nb-card class="body-last-note-card" [nbSpinner]="overlayNoteDetails?.isLoading | async"
                        (click)="noteService.pushNoteDetails(lastNote, profileModel.displayName)">
                        <nb-card-header fxLayout="row" fxLayoutAlign="space-between center"
                            style="padding: 0 1rem; background-color: #f5da65;">
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <button mat-icon-button debouncedClick [throttleTime]="500" (throttleClick)="noteService.pushNoteDetails({
                            referenceId: id,
                            referenceType: 'SALEPROFILE',
                            referenceName: profileModel.displayName
                        })">
                                    <mat-icon>add</mat-icon>
                                </button>
                                <nb-user *ngIf="lastNote.ownerId" [onlyPicture]="true" color="#8f9bb37a"
                                    [matTooltip]="lastNote.user?.userName" [name]="lastNote['ownerNote']"
                                    [picture]="lastNote['avatarNote']" size="small">
                                </nb-user>
                                <span class="limit-line" style="--line: 1;" [matTooltip]="lastNote.title"
                                    matTooltipPosition="left">
                                    Last note: {{lastNote.title}}
                                </span>
                            </div>

                            <span fxFlex="20" class="limit-line"
                                style="--line: 1; width: auto; font-weight: normal; text-align: end;"
                                matTooltip="Last created" matTooltipPosition="left">
                                {{ (lastNote.dateCreated | date: 'short') || 'None' }}
                            </span>
                        </nb-card-header>
                        <nb-card-body style="background-color: #fef9b7;" [innerHTML]="lastNote.note">
                        </nb-card-body>
                    </nb-card>
                </ng-container>
                <div class="row  social-block">
                    <div *nbIsGranted="['view', resourceItem.socialBlock]">
                        <nb-toggle style="padding-left:10px; padding-right: 10px" status="success"
                            [checked]="profileModel.isActive" (click)="readonlyProfile ? '' : activeToggle()"
                            [disabled]="readonlyProfile">
                            <span [matTooltip]="numberTruncateToggle == 1 ? 'Active' : ''">
                                {{'Active' | truncateText:[numberTruncateToggle, '']}}
                            </span>
                        </nb-toggle>
                        <nb-toggle style="padding-left:10px; padding-right: 10px" status="success"
                            [checked]="profileModel.published" (click)="readonlyProfile ? '' : publishedToggle()"
                            [disabled]="readonlyProfile">
                            <span [matTooltip]="numberTruncateToggle == 1 ? 'Published' : ''">
                                {{'Published' | truncateText: [numberTruncateToggle, '']}}
                            </span>
                        </nb-toggle>
                    </div>
                    <nb-toggle style="padding-left:10px; padding-right: 10px" status="success"
                        [checked]="profileModel.isMaster" (click)="readonlyProfile ? '' : masterToggle($event)"
                        [disabled]="readonlyProfile || checkIsMaster">
                        <span [nbSpinner]="checkIsMaster" nbSpinnerSize="tiny"
                            [matTooltip]="numberTruncateToggle == 1 ? 'Master' : ''">
                            {{'Master' | truncateText: [numberTruncateToggle, '']}}
                        </span>
                    </nb-toggle>
                </div>
            </div>
        </div>
    </div>

    <div class="basic-container" style="margin:25px">
        <mat-tab-group mat-stretch-tabs animationDuration="0ms" #tabGroup (selectedIndexChange)="contactTab = null"
            (selectedTabChange)="tabChanged($event)" [(selectedIndex)]="selectedIndex"
            *ngIf="flag == false ;else flagTrue">
            <mat-tab label="General" *nbIsGranted="['view', resourceTab.general]">
                <ng-template matTabContent>
                    <app-general-tab [data]='profileModel' [readonlyProfile]="readonlyProfile"></app-general-tab>
                </ng-template>
            </mat-tab>
            <mat-tab #tabIndexReasonBio label="Reasons & Bio" *nbIsGranted="['view', resourceTab.reason_bio]">
                <ng-template matTabContent>
                    <app-bio-n-reason [id]='profileModel.profileId' [readonlyProfile]="readonlyProfile">
                    </app-bio-n-reason>
                </ng-template>
            </mat-tab>
            <mat-tab label="Contacts" *nbIsGranted="['view', resourceTab.contacts]">
                <ng-template mat-tab-label>
                    Contact ({{profileModel?.profileAdditionDetail?.totalContact || 0}})
                    <app-add-contact-button [id]="id" [type]="'Profile'" [isActivePrimary]="true" (onRefresh)="
                        !isHasPrimaryContact && !contactTab ? refreshData() : '';
                        contactTab ? contactTab.refreshData(true) : ''; resetContacts()"
                        (onRefreshData)="onRefresh($event)">
                    </app-add-contact-button>
                </ng-template>
                <ng-template matTabContent>
                    <app-contact-relationship-grid #contactGrid [relationshipId]="id" [relationshipType]="'Profile'"
                        [isHidePrimaryContact]="false" [formatData]="formatContactType" [showContactType]="true"
                        (getPrimaryContactRelationship)="updatePrimaryContact($event); resetContacts()"
                        [maxHeight]="'60vh'" (refreshDataValue)="onRefresh($event)">
                    </app-contact-relationship-grid>
                    <!-- (refreshParentData)="refreshData()" -->
                    <!-- (getPrimaryContactRelationship)="updatePrimaryContact($event)" -->
                </ng-template>
            </mat-tab>
            <mat-tab label="Locations" *nbIsGranted="['view', resourceTab.locations]">
                <ng-template matTabContent>
                    <app-location-tab [profileDetails]="profileModel" [readonlyProfile]="readonlyProfile">
                    </app-location-tab>
                </ng-template>
            </mat-tab>
            <mat-tab #tabIndexMedia label="Multimedia" *nbIsGranted="['view', resourceTab.multimedia]">
                <ng-template matTabContent>
                    <app-multi-media [profileId]="profileModel.profileId" (refreshData)="refreshData()"
                        [primaryImage]="profileModel.primaryImage" [primaryVideo]="profileModel.primaryVideo"
                        [readonlyMultiMedia]="readonlyProfile" [displayName]="profileModel.displayName">
                    </app-multi-media>
                </ng-template>
            </mat-tab>
            <mat-tab label="Files" *nbIsGranted="['view', 'table-profile-file']">
                <ng-template mat-tab-label>
                  Files
                  <button *nbIsGranted="['view', 'add-profile-file']" #nbFilesBtn="nbPopover" mat-icon-button color="primary" debouncedClick
                    [throttleTime]="500" (throttleClick)="nbPopoverCLick()" [nbPopover]="addFilesMenu" nbPopoverPlacement="left">
                    <mat-icon>add</mat-icon>
                  </button>
                </ng-template>
                <ng-template matTabContent>
                    <app-profile-file-tab #profileFileTab [profileModel]="profileModel" [perm]="fileResource">
                    </app-profile-file-tab>
                </ng-template>
            </mat-tab>
            <mat-tab #tabIndexSeo label="SEO" *nbIsGranted="['view', resourceTab.seo]">
                <ng-template mat-tab-label>
                    SEO
                    <button mat-icon-button color="primary" debouncedClick [throttleTime]="500"
                        (throttleClick)="addNewProfileSeo()">
                        <mat-icon>add</mat-icon>
                    </button>
                </ng-template>
                <ng-template matTabContent>
                    <app-seo-tab-table #profileSeo [seoInfoId]="seoInfoId" [profileId]="profileModel.profileId"
                        [displayName]="profileModel.displayName" [tooltipProp]="tooltipProp">
                    </app-seo-tab-table>
                </ng-template>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    Tasks ({{profileModel?.profileAdditionDetail?.totalTask || 0}})
                    <button mat-icon-button color="primary" debouncedClick [throttleTime]="500"
                        (throttleClick)="createTask()">
                        <mat-icon>add</mat-icon>
                    </button>
                </ng-template>
                <ng-template matTabContent>
                    <app-task-grid-by-profile-id #taskGrid [profileId]="id" [saleModel]="profileModel"
                        (refreshDataValue)="onRefresh($event)"></app-task-grid-by-profile-id>
                </ng-template>
            </mat-tab>
            <mat-tab label="Note" *nbIsGranted="['view', 'table-note-profile']">
                <ng-template mat-tab-label>
                    Note
                    <button mat-icon-button color="primary" debouncedClick [throttleTime]="500" (throttleClick)="noteService.pushNoteDetails({
                    referenceId: id,
                    referenceType: 'SALEPROFILE',
                    referenceName: profileModel.displayName
                })">
                        <mat-icon>add</mat-icon>
                    </button>
                </ng-template>
                <ng-template matTabContent>
                    <app-note-tab #gridNoteTab [id]="id" [referenceType]="'SALEPROFILE'" [resource]="resource"
                        (editChange)="noteService.pushNoteDetails($event.row, profileModel.displayName)"
                        (deletedChange)="overlayNoteDetails ? overlayNoteDetails.deleteNoteFromOutSide($event) : '';getLastNote($event, true)"
                        [nbSpinner]="overlayNoteDetails?.isLoading | async">
                    </app-note-tab>
                </ng-template>
            </mat-tab>
            <mat-tab label="Activities" *nbIsGranted="['view', resourceTab.activities]">
                <ng-template mat-tab-label>
                    Activities
                    <button mat-icon-button color="primary" appAddActivityLogBtn [id]="profileModel.profileId"
                        type="PROFILE">
                        <mat-icon>add</mat-icon>
                    </button>
                </ng-template>
                <ng-template matTabContent>
                    <app-activity-tab [profileId]="profileModel.profileId" [profileModel]="profileModel"
                        [tooltipProp]="tooltipProp"></app-activity-tab>
                </ng-template>
            </mat-tab>
            <mat-tab [label]="'Relationship ' +  '(' + profileModel.relationship + ')'"
                *nbIsGranted="['view', resourceTab.relationship]">
                <ng-template matTabContent>
                    <app-profile-relationship-tab (callBackRefresh)="reloadProfile($event)"
                        [profileId]="profileModel.profileId" [isOpenDialog]="openByDialog"
                        [readonlyProfile]="readonlyProfile" [tooltipProp]="tooltipProp">
                    </app-profile-relationship-tab>
                </ng-template>
            </mat-tab>
            <mat-tab *nbIsGranted="['view', resourceTab.proposal]">
                <ng-template mat-tab-label>
                    Proposal
                    <app-button-add-proposal [profileId]="profileModel.profileId"
                        (onRefresh)="profileProposal ? profileProposal.refreshData($event) : null"></app-button-add-proposal>
                </ng-template>
                <ng-template matTabContent>
                    <app-proposal-tab #profileProposal [artistId]="profileModel.profileId"></app-proposal-tab>
                </ng-template>
            </mat-tab>
            <!-- 2022-05-17 gnguyen start add -->
            <mat-tab label="More Info" *nbIsGranted="['view', resourceTab.more_info]">
                <ng-template matTabContent>
                    <app-extend-data [profileId]="profileModel.profileId" [extendData]="profileModel?.extendData"
                        (onRefresh)="resetContacts()">
                    </app-extend-data>
                </ng-template>
            </mat-tab>

            <!-- 2022-05-17 gnguyen end add -->
        </mat-tab-group>
        <ng-template #flagTrue>
            <mat-tab-group mat-stretch-tabs animationDuration="0ms" #tabGroup (selectedIndexChange)="contactTab = null"
                (selectedTabChange)="tabChanged($event)" [selectedIndex]="2">
                <mat-tab label="General" *nbIsGranted="['view', resourceTab.general]">
                    <ng-template matTabContent>
                        <app-general-tab [data]='profileModel' [readonlyProfile]="readonlyProfile"></app-general-tab>
                    </ng-template>
                </mat-tab>
                <mat-tab #tabIndexReasonBio label="Reasons & Bio" *nbIsGranted="['view', resourceTab.reason_bio]">
                    <ng-template matTabContent>
                        <app-bio-n-reason [id]='profileModel.profileId' [readonlyProfile]="readonlyProfile">
                        </app-bio-n-reason>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Contacts">
                    <ng-template mat-tab-label>
                        Contact ({{profileModel?.profileAdditionDetail?.totalContact || 0}})
                        <app-add-contact-button [id]="id" [type]="'Profile'" [isActivePrimary]="true"
                            (onRefresh)="contactTab ? contactTab.refreshData(true) : ''; isHasPrimaryContact ? '': refreshData(); resetContacts()"
                            (refreshDataValue)="onRefresh($event)">
                        </app-add-contact-button>
                    </ng-template>
                    <ng-template matTabContent>
                        <app-contact-relationship-grid #contactGrid [relationshipId]="id" [relationshipType]="'Profile'"
                            [formatData]="formatContactType" [isHidePrimaryContact]="false"
                            (refreshDataValue)="onRefresh($event)" (getPrimaryContactRelationship)="updatePrimaryContact($event);
                              resetContacts()" [maxHeight]="'60vh'">
                            <!-- (refreshParentData)="refreshData()" -->
                            <!-- (getPrimaryContactRelationship)="updatePrimaryContact($event)" -->
                        </app-contact-relationship-grid>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Locations" *nbIsGranted="['view', resourceTab.locations]">
                    <ng-template matTabContent>
                        <app-location-tab [profileDetails]="profileModel" [readonlyProfile]="readonlyProfile">
                        </app-location-tab>
                    </ng-template>
                </mat-tab>
                <mat-tab #tabIndexMedia label="Multimedia" *nbIsGranted="['view', resourceTab.multimedia]">
                    <ng-template matTabContent>
                        <app-multi-media [profileId]="profileModel.profileId" (refreshData)="refreshData()"
                            [primaryImage]="profileModel.primaryImage" [primaryVideo]="profileModel.primaryVideo"
                            [readonlyMultiMedia]="readonlyProfile" [displayName]="profileModel.displayName">
                        </app-multi-media>
                    </ng-template>
                </mat-tab>
                <mat-tab #tabIndexSeo label="SEO" *nbIsGranted="['view', resourceTab.seo]">
                    <ng-template matTabContent>
                        <app-seo-tab [profileId]="profileModel.profileId" [isOpenDialog]="openByDialog"
                            [displayName]="profileModel.displayName" [readonlySEO]="readonlyProfile"
                            [isDuplicate]="isDuplicate" [seoInfoId]="seoInfoId" [tooltipProp]="tooltipProp">
                        </app-seo-tab>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Note" *nbIsGranted="['view', 'table-note-profile']">
                    <ng-template mat-tab-label>
                        Note
                        <button mat-icon-button color="primary" debouncedClick [throttleTime]="500" (throttleClick)="noteService.pushNoteDetails({
                      referenceId: id,
                      referenceType: 'SALEPROFILE',
                      referenceName: profileModel.displayName
                  })">
                            <mat-icon>add</mat-icon>
                        </button>
                    </ng-template>
                    <ng-template matTabContent>
                        <app-note-tab #gridNoteTab [id]="id" [referenceType]="'SALEPROFILE'" [resource]="resource"
                            (editChange)="noteService.pushNoteDetails($event.row, profileModel.displayName)"
                            (deletedChange)="overlayNoteDetails ? overlayNoteDetails.deleteNoteFromOutSide($event) : ''; "
                            [nbSpinner]="overlayNoteDetails?.isLoading | async">
                        </app-note-tab>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Activities" *nbIsGranted="['view', resourceTab.activities]">
                    <ng-template mat-tab-label>
                        Activities
                        <button mat-icon-button color="primary" appAddActivityLogBtn [id]="profileModel.profileId"
                            type="PROFILE">
                            <mat-icon>add</mat-icon>
                        </button>
                    </ng-template>
                    <ng-template matTabContent>
                        <app-activity-tab [profileId]="profileModel.profileId" [profileModel]="profileModel"
                            [tooltipProp]="tooltipProp"></app-activity-tab>
                    </ng-template>
                </mat-tab>
                <mat-tab [label]="'Relationship ' +  '(' + profileModel.relationship + ')'"
                    *nbIsGranted="['view', resourceTab.relationship]">
                    <ng-template matTabContent>
                        <app-profile-relationship-tab #tabRelationship [profileId]="profileModel.profileId"
                            [isOpenDialog]="openByDialog" [readonlyProfile]="readonlyProfile"
                            [tooltipProp]="tooltipProp">
                        </app-profile-relationship-tab>
                    </ng-template>
                </mat-tab>
                <mat-tab *nbIsGranted="['view', resourceTab.proposal]">
                    <ng-template mat-tab-label>
                        Proposal
                        <app-button-add-proposal [profileId]="profileModel.profileId"
                            (onRefresh)="profileProposal ? profileProposal.refreshData($event) : null"></app-button-add-proposal>
                    </ng-template>
                    <ng-template matTabContent>
                        <app-proposal-tab #profileProposal [artistId]="profileModel.profileId"></app-proposal-tab>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </ng-template>
    </div>
</ng-template>

<ng-template #bodyAutoDataStateLog>
    <app-automate-datastate-log [profileId]="profileModel.profileId ?? id" [profileModel]="profileModel"
        [readonlyProfile]="readonlyProfile" [tooltipProp]="tooltipProp">
    </app-automate-datastate-log>
</ng-template>

<ng-template #blankLastNote>
    <app-blank-page fxFlex="auto" [linkSrc]="'assets/images/notetaking.gif'"
        [message]="'Do you wish to add a new note?'" [contentTemplate]="contentEmptyLastNote">
    </app-blank-page>
</ng-template>

<ng-template #contentEmptyLastNote>
    <span class="mb-2"><strong>Do you wish to add a new note?</strong></span>
    <button class="mb-2" mat-stroked-button color="primary" (throttleClick)="noteService.pushNoteDetails({
      referenceId: id,
      referenceType: 'SALEPROFILE',
      referenceName: profileModel.displayName
  })" debouncedClick [throttleTime]="500">
        Add new
        <mat-icon>add</mat-icon>
    </button>
</ng-template>


<ng-template #noAutomateBody>
    <div class="opacity-scroll" [ngStyle]=" isAutomateDataState && currentUrl.startsWith('/configuration/profile')
    ? {'overflow': 'auto', 'height': '100vh'}
    : {'overflow': 'auto', 'height': '100%'}">
        <ng-container [ngTemplateOutlet]="bodyProfile"></ng-container>
    </div>
</ng-template>

<ng-template #copied>
    <span class="copied">
        Copied!!
    </span>
</ng-template>


<ng-template #addFilesMenu class="column d-flex flex-column align-items-start">
    <input #fileInput hidden="true" type="file" onclick="this.value =null" (change)="addProfileFile($event)"
        [extension]='{"extensions":["doc", "docx", "xls", "xlsx", "ppt", "pdf", "html", "zip", "csv", "txt"]}'
        accept=".doc,.docx,.xls,.xlsx,.ppt,.pdf,.html,.zip,.csv,.txt" />

    <button mat-flat-button class="col float-start" debouncedClick [throttleTime]="500" matTooltipPosition="below"
        (throttleClick)="fileInput.click()">
        <mat-icon style="color: #007100;">add</mat-icon> Add New Files
    </button>

    <hr class="custom-divider">
    <button mat-flat-button class="col d-flex flex-row" debouncedClick [throttleTime]="500" matTooltipPosition="below"
        (click)="openURLDialogRef()">
        <mat-icon style="color: #007100;">add</mat-icon> Add URL
    </button>
</ng-template>

<ng-template #emailRelationship>
    <nb-card class="nb-card-contact">
        <nb-card-header style="background-color: #307abd;color: white;text-align: center;">
            Potential duplicate data
        </nb-card-header>
        <nb-card-body class="body-card-contact">
            <div *ngFor="let item of listEmailRelationship">
                <div class="email-details" (click)="openProfileRelationshipDetail(item)">
                    <div class="container-display-name" fxFlex>
                        {{item?.displayName | truncateText: [27,'...']}}
                    </div>
                    <div class="container-type-name">
                        <button mat-flat-button class="type-name-detail" style="font-size: xx-small;"
                            [ngStyle]="{'background-color':item?.colorCode}">{{item?.displayTypeName
                            | truncateText: [15,'...']}}</button>
                    </div>
                    <!-- <button mat-flat-button style="line-height: 25px;color: white;"
                            [ngStyle]="{'background-color':event.eventInfo.task?.taskStatus.colorCode}"
                            matTooltip="{{event.eventInfo.task?.taskStatus?.taskStatusName}}">{{event.eventInfo.task?.taskStatus?.taskStatusName
                            | truncateText: [20,'...']}}</button> -->
                </div>
            </div>
        </nb-card-body>
    </nb-card>
</ng-template>

<ng-template #SEOAuditTemp>
    <div style="max-height: 40vh; max-width: 420px;">
        <app-seo-audit [id]="id" [focusSEOAudit]="focusSEOAuditElement" [focusProp]="focusSEOAudit"
            (overviewScore)="primarySeo?.seoInfo?.overallScore ? primarySeo.seoInfo.overallScore = $event: ''">
        </app-seo-audit>
        <hr>
    </div>
</ng-template>