<form [formGroup]="artistDetailsForm">
    <div class="row">
        <!-- Company name -->
        <div class="col-sm-12 notify-sec">
            <div class="form-group">
                <label class="form-label">Artist or Company name*</label>
                <input formControlName="Scarlettname" required type="text" placeholder="Your Artist or Company name..."
                    class="contact form-control">
                <mat-error
                    *ngIf="artistDetailsForm.controls.Scarlettname.errors && (artistDetailsForm.controls.Scarlettname.dirty || artistDetailsForm.controls.Scarlettname.touched)">
                    {{artistDetailsForm.controls.Scarlettname['errorMessage']}}</mat-error>
            </div>
        </div>
        <!-- Website -->
        <div class="col-sm-12 notify-sec">
            <div class="form-group">
                <label class="form-label">Website*</label>
                <input formControlName="Webpage" required type="text" placeholder="Your website..."
                    class="contact form-control">
                <mat-error
                    *ngIf="artistDetailsForm.controls.Webpage.errors && (artistDetailsForm.controls.Webpage.dirty || artistDetailsForm.controls.Webpage.touched)">
                    {{artistDetailsForm.controls.Webpage['errorMessage']}}</mat-error>
            </div>
        </div>
        <!-- Facebook -->
        <div class="col-sm-12 notify-sec">
            <div class="form-group">
                <label class="form-label">Facebook</label>
                <input type="text" formControlName="Facebook" placeholder="Your facebook..."
                    class="contact form-control">
                <mat-error
                    *ngIf="artistDetailsForm.controls.Facebook.errors && (artistDetailsForm.controls.Facebook.dirty || artistDetailsForm.controls.Facebook.touched)">
                    {{artistDetailsForm.controls.Facebook['errorMessage']}}</mat-error>
            </div>
        </div>
        <!-- country -->
        <div class="col-sm-12 notify-sec">
            <div class="form-group">
                <label class="form-label">Country*</label>
                <select formControlName="Country" required id="country" name="country" class="form-control">
                    <option *ngFor="let country of countriesArr" [selected]="country.key =='United States of America'"
                        [value]="country.key">{{country.key}}</option>
                </select>
                <mat-error
                    *ngIf="artistDetailsForm.controls.Country.errors && (artistDetailsForm.controls.Country.dirty || artistDetailsForm.controls.Country.touched)">
                    {{artistDetailsForm.controls.Country['errorMessage']}}</mat-error>
            </div>
        </div>
        <!-- street -->
        <div class="col-sm-12 notify-sec">
            <div class="form-group">
                <label class="form-label">Street</label>
                <input formControlName="Address" type="text" placeholder="Your Street..." class="contact form-control">
            </div>
        </div>
        <!-- Street 2 -->
        <div class="col-sm-12 notify-sec">
            <div class="form-group">
                <label class="form-label">Street 2</label>
                <input type="text" formControlName="Address2" placeholder="Your Street 2..."
                    class="contact form-control">
            </div>
        </div>

           <!-- City -->
        <div class="col-sm-12 notify-sec">
            <div class="form-group">
                <label class="form-label">City*</label>
                <input type="text" required formControlName="City" placeholder="Your City..."
                    class="contact form-control">
                <mat-error
                    *ngIf="artistDetailsForm.controls.City.errors && (artistDetailsForm.controls.City.dirty || artistDetailsForm.controls.City.touched)">
                    {{artistDetailsForm.controls.City['errorMessage']}}</mat-error>
            </div>
        </div>

        <!-- state -->
        <div class="col-sm-12 notify-sec">
            <div class="form-group">
                <label class="form-label">State</label>
                <input type="text" formControlName="State" placeholder="Your State..." class="contact form-control">
            </div>
        </div>
     
        <!-- Nearest major city* -->
        <div class="col-sm-12 notify-sec">
            <div class="form-group">
                <label class="form-label">Nearest major city*</label>
                <input type="text" required formControlName="NearestBigCity" placeholder="Your nearest major city..."
                    class="contact form-control">
                <mat-error
                    *ngIf="artistDetailsForm.controls.NearestBigCity.errors && (artistDetailsForm.controls.NearestBigCity.dirty || artistDetailsForm.controls.NearestBigCity.touched)">
                    {{artistDetailsForm.controls.NearestBigCity['errorMessage']}}</mat-error>
            </div>
        </div>
        <!-- Will travel?* -->
        <div class="col-sm-12 notify-sec">
            <div class="form-group">
                <label class="form-label">Will travel?*</label>
                <select formControlName="WillTravel" required id="travel" name="travel" class="form-control">
                    <option value="1">Yes</option>
                    <option selected value="0">No</option>
                </select>
            </div>
        </div>
        <!-- How far -->
        <div class="col-sm-12 notify-sec">
            <div *ngIf="artistDetailsForm.controls.WillTravel.value == '1'" class="form-group notify-sec">
                <label class="form-label">How far?*</label>
                <select formControlName="HowFar" id="how-far" name="how-far" class="form-control">
                    <option value="International">International</option>
                    <option value="National">National</option>
                    <option value="0TO100">0 to 100 (hours)</option>
                    <option value="100TO250">100 to 250 (hours)</option>
                    <option value="CaseByCase">Case-by-case</option>
                </select>
                <mat-error
                    *ngIf="artistDetailsForm.controls.HowFar.errors && (artistDetailsForm.controls.HowFar.dirty || artistDetailsForm.controls.HowFar.touched)">
                    {{artistDetailsForm.controls.HowFar['errorMessage']}}</mat-error>
            </div>
        </div>
        <!-- Postcode/Zip -->
        <div class="col-sm-12 notify-sec">
            <div class="form-group">
                <label class="form-label">Postcode/Zip</label>
                <input formControlName="Zipcode" placeholder="Your Postcode/Zip..." type="text"
                    class="contact form-control">
            </div>
        </div>
    </div>
</form>