import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { SettingService } from 'src/app/shared/services/setting.service';
import { AgreementOnboardingForm } from '../onboarding-form-profile.model';

@Component({
  selector: 'app-agreement-tab',
  templateUrl: './agreement-tab.component.html',
  styleUrls: ['./agreement-tab.component.scss', '../onboarding-form.component.scss']
})
export class AgreementTabComponent implements OnInit {

  agreementForm: FormGroup;
  constructor(
    private formBuilder: RxFormBuilder,
    private settingService: SettingService
  ) {
    this.agreementForm = this.formBuilder.formGroup(AgreementOnboardingForm, new AgreementOnboardingForm());
    this.settingService.getSettingByKeyAndGroup('TERMS_ONBOARDING', 'FRONT_FACING').subscribe(resp => {
      if (resp.result) {
        this.termForm = resp.result.value.split('/&nbs/');
      }
    });
  }

  termForm: string[] = [];

  ngOnInit(): void { }
  comfirmAgreementClicked(event) {
    this.agreementForm.controls.Term.setValue(event.target.value);
  }
  getAgreementOnboardingForm(): AgreementOnboardingForm {
    return this.agreementForm.value;
  }
}
