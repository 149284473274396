import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AttendanceModel } from '../attendance-management.model';
import { FormGroup } from '@angular/forms';
import { QuillConfiguration } from 'src/app/shared/components/stand-alone-component/rich-inline-edit/rich-inline-edit.component';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { DateTimeFormatPipe } from 'src/app/shared/pipes/date-time-format.pipe';
import { AttendanceManagementService } from '../attendance-management.service';
import { NbToastrService } from '@nebular/theme';
import { UserModel } from '../../user-management/user-model';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DISPLAY_INPUT_DATE_NOT_SECONDS } from 'src/app/shared/components/stand-alone-component/primas-custom-date-time-picker/date-format.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { RequestAttendanceComponent } from '../request-attendance/request-attendance.component';
import { Router } from '@angular/router';
import { DailyReportService } from 'src/app/shared/services/daily-report.service';
import { PagingDailyReport } from 'src/app/shared/models/daily-report.model';

@Component({
  selector: 'app-attendance-dialog',
  templateUrl: './attendance-dialog.component.html',
  styleUrls: ['./attendance-dialog.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE], },
    { provide: MAT_DATE_FORMATS, useValue: DISPLAY_INPUT_DATE_NOT_SECONDS }]
})
export class AttendanceDialogComponent implements OnInit {
  isPunchOut: boolean = true;
  loading = false;
  frmAttendance: FormGroup;
  editorOptions = QuillConfiguration;
  titleAttendance: string = 'Punch In';
  attendanceModel: AttendanceModel;
  user;
  timeZone: string = 'GMT';
  durationDisplay: string = '';
  totalDuration: number = 0;
  hasReported: boolean = false;

  constructor(
    private frmBuilder: RxFormBuilder,
    private dateTimeFormatPipe: DateTimeFormatPipe,
    private attendanceService: AttendanceManagementService,
    private dialogRef : MatDialogRef<AttendanceDialogComponent>,
    private toast: NbToastrService,
    private router: Router,
    private dialog: MatDialog,
    private dailyReportService: DailyReportService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.attendanceModel = data?.attendance ?? new AttendanceModel();
    this.user = data?.user ?? new UserModel();
    this.hasReported = data?.hasReported ?? false;
  }
   
  ngOnInit() {
    this.loading = true;
    this.frmAttendance = this.frmBuilder.formGroup(AttendanceModel, this.attendanceModel);
    var toDay = new Date();
    if (this.attendanceModel?.punchinDate) {
      this.isPunchOut = false;
      this.titleAttendance = 'Punch Out';
      var punchInDate = new Date(this.attendanceModel.punchinDate);
      this.totalDuration += (toDay.getTime() - punchInDate.getTime()) / 3600000;
      this.frmAttendance?.get('punchoutDate')?.setValue(new Date());
    } else {
      this.frmAttendance.get('punchinDate').setValue(new Date());
    }
    this.attendanceService.getTodayAttendanceByProfileId(this.user?.nameid, -new Date().getTimezoneOffset()/60).subscribe(attendances => {
      if (attendances.result && attendances.result.length > 0) {
        var getPunchinFirst = false;
        attendances.result.map(attendance => {
          var punchInDate = new Date(attendance?.punchinDate);
          var punchOutDate = attendance.punchoutDate? new Date(attendance.punchoutDate) : new Date();
          if (punchInDate.getDate() === toDay.getDate() && punchInDate.getMonth() === toDay.getMonth()
              && punchInDate.getFullYear() === toDay.getFullYear() && attendance.punchoutDate) {
            this.totalDuration += (punchOutDate.getTime() - punchInDate.getTime()) / 3600000;
            // if (getPunchinFirst == false && this.attendanceModel?.punchinTime) {
            //   this.frmAttendance.get('punchinDate').setValue(new Date(attendance?.punchinDate));
            //   getPunchinFirst = true;
            // }
          }
        });
        this.durationDisplay += Math.floor(this.totalDuration) + 'h ' + Math.round((this.totalDuration % 1) * 60) + 'm';
      }
    });
    if(!this.isPunchOut){
      this.dailyReportService.hasReportedToday(this.user?.nameid).subscribe(resp => {
        if(resp.result){
          this.hasReported = resp.result;
        }
      });
    }
    this.loading = false;
    if (!this.isPunchOut) {
      setInterval(() => {
        this.frmAttendance?.get('punchoutDate')?.setValue(new Date());
      }, 60000);
    } else {
      setInterval(() => {
        this.frmAttendance?.get('punchinDate')?.setValue(new Date());
      }, 60000);
    }

    // get time zone offset
    var timezoneOffset = new Date().getTimezoneOffset();
    var offsetDecimal = -(timezoneOffset / 60);
    if (offsetDecimal >= 0) {
      this.timeZone += ' +' + offsetDecimal.toFixed(2);
    } else {
      this.timeZone += ' ' + offsetDecimal.toFixed(2);
    }
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  saveAttendance() {
    if (this.frmAttendance.valid) {
      this.loading = !this.loading;
      const model: AttendanceModel = Object.assign({}, this.frmAttendance.value);
      if (!model.attendanceId) {
        model.attendanceId = 0;
      }
      if (!this.isPunchOut) {
        model.punchinDate = this.attendanceModel?.punchinDate;
      }
      model.profileId = this.user?.nameid;
      // get time zone offset
      var timezoneOffset = new Date().getTimezoneOffset();
      var offsetDecimal = -(timezoneOffset / 60);
      model.punchinOffset = offsetDecimal;
      model.punchoutOffset = offsetDecimal;
      this.handleAttendance(model, !this.hasReported);
    }
  }

  handleAttendance(model: AttendanceModel, isReport: boolean = false){
    this.attendanceService.saveAttendance(model).subscribe(resp => {
      if (resp.result) {
        if (!this.isPunchOut) {
          this.toast.success(`Punch Out successfully`, 'Success');
          if(isReport){
            this.openWorkLogAndSave();
          }
        } else {
          this.toast.success(`Punch In successfully`, 'Success');
        }
        
        this.closeDialog();
      }
    },
    (error: HttpErrorResponse) => {
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        data: {
          message: "It seems like you are not on the company's internal network. Do you wish to request an attendance?"
        }
      });
      
      const isRequestPunchin = this.attendanceModel.attendanceId ? false : true;

      dialogRef.afterClosed().subscribe(response => {
        if (response) {
          const attendanceRef = this.dialog.open(RequestAttendanceComponent, {
            disableClose: true,
            width: '600px',
            autoFocus: false,
            data: {
              attendance: this.attendanceModel,
              user: this.user,
              isRequestPunchin: isRequestPunchin,
            }
          });
          attendanceRef.afterClosed().subscribe(response => {
            if (response) {
              if(isReport){
                this.openWorkLogAndSave();
              }
              this.closeDialog();
            }
          });
        }
      });
    }).add(() => {
      this.loading = !this.loading;
    });
  }

  confirmSaveAttendance() {
    if (this.totalDuration < 9.50 && !this.isPunchOut) {
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        data: {
          message: "You haven't completed enough working hours. Do you wish to punch out?"
        }
      });
      dialogRef.afterClosed().subscribe(response => {
        if (response) {
          this.saveAttendance();
        }
      });
    } else {
      this.saveAttendance();
    }
  }
  openWorkLogAndSave(){
    this.router.navigate(['/configuration/work-log/create-new']);
  }
}
