import { switchMap, takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { Setting } from './../../../../shared/models/setting.model';
import { ConfirmModalComponent } from './../../../../shared/components/confirm-modal/confirm-modal.component';
import { AfterViewInit, Component, Inject, OnInit, Input, TemplateRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { NbToastrService, NbDialogRef } from '@nebular/theme';
import { ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NbAuthService, NbAuthJWTToken } from '@nebular/auth';
import { RxFormBuilder, model } from '@rxweb/reactive-form-validators';
import { HeaderComponent } from 'src/app/@theme/components';
import { HeadshotComponent } from 'src/app/shared/components/headshot/headshot.component';
import { TblActionType } from 'src/app/shared/enums/tbl-action-type.enum';
import { UserModel } from '../user-model';
import { UserRoleModel } from '../user-role-model';
import { UserService } from '../user.service';
import { ActivatedRoute } from '@angular/router';
import { F } from '@angular/cdk/keycodes';
import { QuillConfiguration } from 'src/app/shared/components/stand-alone-component/rich-inline-edit/rich-inline-edit.component';
import { Helper } from 'src/app/shared/utility/Helper'
import { Clipboard } from '@angular/cdk/clipboard';
import { NbAccessChecker } from '@nebular/security';

@Component({
  selector: 'app-add-edit-user',
  templateUrl: './add-edit-user.component.html',
  styleUrls: ['./add-edit-user.component.scss']
})
export class AddEditUserComponent implements OnInit {
  @Input() editable: boolean = false;
  @ViewChild(HeadshotComponent) headShot: HeadshotComponent;
  @ViewChild('textConfirmGenerate', { static: true }) textConfirmGenerate: TemplateRef<any>;
  @ViewChild('copyDialogContainer', { static: true }) copyDialogContainer: TemplateRef<any>;
  //2022-03-17 thoainda start add
  private destroyed$ = new Subject();
  private routeSub: Subscription;
  id: string;
  //2022-03-17 thoainda end add
  isInputShown: boolean;
  action: TblActionType;
  userModel: UserModel;
  frmUser: FormGroup;
  readonlyCaller = false;
  isLoading = false;
  listRoles: UserRoleModel[] = [];
  editorOptions = QuillConfiguration
  maskedApiToken: string = "";
  fullApiToken: string = "";
  generateMessage: string = "";
  isCopy: boolean = false;
  hasPermissionEditEmployeeInfo: boolean = false;

  constructor(
    public dialModalRef: MatDialogRef<AddEditUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    private frmBuilder: RxFormBuilder,
    private authService: NbAuthService,
    private toast: NbToastrService,
    private cdref: ChangeDetectorRef,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private clipboard: Clipboard,
    private permissionService: NbAccessChecker,
  ) {
    if (data.model) {
      this.id = data.model.id;
    }
    this.id = data.id ?? this.id;
    this.action = data.action;
    this.userModel = data.model ?? new UserModel();
    this.userModel.action = data.action;
    this.routeSub = this.activatedRoute.params.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(params => {
      if (params.id !== undefined) {
        this.userModel.id = params.id;
      }
    });
    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.user = token.getPayload();
        }
      });
    this.permissionService.isGranted('view', 'edit-employee-special').subscribe(e => this.hasPermissionEditEmployeeInfo = e);
  }
  user;
  dataEntryRole: Setting;
  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
  ngOnInit() {
    this.frmUser = this.frmBuilder.formGroup(UserModel, this.userModel);
    this.dialModalRef.updatePosition({ right: '0', });
    this.userService.getRoles().subscribe(res => {
      if (res != null && res.length > 0) {
        this.listRoles = [...res];
      }
    });
    if (this.action == 3) {
      this.userService.getUserById(this.id).pipe(
        takeUntil(this.destroyed$)
      ).subscribe(data => {
        this.userModel = data.result;
        var arrayRole = [this.user.role.toString()];
        arrayRole = [...arrayRole[0].split(',')];
        var existingRoleAdmin = arrayRole.find(x => x == "admin");
        if (existingRoleAdmin == "admin") {
          this.readonlyCaller = false;
        } else {
          this.readonlyCaller = true;
        }
      })
    }

    this.userService.getAPIToken(this.id).pipe(takeUntil(this.destroyed$)).subscribe({
      next: (data) => {
        this.maskedApiToken = data.result;
      }
    })
  }
  closeDialog() {
    this.dialModalRef.close();
  }


  saveData() {
    if (this.frmUser.valid) {
      this.isLoading = !this.isLoading;
      const model: UserModel = Object.assign({}, this.frmUser.value);
      model.base64IMG = this.headShot.pictureHeadShot?.substring(this.headShot.pictureHeadShot?.lastIndexOf(',') + 1);
      model.pictureURL = this.userModel.pictureURL;
      if (this.action == 0) model.isActive = true;
      let isEmptyPassword = false;
      if (this.action == 0 && !model.password && !model.confirmPassword) {
        isEmptyPassword = true;
        model.password = Helper.makeid(6);
      }

      this.userService.saveUser(model).subscribe(resp => {
        if (resp.result) {
          this.toast.success(`Save user ${model.userName} successfully`, 'Success');

          if (this.action == 0 && isEmptyPassword) {
            this.userService.sendEmailResetPassword(resp.result.id).subscribe(emailResp => {
              if (emailResp.result) {
                this.toast.success(
                  `Please check email ${resp.result.email} to reset password`,
                  "Success")
              }
            })
          }

          this.dialModalRef.close(resp.result);
          if (this.action == TblActionType.Profile) {
            this.userService.pictureURLChange.next(resp.result.pictureURL?.replaceAll('\\', '/'));
          }
        }
      }).add(() => {
        this.isLoading = !this.isLoading;
      })
    }
  }

  openPopupCopyToken() {
    this.userService.generateAPIToken(this.id).subscribe({
      next: (response) => {
        if (response.result) {
          this.fullApiToken = window.atob(response.result);
          // var maskedToken = this.fullApiToken.slice(0, 6).padEnd(this.fullApiToken.length, "*");
          this.maskedApiToken = this.fullApiToken;
          this.dialog.open(ConfirmModalComponent, {
            data: {
              externalTemplate: this.copyDialogContainer,
              yesTitle: "DISABLED",
              falseTitle: "Close",
            }
          });
        }
      }
    })
  }

  generateToken(e) {
    e.preventDefault();
    if (this.maskedApiToken != null) {
      this.generateMessage = `Are you sure you want to generate new API Token? The old API Token will be disabled, and all the applications connected to it may not work properly`;
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        data: {
          externalTemplate: this.textConfirmGenerate,
        }
      });

      dialogRef.afterClosed().subscribe(response => {
        if (response) {
          this.openPopupCopyToken()
        }
      });
    } else {
      this.openPopupCopyToken();
    }
  }

  onCopyClick() {
    this.clipboard.copy(this.fullApiToken);
    this.toast.success('Copied this API Token to clipboard', 'Success');
    this.isCopy = true;
    setTimeout(() => {
      this.isCopy = false;
    }, 2000);
  }
}
