export const host = '.';
export const apiUrl = `${host}/api`;
export const tenantId = "common";

export const environment = {
  production: true,
  host,
  maxSizeUpload: 20971520,//20MB
  formatDateTime: 'MM/dd/yyyy HH:mm:ss',
  formatDateTimeZone: "yyyy-MM-dd'T'HH:mm:ss x",
  formatDate: 'MM/dd/yyyy',
  formateHoursTime: 'HH:mm:ss',
  apiAuth: apiUrl + '/auth',
  apiUser: apiUrl + '/usermanagement',
  apiCustomView: apiUrl + '/customview',
  apiProfile: apiUrl + '/profilemanagement',
  apiProfileManagement: apiUrl + '/profilemanagement',
  apiCategory: apiUrl + '/categorymanagement',
  apiContactManagement: apiUrl + '/contactmanagement',
  tagManagement: apiUrl + '/tagmanagement',
  locationManagement: apiUrl + '/locationmanagement',
  reasonManagement: apiUrl + '/reasonmanagement',
  seoInfoManagement: apiUrl + '/seomanagement',
  apiProducer: apiUrl + '/producermanagement',
  mediaManagement: apiUrl + '/mediamanagement',
  apiEnquiry: apiUrl + '/EnquiryBasketManagement',
  apiBooking: apiUrl + '/BookingManagement',
  apiActivityLog: apiUrl + '/ActivityLog',
  apiSetting: apiUrl + '/SettingManagement',
  apiBioRewrite: apiUrl + '/BioRewriteManagement',
  //2022-03-21 thoai add start
  apiSeoInfo: apiUrl + '/SeoManagement',
  //2022-03-21 thoai add end

  //2021-11-5 HMTien add start
  maxImageSizeUpload: 3145728, //3mb 02/03/2022 hmtien update
  //2021-11-5 HMTien add end
  //2022-03-21 hmtien add start
  apiOnboardingData: apiUrl + '/OnboardingData',
  //2023-03-21 hmtien add end
  apiTaskStatus: apiUrl + '/TaskManagement',
  apiTaskType: apiUrl + '/TaskManagement',

  apiDashboard: apiUrl + '/Dashboard',
  apiCampaign: apiUrl + '/CampaignManagement',
  apiTaskManagement: apiUrl + '/TaskManagement',
  aipNotification: apiUrl + '/Notification',
  apiOnboarding: apiUrl + '/OnBoarding',
  apiSummaryReport: apiUrl + '/SummaryReport',
  apiPermission: apiUrl + '/PermissionManagement',
  apiRole: apiUrl + '/rolemanagement',
  changeLogUrl: apiUrl + '/ChangeLogManagement',
  apiDiagnosticHistory: apiUrl + '/DiagnosticHistory',

  //2022-02-04 toanpq add start
  apiTaskScriptManagement: apiUrl + '/TaskScriptManagement',
  apiTaskScript: apiUrl + '/TaskScriptManagement',
  apiTaskScriptGroup: apiUrl + '/TaskScriptGroupManagement',
  apiTaskTemplate: apiUrl + '/TaskTemplateManagement',
  //2022-02-04 toanpq add end
  //2022-03-30 hmtien add start
  apiTaskPlanHistory: apiUrl + '/TaskPlanHistoryManagement',
  apiAutoAssignmentReport: apiUrl + '/AutoAssignmentReportManagement',
  //2022-03-30 hmtien add end
  apiSpotlightr: 'https://api.spotlightr.com/api',
  apiTracking: apiUrl + '/Tracking',
  apiAccessLog: apiUrl + '/AccessLogManagement',
  apiAutomateDataState: apiUrl + '/AutomateDataState',
  //2022-04-13 toanpq add datastate table
  apiDatastateManagement: apiUrl + '/DatastateManagement',
  apiBuyer: apiUrl + '/BuyerManagement',

  //2022-04-28 thoai add start
  apiCallEvent: apiUrl + '/CallEventManagement',
  //2022-04-28 thoai add end
  //2022/18/08 thangnpq add start
  apiCallbackMapping: apiUrl + '/CallbackMappingManagement',
  //2022/18/08 thangnpq add end
  apiSupportTicket: apiUrl + '/SupportTicket',
  apiAdminQueries: apiUrl + '/AdminQueries',
  apiQueryHistory: apiUrl + '/QueryHistory',
  apiFontFacing: apiUrl + '/FontFacing',
  apiFileSyncProgress: apiUrl + '/FileSyncProgress',
  apiSystemLogs: apiUrl + '/SystemLogsManagement',
  apiEmailAttachment: apiUrl + '/EmailAttachmentManagement',
  apiPageSpeed: apiUrl + '/PageSpeedManagement',
  apiPhotoProcessQueue: apiUrl + '/PhotoProcessQueueManagement',
  clientId: "7f6b7400-c61f-4196-9c78-5fff9bd6f4ab",
  authority: `https://login.microsoftonline.com/${tenantId}`,
  redirectUri: "https://hrm.primas.net:7543/",
  apiMapViewCategory: apiUrl + '/MapViewCategory',
  apiDataFields: apiUrl + '/DataFieldsManagement',
  apiSaleLead: apiUrl + '/SaleLeadManagement',
  apiPageNote: apiUrl + '/UserNoteManagement',
  realtimeNotification: apiUrl + '/RealtimeNotification',
  apiSaleOpportunity: apiUrl + '/SaleOpportunityManagement',
  apiSaleAccount: apiUrl + '/SaleAccountManagement',
  apiApiJob: apiUrl + '/ApiJob',
  apiUserTab: apiUrl + '/UserTabManagement',
  apiDynamicContent: apiUrl + '/DynamicContentManagement',
  apiProfileAdditionDetailManagement: apiUrl + '/ProfileAdditionDetailManagement',
  apiUserUpload: apiUrl + '/FileManagerBusiness',
  apiSaleConvertHistory: apiUrl + '/SaleConvertHistoryManagement',
  apiReminderHistory: apiUrl + '/ReminderHistoryManagement',
  apiUserToken: apiUrl + '/UserTokenManagement',
  apiFrontPageData: apiUrl + '/FrontFacing',
  apiGoogleSearchConsole: apiUrl + '/GoogleSearchConsole',
  titleLead: 'Prospect', //Leads => Prospect
  titleAccount: 'Employee', //Account => Employee
  titleOpportunity: 'Proposal', //Opportunity => Request for Proposal
  apiMessage: apiUrl + '/MessageManagement',
  apiSMSManagement: apiUrl + '/SMSManagement',
  titleProfile: 'Artist',
  title1xService: 'Client Fee',
  title1xProduct: 'Total Talent Cost',
  titleTotalValue: 'Profit',
  titleEstimate: 'Budget',
  titleClosedDate: 'Event Date',
  apiClickUp: apiUrl + '/ClickUp',
  apiUserAction: apiUrl + '/UserActionManagement',
  apiStepInFlow: apiUrl + '/StepInFlowManagement',
  apiWorkflowDetail: apiUrl+ '/WorkflowDetailManagement',
  apiCampaignWorkflow: apiUrl + '/CampaignWorkflowManagement',
  apiWorkflowQueue: apiUrl + '/WorkflowQueueManagement',
  apiWorkflowManagement: apiUrl + '/WorkflowManagement',
  apiWorkFlow: apiUrl + '/WorkFlow',
  userApiToken: apiUrl + '/UserApiTokenManagement',
  apiLeaveManagement: apiUrl + '/LeaveManagement',
  apiAttendanceManagement: apiUrl + '/AttendanceManagement',
  apiDailyReportManagement: apiUrl + '/DailyReportManagement',
};
