<!-- <app-primas-toolbar #primasToolbar (onAfterAddFinish)="refreshData(true)"
 [customView]="false" [table]="primasTable"
    [columns]="columnsGridAll" [resource]="resource.toolbar" 
    [addEditComponet]="addEditComponent">
</app-primas-toolbar> -->

<app-primas-table #primasTable (onRefresh)="refreshData($event)" [columnsTable]="columnsGridAll"
    [resource]="resource.table" [usingCustomEdit]="true" (customEditFunction)="onClickEdit($event)"
    [customAction3]="customAction3" [tableAction]="true" [allowSelectRow]="false" [optionHeight]="maxHeight">
</app-primas-table>

<ng-template #customAction3 let-row="row">
    <button mat-menu-item (click)="openConfirmDialog(row.contact)" [disabled]="row.primaryContact">
        <mat-icon [color]="row.primaryContact ? 'basic' : 'warn'">link_off</mat-icon>
        <span>Unlink</span>
    </button>
</ng-template>

<ng-template #primaryContact let-row="row">
    <div *ngIf="row.primaryContact === true;">
        <mat-icon aria-hidden="false" aria-label="check_circle" class="success-icon-table large">check_circle</mat-icon>
    </div>
</ng-template>

<ng-template #name let-row="row">
    <div (click)="onClickEdit({row, rowIndex: null})">
        <b class="pointer">{{row.contact.contactName}}</b>
    </div>
</ng-template>
<ng-template #lName let-row="row">
    <div (click)="onClickEdit({row, rowIndex: null})">
        <b class="pointer">{{row.contact.contactLastName}}</b>
    </div>
</ng-template>
<ng-template #contactEmail let-row="row">
    <ng-container [ngTemplateOutlet]="inlineContact" [ngTemplateOutletContext]="{
        row: row,
        value: row?.contact?.contactEmail ?? '',
        property: 'contactEmail',
        validProp: contactValidEmail
      }"></ng-container>
</ng-template>
<ng-template #contactPhone let-row="row">
    <ng-container [ngTemplateOutlet]="inlineContact" [ngTemplateOutletContext]="{
        row: row,
        value: row?.contact?.contactPhone ?? '',
        property: 'contactPhone',
        pipe: phonePipe
      }"></ng-container>
</ng-template>


<!-- <ng-template #lName let-row="row">
    <div (click)="onClickEdit({row, rowIndex: null})">
        <b class="pointer">{{row.contact.contactLastName}}</b>
    </div>
</ng-template> -->

<ng-template #inlineContact let-row="row" let-value="value" let-property="property" let-pipe="pipe"
    let-validProp="validProp">
    <app-inline-edit fxFlex="1 1 calc(100%-30px)" [inputData]="value"
        (handleSave)="editInlineRow($event, property, row)" [removePaddingBottom]="true" [widthDisplay]="'100%'"
        [usingMessageParent]="true" [validatorValue]="validProp" [enterToSubmit]="true" [escapeToCancel]="true"
        [pipe]="pipe" isAscii="true">
    </app-inline-edit>
</ng-template>

<ng-template #birthDate let-row="row">
    {{row.contact.birthDate | dateFormat}}
</ng-template>
<ng-template #contactEmail let-row="row">
    <span [ngStyle]="{'color': changeColor(row)}"> {{row?.contact?.contactEmail}}</span>
</ng-template>

<ng-template #contactType let-row="row">
    {{row?.contact?.showContactType}}
</ng-template>