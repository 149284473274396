import { Component, Inject, OnInit } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DISPLAY_INPUT_DATE_NOT_SECONDS } from 'src/app/shared/components/stand-alone-component/primas-custom-date-time-picker/date-format.model';
import { AttendanceModel, AttendanceRequestModel } from '../attendance-management.model';
import { FormGroup } from '@angular/forms';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { DateTimeFormatPipe } from 'src/app/shared/pipes/date-time-format.pipe';
import { AttendanceManagementService } from '../attendance-management.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NbToastrService } from '@nebular/theme';
import { UserModel } from '../../user-management/user-model';
import { Moment } from 'moment';
import { UserService } from '../../user-management/user.service';
import { PunchinRequestStatus, PunchoutRequestStatus } from 'src/app/shared/enums/attendance-status.enum';
import { NbAccessChecker } from '@nebular/security';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';

@Component({
  selector: 'app-request-attendance',
  templateUrl: './request-attendance.component.html',
  styleUrls: ['./request-attendance.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE], },
    { provide: MAT_DATE_FORMATS, useValue: DISPLAY_INPUT_DATE_NOT_SECONDS }]
})
export class RequestAttendanceComponent implements OnInit {
  attendanceModel: AttendanceModel;
  attendanceRequestModel: AttendanceRequestModel;
  titleRequestAttendance: string = '';
  timeZone: string = 'GMT';
  frmAttendanceRequest: FormGroup;
  user;
  loading = false;
  hasEditPermission: boolean = false;

  isRequestPunchin: boolean = false;

  punchinRequestDate: Date | string;
  punchinRequestNote: string = "";
  punchoutRequestDate: Date | string;
  punchoutRequestNote: string = "";

  constructor(
    private frmBuilder: RxFormBuilder,
    private dateTimeFormatPipe: DateTimeFormatPipe,
    private attendanceService: AttendanceManagementService,
    private dialogRef : MatDialogRef<RequestAttendanceComponent>,
    private toast: NbToastrService,
    private userService: UserService,
    private permissionService: NbAccessChecker,
    private authService: NbAuthService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.attendanceModel = data?.attendance ?? new AttendanceModel();
    this.user = data?.user ?? new UserModel();
    this.isRequestPunchin = data?.isRequestPunchin;
    this.attendanceRequestModel = new AttendanceRequestModel();
    this.permissionService.isGranted('view', 'edit-punchin-punchout').subscribe(e => this.hasEditPermission = e);
    if (this.attendanceModel.profileId) {
      this.userService.getUserById(this.attendanceModel?.profileId).subscribe(res => {
        if (res.result) {
          this.user = res.result;
        }
      });
    } else {
      this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.user = token.getPayload();
        }
      });
    }
    if (!this.attendanceModel.attendanceId || !this.attendanceModel.punchinDate || !this.attendanceModel.punchoutDate) {
      this.attendanceRequestModel.requestDate = new Date();
    }
  }

  ngOnInit() {
    this.loading = !this.loading;
    this.frmAttendanceRequest = this.frmBuilder.formGroup(AttendanceRequestModel, this.attendanceRequestModel);

    if (this.isRequestPunchin) this.titleRequestAttendance = 'Request Punchin';
    else this.titleRequestAttendance = 'Request Punchout';

    // get time zone offset
    var timezoneOffset = new Date().getTimezoneOffset();
    var offsetDecimal = -(timezoneOffset / 60);
    if (offsetDecimal >= 0) {
      this.timeZone += ' +' + offsetDecimal.toFixed(2);
    } else {
      this.timeZone += ' ' + offsetDecimal.toFixed(2);
    }
    this.loading = !this.loading;
  }

  saveRequestAttendance() {
    if (this.frmAttendanceRequest.valid) {
      this.loading = !this.loading;
      const model: AttendanceRequestModel = Object.assign({}, this.frmAttendanceRequest.value);
      var extendData = {};
      if (!this.attendanceModel?.extendData) {
        extendData = {};
      } else {
        extendData = JSON.parse(this.attendanceModel?.extendData);
      }
      if (this.isRequestPunchin) {
        extendData['PunchinRequestDate'] = model.requestDate;
        extendData['PunchinRequestNote'] = model.requestNote;
      } else {
        extendData['PunchoutRequestDate'] = model.requestDate;
        extendData['PunchoutRequestNote'] = model.requestNote;
      }
      this.attendanceModel.extendData = JSON.stringify(extendData);
      if (this.isRequestPunchin) {
        this.attendanceModel.punchinRequestStatus = PunchinRequestStatus.PunchinWaiting;
      } else {
        this.attendanceModel.punchoutRequestStatus = PunchoutRequestStatus.PunchoutWaiting;
      }
      this.attendanceModel.attendanceId = this.attendanceModel.attendanceId ? this.attendanceModel?.attendanceId : 0;
      this.attendanceModel.profileId = this.attendanceModel.profileId ? this.attendanceModel?.profileId : (this.user?.id ? this.user?.id : this.user?.nameid);
      this.attendanceService.requestChangeAttendance(this.attendanceModel, this.isRequestPunchin).subscribe(resp => {
        if (resp.result) {
          this.toast.success(`Request attendance successfully`, 'Success');
          this.closeDialog(resp.result);
        }
      }).add(() => {
        this.loading = !this.loading;
      });
    }
  }

  dateChanged(data: { value: Moment, isInitial: boolean }, prop: string) {
    if (data.isInitial) {
      this.frmAttendanceRequest.controls[prop].setValue(data.value);
    }
  }

  closeDialog(data: AttendanceModel) {
    this.dialogRef.close(data);
  }
}
