import { NumericValueType, numeric, prop, required } from "@rxweb/reactive-form-validators";
import { ProfileModel } from "../profile-management/profile-model";
import { LeaveEntitlementModel } from "../leave-entitlement-managament/leave-entitlement-management.model";

export class LeaveRequestModel {
    @prop()
    leaveRequestId: number;
    @prop()
    profileId: string;
    profile: ProfileModel | null;
    @prop()
    @required()
    leaveDateFrom: Date | string | null;
    @prop()
    @required()
    leaveDateTo: Date | string | null;
    @prop()
    @required()
    leaveType: number;
    leaveEntitlement: LeaveEntitlementModel | null;
    @prop()
    session: string;
    @prop()
    @numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true, message: 'Only numberic is allowed' })
    numberOfHour: number = 0;
    @prop()
    statusID: number;
    @prop()
    note: string;
    @prop()
    @required()
    title: string;
    @prop()
    leaveBudgetId: number;
    timeZone: number;

    ownerId: string;
    extendData: string;
    dateCreated: Date | string | null;
}

export class LeaveBonusModel {
    @prop()
    leaveRequestId: number;
    @prop()
    lstProfileId: string[];
    @prop()
    leaveDateFrom: Date | string | null;
    @prop()
    leaveDateTo: Date | string | null;
    @prop()
    session: string;
    @prop()
    @numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true, message: 'Only numberic is allowed' })
    numberOfHour: number = 0;
    @prop()
    note: string;
    @prop()
    @required()
    title: string;
    timeZone: number;

    ownerId: string;
    extendData: string;
}