import { digit, email, pattern, prop, propObject, required } from "@rxweb/reactive-form-validators";

export const LocationProps = [
    'Country', 'Region', 'City', 'Address', 'Address2', 'State', 'Zipcode', 'WillTravel', 'HowFar', 'NearestBigCity'
]
export class OnBoardingFormViewModel {
    constructor() {
        // super();
        this.ArtistDetails = new ArtistDetails();
        this.Contacts = [];
        this.ActDetails = [];
    }
    ArtistDetails: ArtistDetails;
    Contacts: ContactOnBoardViewModel[];
    ActDetails: ArtistDetails[];
}

export class ArtistDetails {
    /**
     *
     */
    constructor() {
        this.Country = 'United States of America';
        this.WillTravel = 0;
        // this.HowFar = 'International';
        this.Consent = 1;
    }
    @prop()
    PID: string;
    @prop()
    Scarlettname: string;
    @prop()
    Scarlettlink: string;
    @prop()
    RealName: string;
    @prop()
    Webpage: string;
    @prop()
    Scarlettbio: string;
    @prop()
    Country: string;
    @prop()
    Region: string;
    @prop()
    City: string;
    @prop()
    Address: string;
    @prop()
    Notes: string;
    @prop()
    Rider: string;
    @prop()
    Travel: number;
    @prop()
    Consent: number | boolean;
    @prop()
    Published: number;
    @prop()
    State: string;
    @prop()
    Address2: string;
    @prop()
    Zipcode: string;
    @prop()
    AltusState: string;
    // @prop()
    // DateModified: number;
    // @prop()
    // Datecreated: number;
    @prop()
    Tags: string;
    @prop()
    Lastuser: string;
    @prop()
    Facebook: string;
    @prop()
    SetList: string;
    @prop()
    Testimonials: string | any[];
    @prop()
    PreviousClients: string;
    @prop()
    ProfileRelationship: string;
    @prop()
    Signature: string;
    @prop()
    IsMaster: number;
    @prop()
    Instagram: string;
    @prop()
    Youtube: string;
    @prop()
    Tiktok: string;
    @prop()
    Twitter: string;
    @prop()
    NearestBigCity: string;
    @digit()
    WillTravel: number;
    @required({
        conditionalExpression: function () {
            return this.WillTravel && this.WillTravel == 1 ? true : false;
        }
    })
    HowFar: string;
    @prop()
    RiderAttachment: string;
    @prop()
    //Reason: ReasonOnBoardViewModel[] = [];
    Reason: string;
    @prop()
    Bio: string;
    @prop()
    VideoLinks: MediaOnBoardViewModel[] | any[] = [];
    @prop()
    PhotoLinks: MediaOnBoardViewModel[] | any[] = [];
    @prop()
    FileRider: File;
    @prop()
    FilePhotos: File[] = [];
    @prop()
    TestimonialsForm: TestimonialsForm[] = [];
    static standartlizeOutput(model: ArtistDetails): void {
        // standartlize videos
        if (model && model.VideoLinks && model.VideoLinks.length > 0 && typeof model.VideoLinks !== 'string')
            model.VideoLinks = model.VideoLinks.map(x => x.Media);
        if (model && model.PhotoLinks && model.PhotoLinks.length > 0 && typeof model.PhotoLinks !== 'string')
            model.PhotoLinks = model.PhotoLinks.map(x => x.Media);
        if (model && model.Testimonials && typeof model.Testimonials == 'string')
            model.Testimonials = JSON.parse(model.Testimonials);
        // consent to true:
        model.Consent = 1;
        model.Tags = 'newsignup';
    }
}
export class ReasonOnBoardViewModel {
    @prop()
    RID: number;
    @prop()
    PID: string;
    @prop()
    Reason: string;
}
export class MediaOnBoardViewModel {
    @prop()
    MediaID: number;
    @prop()
    PID: string;
    @prop()
    Media: string;
    @prop()
    MediaTypeID: number;
}

export class ContactOnBoardViewModel {
    /**
     *
     */
    constructor() {
        this.isSameAsArtist = true;
        this.Country = 'United States of America';
        this.ContactTypeID = 1;
    }
    @prop()
    ContactID: number;
    @prop()
    PID: string;
    @pattern({ expression: { phone: /^[(]?(\d|\+)\s?(?:[\d-.x\s()]*)$/ }, message: 'Invalid pattern of phone number' })
    @prop()
    ContactPhone: string;
    @prop()
    ContactTypeID: number;
    @prop()
    ContactName: string;
    @email()
    ContactEmail: string;
    @required({
        conditionalExpression: function () {
            return this.isSameAsArtist === false ? true : false;
        }
    })
    Country: string;
    @prop()
    State: string;
    @required({
        conditionalExpression: function () {
            return this.isSameAsArtist === false ? true : false;
        }
    })
    City: string;
    @prop()
    Zipcode: string;
    @prop()
    Address: string;
    @prop()
    Address2: string;
    @prop()
    ContactLastName: string;
    // @prop()
    PrimaryContact: number;
    @prop()
    @pattern({ expression: { phone: /^[(]?(\d|\+)\s?(?:[\d-.x\s()]*)$/ }, message: 'Invalid pattern of phone number' })
    SecondaryPhone: string;
    @prop()
    @pattern({ expression: { phone: /^[(]?(\d|\+)\s?(?:[\d-.x\s()]*)$/ }, message: 'Invalid pattern of phone number' })
    CellPhone: string;
    @email()
    SecondaryEmail: string;
    @prop()
    Facebook: string;
    @prop()
    Skype: string;
    @prop()
    WhatsApp: string;
    // extend prop for form:
    @prop()
    isSameAsArtist: boolean = true;
}

export class ObjectChangedModel {
    /**
     *
     */
    constructor(PID, EntityTypeID, ActionID, PropertyName, EntityID, PropertyValue, Signature, DateModified) {
        this.PID = PID;
        this.EntityTypeID = EntityTypeID;
        this.ActionID = ActionID;
        this.DateModified = DateModified;
        this.PropertyName = PropertyName;
        this.PropertyValue = PropertyValue;
        this.Signature = Signature;
        this.EntityID = EntityID;
    }
    PID: string;
    EntityTypeID: string;
    ActionID: string;
    PropertyName: string;
    EntityID?: any | null;
    PropertyValue?: any | null;
    Signature?: any | null;
    DateModified?: number | null;
}

export enum ActionOnboardingFormEnums {
    ADDED = "ADDED",
    MODIFIED = "MODIFIED",
    DELETED = "DELETED",
}
export enum EntityTypeOnboardingFormEnums {
    ACT = "ACT",
    CONTACT = "CONTACT",
    ARTIST_DETAILS = "ARTIST_DETAILS",
    CONTACTBLOCK = "CONTACTBLOCK",
    ACTBLOCK = "ACTBLOCK",
    LOCATION = "LOCATION"
}
export class TestimonialsForm {
    ClientNames: string;
    Testimonial: string;
}

export class AgreementOnboardingForm {
    /**
     *
     */
    constructor() {
    }
    @prop()
    Note: string;
    @required()
    Term: boolean;
    @required()
    Signatures: string;
}