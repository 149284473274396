<div class="onboarding-form">
    <div class="header-form">
        <h3>{{titleForm}}</h3>
    </div>
    <mat-horizontal-stepper [selectedIndex]="selectedFrom" (selectionChange)="selectionChangeTab($event)"
        [linear]="true" labelPosition="bottom" #stepperForm>
        <mat-step label="Artist Details" state="artist" [completed]="selectedFrom >= 0">
            <app-artist-details-tab #appArtistDetails [(contactID)]="contactID"
                [(artistDetails)]="onboardingData.ArtistDetails">
            </app-artist-details-tab>
            <div fxLayout="row" fxLayoutAlign="end center">
                <button mat-button mat-raised-button matStepperNext color="primary"
                    [disabled]="validateForm(selectedFrom) ?? true" class="st-btn">Next</button>
            </div>
        </mat-step>

        <mat-step label="Contact Details" state="contact" [completed]="selectedFrom >= 1">
            <app-contact-details-tab #contactDetails [(contactOnBoardingViewModel)]="onboardingData.Contacts">
            </app-contact-details-tab>
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <button mat-button mat-raised-button matStepperPrevious
                    class="previous-btn-form st-btn">Previous</button>
                <button mat-button mat-raised-button matStepperNext color="primary" class="st-btn"
                    [disabled]="validateForm(selectedFrom) ?? true">Next</button>
            </div>
        </mat-step>

        <mat-step label="Act Details" state="act" [completed]="selectedFrom >= 2">
            <app-act-details-tab #actDetails [actDetails]="onboardingData.ActDetails" (validForm)="isNext = $event">
            </app-act-details-tab>
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <button mat-button mat-raised-button matStepperPrevious
                    class="previous-btn-form st-btn">Previous</button>
                <button mat-button mat-raised-button matStepperNext color="primary" class="st-btn"
                    [disabled]="validateForm(selectedFrom) ?? true">Next</button>
            </div>
        </mat-step>

        <mat-step label="Agreement" state="agreement" [completed]="selectedFrom >= 3">
            <app-agreement-tab #appArgreement></app-agreement-tab>
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <button mat-button mat-raised-button matStepperPrevious
                    class="previous-btn-form st-btn">Previous</button>
                <button mat-button mat-raised-button color="primary" class="st-btn" [disabled]="lockSubmit"
                    [nbSpinner]="lockSubmit" [disabled]="validateForm(selectedFrom) ?? true"
                    (click)="SubmitForm()">Submit</button>
            </div>
        </mat-step>

        <!--#region template icon-->
        <ng-template matStepperIcon="artist">
            <i class="fas fa-user"></i>
        </ng-template>
        <ng-template matStepperIcon="contact">
            <i class="fas fa-phone"></i>
        </ng-template>
        <ng-template matStepperIcon="act">
            <i class="fas fa-star"></i>
        </ng-template>
        <ng-template matStepperIcon="agreement">
            <i class="fas fa-bookmark"></i>
        </ng-template>
        <!--#endregion template icon-->
    </mat-horizontal-stepper>
</div>

<div *ngIf="isLoading" class="overlay-container-loading">
    <div class="cv-spinner">
        <span class="custom-spinner"></span>
    </div>
</div>