import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'leaveFormat'
})

export class LeaveFormatPipe implements PipeTransform {
  
  transform(value: number, unit = 'day', hoursPerWorkday = 8): string {
    if (value === undefined || isNaN(value)) return "";
    
    const isNegative = value < 0;
    value = Math.abs(value);
    
    let totalMinutes: number;
    switch (unit) {
      case "day":
        totalMinutes = value * hoursPerWorkday * 60;
        break;
      case "hour":
        totalMinutes = value * 60;
        break;
    }
    
    totalMinutes = Math.round(totalMinutes / 5) * 5;
    if (totalMinutes === 0) return `0${unit[0]}`;
    
    const days = Math.floor(totalMinutes / (hoursPerWorkday * 60));
    const hours = Math.floor((totalMinutes % (hoursPerWorkday * 60)) / 60);
    const minutes = totalMinutes % 60;
    
    const parts: string[] = [];
    if (days > 0) parts.push(`${days}d`);
    if (hours > 0) parts.push(`${hours}h`);
    if (minutes > 0) parts.push(`${minutes}m`);
    
    return `${isNegative ? '-' : ''}${parts.join(' ')}`;
  }
}
