<div class="d-flex flex-wrap" mat-dialog-title style="margin: unset;padding: 1px 10px 1px 28px;"
    [nbSpinner]="isLoading">
    <button mat-button color="primary" (click)="saveData()" [disabled]="!frm.valid">
        <mat-icon fontSet=" material-icons-outlined">save</mat-icon>Save
    </button>
    <button mat-button color="primary" (click)="closeDialog()">
        <mat-icon fontSet=" material-icons-outlined">clear</mat-icon>Cancel
    </button>
    <button mat-icon-button aria-label="Close the dialog" [mat-dialog-close]="false" class="ml-auto">
        <mat-icon fontSet=" material-icons-outlined">close</mat-icon>
    </button>
</div>
<mat-divider></mat-divider>
<mat-dialog-content class="mat-typography">

    <form [formGroup]="frm" class="container" style="padding-top: 25px;padding-right: 50px;padding-left: 50px;">
        <div class="row">
            <div class="col-12" *ngIf="data?.model?.isFromProfile">
                <app-profile-searching (contactSelected)="selectProposal($event)" [label]="environment.titleOpportunity" 
                [typeName]="'OPPORTUNITY'" [placeholderLabel]="'Search ' + environment.titleOpportunity?.toLowerCase() +' name, email or phone...'"
                [profileId]="dataModel?.proposalId" [disabled]="action == 1 && data?.model?.isFromProfile"
                >                    
                </app-profile-searching>
            </div>
            <div class="col-12" *ngIf="!data?.model?.isFromProfile">
                <app-profile-searching (contactSelected)="selectPID($event)"
                    [profileId]="dataModel?.profileId"></app-profile-searching>
            </div>
            <div class="col-12" *ngIf="!data?.model?.isFromProfile">
                <mat-form-field appearance="standard">
                    <mat-label>Status</mat-label>
                    <mat-select required formControlName="automateDataState">
                        <mat-option *ngFor="let status of statusLst" [value]="status.value">
                            {{status.text}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field appearance="standard">
                    <mat-label>Order</mat-label>
                    <input matInput autocomplete="off" formControlName="order" trim="blur">
                    <mat-error *ngIf="frm.controls.order.errors && (frm.controls.order.dirty ||
                             frm.controls.order.touched)">
                        {{frm.controls.order['errorMessage']}}</mat-error>
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field appearance="standard">
                    <mat-label>Artist Fee</mat-label>
                    <input matInput autocomplete="off" formControlName="artistFee" trim="blur">
                    <mat-error *ngIf="frm.controls.artistFee.errors && (frm.controls.artistFee.dirty 
                            || frm.controls.artistFee.touched)">
                        {{frm.controls.artistFee['errorMessage']}}</mat-error>
                </mat-form-field>
            </div>

            <div class="col-12">
                <mat-form-field appearance="standard">
                    <mat-label>Fee to Client</mat-label>
                    <input matInput autocomplete="off" formControlName="feeToClient" trim="blur">
                    <mat-error *ngIf="frm.controls.feeToClient.errors && (frm.controls.feeToClient.dirty ||
                             frm.controls.feeToClient.touched)">
                        {{frm.controls.feeToClient['errorMessage']}}</mat-error>
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field appearance="standard">
                    <mat-label>Fee Includes</mat-label>
                    <textarea cdkTextareaAutosize matInput autocomplete="off" formControlName="feeIncludes" trim="blur"
                        rows="2" placeholder="Press enter for the new line"></textarea>
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field appearance="standard">
                    <mat-label>Fee Excludes</mat-label>
                    <textarea cdkTextareaAutosize matInput autocomplete="off" formControlName="feeExcludes" trim="blur"
                        rows="2" placeholder="Press enter for the new line"></textarea>
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field appearance="standard">
                    <mat-label>Travel</mat-label>
                    <input matInput autocomplete="off" formControlName="travel" trim="blur">
                    <mat-error *ngIf="frm.controls.travel.errors && (frm.controls.travel.dirty ||
                             frm.controls.travel.touched)">
                        {{frm.controls.travel['errorMessage']}}</mat-error>
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field appearance="standard">
                    <mat-label>Accommodation</mat-label>
                    <input matInput autocomplete="off" formControlName="accommodation" trim="blur">
                    <mat-error *ngIf="frm.controls.accommodation.errors && (frm.controls.accommodation.dirty ||
                             frm.controls.accommodation.touched)">
                        {{frm.controls.accommodation['errorMessage']}}</mat-error>
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field appearance="standard">
                    <mat-label>Shipping</mat-label>
                    <input matInput autocomplete="off" formControlName="shipping" trim="blur">
                    <mat-error *ngIf="frm.controls.shipping.errors && (frm.controls.shipping.dirty ||
                             frm.controls.shipping.touched)">
                        {{frm.controls.shipping['errorMessage']}}</mat-error>
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field appearance="standard">
                    <mat-label>Per Diem</mat-label>
                    <input matInput autocomplete="off" formControlName="perDiem" trim="blur">
                    <mat-error *ngIf="frm.controls.perDiem.errors && (frm.controls.perDiem.dirty ||
                             frm.controls.perDiem.touched)">
                        {{frm.controls.perDiem['errorMessage']}}</mat-error>
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field appearance="standard">
                    <mat-label>Third-Party Commission</mat-label>
                    <input matInput autocomplete="off" formControlName="thirdPartyCommission" trim="blur">
                    <mat-error *ngIf="frm.controls.thirdPartyCommission.errors && (frm.controls.thirdPartyCommission.dirty ||
                             frm.controls.thirdPartyCommission.touched)">
                        {{frm.controls.thirdPartyCommission['errorMessage']}}</mat-error>
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field appearance="standard">
                    <mat-label>Total</mat-label>
                    <input matInput autocomplete="off" readonly [ngModel]="total?.toFixed(2)"
                        [ngModelOptions]="{standalone: true}">
                </mat-form-field>
            </div>
        </div>
    </form>

</mat-dialog-content>