import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Page } from "src/app/shared/models/paging/page";
import { PagedData } from "src/app/shared/models/paging/paged-data";
import { ReturnResult } from "src/app/shared/models/return-result";
import { environment } from "src/environments/environment";
import { AttendanceModel, AttendanceReport, AttendanceReportExportModel, AttendanceRequestPagingModel } from "./attendance-management.model";
import { timeout } from "rxjs/operators";
import { PagingRangeDateFilter } from "../activity-log-management/page-filter-range-date";
import { AttendanceDatum } from "../opportunity-management/opportunity-sale-report/opportunity-sale-report.model";

@Injectable({
    providedIn: 'root'
})

export class AttendanceManagementService {
    baseUrl = environment.apiAttendanceManagement;
    constructor(private http: HttpClient) { }

    getAttendancePaging(page: Page, profileId: string = ''): Observable<ReturnResult<PagedData<AttendanceModel | any>>> {
        if (profileId == null) {
            profileId = '';
        }
        return this.http.post<ReturnResult<PagedData<AttendanceModel>>>(`${this.baseUrl}/get?profileId=${profileId}`, page);
    }

    getAttendanceByProfileId(profileId: string) {
        return this.http.get<ReturnResult<AttendanceModel[]>>(`${this.baseUrl}/GetAttendanceByProfileId?profileId=${profileId}`);
    }

    getTodayAttendanceByProfileId(profileId: string, timezone: number = 7) {
        return this.http.get<ReturnResult<AttendanceModel[]>>(`${this.baseUrl}/GetTodayAttendanceByProfileId?profileId=${profileId}&clientTimezone=${timezone}`);
    }

    getAttendanceRange(profileId: string, page: PagingRangeDateFilter): Observable<ReturnResult<AttendanceModel[]>> {
        return this.http.post<ReturnResult<AttendanceModel[]>>(`${this.baseUrl}/GetAttendanceRange?profileId=${profileId}&timezone=${new Date().getTimezoneOffset()/60}`, page);
    }

    saveAttendance(model: AttendanceModel): Observable<ReturnResult<AttendanceModel>> {
        return this.http.post<ReturnResult<AttendanceModel>>(`${this.baseUrl}/SaveAttendance`, model);
    }

    deleteAttendance(id: string): Observable<ReturnResult<boolean>> {
        return this.http.delete<ReturnResult<boolean>>(`${this.baseUrl}/${id}`);
    }

    deleteAttendances(id: number[]): Observable<ReturnResult<boolean>> {
        return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/Delete`, id);
    }

    exportAttendance(page: PagingRangeDateFilter): Observable<ReturnResult<AttendanceModel[]>> {
        return this.http.post<ReturnResult<AttendanceModel[]>>(`${this.baseUrl}/export?timezone=${new Date().getTimezoneOffset()/60}`, page);
    }

    importAttendance(formData: FormData): Observable<ReturnResult<any>> {
        return this.http.post<ReturnResult<any>>(`${this.baseUrl}/import`, formData).pipe(timeout(1200000));
    }

    getAttendanceReport(data: AttendanceReport) {
        return this.http.post<ReturnResult<any>>(`${this.baseUrl}/GetAttendanceReport`, data);
    }

    requestChangeAttendance(model: AttendanceModel, isPunchinRequest: boolean): Observable<ReturnResult<AttendanceModel>> {
        return this.http.post<ReturnResult<AttendanceModel>>(`${this.baseUrl}/RequestChangeAttendance?isPunchinRequest=${isPunchinRequest}`, model);
    }

    requestChangeAttendanceAll(model: AttendanceModel): Observable<ReturnResult<AttendanceModel>> {
        return this.http.post<ReturnResult<AttendanceModel>>(`${this.baseUrl}/RequestChangeAttendanceAll`, model);
    }

    getAttendanceById(attendanceId: number) {
        return this.http.get<ReturnResult<AttendanceModel>>(`${this.baseUrl}/GetAttendanceById?attendanceId=${attendanceId}`);
    }

    getAttendanceRequestPaging(page: Page): Observable<ReturnResult<PagedData<AttendanceRequestPagingModel | any>>> {
        return this.http.post<ReturnResult<PagedData<AttendanceRequestPagingModel>>>(`${this.baseUrl}/AttendanceRequestPaging`, page);
    }

    exportAttendanceReport(model: AttendanceReportExportModel): Observable<ReturnResult<AttendanceModel[]>> {
        return this.http.post<ReturnResult<AttendanceModel[]>>(`${this.baseUrl}/ExportAttendanceReport`, model);
    }

    exportDetailAttendanceReport(model: AttendanceReportExportModel): Observable<ReturnResult<AttendanceModel[]>> {
        return this.http.post<ReturnResult<AttendanceModel[]>>(`${this.baseUrl}/ExportDetailAttendanceReport`, model);
    }

    approveAllRequestChangeAttendance(model: AttendanceModel[]): Observable<ReturnResult<any>> {
        return this.http.post<ReturnResult<any>>(`${this.baseUrl}/ApproveAllRequestChangeAttendance`, model);
    }
}