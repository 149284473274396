<form [formGroup]="contactOnBoardingViewModelForms[0]">
    <div class="contact-block">
        <div class="row">
            <!-- Company name -->
            <div class="col-sm-12 notify-sec">
                <div class="form-group">
                    <label class="form-label">First Name*</label>
                    <input required formControlName="ContactName" placeholder="Your First Name..." type="text"
                        class="contact form-control">
                    <mat-error
                        *ngIf="contactOnBoardingViewModelForms[0]?.controls.ContactName.errors && (contactOnBoardingViewModelForms[0]?.controls.ContactName.dirty || contactOnBoardingViewModelForms[0]?.controls.ContactName.touched)">
                        {{contactOnBoardingViewModelForms[0]?.controls.ContactName['errorMessage']}}</mat-error>
                </div>
            </div>
            <div class="col-sm-12 notify-sec">
                <div class="form-group">
                    <label class="form-label">Last Name*</label>
                    <input required type="text" formControlName="ContactLastName" placeholder="Your Last Name..."
                        class="contact form-control">
                    <mat-error
                        *ngIf="contactOnBoardingViewModelForms[0]?.controls.ContactLastName.errors && (contactOnBoardingViewModelForms[0]?.controls.ContactLastName.dirty || contactOnBoardingViewModelForms[0]?.controls.ContactLastName.touched)">
                        {{contactOnBoardingViewModelForms[0]?.controls.ContactLastName['errorMessage']}}</mat-error>
                </div>
            </div>
            <div class="col-sm-12 notify-sec">
                <div class="form-group">
                    <label class="form-label">Telephone Number*</label>
                    <input required type="text" formControlName="ContactPhone" placeholder="Your Telephone Number..."
                        class="contact form-control">
                    <mat-error
                        *ngIf="contactOnBoardingViewModelForms[0]?.controls.ContactPhone.errors && (contactOnBoardingViewModelForms[0]?.controls.ContactPhone.dirty || contactOnBoardingViewModelForms[0]?.controls.ContactPhone.touched)">
                        {{contactOnBoardingViewModelForms[0]?.controls.ContactPhone['errorMessage']}}</mat-error>
                </div>
            </div>
            <div class="col-sm-12 notify-sec">
                <div class="form-group">
                    <label class="form-label">Second Telephone Number</label>
                    <input type="text" formControlName="SecondaryPhone" placeholder="Your Second Telephone Number..."
                        class="contact form-control">
                    <mat-error
                        *ngIf="contactOnBoardingViewModelForms[0]?.controls.SecondaryPhone.errors && (contactOnBoardingViewModelForms[0]?.controls.SecondaryPhone.dirty || contactOnBoardingViewModelForms[0]?.controls.SecondaryPhone.touched)">
                        {{contactOnBoardingViewModelForms[0]?.controls.SecondaryPhone['errorMessage']}}</mat-error>
                </div>
            </div>
            <div class="col-sm-12 notify-sec">
                <div class="form-group">
                    <label class="form-label">Mobile/Cell Number</label>
                    <input type="text" formControlName="CellPhone" placeholder="Your Mobile/Cell Number..."
                        class="contact form-control">
                    <mat-error
                        *ngIf="contactOnBoardingViewModelForms[0]?.controls.CellPhone.errors && (contactOnBoardingViewModelForms[0]?.controls.CellPhone.dirty || contactOnBoardingViewModelForms[0]?.controls.CellPhone.touched)">
                        {{contactOnBoardingViewModelForms[0]?.controls.CellPhone['errorMessage']}}</mat-error>
                </div>
            </div>
            <div class="col-sm-12 notify-sec">
                <div class="form-group">
                    <label class="form-label">Email*</label>
                    <input required type="email" formControlName="ContactEmail" placeholder="Your Email..."
                        class="contact form-control">
                    <mat-error
                        *ngIf="contactOnBoardingViewModelForms[0]?.controls.ContactEmail.errors && (contactOnBoardingViewModelForms[0]?.controls.ContactEmail.dirty || contactOnBoardingViewModelForms[0]?.controls.ContactEmail.touched)">
                        {{contactOnBoardingViewModelForms[0]?.controls.ContactEmail['errorMessage']}}</mat-error>
                </div>
            </div>
            <div class="col-sm-12 notify-sec">
                <div class="form-group">
                    <label class="form-label">Secondary Email</label>
                    <input type="email" formControlName="SecondaryEmail" placeholder="Your Secondary Email..."
                        class="contact form-control">
                    <mat-error
                        *ngIf="contactOnBoardingViewModelForms[0]?.controls.SecondaryEmail.errors && (contactOnBoardingViewModelForms[0]?.controls.SecondaryEmail.dirty || contactOnBoardingViewModelForms[0]?.controls.SecondaryEmail.touched)">
                        {{contactOnBoardingViewModelForms[0]?.controls.SecondaryEmail['errorMessage']}}</mat-error>
                </div>
            </div>
            <div class="col-sm-12 notify-sec">
                <div class="form-group">
                    <label class="form-label">Facebook</label>
                    <input type="text" formControlName="Facebook" placeholder="Your facebook..."
                        class="contact form-control">
                </div>
            </div>
            <div class="col-sm-12 notify-sec">
                <div class="form-group">
                    <label class="form-label">Skype</label>
                    <input type="text" formControlName="Skype" placeholder="Your Skype..." class="contact form-control">
                </div>
            </div>
            <div class="col-sm-12 notify-sec">
                <div class="form-group">
                    <label class="form-label">WhatsApp</label>
                    <input type="text" formControlName="WhatsApp" placeholder="Your WhatsApp..."
                        class="contact form-control">
                </div>
            </div>
            <!-- toggle same address as artist -->
            <div class="col-sm-12 notify-sec">
                <div class="form-group form-check">
                    <input type="checkbox" formControlName="isSameAsArtist" class="form-check-input same-address"
                        (change)="checkChanged(contactOnBoardingViewModelForms[0])">
                    <label class="form-check-label form-label same-address" for="same-address"
                        (click)="sameAsArtistLabelChecked(contactOnBoardingViewModelForms[0].controls.isSameAsArtist.value,contactOnBoardingViewModelForms[0].controls.isSameAsArtist)">
                        Same address as artist
                    </label>
                </div>
            </div>

            <div *ngIf="!contactOnBoardingViewModelForms[0].controls.isSameAsArtist.value" class="add-contact">
                <!-- location -->
                <div class="col-sm-12 notify-sec">
                    <div class="form-group">
                        <label class="form-label">Country*</label>
                        <select formControlName="Country" id="country" name="country" class="form-control">
                            <option *ngFor="let country of countriesArr"
                                [selected]="country.key =='United States of America'" [value]="country.key">
                                {{country.key}}</option>
                        </select>
                        <mat-error
                            *ngIf="contactOnBoardingViewModelForms[0]?.controls.Country.errors && (contactOnBoardingViewModelForms[0]?.controls.Country.dirty || contactOnBoardingViewModelForms[0]?.controls.Country.touched)">
                            {{contactOnBoardingViewModelForms[0]?.controls.Country['errorMessage']}}</mat-error>
                    </div>
                </div>
                <!-- street -->
                <div class="col-sm-12 notify-sec">
                    <div class="form-group">
                        <label class="form-label">Street</label>
                        <input type="text" formControlName="Address" placeholder="Your Street ..."
                            class="contact form-control">
                        <mat-error
                            *ngIf="contactOnBoardingViewModelForms[0]?.controls.Address.errors && (contactOnBoardingViewModelForms[0]?.controls.Address.dirty || contactOnBoardingViewModelForms[0]?.controls.Address.touched)">
                            {{contactOnBoardingViewModelForms[0]?.controls.Address['errorMessage']}}</mat-error>
                    </div>
                </div>
                <!-- Street 2 -->
                <div class="col-sm-12 notify-sec">
                    <div class="form-group">
                        <label class="form-label">Street 2</label>
                        <input type="text" formControlName="Address2" placeholder="Your Street 2..."
                            class="contact form-control">
                        <mat-error
                            *ngIf="contactOnBoardingViewModelForms[0]?.controls.Address2.errors && (contactOnBoardingViewModelForms[0]?.controls.Address2.dirty || contactOnBoardingViewModelForms[0]?.controls.Address2.touched)">
                            {{contactOnBoardingViewModelForms[0]?.controls.Address2['errorMessage']}}</mat-error>
                    </div>
                </div>
                <!-- City -->
                <div class="col-sm-12 notify-sec">
                    <div class="form-group">
                        <label class="form-label">City*</label>
                        <input formControlName="City" placeholder="Your City..." type="text"
                            class="contact form-control">
                        <mat-error
                            *ngIf="contactOnBoardingViewModelForms[0]?.controls.City.errors && (contactOnBoardingViewModelForms[0]?.controls.City.dirty || contactOnBoardingViewModelForms[0]?.controls.City.touched)">
                            {{contactOnBoardingViewModelForms[0]?.controls.City['errorMessage']}}</mat-error>
                    </div>
                </div>
                <!-- state -->
                <div class="col-sm-12 notify-sec">
                    <div class="form-group">
                        <label class="form-label">State</label>
                        <input formControlName="State" type="text" placeholder="Your State..."
                            class="contact form-control">
                        <mat-error
                            *ngIf="contactOnBoardingViewModelForms[0]?.controls.State.errors && (contactOnBoardingViewModelForms[0]?.controls.State.dirty || contactOnBoardingViewModelForms[0]?.controls.State.touched)">
                            {{contactOnBoardingViewModelForms[0]?.controls.State['errorMessage']}}</mat-error>
                    </div>
                </div>
                <!-- Postcode/Zip -->
                <div class="col-sm-12 notify-sec">
                    <div class="form-group">
                        <label class="form-label">Postcode/Zip</label>
                        <input formControlName="Zipcode" placeholder="Your Postcode/Zip..." type="text"
                            class="contact form-control">
                    </div>
                </div>
            </div>
        </div>
        <!-- more contact block -->
        <div *ngIf="contactOnBoardingViewModelForms && contactOnBoardingViewModelForms.length > 1">
            <div *ngFor="let contact of contactOnBoardingViewModelForms | slice:1; let i = index">
                <ng-container [ngTemplateOutlet]="moreContact" [ngTemplateOutletContext]="{model: contact, index: i}">
                </ng-container>
            </div>
        </div>
        <!-- add more button -->
        <button mat-button mat-raised-button class="st-btn btn-additional" (click)="addMoreContacts()">Add alternative
            contact</button>
    </div>
</form>

<ng-template #moreContact let-model="model" let-index="index">
    <form [formGroup]="model">
        <div style="width: inherit;margin-bottom:10px">
            <mat-expansion-panel class="expandable-block" expanded hideToggle style="border-radius:unset">
                <mat-expansion-panel-header class="custom-expand-header"
                    style="background-color: #307abd;width:inherit">
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <div class="expand-title">
                            Alternative contact {{index+1}}
                        </div>
                        <button mat-icon-button class="absolute-expand-close" (click)="removeSubContacts(index+1)">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                </mat-expansion-panel-header>
                <div style="width: -webkit-fill-available;padding-top: 20px;">
                    <!-- Company name -->
                    <div class="contact-expand notify-sec">
                        <div class="form-group">
                            <label class="form-label">First Name*</label>
                            <input required formControlName="ContactName" placeholder="Your First Name..." type="text"
                                class="contact form-control contact-expand-input">
                            <mat-error
                                *ngIf="model?.controls.ContactName.errors && (model?.controls.ContactName.dirty || model?.controls.ContactName.touched)">
                                {{model?.controls.ContactName['errorMessage']}}</mat-error>
                        </div>
                    </div>
                    <div class="contact-expand notify-sec">
                        <div class="form-group">
                            <label class="form-label">Last Name*</label>
                            <input required formControlName="ContactLastName" type="text"
                                placeholder="Your Last Name..." class="contact form-control contact-expand-input">
                            <mat-error
                                *ngIf="model?.controls.ContactLastName.errors && (model?.controls.ContactLastName.dirty || model?.controls.ContactLastName.touched)">
                                {{model?.controls.ContactLastName['errorMessage']}}</mat-error>
                        </div>
                    </div>
                    <div class="contact-expand notify-sec">
                        <div class="form-group">
                            <label class="form-label">Telephone Number*</label>
                            <input required formControlName="ContactPhone" type="text"
                                placeholder="Your Telephone Number..."
                                class="contact form-control contact-expand-input">
                            <mat-error
                                *ngIf="model?.controls.ContactPhone.errors && (model?.controls.ContactPhone.dirty || model?.controls.ContactPhone.touched)">
                                {{model?.controls.ContactPhone['errorMessage']}}</mat-error>
                        </div>
                    </div>
                    <div class="contact-expand notify-sec">
                        <div class="form-group">
                            <label class="form-label">Second Telephone Number</label>
                            <input type="text" formControlName="SecondaryPhone"
                                placeholder="Your Second Telephone Number..."
                                class="contact form-control contact-expand-input">
                            <mat-error
                                *ngIf="model?.controls.SecondaryPhone.errors && (model?.controls.SecondaryPhone.dirty || model?.controls.SecondaryPhone.touched)">
                                {{model?.controls.SecondaryPhone['errorMessage']}}</mat-error>
                        </div>
                    </div>
                    <div class="contact-expand notify-sec">
                        <div class="form-group">
                            <label class="form-label">Mobile/Cell Number</label>
                            <input type="text" formControlName="CellPhone" placeholder="Your Mobile/Cell Number..."
                                class="contact form-control contact-expand-input">
                            <mat-error
                                *ngIf="model?.controls.CellPhone.errors && (model?.controls.CellPhone.dirty || model?.controls.CellPhone.touched)">
                                {{model?.controls.CellPhone['errorMessage']}}</mat-error>
                        </div>
                    </div>
                    <div class="contact-expand notify-sec">
                        <div class="form-group">
                            <label class="form-label">Email*</label>
                            <input required type="text" formControlName="ContactEmail" placeholder="Your Email..."
                                class="contact form-control contact-expand-input">
                            <mat-error
                                *ngIf="model?.controls.ContactEmail.errors && (model?.controls.ContactEmail.dirty || model?.controls.ContactEmail.touched)">
                                {{model?.controls.ContactEmail['errorMessage']}}</mat-error>
                        </div>
                    </div>
                    <div class="contact-expand notify-sec">
                        <div class="form-group">
                            <label class="form-label">Secondary Email</label>
                            <input type="email" formControlName="SecondaryEmail" placeholder="Your Secondary Email..."
                                class="contact form-control contact-expand-input">
                            <mat-error
                                *ngIf="model?.controls.SecondaryEmail.errors && (model?.controls.SecondaryEmail.dirty || model?.controls.SecondaryEmail.touched)">
                                {{model?.controls.SecondaryEmail['errorMessage']}}</mat-error>
                        </div>
                    </div>
                    <div class="contact-expand notify-sec">
                        <div class="form-group">
                            <label class="form-label">Facebook</label>
                            <input type="text" formControlName="Facebook" placeholder="Your facebook..."
                                class="contact form-control contact-expand-input">
                        </div>
                    </div>
                    <div class="contact-expand notify-sec">
                        <div class="form-group">
                            <label class="form-label">Skype</label>
                            <input type="text" formControlName="Skype" placeholder="Your Skype..."
                                class="contact form-control contact-expand-input">
                        </div>
                    </div>
                    <div class="contact-expand notify-sec">
                        <div class="form-group">
                            <label class="form-label">WhatsApp</label>
                            <input type="text" formControlName="WhatsApp" placeholder="Your WhatsApp..."
                                class="contact form-control contact-expand-input">
                        </div>
                    </div>
                    <!-- toggle same address as artist -->
                    <div class="contact-expand notify-sec">
                        <div class="form-group form-check">
                            <input (change)="checkChanged(model)" checked="" type="checkbox"
                                formControlName="isSameAsArtist" class="form-check-input same-address ">
                            <label class="form-check-label form-label same-address" for="same-address"
                                (click)="ContactSameAsArtistLabelChecked(model.controls.isSameAsArtist.value,model.controls.isSameAsArtist, model)">
                                Same address as artist
                            </label>
                        </div>
                    </div>
                    <div *ngIf="!model.controls.isSameAsArtist.value" class="add-contact">
                        <!-- location -->
                        <div class="contact-expand notify-sec">
                            <div class="form-group">
                                <label class="form-label">Country*</label>
                                <select formControlName="Country" required id="country" name="country"
                                    class="form-control contact-expand-input">
                                    <option *ngFor="let country of countriesArr"
                                        [selected]="country.key =='United States of America'" [value]="country.key">
                                        {{country.key}}</option>
                                </select>
                                <mat-error
                                    *ngIf="model?.controls.Country.errors && (model?.controls.Country.dirty || model?.controls.Country.touched)">
                                    {{model?.controls.Country['errorMessage']}}</mat-error>
                            </div>
                        </div>
                        <!-- street -->
                        <div class="contact-expand notify-sec">
                            <div class="form-group">
                                <label class="form-label">Street</label>
                                <input type="text" formControlName="Address" placeholder="Your Street ..."
                                    class="contact form-control contact-expand-input">
                            </div>
                        </div>
                        <!-- Street 2 -->
                        <div class="contact-expand notify-sec">
                            <div class="form-group">
                                <label class="form-label">Street 2</label>
                                <input type="text" formControlName="Address2" placeholder="Your Street 2..."
                                    class="contact form-control contact-expand-input">
                            </div>
                        </div>
                        <!-- City -->
                        <div class="contact-expand notify-sec">
                            <div class="form-group">
                                <label class="form-label">City*</label>
                                <input formControlName="City" placeholder="Your City..." type="text"
                                    class="contact form-control contact-expand-input">
                                <mat-error
                                    *ngIf="model?.controls.City.errors && (model?.controls.City.dirty || model?.controls.City.touched)">
                                    {{model?.controls.City['errorMessage']}}</mat-error>
                            </div>
                        </div>
                        <!-- state -->
                        <div class="contact-expand notify-sec">
                            <div class="form-group">
                                <label class="form-label">State</label>
                                <input type="text" formControlName="State" placeholder="Your State..."
                                    class="contact form-control contact-expand-input">
                            </div>
                        </div>
                        <!-- Postcode/Zip -->
                        <div class="contact-expand notify-sec">
                            <div class="form-group">
                                <label class="form-label">Postcode/Zip</label>
                                <input type="text" formControlName="Zipcode" placeholder="Your Postcode/Zip..."
                                    class="contact form-control contact-expand-input">
                            </div>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </div>
    </form>
</ng-template>