import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs-compat/Observable';
import { map, tap } from 'rxjs/operators';
import { Page } from 'src/app/shared/models/paging/page';
import { PagedData } from 'src/app/shared/models/paging/paged-data';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { Helper } from 'src/app/shared/utility/Helper';
import { environment } from 'src/environments/environment';
import { KeyPairsValue } from '../profile-management/profile-detail.model';
import { FolderTree, UserUpload, UserUploadHistory, changeURLUpload } from './user-upload.model';

enum FileActionType {
  download = 1,
  upload
}
@Injectable({
  providedIn: 'root'
})
export class UserUploadManagementService {
  baseUrl = environment.apiUserUpload;
  uploadProgress = 0;
  uploadProgressSubject = new Subject<number>();
  public getUploadProgress(): Observable<number> {
    return this.uploadProgressSubject.asObservable();
  }
  constructor(private http: HttpClient, private toast: NbToastrService) { }

  getPaging(page: Page): Observable<ReturnResult<PagedData<UserUpload>>> {
    return this.http.post<ReturnResult<PagedData<UserUpload>>>(`${this.baseUrl}/get`, page);
  }

  uploadFile(file: File, model: UserUpload): Observable<ReturnResult<string>> {
    var formData = new FormData();
    formData.append("file", file);

    return this.http.post<ReturnResult<string>>(`${this.baseUrl}/upload?model=${JSON.stringify(model)}`, formData);
  }
  getProfileEntityUploadFile(page: Page, typeName: string, profileId: string) {
    return this.http.post<ReturnResult<PagedData<UserUpload>>>(`${this.baseUrl}/GetProfileEntityUploadFile?typeName=${typeName}&profileId=${profileId}`, page);
  }
  downloadAsStream(id: number) {
    return this.http.get(`${this.baseUrl}/Download?userUploadId=${id}`, { responseType: 'blob', reportProgress: true, observe: 'events' }).pipe(
      map(e => ({
        progress: this.getPercentage(e),
        response: e
      }))
    );
  }
  public getPercentage(event: HttpEvent<any>): number {
    let percentDone = 0;

    switch (event.type) {

      case HttpEventType.UploadProgress:
        return percentDone;
      case HttpEventType.DownloadProgress:
        if (event && event.total) {
          percentDone = Math.round(100 * event.loaded / event.total);
        }
        this.uploadProgressSubject.next(percentDone);
        return percentDone;

      default:
        // Not an event we care about
        return percentDone;
    }
  }
  downLoadFile(data: any, fileName: string, isHaveGUID: boolean = false) {
    // remove guid name:
    if (fileName && isHaveGUID && fileName.match("_[A-Za-z0-9]{5,5}\.")) {
      let extension = Helper.getFileExtension(fileName)[0];
      let rawName = fileName.slice().replace(`.${extension}`, "");
      rawName = rawName.slice(0, rawName.length - 6);
      fileName = rawName + '.' + extension;
    }
    let downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(data);
    downloadLink.setAttribute('download', fileName);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    downloadLink.remove();
  }
  modifyUserUploadFile(userUploadHistoryId: string, file: File): Observable<ReturnResult<boolean>> {
    var formData = new FormData();
    formData.append("file", file);
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/modify?userUploadHistoryId=${userUploadHistoryId}`, formData);
  }
  getUserUploadHistoryPagingById(page: Page, userUploadHistoryId: string): Observable<ReturnResult<PagedData<UserUploadHistory>>> {
    return this.http.post<ReturnResult<PagedData<UserUploadHistory>>>(`${this.baseUrl}/GetUserUploadHistoryPagingById?id=${userUploadHistoryId}`, page);
  }
  updateUserUploadRecord(model: UserUpload): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/UpdateUserUploadRecord`, model);
  }

  uploadMultiple(model: UserUpload[], files: File[]): Observable<ReturnResult<KeyPairsValue[]>> {
    var data = new FormData();
    for (var i = 0; i < model.length; i++) {
      data.append('models', JSON.stringify(model[i]));
      data.append('files', files[i]);
    }
    return this.http.post<ReturnResult<KeyPairsValue[]>>(`${this.baseUrl}/UploadMultiple`, data);
  }

  deleteUserUpload(id: string): Observable<ReturnResult<boolean>> {
    return this.http.get<ReturnResult<boolean>>(`${this.baseUrl}/DeleteUserUpload?id=${id}`);
  }

  modifyWithUserUpload(userUploadModel: UserUpload, file: File): Observable<ReturnResult<boolean>> {
    var formData = new FormData();
    formData.append("userUploadModel", JSON.stringify(userUploadModel));
    formData.append("file", file);
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/ModifyWithUserUpload`, formData);
  }

  getFolderTree(): Observable<ReturnResult<FolderTree>> {
    return this.http.get<ReturnResult<FolderTree>>(`${this.baseUrl}/GetFolderTree`);
  }
  uploadURL(model:UserUpload):Observable<ReturnResult<boolean>>{
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/UploadURL`,model)
  }
  changeURLUpload(model:changeURLUpload){
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/ChangeURLUpload`,model)
  }
}
