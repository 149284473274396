import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Subject } from 'rxjs';
import { DISPLAY_INPUT_DATE_NOT_SECONDS } from 'src/app/shared/components/stand-alone-component/primas-custom-date-time-picker/date-format.model';
import { TblActionType } from 'src/app/shared/enums/tbl-action-type.enum';
import { AttendanceModel, AttendanceRequestModel } from '../../attendance-management.model';
import { FormGroup } from '@angular/forms';
import { UserModel } from '../../../user-management/user-model';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../../../user-management/user.service';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { NbToastrService } from '@nebular/theme';
import { AttendanceManagementService } from '../../attendance-management.service';
import { SettingService } from 'src/app/shared/services/setting.service';
import { DateTimeFormatPipe } from 'src/app/shared/pipes/date-time-format.pipe';
import { NbAccessChecker } from '@nebular/security';
import { take, takeUntil } from 'rxjs/operators';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { Moment } from 'moment';
import { RequestAttendanceComponent } from '../../request-attendance/request-attendance.component';
import { PunchinRequestStatus, PunchoutRequestStatus } from 'src/app/shared/enums/attendance-status.enum';

@Component({
  selector: 'app-add-edit-attendance-request',
  templateUrl: './add-edit-attendance-request.component.html',
  styleUrls: ['./add-edit-attendance-request.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE], },
    { provide: MAT_DATE_FORMATS, useValue: DISPLAY_INPUT_DATE_NOT_SECONDS }]
})
export class AddEditAttendanceRequestComponent implements OnInit, OnDestroy, AfterViewInit {

  private destroy$: Subject<void> = new Subject<void>();
  action: TblActionType;
  attendanceModel: AttendanceModel;
  isRequest: boolean = false;
  requestPunchinModel: AttendanceRequestModel;
  requestPunchoutModel: AttendanceRequestModel;
  frmAttendance: FormGroup;
  frmRequestPunchin: FormGroup;
  frmRequestPunchout: FormGroup;
  isLoading = false;
  userChosen: UserModel;
  listEmployees: UserModel[];
  isChange: boolean = false;
  user;
  hasEditPermission: boolean = false;
  timeZoneIn: string = 'GMT';
  timeZoneOut: string = 'GMT';
  displayPunchinCurrent: Date | string | null = null;
  displayPunchinRequest: Date | string | null = null;
  displayPunchoutCurrent: Date | string | null = null;
  displayPunchoutRequest: Date | string | null = null;

  constructor(
    public dialModalRef: MatDialogRef<AddEditAttendanceRequestComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    private frmBuilder: RxFormBuilder,
    private authService: NbAuthService,
    private toast: NbToastrService,
    private cdref: ChangeDetectorRef,
    private dialog: MatDialog,
    private attendanceService: AttendanceManagementService,
    private settingService: SettingService,
    private dateTimeFormatPipe: DateTimeFormatPipe,
    private permissionService: NbAccessChecker,
  ) {
    this.action = data?.action;
    this.attendanceModel = data?.model ?? new AttendanceModel();
    this.userChosen = data?.model?.user ?? new UserModel();
    this.isRequest = data?.isRequest ?? this.isRequest;

    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.user = token.getPayload();
        }
    });
    this.permissionService.isGranted('view', 'edit-punchin-punchout').subscribe(e => this.hasEditPermission = e);
    if (this.attendanceModel?.profileId != null) {
      this.userService.getUserById(this.attendanceModel.profileId).pipe(takeUntil(this.destroy$)).subscribe(res => {
        if (res.result) {
          this.getAssignee(res.result);
        }
      });
    }
    if (this.action === TblActionType.Add) {
      
    }
    if (this.action === TblActionType.Edit) {
      this.requestPunchinModel = new AttendanceRequestModel();
      this.requestPunchoutModel = new AttendanceRequestModel();
      var extendData = {};
      if (!this.attendanceModel?.extendData) {
        extendData = {};
      } else {
        extendData = JSON.parse(this.attendanceModel?.extendData);
      }

      if(extendData['PunchinRequestDate']) this.requestPunchinModel.requestDate = new Date(extendData['PunchinRequestDate']);
      if (extendData['PunchoutRequestDate']) this.requestPunchoutModel.requestDate = new Date(extendData['PunchoutRequestDate']);

      if(this.attendanceModel.punchinRequestStatus !== PunchinRequestStatus.PunchinApproved){
        this.displayPunchinCurrent = this.attendanceModel.punchinDate;
        this.displayPunchinRequest = this.requestPunchinModel?.requestDate ? this.requestPunchinModel.requestDate : null;
      }else{
        this.displayPunchinCurrent = this.requestPunchinModel?.requestDate ? this.requestPunchinModel.requestDate : null;
        this.displayPunchinRequest = this.attendanceModel.punchinDate;
      }

      if(this.attendanceModel.punchoutRequestStatus !== PunchoutRequestStatus.PunchoutApproved){
        this.displayPunchoutCurrent = this.attendanceModel.punchoutDate;
        this.displayPunchoutRequest = this.requestPunchoutModel?.requestDate ? this.requestPunchoutModel.requestDate : null;
      }else{
        this.displayPunchoutCurrent = this.requestPunchoutModel?.requestDate ? this.requestPunchoutModel.requestDate : null;
        this.displayPunchoutRequest = this.attendanceModel.punchoutDate;
      }

      this.requestPunchinModel.requestNote = extendData['PunchinRequestNote'];
      this.requestPunchoutModel.requestNote = extendData['PunchoutRequestNote'];
    }
  }
  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  async ngOnInit() {
    this.frmAttendance = this.frmBuilder.formGroup(AttendanceModel, this.attendanceModel);
    this.frmRequestPunchin = this.frmBuilder.formGroup(AttendanceRequestModel, this.requestPunchinModel);
    this.frmRequestPunchout = this.frmBuilder.formGroup(AttendanceRequestModel, this.requestPunchoutModel);
    this.dialModalRef.updatePosition({ right: '0', });
    this.userService.allUserList().subscribe(res => { 
      this.listEmployees = res;
      this.listEmployees.map((user) => {
        let fullName = `${user.firstName} ${user.lastName}`;
        user.fullName = fullName.trim() ? fullName : "Unknown";
      });
    });

    var timezoneOffset = new Date().getTimezoneOffset();
    var offsetDecimal = -(timezoneOffset / 60);
    if (this.attendanceModel?.punchinOffset) {
      if (this.attendanceModel?.punchinOffset >= 0) this.timeZoneIn += ' +' + this.attendanceModel.punchinOffset.toFixed(2);
      else this.timeZoneIn += ' ' + this.attendanceModel.punchinOffset.toFixed(2);
    } else {
      if (offsetDecimal >= 0) {
        this.timeZoneIn += ' +' + offsetDecimal.toFixed(2);
      } else {
        this.timeZoneIn += ' ' + offsetDecimal.toFixed(2);
      }
    }

    if (this.attendanceModel?.punchoutOffset) {
      if (this.attendanceModel?.punchoutOffset >= 0) this.timeZoneOut += ' +' + this.attendanceModel.punchoutOffset.toFixed(2);
      else this.timeZoneOut += ' ' + this.attendanceModel.punchoutOffset.toFixed(2);
    } else {
      if (offsetDecimal >= 0) {
        this.timeZoneOut += ' +' + offsetDecimal.toFixed(2);
      } else {
        this.timeZoneOut += ' ' + offsetDecimal.toFixed(2);
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.frmAttendance)
      this.frmAttendance.valueChanges.pipe(take(1))
        .subscribe(resp => this.isChange = true);
  }

  closeDialog() {
    this.isChange ? this.dialModalRef.close(true) : this.dialModalRef.close();
  }

  dateChanged(data: { value: Moment, isInitial: boolean }, prop: string) {
    if (data.isInitial) {
      this.frmAttendance.controls[prop].setValue(data.value);
    }
  }

  clearAssignee() {
    this.userChosen = null;
  }

  getAssignee(data: UserModel) {
    if (data && data !== this.userChosen) {
      this.userChosen = data;
    }
  }

  requestAttendance(typeRequest: boolean) {
    const attendanceRef = this.dialog.open(RequestAttendanceComponent, {
      disableClose: true,
      width: '600px',
      autoFocus: false,
      data: {
        attendance: this.attendanceModel,
        user: this.user,
        isRequestPunchin: typeRequest,
      }
    });
    attendanceRef.afterClosed().subscribe(response => {
      if (response) {
        this.isChange = true;
        this.attendanceModel = response;
        var extendData = {};
        if (!this.attendanceModel?.extendData) {
          extendData = {};
        } else {
          extendData = JSON.parse(this.attendanceModel?.extendData);
        }
        if (extendData['PunchinRequestDate']) this.requestPunchinModel.requestDate = new Date(extendData['PunchinRequestDate']);
        this.requestPunchinModel.requestNote = extendData['PunchinRequestNote'];
        if (extendData['PunchoutRequestDate']) this.requestPunchoutModel.requestDate = new Date(extendData['PunchoutRequestDate']);

        if(this.attendanceModel.punchinRequestStatus !== PunchinRequestStatus.PunchinApproved){
          this.displayPunchinCurrent = this.attendanceModel.punchinDate;
          this.displayPunchinRequest = this.requestPunchinModel?.requestDate ? this.requestPunchinModel.requestDate : null;
        }else{
          this.displayPunchinCurrent = this.requestPunchinModel?.requestDate ? this.requestPunchinModel.requestDate : null;
          this.displayPunchinRequest = this.attendanceModel.punchinDate;
        }
  
        if(this.attendanceModel.punchoutRequestStatus !== PunchoutRequestStatus.PunchoutApproved){
          this.displayPunchoutCurrent = this.attendanceModel.punchoutDate;
          this.displayPunchoutRequest = this.requestPunchoutModel?.requestDate ? this.requestPunchoutModel.requestDate : null;
        }else{
          this.displayPunchoutCurrent = this.requestPunchoutModel?.requestDate ? this.requestPunchoutModel.requestDate : null;
          this.displayPunchoutRequest = this.attendanceModel.punchoutDate;
        }

        this.requestPunchoutModel.requestNote = extendData['PunchoutRequestNote'];
        this.frmRequestPunchin = this.frmBuilder.formGroup(AttendanceRequestModel, this.requestPunchinModel);
        this.frmRequestPunchout = this.frmBuilder.formGroup(AttendanceRequestModel, this.requestPunchoutModel);
      }
      else this.isChange = false;
    });
  }

  saveAttendanceRequest(mode: string, isRequestPunchin: boolean) {
    if (isRequestPunchin) {
      if (this.frmRequestPunchin.valid) {
        // const model: AttendanceRequestModel = Object.assign({}, this.frmRequestPunchin.value);
        // var extendData = {};
        // if (!this.attendanceModel?.extendData) {
        //   extendData = {};
        // } else {
        //   extendData = JSON.parse(this.attendanceModel?.extendData);
        // }
        // extendData['PunchinRequestDate'] = model.requestDate;
        // extendData['PunchinRequestNote'] = model.requestNote;
        // this.attendanceModel.extendData = JSON.stringify(extendData);
        switch (mode) {
          case 'approve':
            this.attendanceModel.punchinRequestStatus = PunchinRequestStatus.PunchinApproved;
            break;
          case 'reject':
            this.attendanceModel.punchinRequestStatus = PunchinRequestStatus.PunchinRejected;
            break;
        }
        this.attendanceService.requestChangeAttendance(this.attendanceModel, isRequestPunchin).subscribe(resp => {
          if (resp.result) {
            this.attendanceModel = resp.result;
            this.isChange = true;
            this.toast.success(`Save request attendance successfully`, 'Success');
          }
        }).add(() => {
          var extendData = {};
          if (!this.attendanceModel?.extendData) {
            extendData = {};
          } else {
            extendData = JSON.parse(this.attendanceModel?.extendData);
          }
          if (extendData['PunchinRequestssDate']) this.requestPunchinModel.requestDate = new Date(extendData['PunchinRequestDate']);
          
          this.requestPunchinModel.requestNote = extendData['PunchinRequestNote'];
          this.frmRequestPunchin = this.frmBuilder.formGroup(AttendanceRequestModel, this.requestPunchinModel);
        });
      }
    } else {
      if (this.frmRequestPunchout.valid) {
        // const model: AttendanceRequestModel = Object.assign({}, this.frmRequestPunchout.value);
        // var extendData = {};
        // if (!this.attendanceModel?.extendData) {
        //   extendData = {};
        // } else {
        //   extendData = JSON.parse(this.attendanceModel?.extendData);
        // }
        // extendData['PunchoutRequestDate'] = model.requestDate;
        // extendData['PunchoutRequestNote'] = model.requestNote;
        // this.attendanceModel.extendData = JSON.stringify(extendData);
        switch (mode) {
          case 'approve':
            this.attendanceModel.punchoutRequestStatus = PunchoutRequestStatus.PunchoutApproved;
            break;
          case 'reject':
            this.attendanceModel.punchoutRequestStatus = PunchoutRequestStatus.PunchoutRejected;
            break;
        }
        this.attendanceService.requestChangeAttendance(this.attendanceModel, isRequestPunchin).subscribe(resp => {
          if (resp.result) {
            this.attendanceModel = resp.result;
            this.isChange = true;
            this.toast.success(`Save request attendance successfully`, 'Success');
          }
        }).add(() => {
          var extendData = {};
          if (!this.attendanceModel?.extendData) {
            extendData = {};
          } else {
            extendData = JSON.parse(this.attendanceModel?.extendData);
          }
          if (extendData['PunchoutRequestDate']) this.requestPunchoutModel.requestDate = new Date(extendData['PunchoutRequestDate']);

          this.requestPunchoutModel.requestNote = extendData['PunchoutRequestNote'];
          this.frmRequestPunchout = this.frmBuilder.formGroup(AttendanceRequestModel, this.requestPunchoutModel);
        });
      }
    }
  }

  saveAttendanceRequestAll(mode: string) {
    // var extendData = {};
    // if (!this.attendanceModel?.extendData) {
    //   extendData = {};
    // } else {
    //   extendData = JSON.parse(this.attendanceModel?.extendData);
    // }
    if (this.frmRequestPunchin.valid && this.attendanceModel.punchinRequestStatus !== PunchinRequestStatus.Nothing) {
      // const model: AttendanceRequestModel = Object.assign({}, this.frmRequestPunchin.value);
      // extendData['PunchinRequestDate'] = model.requestDate;
      // extendData['PunchinRequestNote'] = model.requestNote;
      switch (mode) {
        case 'approve':
          this.attendanceModel.punchinRequestStatus = PunchinRequestStatus.PunchinApproved;
          break;
        case 'reject':
          this.attendanceModel.punchinRequestStatus = PunchinRequestStatus.PunchinRejected;
          break;
      }
    }
    if (this.frmRequestPunchout.valid && this.attendanceModel.punchoutRequestStatus !== PunchoutRequestStatus.Nothing) {
      // const model: AttendanceRequestModel = Object.assign({}, this.frmRequestPunchout.value);
      // extendData['PunchoutRequestDate'] = model.requestDate;
      // extendData['PunchoutRequestNote'] = model.requestNote;
      switch (mode) {
        case 'approve':
          this.attendanceModel.punchoutRequestStatus = PunchoutRequestStatus.PunchoutApproved;
          break;
        case 'reject':
          this.attendanceModel.punchoutRequestStatus = PunchoutRequestStatus.PunchoutRejected;
          break;
      }
    }
    // this.attendanceModel.extendData = JSON.stringify(extendData);
    this.attendanceService.requestChangeAttendanceAll(this.attendanceModel).subscribe(resp => {
      if (resp.result) {
        this.attendanceModel = resp.result;
        this.isChange = true;
        this.toast.success(`Save request attendance successfully`, 'Success');
      }
    }).add(() => {
      var extendData = {};
      if (!this.attendanceModel?.extendData) {
        extendData = {};
      } else {
        extendData = JSON.parse(this.attendanceModel?.extendData);
      }
      if (extendData['PunchinRequestDate']) this.requestPunchinModel.requestDate = new Date(extendData['PunchinRequestDate']);
      this.requestPunchinModel.requestNote = extendData['PunchinRequestNote'];
      if (extendData['PunchoutRequestDate']) this.requestPunchoutModel.requestDate = new Date(extendData['PunchoutRequestDate']);

      this.requestPunchoutModel.requestNote = extendData['PunchoutRequestNote'];
      this.frmRequestPunchin = this.frmBuilder.formGroup(AttendanceRequestModel, this.requestPunchinModel);
      this.frmRequestPunchout = this.frmBuilder.formGroup(AttendanceRequestModel, this.requestPunchoutModel);
      this.frmAttendance = this.frmBuilder.formGroup(AttendanceModel, this.attendanceModel);
    });
  }
}