import { DailyReport, DailyReportEdit } from 'src/app/shared/models/daily-report.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { tap, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PagedData } from '../models/paging/paged-data';
import { ContactPage, Page } from '../models/paging/page';
import { ReturnResult } from '../models/return-result';

@Injectable({
    providedIn: 'root'
})

export class DailyReportService {
    baseUrl = environment.apiDailyReportManagement;

    constructor(private http: HttpClient) { }

    saveReport(report : DailyReport): Observable<ReturnResult<boolean>> {
        return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SaveReport`, report);
    }

    getDailyReportPaging(page: Page): Observable<ReturnResult<PagedData<DailyReport>>> {
        return this.http.post<ReturnResult<PagedData<DailyReport>>>(`${this.baseUrl}/GetDailyReportPaging`, page);
    }

    deleteReport(model: DailyReport[]): Observable<ReturnResult<boolean>> {
        return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/DeleteReport`, model);
    }
    getDailyReportById(dailyReportId: string): Observable<ReturnResult<DailyReportEdit>> {
        return this.http.get<ReturnResult<DailyReportEdit>>(`${this.baseUrl}/GetDailyReportById?dailyReportId=${dailyReportId}`);
    }
    importReport(formData: FormData): Observable<ReturnResult<any>> {
        return this.http.post<ReturnResult<any>>(`${this.baseUrl}/SaveReportImport`, formData).pipe(
            timeout(1200000)
        );
    }
    getLatestReportByProfileId(profileId: string): Observable<ReturnResult<DailyReport>> {
        return this.http.get<ReturnResult<DailyReport>>(`${this.baseUrl}/GetLatestReportByProfileId?profileId=${profileId}`);
    }
    hasReportedToday(profileId: string): Observable<ReturnResult<boolean>> {
        return this.http.get<ReturnResult<boolean>>(`${this.baseUrl}/HasReportedToday?profileId=${profileId}`);
    }
    isForgotReport(profileId: string): Observable<ReturnResult<boolean>> {
        return this.http.get<ReturnResult<boolean>>(`${this.baseUrl}/IsForgotReport?profileId=${profileId}`);
    }
    disableReminder(): Observable<ReturnResult<boolean>> {
        return this.http.get<ReturnResult<boolean>>(`${this.baseUrl}/DisableReminder`);
    }
}