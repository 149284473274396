import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MediaManagementService } from 'src/app/modules/admin/media-management/media-management.service';
import { Media } from 'src/app/modules/admin/profile-management/profile-detail.model';
import { MutliMediaService } from '../../services/mutli-media.service';
import { UploadVideoComponent } from '../stand-alone-component/upload-video/upload-video.component';
import { SpotlightrService } from '../../services/spotlightr.service';
import { NbToastrService } from '@nebular/theme';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-multi-media-gallery',
  templateUrl: './multi-media-gallery.component.html',
  styleUrls: ['./multi-media-gallery.component.scss']
})
export class MultiMediaGalleryComponent implements OnInit {
  @ViewChild('uploadImage', { static: true }) uploadImage: TemplateRef<any>;

  medias: Media[] = [];
  loading = false;
  selectedItem: Media;
  files: File[] = [];
  dialogUpload: MatDialogRef<any, any>;
  obsUploadVideo = this.spotlightrService.getObsVideoUploadSpotlightr();
  invalidSize: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<MultiMediaGalleryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public mediaService: MediaManagementService,
    private dialog: MatDialog,
    private multiMediaService: MutliMediaService,
    private spotlightrService: SpotlightrService,
    private toast: NbToastrService
  ) {
    if (this.data) {
      this.selectedItem = this.data.selectedItem;
    }
  }

  ngOnInit(): void {
    this.getMedia();
  }

  async getMedia() {
    this.loading = true;
    if (this.data && this.data.profileId) {
      const dataMedia = await this.mediaService.getMediaByProfileId(this.data.profileId, this.data.typeMediaType ?? 1).toPromise();
      if (dataMedia.result) {
        this.medias = dataMedia.result;
      }
    }
    this.loading = false;
  }
  selectItem(media: Media) {
    this.selectedItem = media;
  }
  public trackByFn(index, item) {
    return index;
  }

  clickUpload() {
    this.files = [];
    this.dialogUpload = this.dialog.open(this.data?.typeMediaType == 1 ? this.uploadImage : UploadVideoComponent, {
      disableClose: true,
      autoFocus: false,
      width: '50vw',
      data: {
        profileId: this.data.profileId,
        displayName: this.data.displayName,
      }
    })

  }

  onSelect(event) {
    if (event.addedFiles) {
      const addFiles = event.addedFiles.filter(x => !this.files.map(y => y.name).includes(x.name));
      this.files.push(...addFiles);
      this.validateSize();
    }
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
    this.validateSize();
  }

  async onSaveUpload() {
    if (this.files) {
      this.loading = true;
      var resp = await this.multiMediaService.uploadMultipleMediaAsync(this.data.profileId, this.files).toPromise();
      if (resp.result) {
        this.toast.success("Save successfully!", "Success");
        this.dialogUpload.close();
        await this.getMedia();
        if (this.medias && this.medias.length > 0)
          this.selectItem(this.medias.find((value, i) => i == (this.medias.length - 1)));
      }
      this.loading = false;
    }
  }

  validateSize() {
    if (this.files) {
      this.invalidSize = true
      const totalSize = this.files.map(x => x.size).reduce((a, b) => a + b, 0);
      if (totalSize < environment.maxSizeUpload) this.invalidSize = false;
    }
  }
}
