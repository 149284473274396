import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { RxFormBuilder, RxwebValidators } from '@rxweb/reactive-form-validators';
import { countriesArr } from 'src/app/shared/contances/country-constance';
import { ActionOnboardingFormEnums, ContactOnBoardViewModel, EntityTypeOnboardingFormEnums, ObjectChangedModel } from '../onboarding-form-profile.model';

@Component({
  selector: 'app-contact-details-tab',
  templateUrl: './contact-details-tab.component.html',
  styleUrls: ['./contact-details-tab.component.scss', '../onboarding-form.component.scss']
})
export class ContactDetailsTabComponent implements OnInit, OnChanges {
  @Input() contactOnBoardingViewModel: ContactOnBoardViewModel[];
  contactOnBoardingViewModelForms: FormGroup[] = [];

  constructor(private formBuilder: RxFormBuilder) {
  }
  isSameAsArtist: boolean = true;
  newContactLstFrm: any[] = [];
  // 2022-06-06 tienlm add start
  changesList: ObjectChangedModel[] = [];
  mainProfileId: string;
  countriesArr = countriesArr;
  // 2022-06-06 tienlm add end
  ngOnInit(): void {
    if (this.contactOnBoardingViewModel && this.contactOnBoardingViewModel.length > 0) {
      // set main profileid:
      this.mainProfileId = this.contactOnBoardingViewModel[0]?.PID ?? null;
      this.contactOnBoardingViewModel.forEach((i) => {
        i.isSameAsArtist = true;
      });
      this.contactOnBoardingViewModelForms = [];
      this.contactOnBoardingViewModel.forEach((cont, index) => {
        this.contactOnBoardingViewModelForms.push(this.formBuilder.formGroup(ContactOnBoardViewModel, cont));
        this.contactOnBoardingViewModelForms[index].markAllAsTouched();
      });
    } else {
      // new contact:
      this.contactOnBoardingViewModelForms[0] = this.formBuilder.formGroup(ContactOnBoardViewModel, new ContactOnBoardViewModel());
      this.contactOnBoardingViewModelForms[0].markAllAsTouched();
    }

    // add changes logic:
    // this.contactOnBoardingViewModelForms.forEach((contactForm, index) => {
    //   // add changes detect logic here:
    //   if (contactForm.controls.ContactID.value && contactForm.controls.ContactID.value > 0) {
    //     Object.entries(contactForm.controls).forEach(value => {
    //       const [key, control] = value;
    //       control.valueChanges.subscribe(
    //         (value) => {
    //           this.detectContactChanges(key, value, index, contactForm.controls.contactID.value, contactForm.controls.PID.value);
    //         },
    //       );
    //     });
    //   }
    // });
  }
  sameAsArtistChecked(event) {
    // console.log(event);
  }
  sameAsArtistLabelChecked(value, model: AbstractControl) {
    let newValue = !value;
    model.setValue(newValue);
    debugger;
    if (newValue == false) {
      // add controls
      this.contactOnBoardingViewModelForms[0].controls.Country.setValidators([RxwebValidators.required()]);
      this.contactOnBoardingViewModelForms[0].controls.City.setValidators([RxwebValidators.required()]);
    } else {
      //  value == true: 
      this.contactOnBoardingViewModelForms[0].controls.Country.clearValidators();
      this.contactOnBoardingViewModelForms[0].controls.City.clearValidators();
    }

  }
  //  for child model
  ContactSameAsArtistLabelChecked(value, model: AbstractControl, mainControl: FormGroup) {
    model.setValue(!value);
    if (value) {
      // 
      mainControl.controls.Country.setValidators([RxwebValidators.required()]);
      mainControl.controls.City.setValidators([RxwebValidators.required()]);
    } else {
      mainControl.controls.Country.clearValidators();
      mainControl.controls.City.clearValidators();
    }
  }
  addMoreContacts() {
    let tempContact = new ContactOnBoardViewModel();
    tempContact.PID = this.mainProfileId ?? null;
    tempContact.ContactTypeID
    let newContact = this.formBuilder.formGroup(ContactOnBoardViewModel, tempContact);
    this.contactOnBoardingViewModelForms.push(newContact);
    this.contactOnBoardingViewModelForms[this.contactOnBoardingViewModelForms.length - 1].markAllAsTouched();
  }
  removeSubContacts(index) {
    this.contactOnBoardingViewModelForms.splice(index, 1);
  }
  ngOnChanges(changes: SimpleChanges): void {

    let dataChanges = changes.contactOnBoardingViewModel;
    if (dataChanges && dataChanges.previousValue && JSON.stringify(dataChanges.previousValue) != JSON.stringify(dataChanges.currentValue)) {

      this.ngOnInit();
    }
  }
  getContactDetails(): ContactOnBoardViewModel[] {
    if (this.contactOnBoardingViewModelForms.length > 0) {
      let contactViewModel: ContactOnBoardViewModel[] = this.contactOnBoardingViewModelForms.map(item => item.value);
      return contactViewModel;
    }
    return [];
  }
  zipcodeChanged(control: AbstractControl) {
    console.log(control);
  }
  checkChanged(mainControl: FormGroup) {
    console.log(mainControl.controls.isSameAsArtist.value);
    if (!mainControl.controls.isSameAsArtist.value) {
      mainControl.controls.Country.setValidators([RxwebValidators.required()]);
      mainControl.controls.City.setValidators([RxwebValidators.required()]);
    } else {
      mainControl.controls.Country.clearValidators();
      mainControl.controls.City.clearValidators();
    }
  }
}
