import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { shareReplay, switchMap } from 'rxjs/operators';
import { ProfileService } from 'src/app/modules/admin/profile-management/profile.service';
import { ActDetailsTabComponent } from './act-details-tab/act-details-tab.component';
import { TaskBoardService } from 'src/app/modules/admin/task-management/task-board/task-board.service';
import { SettingService } from '../../services/setting.service';
import { AgreementTabComponent } from './agreement-tab/agreement-tab.component';
import { ArtistDetailsTabComponent } from './artist-details-tab/artist-details-tab.component';
import { ContactDetailsTabComponent } from './contact-details-tab/contact-details-tab.component';
import { ActionOnboardingFormEnums, ArtistDetails, ContactOnBoardViewModel, EntityTypeOnboardingFormEnums, ObjectChangedModel, OnBoardingFormViewModel } from './onboarding-form-profile.model';
import { Helper } from '../../utility/Helper';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-onboarding-form',
  templateUrl: './onboarding-form.component.html',
  styleUrls: ['./onboarding-form.component.scss'],
})
export class OnboardingFormComponent implements OnInit {
  @ViewChild('appArtistDetails', { static: true }) appArtistDetails: ArtistDetailsTabComponent;
  @ViewChild('contactDetails', { static: true }) contactDetails: ContactDetailsTabComponent
  @ViewChild('appArgreement', { static: true }) appAgreement: AgreementTabComponent;
  @ViewChild('actDetails', { static: true }) actDetails: ActDetailsTabComponent;
  @ViewChild('stepperForm', { static: false }) stepperForm: MatStepper;

  @Input() frontFacingUrl: string;
  @Input() titleForm = 'Artist information';
  @Input() pid: string;
  @Input() createNew: boolean = false;
  constructor(
    private dialog: MatDialog,
    private profileService: ProfileService,
    private formBuilder: RxFormBuilder,
    private taskService: TaskBoardService) { }
  selectedFrom: number = 0;
  onboardingData: OnBoardingFormViewModel = new OnBoardingFormViewModel();
  onboardingDataForm: FormGroup;
  isLoading: boolean = false;
  // 2022-06-06 tienlm add start
  onboardingDataBackup: OnBoardingFormViewModel = new OnBoardingFormViewModel();
  changesList: ObjectChangedModel[] = [];
  isNext: boolean = false;
  cacheDataDialog;
  lockSubmit: boolean = false;
  taskType;
  taskStatus;
  contactID: number | null;
  // 2022-06-06 tienlm add end
  ngOnInit(): void {
    if (this.pid) {
      this.isLoading = true;
      this.profileService.getProfileToken(this.pid).subscribe(res => {
        if (res) {
          this.onboardingData = res;
          // check cookie here:
          let cookieData = this.getLocalOnboardingData('onboardingdata');
          let parsedData: OnBoardingFormViewModel = cookieData ? JSON.parse(cookieData) : null;
          if (parsedData && this.pid === parsedData?.ArtistDetails?.PID) {
            // have data:
            this.cacheDataDialog = this.dialog.open(ConfirmModalComponent, {
              data: {
                message: 'Do you wish to continue entering artist data?'
              }
            }).afterClosed().subscribe(resp => {
              if (resp) {
                let cookieData = this.getLocalOnboardingData('onboardingdata');
                let parsedData: OnBoardingFormViewModel = JSON.parse(cookieData);
                this.onboardingData = parsedData;
                this.contactID = this.onboardingData.Contacts && this.onboardingData.Contacts.length > 0 ? this.onboardingData.Contacts[0]?.ContactID : null;
                this.onboardingDataBackup = JSON.parse(JSON.stringify(parsedData)) as OnBoardingFormViewModel;
              } else {
                this.contactID = this.onboardingData.Contacts && this.onboardingData.Contacts.length > 0 ? this.onboardingData.Contacts[0]?.ContactID : null;
                this.onboardingDataBackup = JSON.parse(JSON.stringify(res)) as OnBoardingFormViewModel;
              }
              this.deleteLocalStorage('onboardingdata');
            });
          } else {
            this.contactID = this.onboardingData.Contacts && this.onboardingData.Contacts.length > 0 ? this.onboardingData.Contacts[0]?.ContactID : null;
            this.onboardingDataBackup = JSON.parse(JSON.stringify(res)) as OnBoardingFormViewModel;
            console.log(this.onboardingDataBackup);
          }
        } else {
          window.location.href = !this.frontFacingUrl.endsWith('/') ? (this.frontFacingUrl + '/expired') : (this.frontFacingUrl + 'expired');
        }
        this.isLoading = false;
      });

    } else {
      this.onboardingData = new OnBoardingFormViewModel();
    }
  }

  selectionChangeTab(event: StepperSelectionEvent) {
    if (event) {
      this.selectedFrom = event.selectedIndex;
      setTimeout(() => {
        if (!this.checkSubmitForm())
          return;
      }, 10);
    }
  }

  validateForm(stepIndex: number) {
    if (stepIndex === 0) {
      // artist details validate
      if (this.appArtistDetails.artistDetailsForm.valid) {
        return false;
      } else {
        return true;
      }
    } else if (stepIndex === 1) {
      // contact detail validate:
      return this.validateContactDetails();
    } else if (stepIndex === 2) {
      return this.validateActDetails();
    } else if (stepIndex === 3) {
      // agreement validate
      if (this.appAgreement.agreementForm.controls.Term.value === true && this.appAgreement.agreementForm.valid) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }
  // function to validate contact list details
  validateContactDetails() {
    let validate = false;
    let contactForm = this.contactDetails.contactOnBoardingViewModelForms;
    if (contactForm.length > 0) {
      contactForm.forEach(element => {
        if (!element.valid) {
          validate = true;
        }
      });
      return validate;
    } else {
      return false;
    }
  }

  validateActDetails() {
    let validate = false;
    let actForms = this.actDetails.actDetailsForm;
    if (actForms && actForms.length > 0) {
      actForms.forEach(element => {
        if (!element.valid) {
          validate = true;
        }
      });
      return validate;
    } else {
      return false;
    }
  }

  checkSubmitForm() {
    for (let index = 0; index <= this.selectedFrom; index++) {
      if (this.validateForm(index)) {
        this.selectedFrom = index;
        return false;
      }
    }

    return true;
  }

  // function to combine all parts of regist form
  SubmitForm() {
    if (!this.checkSubmitForm())
      return;
    this.changesList = [];
    this.lockSubmit = true;
    // data from artist details
    let artistDetails: ArtistDetails;
    // changes from artist details
    let artistDetailsChanges: ObjectChangedModel[] = [];
    // get agreement details:
    let agreementFormData = this.appAgreement.getAgreementOnboardingForm();
    if (this.createNew) {
      // signup form contact data:
      artistDetails = this.appArtistDetails.getArtistDetails();
      // add profile note:
      artistDetails.Notes = agreementFormData.Note;
      artistDetails.Signature = agreementFormData.Signatures;
      artistDetails.Tags = 'newsignup';
    } else {
      // update if pid has data:
      // get all changes from artist details details
      artistDetailsChanges = this.appArtistDetails.getChangesArtistDetails();
    }

    // handle changes:
    let contactDetailsPart = this.contactDetails.getContactDetails();
    let actDetailsPart = this.actDetails.getActDetails();
    this.getActDetailChanges(actDetailsPart);
    // attaching location artist:
    if (contactDetailsPart && contactDetailsPart.length > 0) {
      // reuse artistDetails var base on createNew
      let artistData = !this.createNew ? this.appArtistDetails.getArtistDetails() : artistDetails;
      contactDetailsPart.forEach(cont => {
        if (cont.isSameAsArtist) {
          cont.Country = artistData.Country;
          cont.Address = artistData.Address;
          cont.Address2 = artistData.Address2;
          cont.City = artistData.City;
          cont.State = artistData.State;
          cont.Zipcode = artistData.Zipcode;
        }
      });
    }
    // get all changes from contact details
    this.getContactDetailChanges(contactDetailsPart);
    // handle submit update or signup:
    if (this.createNew) {
      // signup:
      let onboardingFormSubmit = new OnBoardingFormViewModel();
      onboardingFormSubmit.ArtistDetails = artistDetails;
      onboardingFormSubmit.ArtistDetails.RealName = onboardingFormSubmit.ArtistDetails.Scarlettname;
      onboardingFormSubmit.Contacts = contactDetailsPart;
      // actDetailsPart?.forEach(act => {
      //   // convert test as tring to array
      //   if (act.Testimonials && typeof act.Testimonials == 'string')
      //     act.Testimonials = JSON.parse(act.Testimonials);
      // });
      // standartlize Output
      actDetailsPart.forEach((element, index) => {
        if (index == 0) {
          element.Notes = agreementFormData?.Note ?? null;
        }
        ArtistDetails.standartlizeOutput(element);
      });
      onboardingFormSubmit.ActDetails = actDetailsPart;
      // CALL API SIGNUP HERE
      this.profileService.signupArtists(onboardingFormSubmit).subscribe(res => {
        if (res.result) {
          window.location.href = !this.frontFacingUrl.endsWith('/') ? (this.frontFacingUrl + '/thank-you/') : (this.frontFacingUrl + 'thank-you/');
        }
        this.lockSubmit = false;
      });
    } else {
      // attach signature:
      this.changesList = this.changesList.concat(artistDetailsChanges);
      //Add consent Artist details.
      let newChanges = new ObjectChangedModel(this.onboardingDataBackup.ArtistDetails.PID, EntityTypeOnboardingFormEnums.ARTIST_DETAILS, ActionOnboardingFormEnums.MODIFIED, 'Consent', this.onboardingDataBackup.ArtistDetails.PID, true, null, + new Date());
      this.changesList.push(newChanges);
      // attach note:
      this.attachNoteForChanges();
      this.attachSignatureForChanges(agreementFormData.Signatures);
      // CALL API SUBMIT ONBOARDING HERE:
      this.profileService.artistUpdate(this.changesList).pipe(
        switchMap(res => {
          if (res.result) {
            // create task:
            let artistData = this.appArtistDetails.getArtistDetails();
            this.taskService.saveSubmitOnBoardingForm(artistData?.PID).subscribe();
            return this.taskService.autoCreateTaskOnBoardingData(artistData?.PID);
          }
        })
      ).subscribe(rs => {
        if (rs.result) {
          window.location.href = !this.frontFacingUrl.endsWith('/') ? (this.frontFacingUrl + '/thank-you/') : (this.frontFacingUrl + 'thank-you/');
        }
      })
    }
  }
  getContactDetailChanges(contacts: ContactOnBoardViewModel[]) {
    if (contacts.length > 0) {
      // contact pasted from data
      contacts.forEach(cont => {
        if (cont.ContactID > 0) {
          // already has:
          let oldContactIndex = this.onboardingDataBackup.Contacts.findIndex(oldCont => oldCont.ContactID == cont.ContactID);
          // if found it in backup
          if (oldContactIndex != -1) {
            // has in old contact block:
            for (var prop in cont) {
              // exception case
              if (prop.toLowerCase() == 'isSameAsArtist'.toLowerCase()) {
                continue;
              }
              // compare property:
              if (JSON.stringify(cont[prop]).trim() !== JSON.stringify(this.onboardingDataBackup.Contacts[oldContactIndex][prop]).trim()) {
                // check if it has already been added to change list:
                let isAlreadyAdded = this.changesList.findIndex(x => x.PID == cont.PID && (x.PropertyName && x.PropertyName.toLowerCase() == prop.toLowerCase()) && x.EntityID == cont.ContactID && x.ActionID == ActionOnboardingFormEnums.MODIFIED);
                if (isAlreadyAdded != -1) {
                  // if not already added
                  this.changesList[isAlreadyAdded].PropertyValue = cont[prop];
                  this.changesList[isAlreadyAdded].DateModified = + new Date();
                } else {
                  let newChanges = new ObjectChangedModel(cont.PID, EntityTypeOnboardingFormEnums.CONTACT, ActionOnboardingFormEnums.MODIFIED, prop, cont.ContactID, cont[prop], null, + new Date());
                  this.changesList.push(newChanges);
                }
              }
            }
          }
        }
        // new contact:
        else {
          let artistDetails = this.appArtistDetails.getArtistDetails();
          // add pid if available
          cont.PID = artistDetails?.PID;
          let newChanges = new ObjectChangedModel(artistDetails.PID, EntityTypeOnboardingFormEnums.CONTACTBLOCK, ActionOnboardingFormEnums.ADDED, '', null, JSON.stringify(cont), null, + new Date())
          this.changesList.push(newChanges);
        }
      });
      // get removed contact:
      let oldContactIds = this.onboardingDataBackup.Contacts.map(x => x.ContactID);
      let newContacIds = contacts.map(x => x.ContactID).filter(y => y != 0);
      let removedContactIds = oldContactIds.filter(x => !newContacIds.includes(x));
      if (removedContactIds.length > 0) {
        for (let index = 0; index < removedContactIds.length; index++) {
          const removeContactInfo = this.onboardingDataBackup.Contacts.find(x => x.ContactID === removedContactIds[index]);
          // check if property has been added:
          let isChangesRemoved = this.changesList.findIndex(x => x.EntityID == removeContactInfo.ContactID && x.ActionID == ActionOnboardingFormEnums.DELETED && x.EntityTypeID == EntityTypeOnboardingFormEnums.CONTACTBLOCK)
          if (isChangesRemoved != -1) {
            continue;
          }
          let newChanges = new ObjectChangedModel(removeContactInfo.PID, EntityTypeOnboardingFormEnums.CONTACTBLOCK, ActionOnboardingFormEnums.DELETED, null, removeContactInfo.ContactID, null, null, + new Date());
          this.changesList.push(newChanges);
        }
      }
    }
  }
  attachSignatureForChanges(signature: string) {
    if (this.changesList.length > 0) {
      this.changesList.forEach(item => {
        item.Signature = signature;
      });
    }
  }

  getActDetailChanges(acts: ArtistDetails[]) {
    if (acts && acts.length > 0) {
      let artistDetails = this.appArtistDetails.getArtistDetails();
      acts.forEach(act => {
        if (!Helper.isNullOrEmpty(act.PID) && !Helper.isEmptyOrSpaces(act.PID)) { //modify
          let oldActIndex = this.onboardingDataBackup.ActDetails.findIndex(oldAct => oldAct.PID == act.PID);
          if (oldActIndex != -1) {
            //Add for each properties act.
            for (var prop in act) {
              if (JSON.stringify(act[prop]) !== JSON.stringify(this.onboardingDataBackup.ActDetails[oldActIndex][prop])) {
                switch (prop) {
                  case 'RealName': case 'Rider': case 'SetList': case 'PreviousClients': case 'Testimonials': {
                    let newChanges = new ObjectChangedModel(artistDetails.PID, EntityTypeOnboardingFormEnums.ACT, ActionOnboardingFormEnums.MODIFIED, prop, act.PID, act[prop], null, + new Date());
                    this.changesList.push(newChanges);
                  }
                    break;
                  case 'VideoLinks': case 'PhotoLinks': {
                    var medias = act[prop].filter(x => !this.onboardingDataBackup.ActDetails[oldActIndex][prop].map(x => x.MediaID).includes(x.MediaID) && x.Media !== 'assets/images/error-photo.jpg');
                    if (medias && medias.length > 0) {
                      medias.forEach(media => {
                        let newChanges = new ObjectChangedModel(artistDetails.PID, EntityTypeOnboardingFormEnums.ACT, ActionOnboardingFormEnums.ADDED, prop, act.PID, media.Media, null, + new Date());
                        this.changesList.push(newChanges);
                      });
                    }
                  }
                    break;
                  case 'Bio': case 'Reason': case 'RiderAttachment': {
                    if (!Helper.isEmptyOrSpaces(act[prop]) && !Helper.isNullOrEmpty(act[prop])) {
                      let newChanges = new ObjectChangedModel(artistDetails.PID, EntityTypeOnboardingFormEnums.ACT, ActionOnboardingFormEnums.ADDED, prop, act.PID, act[prop], null, + new Date());
                      this.changesList.push(newChanges);
                    }
                  }
                    break;
                }
              }
            }
          }
        } else { // new
          act.Consent = true;
          let newChanges = new ObjectChangedModel(artistDetails.PID, EntityTypeOnboardingFormEnums.ACTBLOCK, ActionOnboardingFormEnums.ADDED, '', null, JSON.stringify(act), null, + new Date())
          this.changesList.push(newChanges);
        }
      })

      let oldActIds = this.onboardingDataBackup.ActDetails.map(x => x.PID);
      let newActIds = acts.map(x => x.PID).filter(y => !Helper.isNullOrEmpty(y) && !Helper.isEmptyOrSpaces(y));
      let removedActIds = oldActIds.filter(x => !newActIds.includes(x));
      if (removedActIds.length > 0) {
        for (let index = 0; index < removedActIds.length; index++) {
          const removeActInfo = this.onboardingDataBackup.ActDetails.find(x => x.PID === removedActIds[index]);
          // check if property has been added:
          let isChangesRemoved = this.changesList.findIndex(x => x.EntityID == removeActInfo.PID && x.ActionID == ActionOnboardingFormEnums.DELETED && x.EntityTypeID == EntityTypeOnboardingFormEnums.ACTBLOCK)
          if (isChangesRemoved != -1) {
            continue;
          }
          let newChanges = new ObjectChangedModel(artistDetails.PID, EntityTypeOnboardingFormEnums.ACTBLOCK, ActionOnboardingFormEnums.DELETED, null, removeActInfo.PID, null, null, + new Date());
          this.changesList.push(newChanges);
        }
      }
    }
  }
  saveDataToLocal() {
    let savedData = new OnBoardingFormViewModel();
    savedData.ArtistDetails = this.appArtistDetails.getArtistDetails();
    savedData.Contacts = this.contactDetails.getContactDetails();
    savedData.ActDetails = this.actDetails.getActDetails();
    let approveSaveCookie = true;

    let data = JSON.stringify(savedData);
    window.localStorage.setItem('onboardingdata', data);
  }
  private getLocalOnboardingData(name: string) {
    return window.localStorage.getItem('onboardingdata') ?? ''
  }
  // listen
  @HostListener('window:beforeunload')
  saveData() {
    this.saveDataToLocal();
  }
  deleteLocalStorage(name) {
    window.localStorage.removeItem(name);
  }
  attachNoteForChanges() {
    if (!this.createNew) {
      let agreementData = this.appAgreement.getAgreementOnboardingForm();
      if (agreementData?.Note && agreementData?.Note.trim() !== '' && agreementData.Note !== this.onboardingDataBackup?.ArtistDetails?.Notes) {
        this.changesList.push(new ObjectChangedModel(this.pid, EntityTypeOnboardingFormEnums.ACT, ActionOnboardingFormEnums.MODIFIED, 'Notes', null, agreementData?.Note, agreementData?.Signatures, + new Date()))
      }
    }
  }
}
