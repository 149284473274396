<mat-datepicker #primasDateTimePicker="matDatepicker" class="date-time-picker" (opened)="setupInit()" ngDefaultControl
  [(ngModel)]="timeInput">
  <mat-datepicker-actions class="actions-bar" fxLayout="row" fxLayoutAlign="space-between center">
    <input nbInput type="text" [customNbTimepicker]="timepicker" [(ngModel)]="time" />
    <custom-nb-timepicker [(date)]="time" [minuteStep]="minuteStep > 1? true : false" #timepicker="CustomNbTimepicker"
      [singleColumn]="false" [twelveHoursFormat]="twelveHoursFormat" [step]="minuteStep"
      [minTime]="minTime ? minTime : null" [maxTime]="maxTime ? maxTime : null"
      (onSelectTime)="onSelectTime($event)"></custom-nb-timepicker>
    <!-- <button mat-button matDatepickerCancel>Cancel</button> -->
    <button mat-raised-button color="primary" (click)="applyClicked()" class="apply-btn">Apply</button>
  </mat-datepicker-actions>
</mat-datepicker>

<!-- matDatepickerApply -->