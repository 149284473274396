import { date, digit, maxLength, minNumber, numeric, NumericValueType, prop, required } from "@rxweb/reactive-form-validators";
import { ProfileDetailModel } from "../profile-management/profile-detail.model";
import { AutomateDataState } from "../profile-management/profile-detail/automate-datastate-log/automate-datastate-model";
import { UserModel } from "../user-management/user-model";

export class SaleOpportunity {
  constructor() {
    this.saleOpportunityId = 0;
    this.probability = 0;
    this.amount = 0;
  }
  @prop()
  saleOpportunityId: number;
  @prop()
  @required()
  @maxLength({ value: 255 })
  opportunityName: string;
  @prop()
  @maxLength({ value: 255 })
  stageId: number;
  @prop()
  @maxLength({ value: 255 })
  accountName: string;
  @prop()
  @numeric({ acceptValue: NumericValueType.Both, allowDecimal: true, message: 'Only numberic is allowed' })
  probability: number | 0;
  @prop()
  @maxLength({ value: 255 })
  type: string;
  @prop()
  @prop()
  @numeric({ acceptValue: NumericValueType.Both, allowDecimal: true, message: 'Only numberic is allowed' })
  amount: number | 0;
  @prop()
  @maxLength({ value: 255 })
  leadSource: string;
  @prop()
  @maxLength({ value: 36 })
  referenceId: string;
  @prop()
  @maxLength({ value: 255 })
  referenceType: string;
  @prop()
  @date({ message: 'Please enter valid format: MM/DD/YYYY.Ex: 20/11/2022' })
  closeDate: Date | string | null;
  @prop()
  @maxLength({ value: 255 })
  company: string;
  dateCreated: Date | string | null;
  dateModified: Date | string | null;
  opportunityProfile: ProfileDetailModel | null;
  // fetching data:
  lostReason: string | null;
}
export class SaleOpportunityViewModel extends SaleOpportunity {
  @prop()
  saleAccountId: string;
}
export class SaleOpportunityProfile extends ProfileDetailModel {
  saleOpportunityId: number;
  saleOpportunity: SaleOpportunityViewModel;
}
export class ProposalNonArtistFee {
  proposalNonartistFeeId: number;
  proposalId: string;
  class: string;
  typeName: string;
  amount: number;
  description: string;
  deleted: boolean;
  dateCreated: Date | string | null;
  dateModified: Date | string | null;
  dateDeleted: Date | string | null;
  saleOpportunityProfile: ProfileDetailModel | null;

}
export class ProposalNonArtistFeeInputModel {
  proposalNonartistFeeId: number;
  proposalId: string;
  class: string;
  @required()
  @prop()
  typeName: string;
  @required()
  @prop()
  @numeric({ acceptValue: NumericValueType.Both, allowDecimal: true, message: 'Only numeric is allowed' })
  amount: number;
  @prop()
  description: string;
  deleted: boolean;
  dateCreated: Date | string | null;
  dateModified: Date | string | null;
  dateDeleted: Date | string | null;
  saleOpportunityProfile: ProfileDetailModel | null;
}
export class ProposalArtists {
  @prop()
  id: number = 0;
  @prop()
  @required()
  profileId: string;
  @prop()
  @numeric({ acceptValue: NumericValueType.Both, allowDecimal: true, message: 'Only numeric is allowed' })
  artistFee: string;
  @prop()
  @required()
  automateDataState: number | null;
  @prop()
  @numeric({ acceptValue: NumericValueType.Both, allowDecimal: true, message: 'Only numeric is allowed' })
  feeToClient: number;
  @prop()
  @numeric({ acceptValue: NumericValueType.Both, allowDecimal: true, message: 'Only numeric is allowed' })
  travel: number;
  @numeric({ acceptValue: NumericValueType.Both, allowDecimal: true, message: 'Only numeric is allowed' })
  @prop()
  accommodation: number;
  @numeric({ acceptValue: NumericValueType.Both, allowDecimal: true, message: 'Only numeric is allowed' })
  @prop()
  shipping: number;
  @numeric({ acceptValue: NumericValueType.Both, allowDecimal: true, message: 'Only numeric is allowed' })
  @prop()
  perDiem: number;
  @numeric({ acceptValue: NumericValueType.Both, allowDecimal: true, message: 'Only numeric is allowed' })
  @prop()
  thirdPartyCommission: number;
  total: number;
  @prop()
  @required()
  proposalId: string;
  @prop()
  feeIncludes: string | null;
  @prop()
  feeExcludes: string | null;
  @prop()
  @numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: false, message: 'Only numeric is allowed' })
  order: number;

  dateModified: string | null;
  dateCreated: string | null;
  deleted: boolean;
  dateDeleted: string | null;
  dataState: AutomateDataState;
  proposal: SaleOpportunityProfile
}

export interface ProposalRevision {
  id: number;
  proposalId: string;
  content: string;
  html: string;
  userId: string;
  isActive: boolean;
  friendlyUrl: string;
  deleted: boolean;
  dateDeleted: string | null;
  dateModified: string | null;
  dateCreated: string | null;
  user: UserModel;
}