import { switchMap, take, takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { Setting } from './../../../../shared/models/setting.model';
import { ConfirmModalComponent } from './../../../../shared/components/confirm-modal/confirm-modal.component';
import { AfterViewInit, Component, Inject, OnInit, Input, TemplateRef, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { NbToastrService, NbDialogRef } from '@nebular/theme';
import { ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NbAuthService, NbAuthJWTToken } from '@nebular/auth';
import { RxFormBuilder, model } from '@rxweb/reactive-form-validators';
import { HeaderComponent } from 'src/app/@theme/components';
import { HeadshotComponent } from 'src/app/shared/components/headshot/headshot.component';
import { TblActionType } from 'src/app/shared/enums/tbl-action-type.enum';
import { ActivatedRoute } from '@angular/router';
import { F } from '@angular/cdk/keycodes';
import { QuillConfiguration } from 'src/app/shared/components/stand-alone-component/rich-inline-edit/rich-inline-edit.component';
import { Helper } from 'src/app/shared/utility/Helper'
import { Clipboard } from '@angular/cdk/clipboard';
import { UserModel } from '../../user-management/user-model';
import { UserRoleModel } from '../../user-management/user-role-model';
import { UserService } from '../../user-management/user.service';
import { AddEditUserComponent } from '../../user-management/add-edit-user/add-edit-user.component';
import { Moment } from 'moment';
import { MyErrorStateMatcher } from '../../task-management/add-edit-task/add-edit-task.component';
import { LeaveRequestModel } from '../leave-request-management.model';
import { Datastate } from '../../datastate-management/datastate-management.model';
import { DatastateManagementService } from '../../datastate-management/datastate-management.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DISPLAY_INPUT_DATE } from 'src/app/shared/components/stand-alone-component/primas-custom-date-time-picker/date-format.model';
import { LeaveRequestManagementService } from '../leave-request-management.service';
import { LeaveEntitlementModel } from '../../leave-entitlement-managament/leave-entitlement-management.model';
import { LeaveEntitlementManagamentService } from '../../leave-entitlement-managament/leave-entitlement-management.service';
import { SettingService } from 'src/app/shared/services/setting.service';
import { DatePipe, Time } from '@angular/common';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NbAccessChecker } from '@nebular/security';

@Component({
  selector: 'app-add-edit-leave-request',
  templateUrl: './add-edit-leave-request.component.html',
  styleUrls: ['./add-edit-leave-request.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE], },
    { provide: MAT_DATE_FORMATS, useValue: DISPLAY_INPUT_DATE }]
})
export class AddEditLeaveRequestComponent implements OnInit, OnDestroy, AfterViewInit {

  //2022-03-17 thoainda start add
  private destroyed$ = new Subject();
  private routeSub: Subscription;
  id: string;
  isMyLeaveRequest: boolean = false;
  action: TblActionType;
  leaveRequestModel: LeaveRequestModel;
  frmLeaveRequest: FormGroup;
  isLoading = false;
  listRoles: UserRoleModel[] = [];
  editorOptions = QuillConfiguration;
  matcher = new MyErrorStateMatcher();
  listSessions: string[] = [];
  userChosen: UserModel;
  listEmployees: UserModel[];
  isAssignee: boolean = true;
  listStatus: Datastate[] = [];
  statusDefault: number;
  sessionDefault = 'Entire day';
  isChange: boolean = false;
  timeStartDefault: Date = new Date();
  timeEndDefault: Date = new Date();
  employeeListLeaveEntitlement: LeaveEntitlementModel[] = [];
  leaveEntitlementChoosen: LeaveEntitlementModel;
  checkMyLeaveRequest: boolean = true;
  canEdit: boolean = false;
  user;
  dataEntryRole: Setting;
  waitingID: number = 0;
  approvalID: number = 0;
  rejectID: number = 0;
  cancelledID: number = 0;
  hasEditPermission: boolean = false;
  hasSuperAdminPermission: boolean = false;
  leaveEntitlementDefault: number;
  annualTypeId: number = 0;
  transferTypeId: number = 0;
  seniorityTypeId: number = 0;
  bonusTypeId: number = 0;
  minTime: Time = {
    hours: 9,
    minutes: 0
  };
  maxTime: Time = {
    hours: 18,
    minutes: 30
  };

  constructor(
    public dialModalRef: MatDialogRef<AddEditUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    private frmBuilder: RxFormBuilder,
    private authService: NbAuthService,
    private toast: NbToastrService,
    private cdref: ChangeDetectorRef,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private clipboard: Clipboard,
    public automatService: DatastateManagementService,
    private leaveRequestService: LeaveRequestManagementService,
    private leaveEntitlementService: LeaveEntitlementManagamentService,
    private settingService: SettingService,
    private datePipe: DatePipe,
    private permissionService: NbAccessChecker,
  ) {
    if (data.model) {
      this.id = data.model.id;
    }
    this.id = data.id ?? this.id;
    this.action = data.action;
    this.isMyLeaveRequest = data.isMyLeaveRequest ?? data.model?.isMyLeaveRequest ?? this.isMyLeaveRequest;
    this.leaveRequestModel = data.model ?? new LeaveRequestModel();
    this.userChosen = data.model?.user ?? new UserModel();
    if (this.id) {
      this.leaveRequestModel.profileId = this.id;
    }
    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.user = token.getPayload();
        }
    });
    this.permissionService.isGranted('view', 'accept-leave-request').subscribe(e => this.hasEditPermission = e);
    this.permissionService.isGranted('view', 'super-admin-permission').subscribe(e => this.hasSuperAdminPermission = e);
    this.userService.allUserList().subscribe(res => { 
      this.listEmployees = res;
      this.listEmployees.map((user) => {
        let fullName = `${user.firstName} ${user.lastName}`;
        user.fullName = fullName.trim() ? fullName : "Unknown";
      });
    });
    this.automatService.getAutomateDataState("LEAVE_REQUEST").pipe(takeUntil(this.destroyed$)).subscribe(resp => {
      if (resp.result) {
        this.listStatus = resp.result;
      }
    });
    this.listSessions.push("Entire day");
    this.listSessions.push("Morning");
    this.listSessions.push("Afternoon");
  }
  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
  async ngOnInit() {
    this.frmLeaveRequest = this.frmBuilder.formGroup(LeaveRequestModel, this.leaveRequestModel);
    this.dialModalRef.updatePosition({ right: '0', });
    this.timeStartDefault.setHours(9, 0, 0);
    this.timeEndDefault.setHours(18, 30, 0);
    await this.getListSettingLeaveRequest();
    await this.getListSettingLeaveType();
    this.frmLeaveRequest.get('session').valueChanges.subscribe((valueChanges) => {
      if (valueChanges === "Entire day") {
        this.timeStartDefault.setHours(9, 0, 0);
        this.timeEndDefault.setHours(18, 30, 0);
      }
      if (valueChanges === "Morning") {
        this.timeStartDefault.setHours(9, 0, 0);
        this.timeEndDefault.setHours(14, 30, 0);
      }
      if (valueChanges === "Afternoon") {
        this.timeStartDefault.setHours(14, 30, 0);
        this.timeEndDefault.setHours(18, 30, 0);
      }
    });
    if (this.leaveRequestModel?.profileId != null) {
      this.userService.getUserById(this.leaveRequestModel.profileId).pipe(takeUntil(this.destroyed$)).subscribe(res => {
        if (res.result) {
          this.getAssignee(res.result);
        }
      });
    }
    if (this.action === TblActionType.Add) {
      if (!this.isMyLeaveRequest) {
        this.userService.getUserCurrentLogin().pipe(takeUntil(this.destroyed$)).subscribe(res => {
          if (res.result) {
            this.getAssignee(res.result);
          }
        });
      }
    }
    if (this.action === TblActionType.Edit) {
      if (this.leaveRequestModel?.statusID !== this.waitingID && !this.hasSuperAdminPermission) {
        this.canEdit = true;
      }
      if (this.leaveRequestModel?.session != null) {
        this.sessionDefault = this.leaveRequestModel?.session;
      }
      if (this.leaveRequestModel?.statusID != null) {
        this.statusDefault = this.leaveRequestModel?.statusID;
      }
      if (this.leaveRequestModel?.profileId !== this.user?.nameid && 
          this.leaveRequestModel?.ownerId !== this.user?.nameid && 
          !this.hasEditPermission) {
        this.checkMyLeaveRequest = false;
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.frmLeaveRequest)
      this.frmLeaveRequest.valueChanges.pipe(take(1))
        .subscribe(resp => this.isChange = true);
  }

  closeDialog() {
    if (this.isChange) {
      this.isChange = false;
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        data: {
          message: "Do you wish to close this popup? You will lose your unsaved data."
        }
      });

      dialogRef.afterClosed().subscribe(response => {
        if (response) this.dialModalRef.close();
        else this.isChange = true;
      });
      return;
    }

    this.dialModalRef.close();
  }


  saveData() {
    if (this.frmLeaveRequest.valid) {
      this.isLoading = !this.isLoading;
      const model: LeaveRequestModel = Object.assign({}, this.frmLeaveRequest.value);
      model.profileId = this.userChosen?.id;
      model.leaveRequestId = model.leaveRequestId ? model.leaveRequestId : 0;
      this.leaveRequestService.saveLeaveRequest(model).pipe(takeUntil(this.destroyed$)).subscribe(resp => {
        if (resp.result) {
          this.toast.success(`Save leave request successfully`, 'Success');
          this.dialModalRef.close(resp.result);
        }
      }).add(() => {
        this.isLoading = !this.isLoading;
      });
    }
  }

  dateChanged(data: { value: Moment, isInitial: boolean }, prop: string) {
    if (data.isInitial) {
      this.frmLeaveRequest.controls[prop].setValue(data.value);
    }
  }

  clearAssignee() {
    this.userChosen = null;
    this.isAssignee = false;
  }

  getAssignee(data: UserModel) {
    if (data && data !== this.userChosen) {
      this.userChosen = data;
      this.leaveEntitlementService.getLeaveEntitlementById(this.userChosen?.id).pipe(takeUntil(this.destroyed$)).subscribe(resp => {
        if (resp.result) {
          this.employeeListLeaveEntitlement = resp.result;
          this.employeeListLeaveEntitlement = this.employeeListLeaveEntitlement.sort((a, b) => Number(b?.leaveType?.isPaidSalary) - Number(a?.leaveType?.isPaidSalary));
          if (!this.hasEditPermission) {
            if (this.leaveRequestModel?.leaveEntitlement?.leaveTypeId === this.annualTypeId ||
              this.leaveRequestModel?.leaveEntitlement?.leaveTypeId === this.transferTypeId ||
              this.leaveRequestModel?.leaveEntitlement?.leaveTypeId === this.seniorityTypeId) {
              this.employeeListLeaveEntitlement = this.employeeListLeaveEntitlement.filter(item => item?.leaveType?.isPaidSalary && item.leaveTypeId !== this.bonusTypeId);
            }
          }
        }
      }).add(() => {
        if (this.action === TblActionType.Add) {
          this.leaveEntitlementChoosen = this.employeeListLeaveEntitlement[0];
        }
        if (this.action === TblActionType.Edit) {
          this.leaveEntitlementChoosen = this.employeeListLeaveEntitlement.find(x => x.leaveEntitlementId === this.leaveRequestModel?.leaveType);
        }
      });
      this.isAssignee = true;
    }
  }

  selectionLeaveEntitlement(e) {
    if (e && this.employeeListLeaveEntitlement) {
      this.leaveEntitlementChoosen = this.employeeListLeaveEntitlement.find(x => x.leaveEntitlementId === e.value);
    }
  }

  handleDisplayStatus(state: number, isDisplayColor: boolean = false): string {
    if (this.listStatus?.length <= 0) {
      return isDisplayColor ? '#0000' : '';
    }

    if (isDisplayColor) {
      var findColor = this.listStatus.find(x => x.automateDataStateId === state);
      if (findColor) return findColor.colorCode;
      else return '#0000';
    }
    else {
      var findName = this.listStatus.find(x => x.automateDataStateId === state);
      if (findName) return findName.dataStateName;
      else return '';
    }
  }

  async getListSettingLeaveRequest() {
    var resp = await this.settingService.getSettingByGroupName('LEAVE_REQUEST').pipe(takeUntil(this.destroyed$)).toPromise();
    if (resp.result) {
      this.waitingID = Number(resp.result.find(item => item.key === 'PENDING_ID')?.value);
      this.approvalID = Number(resp.result.find(item => item.key === 'APPROVAL_ID')?.value);
      this.rejectID = Number(resp.result.find(item => item.key === 'REJECT_ID')?.value);
      this.cancelledID = Number(resp.result.find(item => item.key === 'CANCELED_ID')?.value);
    }
  }

  async getListSettingLeaveType() {
    var resp = await this.settingService.getSettingByGroupName('LEAVE_TYPE').pipe(takeUntil(this.destroyed$)).toPromise();
    if (resp.result) {
      this.annualTypeId = Number(resp.result.find(item => item.key === 'ANNUALLY_LEAVE_TYPE_ID')?.value);
      this.transferTypeId = Number(resp.result.find(item => item.key === 'TRANSFER_LEAVE_TYPE_ID')?.value);
      this.seniorityTypeId = Number(resp.result.find(item => item.key === 'SENIORITY_LEAVE_TYPE_ID')?.value);
      this.bonusTypeId = Number(resp.result.find(item => item.key === 'BONUS_LEAVE_TYPE_ID')?.value);
    }
  }

  saveStatusInline(statusChange, row) {
    if (statusChange && row) {
      row.statusID = statusChange;
      this.leaveRequestService.saveLeaveRequest(row).pipe(takeUntil(this.destroyed$)).subscribe(resp => {
        if (resp.result) {
          this.toast.success(`Change status successfully`, 'Success');
          this.dialModalRef.close(resp.result);
        }
      });
    }
  }
  autoCalculateWorkingHour(startDate: Date | string, endDate: Date | string) {
    let numberOfHour: number  = 0;
    startDate = new Date(startDate);
    endDate = new Date(endDate);
    if(startDate && endDate) {
      const diffHour = Math.abs(startDate.getTime() - endDate.getTime()) / 36e5;
      if(this.datePipe.transform(startDate, 'longDate') ==  this.datePipe.transform(endDate, 'longDate')) //In a day case
      {
        if(diffHour >= 5.5) {
            numberOfHour = diffHour - 1.5;
            if(numberOfHour > 8) numberOfHour = 8;
        }
        else {
          numberOfHour = 3;
        }
      }
      else {
          const dayOfWorking = (diffHour / 24) | 0; //get mainly working time
          if(dayOfWorking < 1) numberOfHour = 8;
          else {
            numberOfHour = dayOfWorking * 8;
            let workHour = diffHour - dayOfWorking * 24;
            if(workHour > 9) {
              workHour -= 9;
              if(workHour >= 5.5) {
                workHour = workHour - 1.5;
                if(workHour > 8) workHour = 8;
              }
              else {
                workHour = 3;
              }
              numberOfHour += workHour;
            }
          }
      }
    }
    return numberOfHour;
  }
}
