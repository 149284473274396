import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, startWith, takeUntil } from 'rxjs/operators';
import { ReturnResult } from '../../models/return-result';
import { Helper } from '../../utility/Helper';


@Component({
  selector: 'app-dynamic-content-form',
  templateUrl: './dynamic-content-form.component.html',
  styleUrls: ['./dynamic-content-form.component.scss']
})
export class DynamicContentFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() label: string;
  @Input() control: FormControl;
  @Input() displayKey: string;
  @Input() searchArrayAPI: Observable<ReturnResult<any>>;
  @Input() options = [];
  @Input() isAscii: boolean = false;

  filteredOptions: Observable<string[]>;
  loading = false;
  private _destroy: Subject<void> = new Subject<void>();

  constructor() { }

  ngOnInit(): void {
    this.setupData();
  }
  ngOnChanges(changes: SimpleChanges): void {
    const optionChange = changes.options;
    if (optionChange && optionChange.currentValue && optionChange.currentValue.length) {
      this.pushDataToStream();
    }
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  private _filter(name: string) {
    const filterValue = name.toLowerCase();
    return this.options.filter(option => option[this.displayKey].toLowerCase().includes(filterValue));
  }
  async setupData() {
    try {
      if (this.searchArrayAPI) {
        this.loading = true;
        const data = await this.searchArrayAPI.toPromise();
        if (data.result) this.options = data.result;
        this.loading = false;
      }
      this.pushDataToStream();
    }
    catch (e) {
      console.log(e);
      this.loading = false;
    }
  }
  pushDataToStream() {
    this.filteredOptions = this.control.valueChanges.pipe(
      takeUntil(this._destroy),
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value[this.displayKey];
        return name ? this._filter(name as string) : this.options.slice();
      }),
    );

    if (this.isAscii)
      this.control.valueChanges.pipe(
        takeUntil(this._destroy),
        debounceTime(1000),
      ).subscribe(() => this.control.setValue(Helper.removeNonAscii(this.control.value)));
  }

  displayFn(data) {
    if (data) {
      return data[this.displayKey] ? data[this.displayKey] : data;
    }
  }
}
