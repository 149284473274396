<h2 mat-dialog-title style="display: flex; align-items: center;">
  {{titleRequestAttendance}}
</h2>
<!-- <mat-divider></mat-divider> -->
<mat-dialog-content class="mat-typography">
  <form [formGroup]="frmAttendanceRequest" class="container"
    style="padding-top: 25px; padding-right: 50px; padding-left: 50px">
    <div class="row">
      <!--Request Change Attendance-->
      <ng-container *ngIf="attendanceModel?.attendanceId && attendanceModel?.punchinDate && attendanceModel?.punchoutDate">
        <!--Request Punchin-->
        <ng-container *ngIf="isRequestPunchin">
          <div class="col-12" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="12px">
            <mat-form-field>
              <mat-label>Punched In Time</mat-label>
              <input #dueDateInput matInput [matDatepicker]="punchInPicker.datePicker"
                  (dateChange)="punchInPicker.dateChangeSubject.next($event)" readonly [value]="attendanceModel?.punchinDate">
              <!-- primas -->
              <mat-datepicker-toggle matSuffix [for]="punchInPicker.datePicker" disabled></mat-datepicker-toggle>
              <app-primas-custom-date-time-picker #punchInPicker="PrimasCustomDateTimePicker"></app-primas-custom-date-time-picker>
            </mat-form-field>
            <mat-form-field [fxFlex]="20">
              <mat-label>Time Zone</mat-label>
              <input matInput autocomplete="off" [value]="timeZone" readonly>
            </mat-form-field>
          </div>
          <div class="col-12" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="12px">
            <mat-form-field>
              <mat-label>Select Punchin To Change</mat-label>
              <input required #dueDateInput matInput [matDatepicker]="timePicker.datePicker"
                  formControlName="requestDate" readonly
                  (dateChange)="timePicker.dateChangeSubject.next($event)" (click)="timePicker.datePicker.open()">
              <!-- primas -->
              <mat-datepicker-toggle matSuffix [for]="timePicker.datePicker"></mat-datepicker-toggle>
              <app-primas-custom-date-time-picker #timePicker="PrimasCustomDateTimePicker"
                  [minuteStep]="1" [twelveHoursFormat]="false"
                  (dateChange)="dateChanged($event,'requestDate')"
                  (editManualSave)="dateChanged($event,'requestDate')"></app-primas-custom-date-time-picker>
            </mat-form-field>
            <mat-form-field [fxFlex]="20" [ngClass]="{disabledNoOfCasesDiv: true}">
              <mat-label>Time Zone</mat-label>
              <input matInput autocomplete="off" [value]="timeZone" readonly>
            </mat-form-field>
          </div>
          <div class="col-12" style="height: fit-content">
            <mat-form-field appearance="standard">
              <mat-label style="color: #666666">Reason</mat-label>
              <textarea matInput placeholder="Write reason here..." formControlName="requestNote" trim="blur" required></textarea>
              <mat-error *ngIf="
              frmAttendanceRequest?.controls?.requestNote?.errors &&
              (frmAttendanceRequest?.controls?.requestNote?.dirty ||
                frmAttendanceRequest?.controls?.requestNote?.touched)">
                You must add a request's reason
              </mat-error>
            </mat-form-field>
          </div>
        </ng-container>
        <!--Request Punchout-->
        <ng-container *ngIf="!isRequestPunchin">
          <div class="col-12" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="12px">
            <mat-form-field>
              <mat-label>Punched Out Time</mat-label>
              <input #dueDateInput matInput [matDatepicker]="punchInPicker.datePicker"
                  (dateChange)="punchInPicker.dateChangeSubject.next($event)" readonly [value]="attendanceModel?.punchoutDate">
              <!-- primas -->
              <mat-datepicker-toggle matSuffix [for]="punchInPicker.datePicker" disabled></mat-datepicker-toggle>
              <app-primas-custom-date-time-picker #punchInPicker="PrimasCustomDateTimePicker"></app-primas-custom-date-time-picker>
            </mat-form-field>
            <mat-form-field [fxFlex]="20">
              <mat-label>Time Zone</mat-label>
              <input matInput autocomplete="off" [value]="timeZone" readonly>
            </mat-form-field>
          </div>
          <div class="col-12" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="12px">
            <mat-form-field>
              <mat-label>Select Punchout Time To Change</mat-label>
              <input required #dueDateInput matInput [matDatepicker]="timePicker.datePicker"
                  formControlName="requestDate" readonly
                  (dateChange)="timePicker.dateChangeSubject.next($event)" (click)="timePicker.datePicker.open()">
              <!-- primas -->
              <mat-datepicker-toggle matSuffix [for]="timePicker.datePicker"></mat-datepicker-toggle>
              <app-primas-custom-date-time-picker #timePicker="PrimasCustomDateTimePicker"
                  [minuteStep]="1" [twelveHoursFormat]="false"
                  (dateChange)="dateChanged($event,'requestDate')"
                  (editManualSave)="dateChanged($event,'requestDate')"></app-primas-custom-date-time-picker>
            </mat-form-field>
            <mat-form-field [fxFlex]="20" [ngClass]="{disabledNoOfCasesDiv: true}">
              <mat-label>Time Zone</mat-label>
              <input matInput autocomplete="off" [value]="timeZone" readonly>
            </mat-form-field>
          </div>
          <div class="col-12" style="height: fit-content">
            <mat-form-field appearance="standard">
              <mat-label style="color: #666666">Reason</mat-label>
              <textarea matInput placeholder="Write reason here..." formControlName="requestNote" trim="blur" required></textarea>
              <mat-error *ngIf="
              frmAttendanceRequest?.controls?.requestNote?.errors &&
              (frmAttendanceRequest?.controls?.requestNote?.dirty ||
                frmAttendanceRequest?.controls?.requestNote?.touched)">
                You must add a request's reason
              </mat-error>
            </mat-form-field>
          </div>
        </ng-container>
      </ng-container>
      <!--Request Add New Attendance-->
      <ng-container *ngIf="!attendanceModel?.attendanceId || !attendanceModel?.punchinDate || !attendanceModel?.punchoutDate">
        <div class="col-12" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="12px">
          <mat-form-field>
            <mat-label *ngIf="isRequestPunchin">Punchin Time</mat-label>
            <mat-label *ngIf="!isRequestPunchin">Punchout Time</mat-label>
            <input required #dueDateInput matInput [matDatepicker]="timePicker.datePicker"
                formControlName="requestDate" readonly
                (dateChange)="timePicker.dateChangeSubject.next($event)">
            <!-- primas -->
            <mat-datepicker-toggle matSuffix [for]="timePicker.datePicker"></mat-datepicker-toggle>
            <app-primas-custom-date-time-picker #timePicker="PrimasCustomDateTimePicker"
                [minuteStep]="1" [twelveHoursFormat]="false"
                (dateChange)="dateChanged($event,'requestDate')"
                (editManualSave)="dateChanged($event,'requestDate')"></app-primas-custom-date-time-picker>
          </mat-form-field>
          <mat-form-field [fxFlex]="20" [ngClass]="{disabledNoOfCasesDiv: true}">
            <mat-label>Time Zone</mat-label>
            <input matInput autocomplete="off" [value]="timeZone" readonly>
          </mat-form-field>
        </div>
        <div class="col-12" style="height: fit-content">
          <mat-form-field appearance="standard">
            <mat-label style="color: #666666">Reason</mat-label>
            <textarea matInput placeholder="Write reason here..." formControlName="requestNote" trim="blur" required></textarea>
              <mat-error *ngIf="
              frmAttendanceRequest?.controls?.requestNote?.errors &&
              (frmAttendanceRequest?.controls?.requestNote?.dirty ||
                frmAttendanceRequest?.controls?.requestNote?.touched)">
                You must add a request's reason
              </mat-error>
          </mat-form-field>
        </div>
      </ng-container>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button color="warn" [disabled]="loading" [mat-dialog-close]="false" cdkFocusInitial>Cancel</button>
  <button mat-button color="primary" [nbSpinner]="loading" [disabled]="!frmAttendanceRequest?.valid || loading" (click)="saveRequestAttendance()">Request</button>
</mat-dialog-actions>