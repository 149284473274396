<ng-container *ngIf="isDropDown; else defaultEmployee">
  <mat-form-field [nbSpinner]="employeeLoading">
    <mat-select [formControl]="bankCtrl" matTooltip="Enter 3 words to search..." [ngStyle]="styleMatSelect"
      [placeholder]="label" #singleSelect required (openedChange)="selectionChange($event)" [disabled]="readonly">
      <mat-select-trigger>
        <div *ngIf="bankCtrl?.value">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
            <div>
              <!-- <img [src]="(bankCtrl?.value?.pictureURL || 'assets/images/5.jpg') | safeIframe" width="40px"
                height="40px" style="border-radius: 50%;"> -->
              <nb-user id="nb-user-headshot" fxFlex="30px" size="small" 
                      [onlyPicture]="true" [picture]="bankCtrl?.value?.pictureURL" [name]="bankCtrl?.value?.fullName">
              </nb-user>
            </div>
            <div style="font-size:14px">
              <div fxLayout="row" fxLayoutAlign="start start">
                <strong
                  [innerHTML]="(bankCtrl?.value?.fullName + ' (' + bankCtrl?.value?.userName + ')'  || 'Unknown')">
                </strong>
              </div>
              <!-- <div fxLayout="row" fxLayoutAlign="space-between center">
                <div>
                  <span><strong>Email: </strong>
                    <span [innerHTML]="(bankCtrl?.value?.email  ||'No email display')"></span>
                    <br> </span>
                  <span><strong>Phone: </strong>
                    <span
                      [innerHTML]="((bankCtrl?.value?.phoneNumber | phoneFormat)  ||'No phone display')"></span>
                    <br> </span>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </mat-select-trigger>
      <mat-option>
        <ngx-mat-select-search [formControl]="bankFilterCtrl" [placeholderLabel]="placeholderLabel"
          noEntriesFoundLabel="'No matching item found'"></ngx-mat-select-search>
      </mat-option>
  
      <mat-option *ngIf="banks && banks.length > 0" style="height: 35px !important;" [disabled]="true">About
        {{totalResults | numberFormat}} result(s) match</mat-option>
  
      <mat-option class="col altus-search-option" *ngFor="let item of filteredBanks | async" [value]="item">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
          <div>
            <!-- <img [src]="(item?.pictureURL || 'assets/images/5.jpg') | safeIframe" width="40px"
              height="40px" style="border-radius: 50%;"> -->
              <nb-user id="nb-user-headshot" fxFlex="30px" size="small" 
                      [onlyPicture]="true" [picture]="item?.pictureURL" [name]="item?.fullName">
              </nb-user>
          </div>
          <div style="font-size:14px">
            <div fxLayout="row" fxLayoutAlign="start start">
              <strong
                [innerHTML]="(item?.fullName + ' (' + item?.userName + ')'  || 'Unknown') | highlightTextSearching: bankFilterCtrl?.value">
              </strong>
            </div>
            <!-- <div fxLayout="row" fxLayoutAlign="space-between center">
              <div>
                <span><strong>Email: </strong>
                  <span [innerHTML]="(item?.email  ||'No email display') | highlightTextSearching: bankFilterCtrl?.value"></span>
                  <br> </span>
                <span><strong>Phone: </strong>
                  <span
                    [innerHTML]="((item?.phoneNumber | phoneFormat)  ||'No phone display') | highlightTextSearching: (bankFilterCtrl?.value | phoneFormat)"></span>
                  <br> </span>
              </div>
            </div> -->
          </div>
        </div>
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-container>

<ng-template #defaultEmployee>
  <div *ngIf="bankCtrl.value">
    <ng-container [ngTemplateOutlet]="isNbUser ? nbUserTemplate : defaultImg"
      [ngTemplateOutletContext]="{ item: bankCtrl.value }">
    </ng-container>
    <!-- <img class="user-img" [src]="bankCtrl.value.pictureURL ?? 'assets/images/man.png'| safeIframe" alt="user-avatar"
      style="margin-right: 5px;" (error)="bankCtrl.value.pictureURL = 'assets/images/man.png'"> -->
    <span>
      <strong>{{bankCtrl?.value?.fullName}}</strong>
      <!-- <strong>Phone: </strong>{{agent.phoneNumber}} -->
    </span>
  </div>
</ng-template>

<ng-template #defaultImg let-item="item">
  <img class="user-img" [src]="item?.pictureURL ?? 'assets/images/man.png'| safeIframe" alt="user-avatar"
    style="margin-right: 5px;" (error)="item.pictureURL = 'assets/images/man.png'">
</ng-template>

<ng-template #nbUserTemplate let-item="item">
  <nb-user class="custom-avatar-outline" fxFlex="30px" size="small" [onlyPicture]="true" [picture]="item?.pictureURL"
    [name]="item?.fullName">
  </nb-user>
</ng-template>