<div class="d-flex flex-wrap" mat-dialog-title style="margin: unset; padding: 1px 10px 1px 28px"
  [nbSpinner]="isLoading">
  <button mat-button color="primary" (click)="saveData()" [disabled]="!frmLeaveRequest.valid || canEdit || !checkMyLeaveRequest">
    <mat-icon fontSet=" material-icons-outlined">save</mat-icon>Save
  </button>
  <div fxLayout="row" fxLayoutAlign="end center" class="ml-auto">
    <ng-container *ngIf="hasSuperAdminPermission || (hasEditPermission && leaveRequestModel?.profile?.ownerId === user?.nameid)">
      <ng-container *ngIf="leaveRequestModel?.statusID === waitingID">
        <button mat-stroked-button (click)="saveStatusInline(approvalID, leaveRequestModel)">
          <mat-icon [style.color]="handleDisplayStatus(approvalID, true)">done</mat-icon>
          Approve
        </button>
        <button mat-stroked-button (click)="saveStatusInline(rejectID, leaveRequestModel)" style="margin: 0 10px;">
          <mat-icon [style.color]="handleDisplayStatus(rejectID, true)">block</mat-icon>
          Reject
        </button>
      </ng-container>
      <ng-container *ngIf="leaveRequestModel?.statusID === approvalID">
        <button mat-stroked-button (click)="saveStatusInline(rejectID, leaveRequestModel)" style="margin-right: 20px;">
          <mat-icon [style.color]="handleDisplayStatus(rejectID, true)">block</mat-icon>
          Reject
        </button>
      </ng-container>
      <ng-container *ngIf="leaveRequestModel?.statusID === rejectID">
        <button mat-stroked-button (click)="saveStatusInline(approvalID, leaveRequestModel)" style="margin-right: 20px;">
          <mat-icon [style.color]="handleDisplayStatus(approvalID, true)">done</mat-icon>
          Approve
        </button>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="leaveRequestModel?.profileId === user?.nameid">
      <ng-container *ngIf="leaveRequestModel?.statusID === waitingID">
        <button mat-stroked-button (click)="saveStatusInline(cancelledID, leaveRequestModel)" style="margin-right: 20px;">
          <mat-icon [style.color]="handleDisplayStatus(cancelledID, true)">cancel</mat-icon>
          Cancel
        </button>
      </ng-container>
    </ng-container>
    <button mat-icon-button aria-label="Close the dialog" (click)="closeDialog()" class="ml-auto">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<mat-divider></mat-divider>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="frmLeaveRequest" class="container"
    style="padding-top: 25px; padding-right: 50px; padding-left: 50px">
    <div class="row" [ngClass]="{disabledNoOfCasesDiv: canEdit || !checkMyLeaveRequest}">
      <div class="col-12">
        <div class="alert alert-warning" role="alert" *ngIf="canEdit && checkMyLeaveRequest">
          This Leave Request is starting. You can't edit this Leave Request
        </div>
        <div class="alert alert-warning" role="alert" *ngIf="!canEdit && !checkMyLeaveRequest">
          This Leave Request belongs to {{leaveRequestModel?.profile?.displayName}}, you cannot edit information.
        </div>
        <div class="alert alert-warning" role="alert" *ngIf="canEdit && !checkMyLeaveRequest">
          This Leave Request belongs to {{leaveRequestModel?.profile?.displayName}}, you cannot edit information.
        </div>
      </div>
      <div class="col-12" *ngIf="!isMyLeaveRequest" [ngClass]="{disabledNoOfCasesDiv: action != 0}">
        <app-employee-searching required [banks]="listEmployees" [currentUser]="userChosen"
            (userSelect)="getAssignee($event)">
        </app-employee-searching>
      </div>
      <div class="col-12">
        <mat-form-field appearance="standard">
          <mat-label>Reason</mat-label>
          <input matInput formControlName="title" autocomplete="off" required
            trim="blur" [readonly]="leaveRequestModel?.extendData && leaveRequestModel?.ownerId !== user?.nameid" />
          <mat-error *ngIf="
              frmLeaveRequest?.controls?.title?.errors &&
              (frmLeaveRequest?.controls?.title?.dirty ||
                frmLeaveRequest?.controls?.title?.touched)
            ">
            {{ frmLeaveRequest?.controls?.title["errorMessage"] }}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field appearance="standard">
            <mat-label>Session</mat-label>
            <mat-select formControlName="session" [errorStateMatcher]="matcher" [(ngModel)]="sessionDefault" [disabled]="leaveRequestModel?.extendData && leaveRequestModel?.ownerId !== user?.nameid">
                <mat-option *ngFor="let session of listSessions" [value]="session">
                    {{session}}
                </mat-option>
            </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12" [ngSwitch]="frmLeaveRequest?.value?.session">
        <mat-form-field *ngSwitchDefault>
            <mat-label>Start Date</mat-label>
            <input required #dueDateInput matInput [matDatepicker]="startPicker.datePicker"
                formControlName="leaveDateFrom" readonly
                (dateChange)="startPicker.dateChangeSubject.next($event)" (click)="leaveRequestModel?.extendData?.includes('byDate') && leaveRequestModel?.ownerId !== user?.nameid ? '' : startPicker.datePicker.open()">
            <!-- primas -->
            <mat-datepicker-toggle matSuffix [for]="startPicker.datePicker" [disabled]="leaveRequestModel?.extendData?.includes('byDate') && leaveRequestModel?.ownerId !== user?.nameid"></mat-datepicker-toggle>
            <app-primas-custom-date-time-picker #startPicker="PrimasCustomDateTimePicker"
                [time]="timeStartDefault" [setupTime]="true" [minuteStep]="1" [twelveHoursFormat]="false"
                [minTime]="minTime" [maxTime]="maxTime"
                (dateChange)="dateChanged($event,'leaveDateFrom')"
                (editManualSave)="dateChanged($event,'leaveDateFrom')"></app-primas-custom-date-time-picker>
        </mat-form-field>
        <mat-form-field *ngSwitchCase="'Afternoon'">
          <mat-label>Start Date</mat-label>
          <input required #dueDateInput matInput [matDatepicker]="startPicker.datePicker"
              formControlName="leaveDateFrom" readonly
              (dateChange)="startPicker.dateChangeSubject.next($event)" (click)="leaveRequestModel?.extendData?.includes('byDate') && leaveRequestModel?.ownerId !== user?.nameid ? '' : startPicker.datePicker.open()">
          <!-- primas -->
          <mat-datepicker-toggle matSuffix [for]="startPicker.datePicker" [disabled]="leaveRequestModel?.extendData?.includes('byDate') && leaveRequestModel?.ownerId !== user?.nameid"></mat-datepicker-toggle>
          <app-primas-custom-date-time-picker #startPicker="PrimasCustomDateTimePicker"
              [time]="timeStartDefault" [setupTime]="true" [minuteStep]="1" [twelveHoursFormat]="false"
              [minTime]="minTime" [maxTime]="maxTime"
              (dateChange)="dateChanged($event,'leaveDateFrom')"
              (editManualSave)="dateChanged($event,'leaveDateFrom')"></app-primas-custom-date-time-picker>
        </mat-form-field>
      </div>
      <div class="col-12" [ngSwitch]="frmLeaveRequest?.value?.session">
        <mat-form-field *ngSwitchDefault>
            <mat-label>End Date</mat-label>
            <input required #dueDateInput matInput [matDatepicker]="endPicker.datePicker"
                formControlName="leaveDateTo" readonly
                (dateChange)="endPicker.dateChangeSubject.next($event)" (click)="leaveRequestModel?.extendData?.includes('byDate') && leaveRequestModel?.ownerId !== user?.nameid ? '' : endPicker.datePicker.open()">
            <!-- primas -->
            <mat-datepicker-toggle matSuffix [for]="endPicker.datePicker" [disabled]="leaveRequestModel?.extendData?.includes('byDate') && leaveRequestModel?.ownerId !== user?.nameid"></mat-datepicker-toggle>
            <app-primas-custom-date-time-picker #endPicker="PrimasCustomDateTimePicker"
                [time]="timeEndDefault" [setupTime]="true" [minuteStep]="1" [twelveHoursFormat]="false"
                [minTime]="minTime" [maxTime]="maxTime"
                (dateChange)="dateChanged($event,'leaveDateTo')"
                (editManualSave)="dateChanged($event,'leaveDateTo')"></app-primas-custom-date-time-picker>
        </mat-form-field>
        <mat-form-field *ngSwitchCase="'Morning'">
          <mat-label>End Date</mat-label>
            <input required #dueDateInput matInput [matDatepicker]="endPicker.datePicker"
                formControlName="leaveDateTo" readonly
                (dateChange)="endPicker.dateChangeSubject.next($event)" (click)="leaveRequestModel?.extendData?.includes('byDate') && leaveRequestModel?.ownerId !== user?.nameid ? '' : endPicker.datePicker.open()">
            <!-- primas -->
            <mat-datepicker-toggle matSuffix [for]="endPicker.datePicker" [disabled]="leaveRequestModel?.extendData?.includes('byDate') && leaveRequestModel?.ownerId !== user?.nameid"></mat-datepicker-toggle>
            <app-primas-custom-date-time-picker #endPicker="PrimasCustomDateTimePicker"
                [time]="timeEndDefault" [setupTime]="true" [minuteStep]="1" [twelveHoursFormat]="false"
                [minTime]="minTime" [maxTime]="maxTime"
                (dateChange)="dateChanged($event,'leaveDateTo')"
                (editManualSave)="dateChanged($event,'leaveDateTo')"></app-primas-custom-date-time-picker>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field appearance="standard" *ngIf="statusDefault" [ngClass]="{disabledNoOfCasesDiv: true}">
            <mat-label>Status</mat-label>
            <mat-select required formControlName="statusID" [errorStateMatcher]="matcher" [(ngModel)]="statusDefault">
                <mat-option *ngFor="let status of listStatus" [value]="status?.automateDataStateId">
                    {{status?.dataStateName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12" *ngIf="action != 0">
        <mat-form-field appearance="standard">
          <mat-label>Hours</mat-label>
          <input required matInput formControlName="numberOfHour" autocomplete="off" [readonly]="!hasEditPermission"/>
          <mat-hint>{{frmLeaveRequest?.value?.numberOfHour | leaveFormat:"hour" }} </mat-hint>
          <mat-error *ngIf="
              frmLeaveRequest?.controls?.numberOfHour?.errors &&
              (frmLeaveRequest?.controls?.numberOfHour?.dirty ||
                frmLeaveRequest?.controls?.numberOfHour?.touched)
            ">
            {{ frmLeaveRequest?.controls?.numberOfHour["errorMessage"] }}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12" *ngIf="action === 1">
        <mat-form-field appearance="standard">
          <mat-label>Submission Date</mat-label>
          <input matInput [readonly]="true" [value]="leaveRequestModel?.dateCreated | date:'M/dd/yyyy, HH:mm:ss'" />
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field appearance="standard">
          <mat-label>Choose Leave Entitlement</mat-label>
          <mat-select formControlName="leaveType" required (selectionChange)="selectionLeaveEntitlement($event)" 
                [(ngModel)]="leaveEntitlementChoosen ? leaveEntitlementChoosen.leaveEntitlementId : leaveEntitlementDefault"
                [disabled]="(leaveRequestModel?.extendData || leaveRequestModel?.leaveEntitlement?.leaveTypeId === bonusTypeId || leaveRequestModel?.leaveEntitlement?.leaveType?.isPaidSalary === false) && !hasEditPermission">
            <mat-select-trigger>
              {{leaveEntitlementChoosen?.leaveType?.leaveTypeName}} ( {{(leaveEntitlementChoosen?.usableLeave - leaveEntitlementChoosen?.usedLeave) | leaveFormat}} )
            </mat-select-trigger>
            <mat-option *ngFor="let leaveEntitlement of employeeListLeaveEntitlement" [value]="leaveEntitlement.leaveEntitlementId">
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div>
                  {{leaveEntitlement?.leaveType?.leaveTypeName}} ( {{(leaveEntitlement?.usableLeave - leaveEntitlement?.usedLeave) | leaveFormat }} )
                </div>
                <div *ngIf="leaveEntitlement?.leaveType?.isPaidSalary">
                  <span class="badge badge-pill badge-primary" style="font-size: 12px"
                    [style.background-color]="'#00a339'">
                    Paid Leave
                  </span>
                </div>
              </div>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="
                frmLeaveRequest?.controls?.leaveType?.errors &&
                (frmLeaveRequest?.controls?.leaveType?.dirty ||
                  frmLeaveRequest?.controls?.leaveType?.touched)">
              {{ frmLeaveRequest?.controls?.leaveType["errorMessage"] }}</mat-error>
        </mat-form-field>
      </div>
      <div>
        <div class="col-12" style="height: fit-content">
          <mat-label style="color: #666666">Note</mat-label>
          <quill-editor [modules]="editorOptions" placeholder="Note" formControlName="note">
          </quill-editor>
        </div>
        <button type="submit" style="display: none"></button>
      </div>
    </div>
  </form>
</mat-dialog-content>