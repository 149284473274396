import { prop, required } from "@rxweb/reactive-form-validators";
import { ProfileModel } from "../profile-management/profile-model";
import { AttendanceDatum } from "../opportunity-management/opportunity-sale-report/opportunity-sale-report.model";
import { Page } from "src/app/shared/models/paging/page";

export class AttendanceModel {
    @prop()
    attendanceId: number;
    @prop()
    profileId: string;
    profile: ProfileModel | null;
    @prop()
    punchinDate: Date | string | null;
    @prop()
    punchinTime: number | null;
    @prop()
    punchinNote: string | null;
    @prop()
    punchinOffset: number | null;
    @prop()
    punchoutDate: Date | string | null;
    @prop()
    punchoutTime: number | null;
    @prop()
    punchoutNote: string | null;
    @prop()
    punchoutOffset: number | null;
    @prop()
    duration: number | null;

    status: number | null;
    isForgetPunchout: boolean | null;
    dateAutoPunchout: Date | string | null;
    extendData: string | null;
    punchinRequestStatus: number | null;
    punchoutRequestStatus: number | null;
}


export class AttendanceReport {
    @prop()
    reportMode: string;
    @prop()
    fromDate: Date | null;
    @prop()
    toDate: Date | null;
    @prop()
    order: number = 0;
    @prop()
    listProfile: string[] = [];
    @prop()
    listStatus: string[] = [];
    @prop()
    listRoles: string[] = [];
    @prop()
    listStatusLeave: string[] = [];
}

export class AttendanceRequestModel {
    @prop()
    requestDate: Date | string | null;
    @prop()
    requestNote: string | null;
}

export class AttendanceRequestPagingModel extends AttendanceModel {
    punchinRequestDate: Date | string | null;
    punchinRequestNote: string | null;
    punchoutRequestDate: Date | string | null;
    punchoutRequestNote: string | null;
}

export class AttendanceReportExportModel {
    data: AttendanceDatum[];
    dataFilter: AttendanceReport;
}

export class PageRequestAttendance extends Page {
    status = "Waiting";
}