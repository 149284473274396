<div *ngIf="!reloadTabName" appChangeUrlObjects [id]="task.taskId" [isOpenDialog]="true" [topic]="'task'" [name]="task?.taskName"></div>
<div class="d-flex flex-wrap" mat-dialog-title style="margin: unset;padding: 1px 10px 1px 28px;">
    <mat-form-field appearance="standard" style="width: 200px; padding-left: 25px;"
        class="task-type no-line no-padding no-padding-flex no-padding-infix without-arrow">
        <mat-select [errorStateMatcher]="matcher" (selectionChange)="changeTaskType($event)"
            [(ngModel)]="task.taskTypeId">
            <mat-select-trigger style="font-size: medium;">
                <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 16 16">
                    <g fill="none" fill-rule="evenodd">
                        <path
                            d="M0 1.777C0 .796.796 0 1.777 0h12.446C15.204 0 16 .796 16 1.777v12.446c0 .981-.796 1.777-1.777 1.777H1.777A1.778 1.778 0 0 1 0 14.223V1.777z"
                            [attr.fill]="taskTypeSelected?.colorCode" />
                        <path
                            d="M4.5 12.5V4.378c0-.485.392-.878.875-.878h5.25c.483 0 .875.393.875.878V12.5L8 8.988 4.5 12.5z"
                            fill="#FFF" />
                    </g>
                </svg>
                {{taskTypeSelected?.taskTypeName}} - {{task.taskId}}
            </mat-select-trigger>
            <mat-option *ngFor="let type of taskType" [value]="type.taskTypeId" style="font-size: medium;">
                <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 16 16">
                    <g fill="none" fill-rule="evenodd">
                        <path
                            d="M0 1.777C0 .796.796 0 1.777 0h12.446C15.204 0 16 .796 16 1.777v12.446c0 .981-.796 1.777-1.777 1.777H1.777A1.778 1.778 0 0 1 0 14.223V1.777z"
                            [attr.fill]="type.colorCode" />
                        <path
                            d="M4.5 12.5V4.378c0-.485.392-.878.875-.878h5.25c.483 0 .875.393.875.878V12.5L8 8.988 4.5 12.5z"
                            fill="#FFF" />
                    </g>
                </svg>
                {{type.taskTypeName}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <div class="ml-auto">
        <button mat-icon-button *ngIf="task?.clickUpExtend && task?.clickUpExtend?.clickUpId" (click)="openClickUp()"
            matTooltip="View ClickUp site">
            <mat-icon svgIcon="click-up"></mat-icon>
        </button>
        <button mat-icon-button matTooltip="Duplicate task" (click)="duplicateTask()" [nbSpinner]="isLoadingDuplicate">
            <mat-icon>content_copy</mat-icon>
        </button>
        <button mat-icon-button [disabled]="!(pagingFilter || filterList) || (indexTask == 0 || indexTask == -1) || isLoadPrevious ? 'true' : 'false'
            " matTooltip="Previous task" (click)="preTaskClick()" [nbSpinner]="isLoadPrevious">
            <mat-icon>arrow_back</mat-icon>
        </button>
        <button mat-icon-button
            [disabled]="!(pagingFilter || filterList) || (indexTask == (tasksLength - 1)) || isLoadNext ? 'true' : 'false'"
            [nbSpinner]="isLoadNext" matTooltip="Next task" (click)="nextTaskClick()">
            <mat-icon>arrow_forward</mat-icon>
        </button>
        <button mat-icon-button class="ml-auto float-start" [matTooltip]="'Copy this task url to clipboard'"
            matTooltipPosition="left" (click)="copyToClipboard()">
            <mat-icon style="transform: scale(0.9);">share</mat-icon>
        </button>
        <button mat-icon-button color="warn" matTooltip="Delete Task" (click)="deleteTask()">
            <mat-icon>delete</mat-icon>
        </button>
        <!-- <button *ngIf="isMaximum" mat-icon-button aria-label="Maximize screen" (click)="maximumScreen()"
            class="ml-auto">
            <mat-icon *ngIf="!isMaximum"> open_in_full</mat-icon>
            <mat-icon *ngIf="isMaximum">close_fullscreen</mat-icon>
        </button> -->
        <button mat-icon-button matTooltip="Close" (click)="closeDialog()">
            <mat-icon style="color: red">close</mat-icon>
        </button>
    </div>
</div>

<mat-divider></mat-divider>

<mat-dialog-content style="max-height: 100vh" class="mat-typography">
    <div *ngIf="!isMaximum; else maximumMode">
        <div style="padding: 25px 50px">
            <div class="row">
                <div class="col-8">
                    <h1>
                        <app-inline-edit [inputData]="task.taskName" (handleSave)="saveTitle($event)"></app-inline-edit>
                    </h1>
                    <div class="row">
                        <div class="col-6">
                            <mat-label><b>DUE DATE</b></mat-label>
                            <mat-form-field>
                                <input matInput [matDatepicker]="startPicker.datePicker" required
                                    [errorStateMatcher]="matcher" [(ngModel)]="task.taskStartDate"
                                    (dateChange)=" !startPicker.cancelUpdate ? processDateChangeInput($event,'taskStartDate',true) : null"
                                    [disabled]="!allowChangeDueDate">
                                <!-- <ngx-mat-datetime-picker #startPicker [showSeconds]="true">
                                    <ng-template (click)="saveData()">
                                        <span>OK</span>
                                    </ng-template>
                                </ngx-mat-datetime-picker> -->
                                <mat-datepicker-toggle matSuffix [for]="startPicker.datePicker"></mat-datepicker-toggle>
                                <app-primas-custom-date-time-picker #startPicker="PrimasCustomDateTimePicker"
                                    (dateChange)="dateChanged($event,'taskStartDate')"></app-primas-custom-date-time-picker>
                            </mat-form-field>
                        </div>
                        <div class="col-6">
                            <mat-label><b>FINISHED DATE</b></mat-label>
                            <mat-form-field>
                                <input matInput [matDatepicker]="endPicker.datePicker" required
                                    [(ngModel)]="task.taskEndDate" [errorStateMatcher]="matcher"
                                    (dateChange)="!endPicker.cancelUpdate? processDateChangeInput($event,'taskEndDate',true): null">
                                <mat-datepicker-toggle matSuffix [for]="endPicker.datePicker"></mat-datepicker-toggle>
                                <!-- <ngx-mat-datetime-picker #endPicker [showSeconds]="true">
                                    <ng-template>
                                        <span>OK</span>
                                    </ng-template>
                                </ngx-mat-datetime-picker> -->
                                <app-primas-custom-date-time-picker #endPicker="PrimasCustomDateTimePicker"
                                    (dateChange)="dateChanged($event,'taskEndDate')"></app-primas-custom-date-time-picker>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row" *ngIf="isMaximum">
                        <div class="col-12">
                            <mat-label><b>ASSIGNEE</b>
                                <div *ngIf="task.assigneeTo !== null"
                                    style=" align-items: center; display: flex; flex-direction: row;">
                                    <nb-user *ngIf="task.assigneeTo; else noAssignee" [onlyPicture]="true"
                                        color="#8f9bb37a" [picture]="userImage" size="small"
                                        [name]="userNameImage || userChosen?.userName || task?.userName || 'Unknown'">
                                    </nb-user>
                                    <span>
                                        <strong>
                                            {{userNameImage || userChosen?.userName || task?.userName || 'Unknown'}}
                                        </strong> - ({{userChosen?.userName || task?.userName || 'Unknown'}})
                                    </span>
                                    <span>
                                        <button mat-icon-button (click)="clearAssignee()">
                                            <mat-icon>close</mat-icon>
                                        </button>
                                    </span>
                                </div>
                                <!-- <div *ngIf="task.assigneeTo !== null">
                                    <span>
                                        <img class="user-img"
                                            [src]="userChosen.pictureURL ?? 'assets/images/phone-call.png'| safeIframe"
                                            alt="user-avatar" style="margin-right: 5px;"
                                            (error)="userChosen.pictureURL = 'assets/images/phone-call.png'">
                                    </span>
                                    <strong>{{userChosen.firstName}} {{userChosen.lastName}}</strong> -
                                    ({{task.userName}})
                                    <span>
                                        <button mat-icon-button (click)="clearAssignee()">
                                            <mat-icon>close</mat-icon>
                                        </button>
                                    </span>
                                </div> -->
                            </mat-label>
                            <div class="col-12" style="margin-bottom: 8px;" *ngIf="task.assigneeTo === null">
                                <app-agent-chosen (assignAgentChange)="getAssignee($event)"></app-agent-chosen>
                            </div>
                        </div>
                    </div>

                    <!-- resolution code -->
                    <div>
                        <mat-label><b>RESOLUTION CODE</b></mat-label>
                        <div>
                            <!-- <mat-form-field appearance="standard"
                                class="task-type no-line no-padding no-padding-flex no-padding-infix without-arrow"
                                align="center" style="width: 120px; background-color: rgb(235, 236, 240);">
                                <mat-select [(ngModel)]="task.resolutionCodeId" [errorStateMatcher]=" matcher"
                                    class="status-task" [style.color]="taskStatusSelected.colorCode"
                                    (selectionChange)=" changeResolutionCode($event)">
                                    <mat-option *ngFor="let resolutionCode of resolutionCodes"
                                        [value]="resolutionCode.resolutionCodeId">
                                        {{resolutionCode.resolutionCodeName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field> -->
                            <mat-form-field appearance="standard" align="center">
                                <mat-select [(ngModel)]="task.resolutionCodeId" [errorStateMatcher]=" matcher"
                                    [style.color]="taskStatusSelected?.colorCode"
                                    (selectionChange)=" changeResolutionCode($event)">
                                    <mat-option value="-1">
                                        None
                                    </mat-option>
                                    <mat-option disabled="true">
                                        -- Please select --
                                    </mat-option>
                                    <mat-option *ngFor="let resolutionCode of resolutionCodes"
                                        [value]="resolutionCode.resolutionCodeId">
                                        {{resolutionCode.resolutionCodeName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div>
                        <div><b>RESOLUTION COMMENT</b></div>
                        <div style="margin-bottom: 1.34375em;">
                            <app-rich-inline-edit [inputData]="task.resolution" (handleSave)="saveResolution($event)">
                            </app-rich-inline-edit>
                        </div>
                    </div>
                    <div><b>TASK DESCRIPTION</b></div>
                    <div style="margin-bottom: 1.34375em;">
                        <app-rich-inline-edit [inputData]="task.taskDescription" (handleSave)="saveDescription($event)"
                            clickableIcon="true">
                        </app-rich-inline-edit>
                    </div>
                </div>
                <div class="col-4">
                    <mat-label><b>PRIORITY</b></mat-label>
                    <mat-form-field appearance="standard"
                        class="task-type no-line no-padding no-padding-flex no-padding-infix without-arrow">
                        <mat-select [(ngModel)]="task.taskPriority" [errorStateMatcher]="matcher"
                            (selectionChange)="changeTaskTaskPriority($event)">
                            <mat-select-trigger> <img class="user-img" [src]="priorityImg | safeIframe">
                                {{taskPrioritySelected?.priorityName}}
                            </mat-select-trigger>
                            <mat-option *ngFor="let priority of taskPriority" [value]="priority.priorityId">
                                <img class="user-img"
                                    [src]="'assets/images/priority/' + priority.priorityId.toString().toLowerCase() + '.svg' | safeIframe">
                                {{priority.priorityName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-label><b>STATUS</b></mat-label>
                    <div>
                        <mat-form-field appearance="standard"
                            class="task-type no-line no-padding no-padding-flex no-padding-infix without-arrow"
                            align="center" style="width: 120px; background-color: rgb(235, 236, 240);">
                            <mat-select [(ngModel)]="task.taskStatusId" [errorStateMatcher]="matcher"
                                class="status-task" [style.color]="taskStatusSelected?.colorCode"
                                (selectionChange)=" changeTaskStatus($event)">
                                <mat-option *ngFor="let status of taskStatus" [value]="status.taskStatusId">
                                    <div [style.color]="status.colorCode">
                                        {{status.taskStatusName}}
                                    </div>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <mat-label><b>ASSIGNEE</b>
                        <div *ngIf="task.assigneeTo !== null"
                            style=" align-items: center; display: flex; flex-direction: row;">
                            <nb-user *ngIf="task.assigneeTo; else noAssignee" [onlyPicture]="true" color="#8f9bb37a"
                                [picture]="userImage" size="small"
                                [name]="userNameImage || userChosen?.userName || task?.userName || 'Unknown'">
                            </nb-user>
                            <span>
                                <strong>{{userNameImage || userChosen?.userName || task?.userName || 'Unknown'}}
                                </strong> - ({{userChosen?.userName || task?.userName ||'Unknown'}})
                            </span>
                            <span>
                                <button mat-icon-button (click)="clearAssignee()">
                                    <mat-icon>close</mat-icon>
                                </button>
                            </span>
                        </div>
                        <!-- <div *ngIf="task.assigneeTo !== null">
                            <span>
                                <img class="user-img"
                                    [src]="userChosen.pictureURL ?? 'assets/images/phone-call.png'| safeIframe"
                                    alt="user-avatar-toan-test" style="margin-right: 5px;"
                                    (error)="userChosen.pictureURL = 'assets/images/phone-call.png'">
                            </span>
                            <strong>{{userChosen.firstName}} {{userChosen.lastName}}</strong> -
                            ({{task.userName}})
                            <span>
                                <button mat-icon-button (click)="clearAssignee()">
                                    <mat-icon>close</mat-icon>
                                </button>
                            </span>
                        </div> -->
                    </mat-label>
                    <div class="col-12" style="margin-bottom: 8px;" *ngIf="task.assigneeTo === null">
                        <app-agent-chosen (assignAgentChange)="getAssignee($event)"></app-agent-chosen>
                    </div>
                    <!-- Relationship infor: -->
                    <mat-label><b>RELATIONSHIP</b> </mat-label> -
                    {{task.relationshipType != 'none'
                    ? (task.relationshipType?.toUpperCase() || 'No relationship selected')
                    : 'No relationship selected'}}

                    <div style="display: flex; flex-direction: column;">
                        <div class="float-left">
                            <mat-form-field appearance="standard">
                                <mat-select [(ngModel)]="task.relationshipType"
                                    (selectionChange)="selectionChangeRelationship()">
                                    <mat-option value="none">None</mat-option>
                                    <mat-option *ngFor="let data of relationshipData" [value]="data.value">
                                        {{data.viewValue}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <!-- <div *ngIf="task.relationshipType === 'profile'; else searchContact">
                            <app-profile-chosen (profileSelected)="getProfileSelected($event)"></app-profile-chosen>
                        </div> -->
                        <div *ngIf="task.relationshipType && task.relationshipType != 'none'"
                            [ngSwitch]="task.relationshipType">

                            <app-profile-chosen *ngSwitchCase="task.relationshipType == 'profile' 
                            ? task.relationshipType : ''" [tooltipProp]="tooltipProp"
                                (profileSelected)="getProfileSelected($event)"
                                [apiGet]="apiGetSearchRelationship ? apiGetSearchRelationship : null"
                                [placeholder]="placeholderSearch ? placeholderSearch : null"
                                [funcMapTitleModel]="funcMapTitleSearch ? funcMapTitleSearch : null">
                            </app-profile-chosen>

                            <app-profile-chosen *ngSwitchCase="task.relationshipType == 'saleLeads' 
                                || task.relationshipType == 'saleAccount'
                                || task.relationshipType == 'opportunity'
                                ? task.relationshipType : ''" [tooltipProp]="tooltipProp"
                                (profileSelected)="getProfileSelected($event)"
                                [apiGet]="apiGetSearchRelationship ? apiGetSearchRelationship : null"
                                [placeholder]="placeholderSearch ? placeholderSearch : null"
                                [funcMapTitleModel]="funcMapTitleSearch ? funcMapTitleSearch : null"
                                [isOneLineResult]="true">
                            </app-profile-chosen>
                            <app-search-buyer-entity *ngSwitchCase="'buyer'" (buyerSelected)="buyerSelected($event)">
                            </app-search-buyer-entity>
                            <div *ngSwitchDefault>
                                <app-buyer-chosen (contactSelected)="getContactSelected($event)"></app-buyer-chosen>
                            </div>
                        </div>
                        <div *ngIf="contactRelationship; then contactType else profileDisplay"></div>
                        <div *ngIf="buyerRelationship">
                            <ng-container *ngTemplateOutlet="buyerDisplay;context:null">
                            </ng-container>
                        </div>
                    </div>
                    <mat-divider></mat-divider>
                    <br>
                    Created - {{task.dateCreated | date:'medium'}} <br>
                    Updated - {{task.dateModified | date:'medium'}}
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>

<ng-template #maximumMode>
    <div style="padding: 25px 50px">
        <div class="row">
            <div class="col-3">
                <h1>
                    <app-inline-edit [inputData]="task.taskName" (handleSave)="saveTitle($event)"></app-inline-edit>
                </h1>
                <div class="row" *ngIf="isMaximum">
                    <div class="col-6">
                        <mat-label><b>STATUS</b></mat-label>
                        <mat-form-field appearance="standard"
                            class="task-type no-line no-padding no-padding-flex no-padding-infix without-arrow"
                            style="background-color: rgb(235, 236, 240);">
                            <mat-select [(ngModel)]="task.taskStatusId" [errorStateMatcher]="matcher"
                                class="status-task" [style.color]="taskStatusSelected?.colorCode"
                                (selectionChange)=" changeTaskStatus($event)">
                                <mat-option *ngFor="let status of taskStatus" [value]="status.taskStatusId">
                                    <div [style.color]="status.colorCode">
                                        {{status.taskStatusName}}
                                    </div>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-label><b>PRIORITY</b></mat-label>
                        <mat-form-field appearance="standard"
                            class="task-type no-line no-padding no-padding-flex no-padding-infix without-arrow">
                            <mat-select [(ngModel)]="task.taskPriority" [errorStateMatcher]="matcher"
                                (selectionChange)="changeTaskTaskPriority($event)">
                                <mat-select-trigger> <img class="user-img" [src]="priorityImg | safeIframe">
                                    {{taskPrioritySelected?.priorityName}}
                                </mat-select-trigger>
                                <mat-option *ngFor="let priority of taskPriority" [value]="priority.priorityId">
                                    <img class="user-img"
                                        [src]="'assets/images/priority/' + priority.priorityId.toString().toLowerCase() + '.svg' | safeIframe">
                                    {{priority.priorityName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <mat-label><b>DUE DATE</b></mat-label>
                        <mat-form-field>
                            <input matInput [matDatepicker]="startPicker.datePicker" required
                                [errorStateMatcher]="matcher" [(ngModel)]="task.taskStartDate"
                                (dateChange)="!startPicker.cancelUpdate ? processDateChangeInput($event,'taskStartDate',true) : null"
                                [disabled]="!allowChangeDueDate">
                            <!-- <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                            <ngx-mat-datetime-picker #startPicker [showSeconds]="true">
                                <ng-template (click)="saveData(true)">
                                    <span>OK</span>
                                </ng-template>
                            </ngx-mat-datetime-picker> -->
                            <mat-datepicker-toggle matSuffix [for]="startPicker.datePicker"></mat-datepicker-toggle>
                            <app-primas-custom-date-time-picker #startPicker="PrimasCustomDateTimePicker"
                                (dateChange)="dateChanged($event,'taskStartDate')"></app-primas-custom-date-time-picker>
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-label><b>FINISHED DATE</b></mat-label>
                        <mat-form-field>
                            <input matInput [matDatepicker]="endPicker.datePicker" required
                                [(ngModel)]="task.taskEndDate" [errorStateMatcher]="matcher"
                                (dateChange)="!endPicker.cancelUpdate ? processDateChangeInput($event,'taskEndDate',true) : null">
                            <mat-datepicker-toggle matSuffix [for]="endPicker.datePicker"></mat-datepicker-toggle>
                            <!-- <ngx-mat-datetime-picker #endPicker [showSeconds]="true">
                                <ng-template>
                                    <span>OK</span>
                                </ng-template>
                            </ngx-mat-datetime-picker> -->
                            <app-primas-custom-date-time-picker #endPicker="PrimasCustomDateTimePicker"
                                (dateChange)="dateChanged($event,'taskEndDate')"></app-primas-custom-date-time-picker>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row" *ngIf="isMaximum">
                    <div class="col-12">
                        <mat-label><b>ASSIGNEE</b>
                            <div *ngIf="task.assigneeTo !== null"
                                style=" align-items: center; display: flex; flex-direction: row;">
                                <nb-user *ngIf="task.assigneeTo; else noAssignee" [onlyPicture]="true" color="#8f9bb37a"
                                    [picture]="userImage" size="small"
                                    [name]="userNameImage || userChosen?.userName || task?.userName || 'Unknown'">
                                </nb-user>
                                <span>
                                    <strong>
                                        {{userNameImage || userChosen?.userName || task?.userName || 'Unknown'}}
                                    </strong> - ({{userChosen?.userName || task?.userName || 'Unknown'}})
                                </span>
                                <span>
                                    <button mat-icon-button (click)="clearAssignee()">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                </span>
                            </div>
                            <!-- <div *ngIf="task.assigneeTo !== null">
                                <span>
                                    <img class="user-img"
                                        [src]="userChosen.pictureURL ?? 'assets/images/phone-call.png'| safeIframe"
                                        alt="user-avatar-toan-test-avt" style="margin-right: 5px;"
                                        (error)="userChosen.pictureURL = 'assets/images/phone-call.png'">
                                </span>
                                <strong>{{userChosen.firstName}} {{userChosen.lastName}}</strong> -
                                ({{task.userName}})
                                <span>
                                    <button mat-icon-button (click)="clearAssignee()">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                </span>
                            </div> -->
                        </mat-label>
                        <div class="col-12" style="margin-bottom: 8px;" *ngIf="task.assigneeTo === null">
                            <app-agent-chosen (assignAgentChange)="getAssignee($event)"></app-agent-chosen>
                        </div>
                    </div>
                </div>
                <div><b>TASK DESCRIPTION</b></div>
                <div style="margin-bottom: 1.34375em;">
                    <app-rich-inline-edit [inputData]="task.taskDescription" (handleSave)="saveDescription($event)"
                        clickableIcon="true">
                    </app-rich-inline-edit>
                </div>
                <div>
                    <mat-label><b>RESOLUTION CODE</b></mat-label>
                    <div>
                        <mat-form-field appearance="standard" align="center">
                            <mat-select [(ngModel)]="task.resolutionCodeId" [errorStateMatcher]=" matcher"
                                [style.color]="taskStatusSelected?.colorCode"
                                (selectionChange)=" changeResolutionCode($event)">
                                <mat-option value="-1">
                                    None
                                </mat-option>
                                <mat-option disabled="true">
                                    -- Please select --
                                </mat-option>
                                <mat-option *ngFor="let resolutionCode of resolutionCodes"
                                    [value]="resolutionCode.resolutionCodeId">
                                    {{resolutionCode.resolutionCodeName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div><b>RESOLUTION COMMENT</b></div>
                    <div style="margin-bottom: 1.34375em;">
                        <app-rich-inline-edit [inputData]="task.resolution" (handleSave)="saveResolution($event)">
                        </app-rich-inline-edit>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <br>
                Created - {{task.dateCreated | date:'medium'}} <br>
                Updated - {{task.dateModified | date:'medium'}}
            </div>
            <div class="col-9 body-content-relationship" *ngIf="profileRelationship">
                <ng-container [ngSwitch]="profileRelationship.typeName">
                    <ng-container *ngSwitchCase="'LEADS'">
                        <app-sale-lead-details [optionalTaskId]="task.taskId" [id]="task.relationshipId"
                            [openByDialog]="false" [creatableTask]="false" (refreshTask)="setRefreshTask()">
                        </app-sale-lead-details>
                    </ng-container>
                    <ng-container *ngSwitchCase="'SALEACCOUNT'">
                        <app-sale-account-details [optionalTaskId]="task.taskId" [id]="task.relationshipId"
                            [openByDialog]="false" [creatableTask]="false" (refreshTask)="setRefreshTask()">
                        </app-sale-account-details>
                    </ng-container>
                    <ng-container *ngSwitchCase="'OPPORTUNITY'">
                        <app-opportunity-details [optionalTaskId]="task.taskId" [id]="task.relationshipId"
                            [openByDialog]="false" [creatableTask]="false" (refreshTask)="setRefreshTask()"
                            (saleOpportunityEmit)="profileRelationship.saleOpportunity = $event">
                        </app-opportunity-details>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <app-profile-detail [optionalTaskId]="task.taskId" [id]="task.relationshipId"
                            [isShowButton]="false" [openByDialog]="isOpenDialog" [creatableTask]="false"
                            [tooltipProp]="tooltipProp" (refreshTask)="setRefreshTask()">
                        </app-profile-detail>
                    </ng-container>
                </ng-container>
            </div>
            <div *ngIf="contactRelationship" class="col-9 body-content-relationship" style="overflow: auto">
                <app-contact [includeLocation]="true" [profileId]='null' [selfGetApi]='false' [isDialog]='false'
                    [isUpdateAction]='true' [isAddMoreBtn]='false' [isDupplicatable]='false' [isLastSave]='false'
                    [isSetPrimaryable]='false' [contactLst]='contactComponent' [readonlyContact]="readonlyContact"
                    [isOwnerMode]='true' (refreshTask)="setRefreshTask()"></app-contact>
            </div>
            <div *ngIf="buyerRelationship" class="col-9 body-content-relationship">
                <app-edit-inline-buyer [buyerModel]="buyerRelationship" [(optionalTaskId)]="task.taskId"
                    [partialView]="true" (refreshTask)="setRefreshTask()"></app-edit-inline-buyer>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #contactType>
    <div class="row" *ngIf="contactRelationship">
        <div class="col-12">
            <div>
                <nb-card style="height: 100% !important; padding-bottom: 10px !important">
                    <nb-card-header>
                        {{contactRelationship?.contactName || ''}} {{contactRelationship?.contactLastName || ''}}
                        <span class="float-right">
                            <button mat-icon-button (click)="maximumScreen()">
                                <mat-icon>open_in_full</mat-icon>
                            </button>
                        </span>
                        <div class="text-muted">
                            {{contactRelationship?.relationship}}
                        </div>
                    </nb-card-header>
                    <nb-card-body>
                        <div class="col-12">
                            <div class="row">
                                <span>
                                    <mat-icon class="middle-align material-icons-outlined contact-icon">phone</mat-icon>
                                </span>
                                <div class="text-muted">
                                    {{(contactRelationship?.contactPhone | phoneFormat) || 'No phone display'}}
                                </div>
                            </div>
                            <div class="row">
                                <span>
                                    <mat-icon class="middle-align material-icons-outlined contact-icon">email</mat-icon>
                                </span>
                                {{contactRelationship?.contactEmail || 'No email display'}}
                            </div>
                            <br>
                            <div class="row" *ngIf="address1">
                                <span class="middle-align material-icons-outlined contact-icon">home</span>
                                {{address1}}
                            </div>
                            <div class="row" *ngIf="address2">
                                <span class="middle-align material-icons-outlined contact-icon ">location_on</span>
                                {{address2}}
                            </div>
                        </div>
                    </nb-card-body>
                </nb-card>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #profileDisplay>
    <div class="row"
        *ngIf="profileRelationship  && (profileRelationship.typeName == 'SALEPROFILE' || !profileRelationship.typeName)">
        <div class="col-12">
            <div>
                <nb-card style="height: 100% !important; padding-bottom: 10px !important">
                    <nb-card-header>
                        <div class="col-12">
                            <div class="row">
                                <div class="col">
                                    <div class="row">
                                        <span class="d-flex align-items-center" style="margin-right: 10px;">
                                            <img [src]="profileRelationship?.media?.media1  || defaultAvatar"
                                                (error)="defaultAvatar = 'assets/images/5.jpg'" alt="Avatar"
                                                class="avatar">
                                        </span>
                                        <div class="col">
                                            <div [matTooltip]="findItemByKey(tooltipProp, 'displayName') ?? 'Display name'"
                                                matTooltipPosition="left">
                                                <span>
                                                    <strong>{{profileRelationship?.displayName || ''}}</strong>
                                                </span>
                                            </div>
                                            <div [matTooltip]="findItemByKey(tooltipProp, 'realName') ?? 'Real name'"
                                                matTooltipPosition="left" class="text-muted">
                                                <span>
                                                    {{profileRelationship?.realName || ''}}
                                                </span>
                                            </div>

                                        </div>
                                        <span>
                                            <button mat-icon-button selected (click)="maximumScreen()">
                                                <mat-icon>open_in_full</mat-icon>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </nb-card-header>
                    <nb-card-body class="card-image">
                        <img matTooltip="Profile image" matTooltipPosition="left"
                            [src]="profileRelationship?.media?.media1 || defaultAvatar"
                            (error)="defaultAvatar = 'assets/images/5.jpg'" width="100%" [style.height.px]="180"
                            style=" object-fit:cover; object-position: center" alt="Profile Image">
                    </nb-card-body>
                </nb-card>
            </div>
        </div>
    </div>
    <ng-container *ngIf="profileRelationship && profileRelationship.typeName == 'LEADS'"
        [ngTemplateOutlet]="opportunityBlock" [ngTemplateOutletContext]="{
            title: profileRelationship?.displayName,
            tooltip: environment.titleLead + ' name'      
        }">
    </ng-container>
    <ng-container *ngIf="profileRelationship && profileRelationship.typeName == 'SALEACCOUNT'"
        [ngTemplateOutlet]="opportunityBlock" [ngTemplateOutletContext]="{
         title: profileRelationship.displayName,
         tooltip: environment.titleAccount + ' name'      
        }">
    </ng-container>
    <ng-container *ngIf="profileRelationship && profileRelationship.typeName == 'OPPORTUNITY' && 
        profileRelationship.saleOpportunity" [ngTemplateOutlet]="opportunityBlock" [ngTemplateOutletContext]="{
            title: profileRelationship.saleOpportunity.opportunityName,
            tooltip: environment.titleOpportunity + ' name'      

        }">
    </ng-container>
</ng-template>

<ng-template #searchContact>
    <div>
        <app-buyer-chosen (contactSelected)="getContactSelected($event)"></app-buyer-chosen>
    </div>
</ng-template>
<ng-template #contactBlock>
    <div>
        <app-contact-info [contact]="contactRelationship"></app-contact-info>
    </div>
</ng-template>
<ng-template #noAssignee>
    <span style="margin-right:5px">Unassigned</span>
</ng-template>

<ng-template #buyerDisplay>
    <div *ngIf="buyerRelationship">
        <nb-card style="height: 100% !important; padding-bottom: 10px !important">
            <nb-card-header>
                <div class="col-12">
                    <div class="row">
                        <div class="col">
                            <div class="row">
                                <!-- <span class="d-flex align-items-center" style="margin-right: 10px;">
                                    <img [src]="displayImage || 'assets/images/5.jpg'"
                                        (error)="displayImage = 'assets/images/5.jpg'" alt="Avatar" class="avatar">
                                </span> -->
                                <div class="col">
                                    <div matTooltip="Company name" matTooltipPosition="left">
                                        <span>
                                            <strong>{{buyerRelationship?.companyName || 'None'}}</strong>
                                        </span>
                                    </div>
                                    <div matTooltip="Website" matTooltipPosition="left" class="text-muted">
                                        <span>
                                            {{buyerRelationship?.companyWebsite || 'None'}}
                                        </span>
                                    </div>
                                </div>
                                <span>
                                    <button mat-icon-button (click)="maximumScreen()">
                                        <mat-icon> open_in_full</mat-icon>
                                    </button>

                                </span>
                            </div>
                        </div>
                    </div>
                </div>

            </nb-card-header>
            <nb-card-body>
                <p matTooltip="Facebook" matTooltipPosition="left">
                    <span class="fab fa-facebook fa-lg social-icon blue-color">
                    </span>
                    <span>
                        {{buyerRelationship?.facebook || 'No Information'}}
                    </span>
                </p>
                <!-- insta -->
                <p matTooltip="Instagram" matTooltipPosition="left">
                    <span class="fab fa-instagram fa-lg social-icon blue-color">
                    </span>
                    <span>
                        {{buyerRelationship?.instagram || 'No Information'}}
                    </span>
                </p>
                <!-- Twitter -->
                <p matTooltip="Twitter" matTooltipPosition="left">
                    <span class="fab fa-twitter fa-lg social-icon blue-color">
                    </span>
                    <span>
                        {{buyerRelationship?.twitter || 'No Information'}}
                    </span>
                </p>
            </nb-card-body>
        </nb-card>
    </div>
</ng-template>

<ng-template #showBlockRelationship let-title="title" let-subTitle="subTitle" let-labelTitle="labelTitle"
    let-labelSubTitle="labelSubTitle" let-labelImg="labelImg">
    <nb-card style="height: 100% !important; padding-bottom: 10px !important">
        <nb-card-header>
            <div class="col-12">
                <div class="row">
                    <div class="col">
                        <div class="row">
                            <span class="d-flex align-items-center" style="margin-right: 10px;">
                                <img [src]="defaultAvatar || 'assets/images/5.jpg'"
                                    (error)="defaultAvatar = 'assets/images/5.jpg'" alt="Avatar" class="avatar">
                            </span>
                            <div class="col">
                                <div class="limit-line" style="--line: 1;" [matTooltip]="labelTitle"
                                    matTooltipPosition="left">
                                    <span><strong>{{title || 'None'}}</strong></span>
                                </div>
                                <div class="limit-line" style="--line: 1;" [matTooltip]="labelSubTitle"
                                    matTooltipPosition="left" class="text-muted">
                                    <span>{{subTitle || 'None'}}</span>
                                </div>
                            </div>
                            <span>
                                <button mat-icon-button selected (click)="maximumScreen()">
                                    <mat-icon>open_in_full</mat-icon>
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </nb-card-header>
        <nb-card-body class="card-image">
            <img [matTooltip]="labelImg" matTooltipPosition="left" [src]="defaultAvatar || 'assets/images/5.jpg'"
                (error)="defaultAvatar = 'assets/images/5.jpg'" width="100%" [style.height.px]="180"
                style=" object-fit:cover; object-position: center" alt="Profile Image">
        </nb-card-body>
    </nb-card>
</ng-template>

<ng-template #opportunityBlock let-title="title" let-subTitle="subTitle" let-labelTitle="labelTitle"
    let-tooltip="tooltip">
    <nb-card>
        <nb-card-header fxLayout="row" fxLayoutAlign="space-between center" class="custom-header">
            <div fxLayout="row" fxFlex="1 1 calc(100%-100px)">
                <span class="title-header-card limit-line mr-1" style="--line: 1;" [matTooltip]="tooltip"
                    matTooltipPosition="left">
                    {{title}}
                </span>
            </div>

            <div fxFlex="100px" fxLayoutAlign="end center">
                <button mat-icon-button (click)="maximumScreen()">
                    <mat-icon>open_in_full</mat-icon>
                </button>
            </div>
        </nb-card-header>
    </nb-card>

    <ng-template #loadingUser>
        <ngx-skeleton-loader fxFlex count="1" appearance="line" animation="progress"
            [theme]="{ 'margin-bottom': '0px' }">
        </ngx-skeleton-loader>
    </ng-template>
</ng-template>