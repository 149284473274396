<div class="term-form">
    <span>
        <p>By submitting content to Altus Entertainment, you hereby agree as follows:</p>
    </span>
    <span *ngFor="let line of termForm; let i = index">
        <p><b>{{i+1}}.</b> {{line}}</p>
    </span>
</div>

<form [formGroup]="agreementForm">
    <div class="row">
        <div class="col-sm-12 notify-sec">
            <div class="form-group">
                <label class="form-label">Note</label>
                <textarea formControlName="Note" type="text" class="note form-control"></textarea>
            </div>
            <div class="form-group form-check">
                <input formControlName="Term" type="checkbox" class="form-check-input">
                <label class="form-label" for="agreeTerms">I have read, understood and accept the terms of the applicant
                    declaration.
                </label>
            </div>
            <div class="form-group">
                <label class="form-label">Signature</label>
                <input formControlName="Signatures" style="max-width: 300px" required type="text" name="rider"
                    placeholder="Your Name..." class="signature form-control" im="blur">
            </div>
        </div>
    </div>
</form>