import { NbToastrService } from '@nebular/theme';
import { NbAuthService } from '@nebular/auth';
import { Value } from './../enquiry-management/payment-plan/payment-plan.component';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil, finalize } from 'rxjs/operators';
import { countries } from 'src/app/shared/components/stand-alone-component/contact/contact-type';
import { PrimasTableComponent } from 'src/app/shared/components/template-management/primas-table/primas-table.component';
import { PrimasToolbarComponent } from 'src/app/shared/components/template-management/primas-toolbar/primas-toolbar.component';
import { permissionSumaryReport } from 'src/app/shared/contances/permission';
import { PrimasFilterType } from 'src/app/shared/enums/primas-value-type.enum';
import { PagedData } from 'src/app/shared/models/paging/paged-data';
import { ProfileSummary } from './summary-report.model';
import { SummaryReportService } from './summary-report.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { AdminTabModeService } from 'src/app/shared/components/stand-alone-component/admin-tab-mode/admin-tab-mode.service';

@Component({
  selector: 'app-summary-report',
  templateUrl: './summary-report.component.html',
  styleUrls: ['./summary-report.component.scss']
})
export class SummaryReportComponent implements OnInit, AfterViewInit {
  // @ViewChild('primasToolbar', { static: true }) primasToolbar: PrimasToolbarComponent;
  // @ViewChild('primasTable', { static: true }) primasTable: PrimasTableComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public filteredBanks: ReplaySubject<DropDownValue[]> = new ReplaySubject<DropDownValue[]>(1);
  @ViewChild('singleSelect') singleSelect: MatSelect;
  /** control for the selected bank */
  public bankCtrl: FormControl = new FormControl();
  protected _onDestroy = new Subject<void>();

  /** control for the MatSelect filter keyword */
  public bankFilterCtrl: FormControl = new FormControl();
  dateTimeChosen: DropDownValue[] = []
  dateChosen: DropDownValue;
  pageSize = 10;
  currentPage = 0;
  isLoadingExport: false;
  loading = false;
  optionPublished: any;
  optionContact: any;
  isShowRefactorButton: boolean = true;
  countryLength = countries.length;
  loadingRefactor: boolean = false;
  parentDisplayColumns = ['firstEmpty', 'emailedGroup', 'calledGroup', 'secondEmpty']
  displayColumns = [
    'Country',
    'Total',
    'Hascontactinfo',
    'HasContactEmail',
    // 'TotalEmailed',
    'emailSuccess',
    'emailFailed',
    //'ReadEmail',
    'Replied',
    //'Unsub',
    //'totalCall',
    'totalSuccess',
    'totalFailed',
    'SubmittedForms',
    //'Accepted',
    //'Rejected',
    //'ReadyToPublish',
    'Published',
    "Consent"
  ];
  dataSource = new MatTableDataSource<ProfileSummary>();
  constructor(private service: SummaryReportService, private datePipe: DatePipe, private authService: NbAuthService, private toast: NbToastrService,
    private dialog: MatDialog, private tabModeService: AdminTabModeService) {
    this.authService.onTokenChange().subscribe(e => {
      // const payLoad = JSON.parse(e.getPayload()['permission']);
      // if (payLoad) {
      // }
    });
    this.tabModeService.toggleLoading(false);
  }
  ngAfterViewInit() {
    this.setInitialValue();
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit() {
    this.refreshData();
    this.service.getSummaryReport().subscribe(resp => {
      if (resp) {
        this.dataSource = new MatTableDataSource<ProfileSummary>(resp.data);
        this.loadPageData(resp);
      }
    });
    this.service.getAllRevision().subscribe(e => {
      if (e.result) {
        this.dateTimeChosen = e.result.map(date => {
          return {
            value: date,
            text: this.datePipe.transform(date, 'medium')
          }
        });
        // load the initial bank list
        this.filteredBanks.next(this.dateTimeChosen.slice());
        const data = this.dateTimeChosen[0];
        this.bankCtrl.setValue(data)
        // listen for search field value changes
        this.bankFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterBanks();
          });
      }

    })
    //this.refreshData();
  }

  refreshDate() {
    this.service.getAllRevision().subscribe(e => {
      if (e.result) {
        this.dateTimeChosen = e.result.map(date => {
          return {
            value: date,
            text: this.datePipe.transform(date, 'medium')
          }
        });
        // load the initial bank list
        this.filteredBanks.next(this.dateTimeChosen.slice());
        const data = this.dateTimeChosen[0];
        this.bankCtrl.setValue(data)
        // listen for search field value changes
        this.bankFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterBanks();
          });
      }

    })
  }
  refreshData(date: string = null, isReset: boolean = false): void {
    this.loading = true;
    // call service to reload data:
    // need to process page data here

    if (!isReset && !this.bankCtrl.invalid) {
      const formValue = this.bankCtrl.value;
      if (formValue)
        date = formValue.value;
    }

    this.service.refreshSummaryReport({
      size: this.pageSize ?? 10,
      pageNumber: this.currentPage ?? 0,
      totalElements: 3,
      totalPages: 0,
      viewId: null,
      orders: null,
      filter: null
    }, date).subscribe(e => {
      this.loading = false;
      this.loadPageData(e);
      if (isReset)
        this.refreshDate();
    });
  }
  loadPageData(pageData: PagedData<ProfileSummary>) {
    if (this.paginator) {
      this.paginator.pageSize = this.pageSize;
      this.paginator.length = countries.length;
      this.paginator.pageIndex = this.currentPage;
    }
  }
  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.refreshData();
  }
  showPercent(first, total) {
    if (total === 0) return '0';
    if (first === 0 && total === 0) return '0';
    return ((first / total) * 100).toFixed(1);
  }
  refreshGetReport() {
    this.loading = true;
    this.service.refreshDataSummaryReport().subscribe(e => {
      if (e.result) {
        this.currentPage = 0;
        this.pageSize = 10;
        this.refreshData(null, true);
      }
    })
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  /**
   * Sets the initial value after the filteredBanks are loaded initially
   */
  protected setInitialValue() {
    this.filteredBanks
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a: DropDownValue, b: DropDownValue) => a && b && a.value === b.value;
      });
  }

  protected filterBanks() {
    if (!this.dateTimeChosen) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCtrl.value;
    if (!search) {
      this.filteredBanks.next(this.dateTimeChosen.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredBanks.next(
      this.dateTimeChosen.filter(bank => bank.text.toLowerCase().indexOf(search) > -1)
    );
  }
  applyChange() {
    if (!this.bankCtrl.invalid) {
      const formValue = this.bankCtrl.value;

      this.refreshData(formValue.value)
    }
  }
  refactorData() {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        message: 'Do you wish to use this feature?'
      }
    });
    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        this.loadingRefactor = true;
        this.toast.primary("This feature is working. Please wait a few minutes!")
        this.service.refactorReportData().subscribe(e => {
          if (e.result) {
            this.loadingRefactor = false;
            this.toast.success("Refactor is successfully!")
          }
        });
      }
    })

  }
}
export class DropDownValue {
  /**
   *
   */
  constructor(text: string = null, value: string = null, color: string = null) {
    this.text = text ?? this.text;
    this.value = value ?? this.value;
    this.color = color ?? this.color;
  }
  value: any;
  text: string;
  color?: string | null;
}
export class DropDownValueEmailCampaign extends DropDownValue{
  icon?:string | null;
}
export interface GraphReportTwoLine {
  timeStamp: string;
  firstValue: number;
  secondValue: number;
}

export interface GraphAvgReport {
  avgFirstValue: number;
  avgSecondValue: number;
}

export interface GrapthReport {
  chartData: GraphReportTwoLine[];
  avgData: GraphAvgReport;
}

export interface RevisionReport {
  revision: string;
  dateTimeRevision: string;
}
export interface TableOfContent extends DropDownValue {
  children?: TableOfContent[]
  statusControlName?: string;
}
