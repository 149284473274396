import { permissionProfileDetailTab, permissionProfileFile, permissionGridNoteProfile } from './../../../../shared/contances/permission';
import { Contact, ContactContactType, KeyPairsValue, ProfileSeo, SeoInfo } from './../profile-detail.model';
import { ReasonRequest } from 'src/app/shared/models/request/reason-request.model';
import { ReasonBioService } from './bio-n-reason/reason-bio.service';
import { da } from 'date-fns/locale';
import { ProfileRequest } from './../../../../shared/models/request/profile-request.model';
import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnChanges, OnDestroy, OnInit, ViewEncapsulation, ViewChild, Output, Input, HostListener, ElementRef, EventEmitter, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { NbPopoverDirective, NbToastrService, NbWindowService, NbWindowState } from '@nebular/theme';
import { FormGroupExtension, RxFormBuilder, async, compare } from '@rxweb/reactive-form-validators';
import { TblActionType } from 'src/app/shared/enums/tbl-action-type.enum';
import { TooltipPosition } from '@angular/material/tooltip';
import { Media, ProfileDetailModel, ProfileSaveChangeModel } from '../profile-detail.model';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { ProfileService } from '../profile.service';
import { InlineEditComponent } from '../../../../shared/components/stand-alone-component/inline-edit/inline-edit.component';
import { MatTab, MatTabChangeEvent } from '@angular/material/tabs';
import baguetteBox from 'baguettebox.js';
import { Subject, Subscription } from 'rxjs';
import { AltusState } from 'src/app/shared/enums/altus-state.enum';
import { DataState, dataStateList } from 'src/app/shared/contances/primas-data-state';
import { concatMap, debounceTime, first, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { SettingService } from 'src/app/shared/services/setting.service';
import { Setting } from 'src/app/shared/models/setting.model';
import { UserService } from '../../user-management/user.service';
import { CallStatusModalComponent } from '../../task-management/call-status-modal/call-status-modal.component';
import { SignalrDynamicService } from 'src/app/shared/services/signalr-dynamic.service';
import { DataStates } from 'src/app/shared/models/data-state.model';
import { MergeChangesComponent } from './action-block/merge-changes/merge-changes.component';
import { MergeChangesService } from './action-block/merge-changes/merge-changes.service';
import { FormBuilder, FormGroup, FormGroupName, Validators } from '@angular/forms';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { ConfirmSaveAllChangeComponent } from './confirm-save-all-change/confirm-save-all-change.component';
import { debug } from 'console';
import { ContactService } from 'src/app/shared/services/contact.service';
import { TabProfile } from 'src/app/shared/enums/tab-profile.enum';
import { Page } from 'src/app/shared/models/paging/page';
import { NextOrPrevious } from 'src/app/shared/components/stand-alone-component/next-or-previous-detail/next-previous.model';
import { NextOrPreviousDetailComponent } from 'src/app/shared/components/stand-alone-component/next-or-previous-detail/next-or-previous-detail.component';
import { ProfileRelationshipTabComponent } from './profile-relationship-tab/profile-relationship-tab.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { Clipboard, ClipboardModule } from '@angular/cdk/clipboard';
import { DatastateManagementService } from '../../datastate-management/datastate-management.service';
import { AutomateDataState, ReturnDataField } from './automate-datastate-log/automate-datastate-model';
import { NgxCaptureService } from 'ngx-capture';
import { AddNewEmailComponent } from './action-block/add-new-email/add-new-email.component';
import { SendSupportTicketComponent } from '../send-support-ticket/send-support-ticket.component';
import { WPHomePage, WPTypeDetail } from '../../wp-homepage-management/wp-home-page.model';
import { WpHomepageManagementService } from '../../wp-homepage-management/wp-homepage-management.service';
import { permissionDetailProfileItem } from 'src/app/shared/contances/permission';
import { Helper } from 'src/app/shared/utility/Helper';
import { DataFieldsManagementService } from 'src/app/shared/services/data-fields-management.service';
import { EntityColorEnums } from 'src/app/shared/enums/entity-color.enums';
import { AdminTabModeService } from 'src/app/shared/components/stand-alone-component/admin-tab-mode/admin-tab-mode.service';
import { TabTagIndexModel, TabTypeModeEnums } from 'src/app/shared/components/stand-alone-component/admin-tab-mode/tab-mode.model';
import { AddExistingContactComponent } from './contact-tab/add-existing-contact/add-existing-contact.component';
import { TableVariable } from 'src/app/shared/contances/data-table-contance';
import { ContactComponent } from 'src/app/shared/components/stand-alone-component/contact/contact.component';
import { ContactRelationshipGridComponent } from 'src/app/shared/components/contact-relationship-grid/contact-relationship-grid.component';
import { UserUpload } from '../../user-upload-management/user-upload.model';
import { HotToastService } from '@ngneat/hot-toast';
import { UserUploadManagementService } from '../../user-upload-management/user-upload-management.service';
import { ProfileFileTabComponent } from 'src/app/shared/components/stand-alone-component/profile-file-tab/profile-file-tab.component';
import { NoteManagementService } from 'src/app/shared/components/note-management/note-management.service';
import { OverlayNoteDetailsComponent } from 'src/app/shared/components/note-management/overlay-note-details/overlay-note-details.component';
import { NoteTabComponent } from 'src/app/shared/components/note-tab/note-tab.component';
import { NoteDetails } from 'src/app/shared/components/note-management/noteManagement.model';
import { ShadowProfileEnum } from 'src/app/shared/enums/shadow-profile.enum';
import { AddEditTaskComponent } from '../../task-management/add-edit-task/add-edit-task.component';
import { TaskGridByProfileIdComponent } from '../../sale-lead-management/sale-lead-details/task-grid-by-profile-id/task-grid-by-profile-id.component';
import { AddNewSeoComponent } from './seo-tab/add-new-seo/add-new-seo.component';
import { SeoTabTableComponent } from './seo-tab-table/seo-tab-table.component';
import { UploadUrlComponent } from 'src/app/shared/components/stand-alone-component/profile-file-tab/upload-url/upload-url.component';
import { ProposalTabComponent } from './proposal-tab/proposal-tab.component';
import { EmailRelationship, ListEmailContact, LookUpListEmailOutlook, LookupEmailRelationship } from '../../contact-type-management/contact-type.model';
import { environment } from 'src/environments/environment';
import { SortOrderType } from 'src/app/shared/enums/sort-order-type.enum';
import { E } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-profile-detail',
  templateUrl: './profile-detail.component.html',
  styleUrls: ['./profile-detail.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class ProfileDetailComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('tabGroup') tabGroup;
  nbFilesPopover: NbPopoverDirective;
  @ViewChild('nbFilesBtn', { static: false }) set setFilesPopoverBtn(content: NbPopoverDirective) { if (content) this.nbFilesPopover = content }
  contactTab: ContactRelationshipGridComponent;
  @ViewChild('contactGrid', { static: false }) set contactAccount(content: ContactRelationshipGridComponent) { if (content) this.contactTab = content };
  fileTab: ProfileFileTabComponent;
  @ViewChild('profileFileTab', { static: false }) set setProfileFileTab(content: ProfileFileTabComponent) { if (content) this.fileTab = content }
  taskGrid: TaskGridByProfileIdComponent;
  @ViewChild('taskGrid', { static: false }) set setTaskGrid(content: TaskGridByProfileIdComponent) { if (content) this.taskGrid = content };
  profileSeo: SeoTabTableComponent;
  @ViewChild('profileSeo', { static: false }) set setProfileSeo(content: SeoTabTableComponent) { if (content) this.profileSeo = content };
  profileProposal: ProposalTabComponent;
  @ViewChild('profileProposal', { static: false }) set setProposal(content: ProposalTabComponent) { if (content) this.profileProposal = content };
  @ViewChild('SEOAuditTemp', { static: true }) seoAuditTemp: TemplateRef<any>;

  @Input() id: string;
  @Input() creatableTask = true;
  // flag to disable call task status, type, priority.
  @Input() isDisableTaskAPI = false
  @Input() optionalTaskId: string;
  @Input() openByDialog = true;
  @Input() isShowButton: boolean = false;
  @Input() tooltipProp: KeyPairsValue[] = [
    { key: 'displayName', value: 'Display Name' },
    { key: 'realName', value: 'Real Name' },
    { key: 'producer.producerName', value: 'Production company' },
    { key: 'parentId', value: 'Production company' }, // producer for activity tab
    { key: 'webPage', value: 'Website' },
  ];

  @Output() onClose: EventEmitter<boolean> = new EventEmitter();
  @Output() refreshTask: EventEmitter<void> = new EventEmitter();

  @ViewChild('btnNextOrPre', { static: true }) btnNextOrPre: NextOrPreviousDetailComponent;
  @ViewChild('tabRelationship', { static: true }) tabRelationship: ProfileRelationshipTabComponent;
  @ViewChild(MatMenuTrigger) matMenuTrigger: MatMenuTrigger;
  noteTab: NoteTabComponent;
  @ViewChild('gridNoteTab', { static: false }) set setGridNoteTab(content: NoteTabComponent) { if (content) this.noteTab = content };
  @ViewChild('tabIndexSeo') tabIndexSeo: MatTab;
  @ViewChild('tabIndexMedia') tabIndexMedia: MatTab;
  @ViewChild('tabIndexReasonBio') tabIndexReasonBio: MatTab;
  @ViewChild('displayNameProp') displayNameProp: ElementRef<any>;

  // isSaveAllChange: boolean = false;
  action: TblActionType;
  subjectProfile = '';
  isLoading = false;
  profileModel: ProfileDetailModel = new ProfileDetailModel();
  isEditing: boolean;
  dataState: DataStates[] = [];
  isRefreshing = false;
  currentState: DataStates | AutomateDataState;
  isHiddenData: boolean = false;
  // show pop flag
  isShowPopup = false;
  // 2021-09-10 tien add
  private routeSub: Subscription;
  // 2021-09-10 tien add end
  private destroyed$ = new Subject();
  asteriskSub = null;
  isPopupAsteriskOpen = false;
  private dialogAsteriskRef: MatDialogRef<CallStatusModalComponent>;
  //2021-11-1 vuonglqn start add
  readonlyProfile = false;
  isHasUpdateNew = false;
  user: any;
  //2021-11-1 vuonglqn start end

  //2022-1-4 vuonglqn add start
  currentUrl: string = '';
  //2022-1-4 vuonglqn add end

  //2022-01-12 hmtien add start
  dataEntryRole: Setting;
  flag = false;
  userRolesId: String[] | null;
  popUpSetting: boolean = true;
  //2022-01-12 hmtien add end

  //2022-03-16 hmtien add start
  webPage: string | null;
  //2022-03-16 hmtien add end

  openByDialog1 = true;
  theLastAsteriskCallStatus = null;
  // 2022-02-10 tienlm add start
  bypassPermissions = false;
  // 2022-02-10 tienlm add start
  profileSaveChange = new ProfileSaveChangeModel();
  seoChange = [];
  contactChange = [];
  selectedIndex = 0;
  //2022-03-23 vuonglqn add start
  profileNextOrPre: NextOrPrevious = new NextOrPrevious();
  parentId: string;
  //2022-03-23 vuonglqn add end
  numberTruncateToggle: number = 20;
  checkIsMaster: boolean = false;
  //2022-04-12 tienlm add start
  primarySeoLink: string;
  primarySeo: ProfileSeo = null;
  loadingSeoLinkBtn = false;
  defaultSeoLinkDomain: string
  disableSeoLink = false;
  seoTooltip: string;
  //2022-04-12 tienlm add end
  isAutomateDataState: boolean = false;
  isHasDataField: boolean = true;
  autoDataState: AutomateDataState[] = [];
  uploadedFile: any;
  uploading: boolean = false;
  isDuplicate: boolean = false;
  duplicateIconLoading: boolean = false;
  seoSubscription: Subscription;
  typeHomePage: WPTypeDetail[] = [];
  featureActHP: WPHomePage = { homePageId: -1 } as WPHomePage;
  seasonalActHP: WPHomePage = { homePageId: -1 } as WPHomePage;

  //2022-08-17 ducqm add start
  resourceTab = permissionProfileDetailTab;
  //2022-08-17 ducqm add end

  //Seo-Info purpose
  seoInfoId = 0;
  dynamicName = '';
  resourceItem = permissionDetailProfileItem;
  findItemByKey = Helper.findItemByKey;
  colorCode = EntityColorEnums;
  isCopy = false;
  isHasPrimaryContact = false;
  existingContactDialogRef: MatDialogRef<any, any>;
  contactDialogRef: any;
  isTabMode = Helper.checkTabMode();
  totalContacts: Contact[] = null;
  resetContacts: () => void = () => this.totalContacts = null;
  fileResource = permissionProfileFile;
  resource = permissionGridNoteProfile;
  overlayNoteDetails: OverlayNoteDetailsComponent;
  lastNote: NoteDetails;
  changeToRelationship = false;
  listRelationshipContact: string[] = [];
  listEmailRelationship: EmailRelationship[] = [];
  isContactsRelationship: boolean = false;
  isShowPopover: boolean = true;
  environment = environment;
  isLoadingDataDuplicated: boolean = false;
  formatContactType: (row: any) => any = (row: any) => {
    try {
      var contactTypeStr: string = '';
      if (row && row.contact && row.contact.contactContactTypes && row.contact.contactContactTypes.length > 0) {
        var contactTypes: ContactContactType[] = row.contact.contactContactTypes;
        let findUnique: (arr) => any[] = arr => arr.filter((item, index) => arr.indexOf(item) === index)

        contactTypeStr = findUnique(contactTypes.map(x => x?.contactType?.contactTypeName || '').filter(x => x)).join(", ");
        row.contact['showContactType'] = contactTypeStr || '';
      }
    } catch (ex) {
      console.warn(ex);
    }

    return row;
  }

  focusSEOAudit = {
    "Input": 'seo',
    "Title tag": 'seo',
    "Meta description": 'seo',
    "Page Headings summary": 'displayName',
    "Image analysis": 'media',
    "Keyword density": 'seo',
    "Related keywords": 'seo',
  }

  constructor(
    public dialModalRef: MatDialogRef<ProfileDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private route: ActivatedRoute,
    private router: Router,
    private profileService: ProfileService,
    private toast: NbToastrService,
    private authService: NbAuthService,
    //2022-01-12 hmtien add start
    private userService: UserService,
    private signalrService: SignalrDynamicService,
    private dialog: MatDialog,
    private settingsService: SettingService,
    private updateInformationService: MergeChangesService,
    private frmBuilder: RxFormBuilder,
    private reasonService: ReasonBioService,
    private contactService: ContactService,
    private clipboard: Clipboard,
    private dataStateService: DatastateManagementService,
    private wpHomePageService: WpHomepageManagementService,
    private dataFieldsService: DataFieldsManagementService,
    private tabModeService: AdminTabModeService,
    private cdref: ChangeDetectorRef,
    private userUploadService: UserUploadManagementService,
    private hotToast: HotToastService,
    public noteService: NoteManagementService,
    private windowService: NbWindowService
    //2022-01-12 hmtien add end
  ) {
    if (data.model) {
      this.id = data.model.profileId;
      //if (this.router.url.includes('configuration/profile')) Helper.cancelNavigate(`/configuration/profile/${this.id}`);
    }
    this.id = data.id ?? this.id;
    this.seoInfoId = data.seoInfoId ?? 0;
    this.parentId = data.parentId;
    //2022-1-4 vuonglqn add start
    if (this.router.url.includes('/configuration/profile') ||
      this.router.url.includes('/configuration/dashboard-seo') ||
      this.router.url.includes('/configuration/email-history') ||
      this.router.url.includes('/configuration/email-inbox') ||
      this.router.url.includes('/configuration/history-data') ||
      this.router.url.includes('/configuration/email-campaign') ||
      this.router.url.includes('/configuration/activity-log') ||
      this.router.url.includes('/configuration/dashboard')
    ) {
      this.isShowButton = true;
    }
    let gettabMode = window.localStorage.getItem("subject_profile");
    if (gettabMode) {
      this.subjectProfile = gettabMode;
    }
    this.currentUrl = this.router.url; //get current url.
    this.selectedIndex = this.currentUrl.includes('/configuration/task') ||
      this.currentUrl.includes('/configuration/dashboard-crm') ||
      this.currentUrl.includes('/configuration/dashboard-sale')
      ? TabProfile.Activities
      : this.currentUrl.includes('/configuration/dashboard-seo')
        ? TabProfile.SEO
        : TabProfile.General;

    //2022-1-4 vuonglqn add end
    // check dashboard:
    if (this.currentUrl === '/configuration/activity-log'
      || this.currentUrl === '/configuration/auto-assignment-report'
    ) {
      this.openByDialog1 = false;
    }
    // if (this.currentUrl === '/configuration/auto-assignment-report') {
    //   this.isShowButton = false;
    // }

    const idParam = this.route.snapshot.params;
    if (idParam?.profileId || idParam?.id)
      this.id = idParam.profileId || idParam.id;

    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.user = token.getPayload();
          if (this.currentUrl.startsWith('/configuration/profile')) {
            // get cache tab index in local storage:
            let tag = this.getCacheTab();

            // modify starting tab by get URL tab index with #
            let patternRegex = /#([\d]+)/;
            if (!Helper.isNullOrEmpty(tag)) {
              this.selectedIndex = parseInt(tag.replace('#', ''));
              Helper.handleTabChangedUrl(this.selectedIndex, '/configuration/profile');
            }
            else if (patternRegex.test(this.currentUrl)) {
              // Special case that has # at the end of the url
              let matchTabIndex = this.currentUrl.match(patternRegex).length > 0 ? Number.parseInt(this.currentUrl.match(patternRegex)[0].replace('#', '')) : Number.parseInt(TabProfile[this.user?.defaultTabProfile]);
              this.selectedIndex = matchTabIndex;
            } else {
              // normal case that no # at the end of the url
              var tabDefault = TabProfile[this.user?.defaultTabProfile];
              this.selectedIndex = Number.parseInt(tabDefault);
            }
          }
        }
      });

    this.settingsService.getSettingByKeyAndGroup("FONT-FACING", "DOMAIN").subscribe(res => {
      if (res.result) {
        this.defaultSeoLinkDomain = res.result.value;
      }
      this.loadingSeoLinkBtn = false;
    });
    // check seo infor:

    this.seoSubscription = this.profileService.getSeoInfo().subscribe((res: ReturnResult<ProfileSeo[]>) => {
      if (res.result != null) {
        if (res.result.length > 0) {
          let primarySeo = res.result.find((x: ProfileSeo) => x.primarySeoInfo);
          if (primarySeo != undefined) {
            this.primarySeo = primarySeo;
            this.primarySeoLink = primarySeo.seoInfo.friendlyUrl;

          } else {
            this.primarySeo = res.result[0];
            this.primarySeoLink = res.result[0]?.seoInfo?.friendlyUrl;
          }
          if (!this.primarySeoLink) {
            this.disableSeoLink = true;
          }
          else {
            this.disableSeoLink = false;
          }
        } else {
          this.disableSeoLink = true;
        }
      }

      this.loadingSeoLinkBtn = false;
    });

    //Get api automateDataState
    // parseInt(localStorage.getItem("IS_AUTOMATE_DATASTATE")) > 0
    if (true) {
      this.isAutomateDataState = true;
      this.dataStateService.getAutomateDataState().subscribe(resp => {
        if (resp.result) {
          this.autoDataState = resp.result;
          if ((this.currentState == undefined || this.currentState == null) && this.profileModel)
            this.currentState = this.autoDataState.find(x => x.automateDataStateId == (this.profileModel.automateDataStateId ?? 1));
        }
      })
    }

    this.wpHomePageService.getListTypeDetailsAsync().pipe(
      concatMap(resp1 => {
        if (resp1.result) this.typeHomePage = resp1.result;
        return this.wpHomePageService.getFeatureAndSeasonalAct(this.id);
      })
    ).subscribe(resp => this.updateFeatureAndSeasonalAct(resp?.result ?? []));


    this.profileService.getDataFieldProfile().pipe(takeUntil(this.destroyed$))
      .subscribe(resp => {
        if (resp.result) {
          this.isHasDataField = resp.result.dataFields.length > 0 ? true : false;
          if (this.currentUrl.startsWith('/configuration/profile'))
            if (this.openByDialog && this.dialModalRef?.componentInstance) {
              this.dialModalRef.updateSize(this.isHasDataField ? '80vw' : '63vw', Helper.heightDialog());
            }
        }
      });

    this.setupDataFields();
  }

  updateFeatureAndSeasonalAct(result: WPHomePage[]) {
    let featureActId = this.typeHomePage.find(x => x.typeName == "FEATURED ACT")?.typeId ?? -1;
    let seasonalActId = this.typeHomePage.find(x => x.typeName == "SEASONAL ACT")?.typeId ?? -1;

    this.featureActHP = result?.find(x => x.typeId == featureActId) ?? {
      homePageId: 0,
      typeId: featureActId != -1 ? featureActId : 1,
      content: this.id
    } as WPHomePage;

    this.seasonalActHP = result?.find(x => x.typeId == seasonalActId) ?? {
      homePageId: 0,
      typeId: featureActId != -1 ? seasonalActId : 3,
      content: this.id
    } as WPHomePage;
  }

  toggleHiddenData() {
    this.profileService.toggleHiddenInternalData();
  }

  ngOnInit() {
    this.setupFirstLoadDetail();
    this.userService.getAllUser();
    // this.profileService.resetProfileDataChange();
    // this.profileService.resetSEODataChange();
    // this.profileService.resetContactDataChange();

    this.profileService.requestDataFieldProfile(this.id);
    this.getDynamicEntityName();

    //2022-04-13 vuonglqn add start

    //First we need to reset any change profile old profile to make sure any new data is work
    // tienlm del start 09-01-2023
    // this.settingsService.getSettingByKeyAndGroup("IS_AUTOMATE_DATASTATE", "SYSTEM").pipe(takeUntil(this.destroyed$))
    //   .subscribe(resp => {
    //     if (resp.result) {
    //       this.isAutomateDataState = (resp.result.value == "1");
    //       if (this.isAutomateDataState && this.isHasDataField) {
    //         this.profileService.isAutomateDataState$.next(this.isAutomateDataState);
    //         if (this.currentUrl.startsWith('/configuration/profile'))
    //           if (this.openByDialog && this.dialModalRef?.componentInstance) {
    //             this.dialModalRef.updateSize('80vw', Helper.heightDialog());
    //           }
    //       }
    //     }
    //   });
    // tienlm del end 09-01-2023
    this.getLastNote();
    if (true) {
      this.isAutomateDataState = true;
      if (this.isAutomateDataState && this.isHasDataField) {
        this.profileService.isAutomateDataState$.next(this.isAutomateDataState);
        if (this.currentUrl.startsWith('/configuration/profile'))
          if (this.openByDialog && this.dialModalRef?.componentInstance) {
            this.dialModalRef.updateSize('80vw', Helper.heightDialog());
          }
      }
    }
    //2022-04-13 vuonglqn add end

    this.numberTruncateToggle = window.innerWidth > 1440 ? 20 : 1;
    this.profileService.getIsHiddenInternalData().subscribe(e => {
      this.isHiddenData = e;
    })

    // this.profileService.isSaveAllChange().subscribe(e => {
    //   this.isSaveAllChange = e
    // })
    // this.profileService.profileDataChange().subscribe(e => {
    //   this.profileSaveChange = e;
    // })
    // this.profileService.seoInfoChange().subscribe(e => {
    //   this.seoChange = e;
    // })
    // this.profileService.contactChange().subscribe(e => {
    //   this.contactChange = e;
    // })
    this.profileService.refreshDataState();
    this.profileService.getDataState().subscribe(e => this.dataState = e);
    // this.settingsService.getValueByGroupName().subscribe(resp => {
    //   if (resp) {
    //     const settingPopUp = resp.find(x => x.key === 'CALL_PROGRESS_DISPLAY');
    //     if (settingPopUp) {
    //       this.popUpSetting = settingPopUp;
    //     }
    //   }
    // });
    // this.settingsService.getSettingByKeyAndGroup("CALL_PROGRESS_DISPLAY", "SYSTEM").subscribe(resp => {
    //   if (resp.result) {
    //     this.popUpSetting = resp.result;
    //   }
    // });
    this.updateInformationService.isAnyUpdateInformation(this.id).subscribe(resp => {
      if (resp.result) {
        this.isHasUpdateNew = true;
      }
    })
    //2022=01=12 hmtien add start
    //configuration/profile
    if (this.data.flag != null) // open profile detail component from profile manager component
    {
      this.dataEntryRole = this.data.dataEntryRole;
      this.flag = this.data.flag;
      this.userRolesId = this.data.userRolesId;
    } else { // open profile detail component from profile manager component
      //configuration/profile/{id}
      this.route.data.subscribe(item => {
        if (item.dataEntryRole != null && item.userRolesId != null) {
          this.dataEntryRole = item.dataEntryRole.result;
          this.userRolesId = item.userRolesId.result;
          for (var i = 0; i < this.userRolesId.length; i++) {
            if (this.dataEntryRole.value.includes(this.userRolesId[i].toString())) {
              this.flag = true;
              break;
            }
          }
        }

      });
    }
    //2022-01-12 hmtien add end
    if (this.openByDialog && this.dialModalRef?.componentInstance) {
      if (this.isTabMode) {
        this.dialModalRef.afterOpened().subscribe(() => {
          var overlayBackdrops = window.document
            .querySelectorAll<any>('.cdk-overlay-backdrop.cdk-overlay-dark-backdrop.cdk-overlay-backdrop-showing');
          for (var i = 0; i < overlayBackdrops.length; i++)
            overlayBackdrops[i].classList.add('overlay-backdrop-tab-mode');
        })
      }

      this.dialModalRef.updatePosition({ right: '0', bottom: '0' });
      this.dialModalRef.updateSize('63vw', Helper.heightDialog());
    }
    this.profileService.setLoadingStatus(true);
    this.profileService.getIsLoading().subscribe(e => {
      this.isLoading = e;
    })

    // Get Popup
    this.asteriskSub = this.signalrService.messageReceived.subscribe(data => {

      if (data.dynamicDataType === 2 && !this.openByDialog1) {
        debugger
        // tslint:disable-next-line:radix
        if (this.popUpSetting) {
          if (!this.isPopupAsteriskOpen) {
            this.isPopupAsteriskOpen = !this.isPopupAsteriskOpen;
            this.dialogAsteriskRef = this.dialog.open(CallStatusModalComponent, {
              // height: '140px',
              // width: '294px',
              // panelClass: 'custom-call-status',
              hasBackdrop: false,
              data: {
                model: JSON.parse(data.data)
              }
            });
            this.dialogAsteriskRef.afterClosed().subscribe((result) => {
              this.isPopupAsteriskOpen = !this.isPopupAsteriskOpen;
              if (!result.hide) {
                this.theLastAsteriskCallStatus = null;
              }
            });
          } else {

            if (this.dialogAsteriskRef != null && this.isPopupAsteriskOpen) {
              this.dialogAsteriskRef.componentInstance.model = JSON.parse(data.data);
            }
          }
          this.theLastAsteriskCallStatus = JSON.parse(data.data);
        }
      }
    });
    // call api seoInfo
    this.loadingSeoLinkBtn = true;
    this.profileService.refreshSeoInfo(this.id);
  }

  ngAfterViewInit(): void {
    this.overlayNoteDetails = this.noteService.overlayNoteDetailsComponent;

    //Listen sub to define property for next or previous
    this.profileService.getPageFilterObs().pipe(takeUntil(this.destroyed$)).subscribe(res => {
      if (res && this.id) {
        this.profileNextOrPre.pageFilter = res.page;
        this.profileNextOrPre.currentId = this.id;
        this.profileNextOrPre.index = this.profileNextOrPre.pageFilter.size == -1
          ? res.data.findIndex(x => x.profileId == this.id)
          : res.data.findIndex(x => x.profileId == this.id) + (this.profileNextOrPre.pageFilter.pageNumber * this.profileNextOrPre.pageFilter.size);
      }
    })

    this.overlayNoteDetails.onRefreshed().pipe(
      takeUntil(this.destroyed$),
      debounceTime(2000)
    ).subscribe(resp => {
      this.overlayNoteDetails.completedLoading();
      if (resp && this.noteTab) this.noteTab.refreshData();
      this.getLastNote(resp?.id, resp?.isDeleted);
    });


  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();

    if (this.routeSub != null)
      this.routeSub.unsubscribe();

    if (this.asteriskSub != null) {
      this.asteriskSub.unsubscribe();
    }
  }

  refreshData(isLoading = false) {
    if (isLoading) this.isLoading = true;
    this.profileService.refreshData(this.id).subscribe(() => {
      this.isLoading = false;
    });
  }
  async setupFirstLoadDetail() {
    this.isLoading = true;
    var respData = await this.profileService.refreshData(this.id).toPromise();
    if (respData && respData.result) {
      this.profileService.getProfileById().pipe(
        // it is now important to unsubscribe from the subject
        takeUntil(this.destroyed$)
      ).subscribe(async data => {
        if (data.message == null) {
          this.isLoadingDataDuplicated = true;
          this.profileNextOrPre.isLoadingNext = false;
          this.profileNextOrPre.isLoadingPre = false;
          this.profileModel = data.result;
          this.isHasPrimaryContact = this.profileModel?.contactId > 0;

          this.profileModel.tagList = this.profileModel.tags !== ''
            && this.profileModel.tags != null ? this.profileModel.tags.split(',') : [];

          const profileObj = this.profileModel;
          if (profileObj.profileContacts && profileObj.profileContacts.length > 0) {
            this.profileModel.contact =
              profileObj.contact ? profileObj.contact :
                profileObj.profileContacts.find(i => i.contact.primaryContact === true) === undefined ? profileObj.profileContacts[0].contact :
                  profileObj.profileContacts.find(i => i.contact.primaryContact === true).contact;

            var listEmailContact = new ListEmailContact();
            for (const item of profileObj.profileContacts) {
              if (item?.contact?.contactEmail) {
                listEmailContact.emails.push(item?.contact?.contactEmail);
              }
            }
            await this.checkListEmailOutLook(listEmailContact);
            this.isLoadingDataDuplicated = false;
            if (this.listEmailRelationship.length == 0 && this.isContactsRelationship) {
              this.isContactsRelationship = false;
            }
          }

          this.currentState = this.isAutomateDataState
            ? this.autoDataState.find(x => x.automateDataStateId == (this.profileModel.automateDataStateId ?? 1))
            : this.dataState.find(x => x.dataStateId == this.profileModel.dataState.toString());

          var arrayRole = [this.user.role.toString()];
          arrayRole = [...arrayRole[0].split(',')];
          var existingRoleAdmin = arrayRole.find(x => x == "admin");
          if (existingRoleAdmin == "admin") {
            this.readonlyProfile = false;
          } else {
            if (data.result.ownerId !== this.user.nameid) {
              // check additional permission (by pass permission)
              this.settingsService.checkByPassUserSetting(this.user.nameid).subscribe(resp => {
                if (resp.result) {
                  this.readonlyProfile = false;
                  this.bypassPermissions = true;
                } else {
                  this.readonlyProfile = true;
                  this.toast.warning('This profile not belongs to this user, you can read-only', 'Notification');
                }
              })

            } else {
              this.readonlyProfile = false;
            }
          }
        } else {
          if (!this.openByDialog && this.currentUrl.includes('/profile')) {
            this.router.navigateByUrl('/auth');
          }
        }
        this.isLoading = false;
        this.checkDuplicateDisplayName(this.profileModel.displayName);
      });
    }
  }

  editWebPage(data) {
    let profileRequest: ProfileRequest = new ProfileRequest();
    profileRequest.webPage = data;
    this.profileService.editProfile(this.id, profileRequest).subscribe((result: ReturnResult<boolean>) => {
      if (result.result) {
        this.isRefreshing = true;
        this.toast.success('Saved');
      }
      else this.toast.danger('Failed')
    }).add(() => {
      this.refreshData();
    })
  }
  editDisplayName(data) {
    if (data === "") {
      this.toast.danger('Failed');
      return;
    };
    let profileRequest: ProfileRequest = new ProfileRequest();
    profileRequest.displayName = data;
    this.profileService.editProfile(this.id, profileRequest).subscribe((result: ReturnResult<boolean>) => {
      if (result.result) {
        this.isRefreshing = true;
        this.toast.success('Saved');
      }
      else this.toast.danger('Failed')
    }).add(() => {
      this.refreshData();
    })
  }
  editRealName(data) {
    if (data === "") {
      this.toast.danger('Failed');
      return;
    };
    let profileRequest: ProfileRequest = new ProfileRequest();
    profileRequest.realName = data;
    this.profileService.editProfile(this.id, profileRequest).subscribe((result: ReturnResult<boolean>) => {
      if (result.result) {
        this.isRefreshing = true;
        this.toast.success('Saved');
      }
      else this.toast.danger('Failed')
    }).add(() => {
      this.refreshData();
    })
  }
  activeToggle() {
    let profileRequest: ProfileRequest = new ProfileRequest();
    profileRequest.isActive = !this.profileModel.isActive;
    this.profileService.editProfile(this.id, profileRequest).subscribe((result: ReturnResult<boolean>) => {
      if (result.result) {
        this.isRefreshing = true;
        this.toast.success('Saved');
      }
      else this.toast.danger('Failed')
      //    this.refreshData();
    }).add(() => {
      this.refreshData();
    });
  }
  publishedToggle() {
    let profileRequest: ProfileRequest = new ProfileRequest();
    profileRequest.published = !this.profileModel.published;
    this.profileService.editProfile(this.id, profileRequest).subscribe((result: ReturnResult<boolean>) => {
      if (result.result) {
        this.isRefreshing = true;
        this.toast.success('Saved');
        this.profileService.requestDataFieldProfile(this.profileModel.profileId);
      }
      else this.toast.danger('Failed')
      this.refreshData();

    },
      err => { this.refreshData() })
  }
  editDataState(id: number) {
    let profileRequest: ProfileRequest = new ProfileRequest();
    profileRequest.dataState = id;
    debugger
    this.profileService.editProfile(this.id, profileRequest).subscribe((result: ReturnResult<boolean>) => {
      if (result.result) {
        this.isRefreshing = true;
        this.toast.success('Saved');
        this.matMenuTrigger.closeMenu();
      }
      else this.toast.danger('Failed')
      this.refreshData();
    },
      err => { this.refreshData() })
  }

  editAutoDataState(id: number) {
    let profileRequest: ProfileRequest = new ProfileRequest();
    profileRequest.automateDataStateId = id;

    this.profileService.editProfile(this.id, profileRequest).subscribe((result: ReturnResult<boolean>) => {
      if (result.result) {
        this.isRefreshing = true;
        this.profileService.requestDataFieldProfile(this.profileModel.profileId);
        this.toast.success('Saved');
        this.matMenuTrigger.closeMenu();
      }
      else this.toast.danger('Failed')
      this.refreshData();
    },
      err => { this.refreshData() })
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    // this.onTabChange(true);
    if (this.router.url.includes('/configuration/profile')) {
      Helper.handleTabChangedUrl(this.selectedIndex, '/configuration/profile');
      this.tabModeService.setTagIndexToCurrentTab(tabChangeEvent.index, this.id);
    }
  }
  tabOnClick() {
    // this.onTabChange();

  }
  async closeDialog() {
    // if (this.isSaveAllChange) {
    //   this.onConfirm();
    // }
    // else {
    this.onClose.emit(this.isRefreshing);
    if (this.dialModalRef?.componentInstance) {
      this.dialModalRef.close(this.isRefreshing);
      this.dialModalRef.afterClosed().subscribe(e => {
        if (this.parentId) {
          this.profileService.setLoadingStatus(true);
          this.isLoading = true
          this.profileService.refreshData(this.data.parentId).subscribe(e => {
            this.isLoading = false
          })
        } else {
          // remove tab cache:
          this.tabModeService.removeCurrentTabTag();
          // if (this.router.url.includes(`configuration/profile`)) window.history.back();
        }
      })
    } else {
      if (Helper.checkUrlDetailObj('profile')) {
        var urlBack = Helper.popBackURL() || `/configuration/profile`;
        var backTab = this.tabModeService.getTabByUrl(urlBack);
        if (backTab) await this.tabModeService.createNewTab(backTab.tabUrl, backTab.tabName, backTab.tabType, true, true);
        let isMatchUrlTab = Helper.findLastIndex(this.tabModeService.tabData, 'tabUrl', urlBack);
        if (isMatchUrlTab != -1) {
          this.tabModeService.activeTabObservable.next(isMatchUrlTab);
        }
        this.router.navigate([urlBack]);
      }
    }
    // }
  }
  setEditMode(mode: boolean) {
    this.isEditing = mode;
  }
  // 2021-09-07 tien add start
  getAvt(): Media {
    const media = this.profileModel.media;
    return media;

  }
  // 2021-09-07 tien add end
  //2021-09-09 hieuvm start add
  editSocialAccount(data) {
    let profileRequest: ProfileRequest = new ProfileRequest();
    profileRequest[data.fieldName] = data.data;
    this.profileService.editProfile(this.id, profileRequest).subscribe((result: ReturnResult<boolean>) => {
      if (result.result) {
        this.isRefreshing = true;
        this.toast.success('Saved');
      }
      else this.toast.danger('Failed')
    }).add(() => {
      this.refreshData();
    })
  }
  //2021-09-09 hieuvm end add
  // 2021-09-13 tien add start
  popProfileToNewPage() {
    if (this.router.url.includes('view')) {
      const trueUrl = this.router.url.split('/view')[0];
      // window.open(`${trueUrl}/${this.profileModel.profileId}`, '_blank');
      this.tabModeService.createNewTab(`/configuration/profile/${this.id}`, `Profile #${this.id.substring(0, 5)}..`, TabTypeModeEnums.Link);

    } else {
      // if (this.router.url.includes('/configuration/profile'))
      //   window.open(`${this.router.url}/${this.profileModel.profileId}`, '_blank');
      // else window.open(`/configuration/profile/${this.profileModel.profileId}`, '_blank');
      this.tabModeService.createNewTab(`/configuration/profile/${this.id}`, `Profile #${this.id.substring(0, 5)}..`, TabTypeModeEnums.Link);

    }
  }
  // 2021-09-13 tien add end

  //2021-10-19 vuonglqn add start
  //edit favorite, popular, top art.
  changeArtToggle(prop: string) {
    let profileRequest: ProfileRequest = new ProfileRequest();
    profileRequest[prop] = !this.profileModel[prop];
    this.profileService.editProfile(this.id, profileRequest).subscribe((result: ReturnResult<boolean>) => {
      if (result.result) {
        this.isRefreshing = true;
        this.toast.success('Saved');
      }
      else this.toast.danger('Failed')
      this.refreshData();
    },
      err => { this.refreshData() })
  }
  //2021-10-19 vuonglqn add end
  onShowLastAsteriskCallStatus() {
    if (this.theLastAsteriskCallStatus) {
      this.isPopupAsteriskOpen = !this.isPopupAsteriskOpen;
      this.dialogAsteriskRef = this.dialog.open(CallStatusModalComponent, {
        // height: '140px',
        // width: '294px',
        // panelClass: 'custom-call-status',
        hasBackdrop: false,
        data: {
          model: this.theLastAsteriskCallStatus
        }
      });
      this.dialogAsteriskRef.afterClosed().subscribe((result) => {
        this.isPopupAsteriskOpen = !this.isPopupAsteriskOpen;
        if (!result.hide) {
          this.theLastAsteriskCallStatus = null;
        }
      });
    }
  }
  // 2022-02-18 tienlm add start
  showMergeChange() {
    const dialogRef = this.dialog.open(MergeChangesComponent, {
      data: {
        profile: this.profileModel
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      this.refreshData(true);
    })
  }
  dataChange(data, prop: string) {
    // this.profileService.dataChange(data, prop);
  }
  removeDataChange(prop: string) {

  }

  isEmptyProperty(obj): boolean {
    return Object.values(obj).every(value => {
      if (value === null || value === undefined) {
        return true;
      }
      return false;
    });
  }
  // onConfirm(isSaveButton: boolean = false) {
  //   var message = !isSaveButton ? 'You have unchanged information, do you wish to save all instead?' :
  //     'Do you wish to save all changes?'
  //   const dialogRef = this.dialog.open(ConfirmSaveAllChangeComponent, {
  //     data: {
  //       message: message,
  //       falseTitle: 'Cancel',
  //       yestTitle: 'Yes'
  //     }
  //   });
  //   const isNeedReload: boolean = !isSaveButton;
  //   dialogRef.afterClosed().subscribe(response => {
  //     if (response !== undefined) {
  //       if (response) {
  //         var profileData = JSON.parse(JSON.stringify(this.profileSaveChange));
  //         if (this.profileSaveChange.bios != null && this.profileSaveChange.bios.length > 0) {
  //           debugger;
  //           this.saveBios(isNeedReload);
  //         }
  //         if (this.profileSaveChange.reason != null && this.profileSaveChange.reason.length > 0) {
  //           this.saveReason(isNeedReload);
  //         }
  //         this.editProfile(profileData);
  //         if (!isSaveButton)
  //           this.dialModalRef.close(this.isRefreshing);
  //         this.saveSEO(true);
  //         this.saveContact(true);
  //       }
  //       else {
  //         this.profileService.resetProfileDataChange();
  //         this.profileService.resetSEODataChange();
  //         this.profileService.resetContactDataChange();
  //         this.refreshData(true);
  //         if (!isSaveButton)
  //           this.dialModalRef.close(this.isRefreshing);
  //       }
  //     }
  //   })
  // }

  // onTabChange(isClickTab: boolean = false) {
  //   if (this.isSaveAllChange) {
  //     const dialogRef = this.dialog.open(ConfirmSaveAllChangeComponent, {
  //       data: {
  //         message: 'You have unchanged information, do you wish to save all instead?',
  //         isShowCancel: !isClickTab
  //       }
  //     });
  //     dialogRef.afterClosed().subscribe(response => {
  //       if (response !== undefined) {
  //         if (response) {
  //           var profileData = JSON.parse(JSON.stringify(this.profileSaveChange));
  //           if (this.profileSaveChange.bios != null && this.profileSaveChange.bios.length > 0) {
  //             this.saveBios(true);
  //           }
  //           if (this.profileSaveChange.reason != null && this.profileSaveChange.reason.length > 0) {
  //             this.saveReason(true);
  //           }
  //           this.editProfile(profileData);
  //           this.saveSEO(true);
  //           this.saveContact(true);
  //         }
  //         else {
  //           this.profileService.resetProfileDataChange();
  //           this.profileService.resetSEODataChange();
  //           this.profileService.resetContactDataChange();
  //           this.refreshData(true);
  //         }
  //       }
  //     })
  //   }
  // }

  editProfile(profileData) {
    profileData.reason = null;
    profileData.bios = null;
    profileData.setList = null;
    profileData.previousClients = null;
    profileData.testimonials = null;
    if (!this.profileService.isEmptyProperty(profileData)) {
      this.profileService.editProfile(this.id, profileData).subscribe(e => {

        if (e.result) {
          // this.profileService.resetProfileDataChange();
          this.toast.success('Saved');
          this.refreshData(true)

        }
        else this.toast.danger('Failed')
      },
        err => {
          this.refreshData(true)
        })
    }
  }
  saveReason(isLoading: boolean = false) {
    if (this.profileSaveChange.reason) {
      var reason: ReasonRequest[] = JSON.parse(JSON.stringify(this.profileSaveChange.reason));

      const len = reason.length;
      if (len > 0 && isLoading) this.isLoading = true;

      reason.forEach((e, index) => {
        if (e.reasonId == 0) {
          //add case
          this.profileService.addReason(e).subscribe(rs => {
            this.profileService.removeReason(e);
            if (len - 1 == index) {
              if (rs.result) {
                this.toast.success('Saved');
              }
              else this.toast.danger('Failed')
              this.reasonService.refreshData(this.profileModel.profileId).subscribe(e => {
                this.isLoading = false;
              });
            }
          });

        }
        else {
          //edit case
          this.profileService.editReason(e).subscribe(rs => {
            this.profileService.removeReason(e);
            if (len - 1 == index) {
              if (rs.result) {
                this.toast.success('Saved');
              }
              else this.toast.danger('Failed')
              this.reasonService.refreshData(this.profileModel.profileId).subscribe(e => {
                this.isLoading = false;

              });
            }

          });
        }
      })

    }
  }
  saveBios(isLoading: boolean = false) {
    if (this.profileSaveChange.bios) {
      const len = this.profileSaveChange.bios.length;
      if (len > 0 && isLoading) this.isLoading = true;
      this.profileSaveChange.bios.forEach((e, index) => {
        this.profileService.editBioRewrite(this.profileModel.profileId, e.content, e.id, e.priority ?? null).subscribe(rs => {
          this.profileService.removeBioRewrite(e);
          if (len - 1 == index) {
            if (rs.result) {
              this.toast.success('Saved');
            }
            else this.toast.danger('Failed')
            this.reasonService.refreshData(this.profileModel.profileId).subscribe(e => {
              this.isLoading = false;
            });
          }
        });
      })
    }
  }
  // saveSubPropProfile(prop: string, data: string) {
  //   debugger;
  //   this.reasonService.setProfileSubProperty(this.profileModel.profileId,prop,data).subscribe(e => {
  //     this.profileService.refreshData(this.profileModel.profileId);
  //   })
  // }

  clickNextOrPre(modelNextOrPre: any, isNext: boolean) {
    this.profileNextOrPre = modelNextOrPre;
    // this.profileService.resetProfileDataChange();
    // this.profileService.resetSEODataChange();
    // this.profileService.resetContactDataChange();

    this.profileService.nextOrPreProfile(this.profileNextOrPre, isNext).subscribe(res => {
      if (res) {
        this.id = res.result['currentId'];
        Helper.cancelNavigateNoTrackingHistory(`/configuration/profile/${this.id}`);
        this.wpHomePageService.getListTypeDetailsAsync().pipe(
          concatMap(resp1 => {
            if (resp1.result) this.typeHomePage = resp1.result;
            return this.wpHomePageService.getFeatureAndSeasonalAct(this.id);
          })
        ).subscribe(resp => this.updateFeatureAndSeasonalAct(resp?.result ?? []));

        this.profileNextOrPre.currentId = res.result['currentId'];
        this.profileNextOrPre.pageFilter = res.result['pageFilter'];
        this.profileNextOrPre.index = res.result['index'];
        this.profileService.requestDataFieldProfile(this.id);
        this.refreshData(true);
        this.lastNote = null;
        this.getLastNote()
        this.profileService.refreshSeoInfo(this.id);
      }
    });
  }
  saveSEO(isLoading: boolean = false) {
    if (this.seoChange) {
      const len = this.seoChange.length;
      if (len > 0 && isLoading) this.isLoading = true;
      this.seoChange.forEach((e, index) => {
        this.profileService.editSeoInfo(e, this.profileModel.profileId).subscribe(rs => {
          this.profileService.removeSeo(e);
          if (len - 1 == index) {
            if (rs.result) {
              this.toast.success('Saved');
              //this.profileService.seoInfoChange$.next([]);
            }
            else this.toast.danger('Failed')
            this.reasonService.refreshData(this.profileModel.profileId).subscribe(e => {
              this.isLoading = false;
            });
          }
        });
      })
    }
  }
  saveContact(isLoading: boolean = false) {
    if (this.contactChange) {
      const len = this.contactChange.length;
      if (len > 0 && isLoading) this.isLoading = true;
      this.contactChange.forEach((e, index) => {
        this.contactService.editInlineContact(e).subscribe(rs => {
          this.profileService.removeContact(e);
          if (len - 1 == index) {
            if (rs.result) {
              this.toast.success('Saved');
              this.profileService.contactChange$.next([]);
            }
            else this.toast.danger('Failed')
            this.profileService.refreshData(this.profileModel.profileId).subscribe(e => {
              this.isLoading = false;
            });
          }
        });
      })
    }
  }
  reloadProfile(bool) {
    this.refreshData(bool);
  }

  //2022-03-25 vuonglqn add start
  @HostListener('window:resize', ['$event'])
  onResizeTextSwitch(event) {
    if (event) {
      this.numberTruncateToggle = event.target.innerWidth > 1440 ? 20 : 1;
    }
  }

  masterToggle(event: boolean) {
    if (!this.profileModel.isMaster) {
      this.checkIsMaster = true;
    }
    if (this.profileModel.actGroupId)
      this.profileService.resetIsMasterActGroup(this.profileModel.actGroupId).pipe(first())
        .subscribe(respReset => {
          this.checkIsMaster = false;
          if (respReset.result) {
            this.updateMaster();
          } else {
            this.toast.danger("Reset master flag failed.", "Error")
          }
        });
    else {
      this.updateMaster();
      this.checkIsMaster = false;
    }
  }

  updateMaster() {
    let profileRequest: ProfileRequest = new ProfileRequest();
    profileRequest.isMaster = !this.profileModel.isMaster;
    this.profileService.editProfile(this.id, profileRequest).pipe(first()).subscribe((result: ReturnResult<boolean>) => {
      if (result.result) {
        this.isRefreshing = true;
        this.toast.success('Saved');
      }
      else this.toast.danger('Failed')
      this.refreshData();
      if (this.selectedIndex == TabProfile.Relationship)
        this.profileService.refreshProfileRelationship(this.profileModel.profileId).subscribe();
    },
      err => { this.refreshData() })
  }
  //2022-03-25 vuonglqn add end
  //2022-04-12 tienlm add start
  openSeoLink() {
    if (this.primarySeoLink && this.defaultSeoLinkDomain) {
      this.clipboard.copy(`${this.defaultSeoLinkDomain}/act/${this.primarySeoLink}`);
      window.open(`${this.defaultSeoLinkDomain}/act/${this.primarySeoLink}`, "_blank");
    } else {
      this.loadingSeoLinkBtn = true;
      let announceError = [false, false];
      if (!this.defaultSeoLinkDomain) {
        // this.settingsService.getDefaultDomainList();
        announceError[0] = true;
      }
      if (!this.primarySeoLink) {
        this.profileService.refreshSeoInfo(this.id);
        announceError[1] = true;
      }
      if (announceError[0] && announceError[1]) {
        this.toast.warning('This seo link cannot be opened right now. Please  try again later', 'Warning');
      } else if (!announceError[0] && announceError[1]) {
        this.toast.warning('No seo link found right now. Please  try again later', 'Warning');
      } else if (announceError[0] && !announceError[1]) {
        this.toast.warning('Trouble with finding front-facing domain. Please  try again later', 'Warning');
      }
    }
  }
  addProfileFile(fileInputEvent: any) {
    this.uploading = true;
    this.uploadedFile = fileInputEvent.target.files[0];
    let acceptanceExtension = ["doc", "docx", "xls", "xlsx", "ppt", "pdf", "html", "zip", "csv", "txt"];
    let extension = Helper.getFileExtension(fileInputEvent.target.files[0].name) ? Helper.getFileExtension(fileInputEvent.target.files[0].name)[0] : null;
    let IsAccept = extension ? (acceptanceExtension.indexOf(extension) == -1 ? false : true) : false;
    if (IsAccept) {
      let submitModel = new UserUpload();
      submitModel.fileName = this.uploadedFile.name;
      submitModel.referenceType = "SALEPROFILE";
      submitModel.referenceId = this.profileModel.profileId;
      submitModel.owners = this.user.nameid;
      this.userUploadService.uploadFile(this.uploadedFile, submitModel).pipe(this.hotToast.observe(
        {
          loading: 'Uploading file...',
          success: (s) => 'file successfully uploaded',
          error: (e) => 'An error occurred while uploading...'
        }
      )).subscribe({
        next: res => {
          if (res.result) {
            this.uploading = false;
            // this.toast.success('Upload file successfully', 'Success');
            this.fileTab?.refreshData(true);
          }
        },
        complete: () => {
        }
      });
    } else {
      this.toast.warning('Please select file with these extensions: doc,docx,xls,xlsx,ppt,pdf,html,zip,csv,txt', "Warning");
    }
    this.nbFilesPopover.hide();
  }

  copyToClipboard() {
    const url = window.location.href.split('configuration')[0] + `configuration/profile/${this.id}`;
    this.clipboard.copy(url);
    this.toast.info('Copied this ' + this.subjectProfile + ' profile url to clipboard', 'Success');
  }
  copyGUIDToClipboard() {
    this.clipboard.copy(this.id);
    this.toast.success('Copied this ' + this.subjectProfile + ' profile id to clipboard', 'Success');
    this.isCopy = true;
    setTimeout(() => {
      this.isCopy = false;
    }, 2000);
  }
  //2022-04-12 tienlm add end
  checkDuplicateDisplayName(displayName: string) {
    //this.duplicateIconLoading = true;
    this.profileService.checkDuplicateDisplayName(displayName).subscribe(e => {
      this.isDuplicate = e.result;
      // this.duplicateIconLoading = false;
    })
  }

  saveFeatureOrSeasonalAct(value: WPHomePage) {
    if (this.id && value && value.homePageId == 0) {
      let data = {
        homePageId: 0,
        typeId: value.typeId,
        content: this.id,
        order: 0
      } as WPHomePage;

      this.wpHomePageService.saveFeatureAndSeasonalAct(data).subscribe(resp => {
        if (resp.result) this.toast.success('Save success!', 'Success');
      }).add(() =>
        this.wpHomePageService.getFeatureAndSeasonalAct(this.id)
          .subscribe(resp => this.updateFeatureAndSeasonalAct(resp?.result ?? []))
      );

    } else if (value && value.homePageId > 0) {
      this.wpHomePageService.removeHomePages([value.homePageId]).subscribe(resp => {
        if (resp.result) this.toast.success('Save success!', 'Success');
      }).add(() =>
        this.wpHomePageService.getFeatureAndSeasonalAct(this.id)
          .subscribe(resp => this.updateFeatureAndSeasonalAct(resp?.result ?? [])));
    }

    if (JSON.stringify(value) === JSON.stringify(this.featureActHP))
      this.featureActHP.homePageId = -1;

    if (JSON.stringify(value) === JSON.stringify(this.seasonalActHP))
      this.seasonalActHP.homePageId = -1;
  }

  toggleReviewableState() {
    if (this.profileModel.isActive) {
      return false;
    }
    return true;
  }
  copyPreviewFrontFacingSite() {
    if (this.defaultSeoLinkDomain) {
      this.clipboard.copy(`${this.defaultSeoLinkDomain}/preview/?pid=${this.id}`);
      this.toast.info('Copied preview site to clipboard', 'Success');
    } else {
      this.toast.warning('Copy preview site to clipboard failed.', 'Error');
    }
  }

  setupDataFields() {
    if (!this.currentUrl.includes('task')
      && !this.currentUrl.includes('dashboard')
      && !this.currentUrl.includes('call-event')
      && !this.currentUrl.includes('auto-assignment-report')
    )
      this.dataFieldsService.getDisplayDataByScreen("ProfileDetails").subscribe(resp => {
        if (resp.result) this.tooltipProp = [...Helper.formatDisplayColumn(this.tooltipProp, resp.result, 'key', 'value')];
      });

    if (this.currentUrl.includes('dashboard-seo'))
      this.dataFieldsService.getDisplayDataByScreen("ProfileDetails").subscribe(resp => {
        if (resp.result) this.tooltipProp = [...Helper.formatDisplayColumn(this.tooltipProp, resp.result, 'key', 'value')];
      });
  }
  getDynamicEntityName() {
    this.dynamicName = window.localStorage.getItem('subject_profile') ? window.localStorage.getItem('subject_profile') : '';
    if (this.dynamicName && this.dynamicName != '') {
      this.dynamicName = this.dynamicName.charAt(0).toUpperCase() + this.dynamicName.slice(1);
    }
  }

  nbPopoverCLick() {
    this.nbFilesPopover.show();
  }

  updatePrimaryContact(contactLst: Contact[]) {
    if (this.profileModel) {
      let primaryContact = contactLst.find(x => x.primaryContact == true);
      //this.totalContacts = contactLst.map((x: any) => x.contact);
      if (primaryContact) {
        this.profileModel.contact = primaryContact;
        this.profileModel.contactId = primaryContact.contactId;
        this.profileModel.accountContactId = primaryContact.contactId;
        this.isHasPrimaryContact = this.profileModel.contact && !this.profileModel.contact.deleted ? true : false;
      } else {
        this.profileModel.contact = null;
        this.profileModel.contactId = null;
        this.profileModel.accountContactId = 0;
        this.isHasPrimaryContact = false;
      }
      this.profileModel = { ...this.profileModel };
    }
  }
  getCacheTab() {
    let currentTabMode = this.tabModeService.getCurrentActiveTab();
    let tag: string = null;
    if (currentTabMode) {
      let tabTagIndex: TabTagIndexModel = this.tabModeService.getTabTagIndex(currentTabMode.tabId);
      if (tabTagIndex && tabTagIndex.uniqueId && typeof tabTagIndex.uniqueId == 'string' && tabTagIndex.uniqueId == this.id) {
        tag = tabTagIndex.tag;
      }
    }
    return tag;
  }
  getLastNote(resp?: number, isDelete: boolean = false) {
    if (this.lastNote && this.lastNote.id == resp && isDelete)
      this.lastNote = null;

    if ((this.lastNote && this.lastNote.id <= resp && this.lastNote.referenceId == this.id) || !this.lastNote)
      this.noteService.lastNoteByReferenceId(this.id, ShadowProfileEnum[ShadowProfileEnum.SALEPROFILE])
        .subscribe({
          next: resp => {
            if (resp.result)
              this.lastNote = Object.assign(resp.result, {
                innerTextNote: Helper.removeStyleHtml(resp.result.note),
                ownerNote: (`${resp.result.user?.firstName || ''} ${resp.result.user?.lastName || ''}`).trim() || resp.result.user?.userName || 'Unknown',
                avatarNote: resp.result.user?.pictureURL?.replace(/\\/g, '/'),
                //referenceName: this.saleLeadModel.displayName
              })
          },
          //complete: () => this.overlayNoteDetails.completedLoading()
        });
  }
  createTask() {
    const dialogRef = this.dialog.open(AddEditTaskComponent, {
      disableClose: true,
      height: '100vh',
      width: '600px',
      panelClass: 'dialog-detail',
      autoFocus: false,
      data: {
        action: TblActionType.Add,
        profile: this.profileModel,
      }
    });
    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        // this.toast.success('Create task success!', 'Success');
        this.refreshData();
        if (this.taskGrid) this.taskGrid.refreshData();

      }
    });
  }

  copyConsentLink() {
    if (!this.defaultSeoLinkDomain) this.toast.warning("Please setup url in setting", "Warning");
    else {
      this.clipboard.copy(`${this.defaultSeoLinkDomain.endsWith("/")
        ? this.defaultSeoLinkDomain
        : this.defaultSeoLinkDomain + '/'
        }terms-and-conditions/?pid=${this.id}`);
      this.toast.info(`Copied url successfully`, 'Success')
    }
  }
  addNewProfileSeo() {
    this.profileService.getFriendlyUniqueUrl(this.profileModel?.displayName).subscribe((res: ReturnResult<string>) => {
      if (res.result) {
        const dialogRef = this.dialog.open(AddNewSeoComponent, {
          data:
          {
            id: this.profileModel?.profileId,
            displayName: this.profileModel?.displayName,
            friendlyUrl: res.result,
            tooltipProp: this.tooltipProp
          }
        });
        dialogRef.afterClosed().subscribe(resp => {
          if (resp) {
            this.toast.success(`Save successfully`, "Success");
            if (this.profileSeo) {
              this.profileSeo.refreshData();
            }

          }
        });
      }
    })

  }
  openURLDialogRef() {
    const dialogRef = this.dialog.open(UploadUrlComponent, {
      width: '30vw',
      maxWidth: '30vw',
      data: {
        profileId: this.profileModel.profileId,
        nameId: this.user.nameid,
        referenceType: "SALEPROFILE"
      }
    });
    dialogRef.afterClosed().subscribe(resp => {
      if (resp) {
        this.fileTab?.refreshData(true);
      }
    });
  }
  onRefresh(bool) {
    this.refreshData();
  }
  async checkListEmailOutLook(listEmailContact: ListEmailContact) {
    try {
      this.isShowPopover = true;
      const listRelationship: EmailRelationship[] = [];

      var resp = await this.contactService.lookupListEmailOutLook(listEmailContact).toPromise();
      if (resp.result) {
        Object.entries(resp.result).forEach(([key, value]: [string, LookUpListEmailOutlook]) => {
          let contacts = value.contacts || [];
          let isCheckRelationship = contacts.some(x => x?.relationship?.length > 0);
          let isCheckRelationshipMoreThan1 = contacts.some(x => x?.relationship?.length > 1);
          if ((isCheckRelationship && contacts.length > 1) || (isCheckRelationshipMoreThan1 && contacts.length > 0)) {
            this.isContactsRelationship = true;
            let relationships = Helper.convertTwoToOneArr(contacts, (x) => x.relationship);
            // Check duplicate relationship
            if (relationships) {
              if (listRelationship.length > 0) {
                for (const item of relationships) {
                  const checkDuplicate = listRelationship.some(x => x.id == item.id);
                  if (!checkDuplicate) {
                    listRelationship.push(item);
                  }
                }
              }
              else {
                listRelationship.push(...relationships);
              }
            }
          }
        })
        if (listRelationship) {
          var filterList = listRelationship.filter(x => x.id !== this.profileModel?.profileId);
          if (filterList) {
            this.listEmailRelationship = filterList.sort(Helper.sortBy('type', SortOrderType.DESC));
          }
        }
        this.setColorCodeTypeName();
      }
    }
    catch (ex) {
      console.log(ex);
    }
  }
  openProfileRelationshipDetail(emailRelationship: EmailRelationship) {
    this.isShowPopover = false;
    window.open(`/configuration/${emailRelationship.typeRedirect}/${emailRelationship.id}`, '_blank');
    this.isShowPopover = true;
  }
  setColorCodeTypeName() {
    if (this.listEmailRelationship && this.listEmailRelationship.length > 0) {
      for (var item of this.listEmailRelationship) {
        if (item.type !== null) {
          switch (item.type) {
            case 'SALEPROFILE':
              item.colorCode = '#808080';
              item.displayTypeName = this.environment.titleProfile;
              item.typeRedirect = 'profile';
              break;
            case 'SALEACCOUNT':
              item.colorCode = '#808000';
              item.displayTypeName = this.environment.titleAccount;
              item.typeRedirect = 'sale-account';
              break;
            case 'OPPORTUNITY':
              item.colorCode = '#004d4d';
              item.displayTypeName = this.environment.titleOpportunity;
              item.typeRedirect = 'opportunity';
              break;
            case 'LEADS':
              item.colorCode = '#5c5c8a';
              item.displayTypeName = this.environment.titleLead;
              item.typeRedirect = 'sale-lead';
              break;
            default:
              break;
          }
        }
      }
    }
  }

  clickSEOAudit() {
    this.windowService.open(this.seoAuditTemp, {
      hasBackdrop: false,
      initialState: NbWindowState.MAXIMIZED,
      title: this.profileModel.displayName,
      buttons: {
        minimize: true,
        maximize: true,
        fullScreen: false,
      }
    });
  }

  focusSEOAuditElement: (key: string) => void =
    (key: string) => {
      try {
        if (key) {
          let group = this.focusSEOAudit[key];
          if (group) {
            switch (group) {
              case 'seo':
                this.selectedIndex = this.selectedIndex + this.tabIndexSeo.position;
                break;
              case 'media':
                this.selectedIndex = this.selectedIndex + this.tabIndexMedia.position;
                break;
              case 'displayName':
                if (this.displayNameProp?.nativeElement) {
                  this.displayNameProp.nativeElement.classList.add('action-seo-focus');
                  setTimeout(() => this.displayNameProp.nativeElement.classList.remove('action-seo-focus'), 5000);
                }
                break;
            }
          }
        }
      }
      catch (ex) {
        console.log(ex);
      }
    }
}
