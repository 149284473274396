import { NbMenuItem } from '@nebular/theme';
import { ExtendNbMenuItem } from 'src/app/shared/components/stand-alone-component/admin-tab-mode/tab-mode.model';
import { environment } from 'src/environments/environment';
//  allowOpenTab: will allow open tab or not based on logic:
//  default open tab if: null || empty || true
// default not allow open if: allowOpenTab = false
export const MENU_ITEMS: ExtendNbMenuItem[] = [
  {
    title: 'Dashboard',
    icon: 'home-outline',
    link: '/configuration/dashboard',
    allowOpenTab: false,
    data: {
      permission: 'view',
      resource: 'configuration-dashboard'
    },
  },
  {
    title: 'Dashboard',
    icon: 'home-outline',
    allowOpenTab: false,
    link: '/configuration/dashboard-crm',
    data: {
      permission: 'view',
      resource: 'configuration-dashboard-crm'
    },
  },
  {
    title: 'Sale Dashboard',
    icon: 'home-outline',
    link: '/configuration/dashboard-sale',
    allowOpenTab: false,
    data: {
      permission: 'view',
      resource: 'configuration-dashboard-sale'
    },
  },
  {
    title: 'SEO Dashboard',
    icon: 'home-outline',
    link: '/configuration/dashboard-seo',
    allowOpenTab: false,
    data: {
      permission: 'view',
      resource: 'configuration-dashboard-seo'
    },
  },
  {
    title: 'Contact Map',
    icon: 'globe-2-outline',
    link: '/configuration/dashboard-contact-map',
    data: {
      permission: 'view',
      resource: 'configuration-dashboard-contact-map'
    },
  },
  {
    link: '/configuration/task',
    title: 'Task',
    icon: 'archive-outline',
    data: {
      permission: 'view',
      resource: 'configuration-task'
    },
  },
  {
    title: 'Artist Profile',
    icon: 'book-outline',
    link: '/configuration/profile',
    data: {
      permission: 'view',
      resource: 'configuration-profile'
    }
  },
  {
    title: 'Buyer',
    icon: 'pantone-outline',
    link: '/configuration/buyer',
    data: {
      permission: 'view',
      resource: 'configuration-buyer'
    }
  },
  {
    title: 'Saleleads',
    icon: 'credit-card-outline',
    link: '/configuration/sale-lead',
    data: {
      permission: 'view',
      resource: 'configuration-sale-lead'
    }
  },
  {
    title: 'Opportunities',
    icon: 'done-all-outline',
    link: '/configuration/opportunity',
    data: {
      permission: 'view',
      resource: 'configuration-opportunity'
    }
  },
  {
    title: 'Employee',
    icon: 'smiling-face-outline',
    link: '/configuration/employee',
    data: {
      permission: 'view',
      resource: 'configuration-sale-account'
    }
  },
  {
    link: '/configuration/producer',
    title: 'Producer',
    icon: 'map-outline',
    data: {
      permission: 'view',
      resource: 'configuration-producer'
    }
  },
  {
    link: '/configuration/contact',
    title: 'Contact',
    icon: 'phone-call-outline',
    data: {
      permission: 'view',
      resource: 'configuration-contact'
    }
  },
  {
    link: '/configuration/category',
    title: 'Category',
    icon: 'layers-outline',
    data: {
      permission: 'view',
      resource: 'configuration-category'
    }
  },
  {
    title: 'Email',
    icon: 'flag-outline',
    data: {
      permission: 'view',
      resource: 'configuration-campaign-nav'
    },
    children: [
      {
        link: '/configuration/email-inbox',
        title: 'Email Inbox',
        icon: 'inbox-outline',
        data: {
          permission: 'view',
          resource: 'configuration-email-inbox'
        },
      },
      {
        link: '/configuration/email-history',
        title: 'Email Sent',
        icon: 'email-outline',
        data: {
          permission: 'view',
          resource: 'configuration-email-history'
        },
      },
      {
        link: '/configuration/email-scheduler',
        title: 'Email Scheduler',
        icon: 'at-outline',
        data: {
          permission: 'view',
          resource: 'configuration-email-scheduler'
        },
      },
      {
        link: '/configuration/email-template-user',
        title: 'Email Template',
        icon: 'color-palette-outline',
        data: {
          permission: 'view',
          resource: 'configuration-email-template-end-user',
        },
      },
      {
        link: '/configuration/email-campaign',
        title: 'Email Campaign',
        icon: 'paper-plane-outline',
        data: {
          permission: 'view',
          resource: 'configuration-email-campaign',
        }
      },
      {
        link: '/configuration/campaign',
        title: 'Campaign Report',
        icon: 'clock-outline',
        data: {
          permission: 'view',
          resource: 'configuration-campaign'
        },
      },
      {
        link: '/configuration/workflow',
        title: 'Workflow',
        icon: 'flip-2-outline',
        data: {
          permission: 'view',
          resource: 'configuration-workflow'
        },
      },
    ],
  },
  {
    link: '/configuration/enquiry',
    title: 'Inquiry',
    icon: 'shopping-bag-outline',
    data: {
      permission: 'view',
      resource: 'configuration-enquiry'
    },
  },
  {
    link: '/configuration/booking',
    title: 'Proposal',
    icon: 'shopping-cart-outline',
    data: {
      permission: 'view',
      resource: 'configuration-booking'
    },
  },
  {
    link: '/configuration/sms-history',
    title: 'SMS History',
    icon: 'speaker_notes',
    data: {
      permission: 'view',
      resource: 'configuration-sms-history'
    },
  },

  {
    title: 'Report',
    icon: 'bar-chart-outline',
    data: {
      permission: 'view',
      resource: 'configuration-report'
    },
    children: [
      {
        link: '/configuration/summary-report',
        title: 'Profile Summary',
        icon: 'pie-chart-outline',
        data: {
          permission: 'view',
          resource: 'configuration-summary-report'
        },
      },
      {
        link: '/configuration/activity-log',
        title: 'Activity',
        icon: 'activity-outline',
        data: {
          permission: 'view',
          resource: 'configuration-activity-log'
        },
      },
      {
        link: '/configuration/category-report',
        title: 'Category Report',
        icon: 'layers-outline',
        data: {
          permission: 'view',
          resource: 'configuration-category-report'
        },
      },
      {
        link: '/configuration/diagnostic-history',
        title: 'Diagnostic History',
        icon: 'trending-up-outline',

        data: {
          permission: 'view',
          resource: 'configuration-diagnostic-history'
        },
      },
      {
        link: '/configuration/auto-assignment-report',
        title: 'Auto Assignment Report',
        icon: 'file-text-outline',

        data: {
          permission: 'view',
          resource: 'configuration-auto-assignment-report'
        },
      },
      {
        title: 'Access Logs',
        icon: 'monitor-outline',
        link: '/configuration/access-log',
        data: {
          permission: 'view',
          resource: 'configuration-access-log'
        }
      },
      {
        title: 'Admin Queries',
        link: '/configuration/admin-queries',
        icon: 'funnel-outline',
        data: {
          permission: 'view',
          resource: 'configuration-admin-queries'
        },
      },
      {
        title: 'Datastate Report',
        link: '/configuration/data-state-report',
        icon: 'pantone',
        data: {
          permission: 'view',
          resource: 'configuration-data-state-report'
        },
      },
      {
        title: 'Sale Report',
        link: '/configuration/sales-report',
        icon: 'bar-chart',
        data: {
          permission: 'view',
          resource: 'opportunity-sale-report-button'
        },
      },
    ]
  },
  {
    title: 'Admin',
    data: {
      permission: 'view',
      resource: 'configuration-admin'
    },
    icon: 'people-outline',
    children: [
      {
        title: 'Front Facing',
        data: {
          permission: 'view',
          resource: 'configuration-wp'
        },
        icon: 'tv-outline',
        children: [
          {
            link: '/configuration/wp-homepage',
            title: 'Home Page',
            icon: 'globe-outline',
            data: {
              permission: 'view',
              resource: 'configuration-wp-homepage',
            },
          },
          {
            link: '/configuration/front-page-data',
            title: 'Front Page Data',
            icon: 'globe-outline',
            data: {
              permission: 'view',
              resource: 'configuration-front-page-data',
            },
          },
          {
            link: '/configuration/summary-indexing',
            title: 'Indexing Summary',
            icon: 'pie-chart-outline',
            data: {
              permission: 'view',
              resource: 'configuration-summary-indexing'
            },
          },
          {
            link: '/configuration/indexing-tool',
            title: 'Indexing Tool',
            icon: 'briefcase-outline',
            data: {
              permission: 'view',
              resource: 'configuration-indexing-tool'
            },
          },
          {
            link: '/configuration/indexing-tool-setting',
            title: 'Indexing Setting',
            icon: 'briefcase-outline',
            data: {
              permission: 'view',
              resource: 'configuration-indexing-tool-setting'
            },
          },
        ]
      },
      {
        title: 'Data Diagnostic',
        data: {
          permission: 'view',
          resource: 'configuration-data-diagnostic'
        },
        icon: 'trending-up-outline',
        children: [
          {
            link: '/configuration/bio-rewrite',
            title: 'Bio',
            icon: 'book-open-outline',
            data: {
              permission: 'view',
              resource: 'configuration-bio-rewrite',
            },
          },
          {
            link: '/configuration/bunny-upload-tracker',
            title: 'Bunny Upload Tracker',
            icon: 'monitor-outline',
            data: {
              permission: 'view',
              resource: 'configuration-bunny-upload-tracker'
            }
          },
          {
            link: '/configuration/call-event',
            title: 'Call Event',
            icon: 'phone-call-outline',
            data: {
              permission: 'view',
              resource: 'configuration-call-event',
            },
          },
          {
            link: '/configuration/callback-mapping',
            title: 'Callback Mapping',
            icon: 'radio-outline',
            data: {
              permission: 'view',
              resource: 'configuration-callback-mapping'
            },
          },
          {
            link: '/configuration/sale-convert-history',
            title: 'Convert History',
            icon: 'npm-outline',
            data: {
              permission: 'view',
              resource: 'configuration-sale-convert-history'
            },
          },
          {
            link: '/configuration/custom-view',
            title: 'Custom View',
            icon: 'eye-outline',
            data: {
              permission: 'view',
              resource: 'configuration-custom-view'
            },
          },
          {
            link: '/configuration/dynamic-content',
            title: 'Dynamic Content',
            icon: 'keypad-outline',
            data: {
              permission: 'view',
              resource: 'configuration-dynamic-content',
            },
          },
          {
            link: '/configuration/email-attachment',
            title: 'Email Attachment',
            icon: 'file-outline',
            data: {
              permission: 'view',
              resource: 'configuration-email-attachment',
            },
          },
          {
            link: '/configuration/file-sync-progress',
            title: 'File Sync Progress',
            icon: 'file-text',
            data: {
              permission: 'view',
              resource: 'configuration-file-sync-progress'
            },
          },
          {
            link: '/configuration/indexing-history',
            title: 'Indexing History',
            icon: 'book-outline',
            data: {
              permission: 'view',
              resource: 'configuration-indexing-history'
            },
          },
          {
            link: '/configuration/indexing-queue',
            title: 'Indexing Queue',
            icon: 'briefcase-outline',
            data: {
              permission: 'view',
              resource: 'configuration-indexing-queue'
            },
          },
          {
            link: '/configuration/indexing-progress-queue',
            title: 'Indexing Progress',
            icon: 'briefcase-outline',
            data: {
              permission: 'view',
              resource: 'configuration-indexing-progress-queue'
            },
          },
          {
            link: '/configuration/location',
            title: 'Location',
            icon: 'map',
            data: {
              permission: 'view',
              resource: 'configuration-location'
            },
          },
          {
            link: '/configuration/media',
            title: 'Media Data',
            icon: 'play-circle-outline',
            data: {
              permission: 'view',
              resource: 'configuration-media',
            },
          },
          {
            link: '/configuration/media-resize',
            title: 'Media Resize',
            icon: 'camera-outline',
            data: {
              permission: 'view',
              resource: 'configuration-media-resize',
            },
          },
          {
            link: '/configuration/onboarding-data',
            title: 'Onboarding Data',
            icon: 'eye-outline',
            data: {
              permission: 'view',
              resource: 'configuration-onboarding-data',
            },
          },
          {
            title: 'SEO Data',
            icon: 'layout-outline',
            link: '/configuration/seo-info',
            data: {
              permission: 'view',
              resource: 'configuration-seo-info'
            },
          },
          {
            link: '/configuration/system-log',
            title: 'System Files',
            icon: 'archive-outline',
            data: {
              permission: 'view',
              resource: 'configuration-system-log'
            },
          },
          {
            link: '/configuration/system-logs',
            title: 'System Logs',
            icon: 'archive',
            data: {
              permission: 'view',
              resource: 'configuration-system-logs'
            },
          },
          {
            link: '/configuration/support-ticket',
            title: 'Support Ticket',
            icon: 'headphones-outline',
            data: {
              permission: 'view',
              resource: 'configuration-support-ticket'
            },
          },

          {
            link: '/configuration/reason',
            title: 'Reason',
            icon: 'at-outline',
            data: {
              permission: 'view',
              resource: 'configuration-reason'
            },
          },
          {
            link: '/configuration/reminder-history',
            title: 'Reminder History',
            icon: 'clock-outline',
            data: {
              permission: 'view',
              resource: 'configuration-reminder-history'
            },
          },
          {
            link: '/configuration/tags',
            title: 'Tags',
            icon: 'pricetags-outline',
            data: {
              permission: 'view',
              resource: 'configuration-tags'
            },
          },
          {
            link: '/configuration/page-speed',
            title: 'Page Speed',
            icon: 'wifi-outline',
            data: {
              permission: 'view',
              resource: 'configuration-support-ticket'
            },
          },
          {
            link: '/configuration/photo-process-queue',
            title: 'Photo Queue',
            icon: 'loader-outline',
            data: {
              permission: 'view',
              resource: 'configuration-photo-process-queue'
            },
          },
          {
            link: '/configuration/user-tab',
            title: 'User Tab',
            icon: 'person-outline',
            data: {
              permission: 'view',
              resource: 'configuration-user-tab'
            },
          },
          {
            link: '/configuration/user-upload',
            title: 'User Upload',
            icon: 'upload-outline',
            data: {
              permission: 'view',
              resource: 'configuration-user-upload'
            },
          },
          {
            link: '/configuration/user-token',
            title: 'User Token',
            icon: 'eye-outline',
            data: {
              permission: 'view',
              resource: 'configuration-user-token'
            },
          },
          {
            link: '/configuration/proposal-template',
            title: 'Proposal Template',
            icon: 'file-text-outline',
            data: {
              permission: 'view',
              resource: 'configuration-proposal-template'
            },
          },
          {
            link: '/configuration/campaign-workflow',
            title: 'WF - Campaign Workflow',
            icon: 'layers',
            data: {
              permission: 'view',
              resource: 'configuration-campaign-workflow'
            }
          },
          {
            link: '/configuration/workflow-detail',
            title: 'WF - Detail',
            icon: 'file-text-outline',
            data: {
              permission: 'view',
              resource: 'configuration-workflow-detail'
            },
          },
          {
            link: '/configuration/workflow-queue',
            title: 'WF Queue',
            icon: 'swap-outline',
            data: {
              permission: 'view',
              resource: 'configuration-workflow-queue'
            },
          },
          {
            link: '/configuration/step-in-flow',
            title: 'WF - Step In Flow',
            icon: 'trending-up-outline',
            data: {
              permission: 'view',
              resource: 'configuration-step-in-flow'
            },
          },
          {
            link: '/configuration/user-action',
            title: 'WF - User Action',
            icon: 'person-done',
            data: {
              permission: 'view',
              resource: 'configuration-user-action'
            }
          },
        ]
      },
      {
        title: 'Configuration',
        data: {
          permission: 'view',
          resource: 'configuration-configurations'
        },
        icon: 'menu-2-outline',
        children: [
          {
            link: '/configuration/buyer-type',
            title: 'Buyer Type',
            icon: 'browser-outline',
            data: {
              permission: 'view',
              resource: 'configuration-buyer-type',
            },
          },
          {
            link: '/configuration/contact-type',
            title: 'Contact Type',
            icon: 'phone-outline',
            data: {
              permission: 'view',
              resource: 'configuration-contact-type',
            },
          },
          {
            link: '/configuration/data-field',
            title: 'Data Fields',
            icon: 'file-text-outline',
            data: {
              permission: 'view',
              resource: 'configuration-data-field'
            },
          },
          {
            link: '/configuration/email-template',
            title: 'Email Template',
            icon: 'color-palette-outline',
            data: {
              permission: 'view',
              resource: 'configuration-email-template',
            },
          },
          {
            link: '/configuration/data-state',
            title: 'Legacy Data State',
            icon: 'browser',
            data: {
              permission: 'view',
              resource: 'configuration-data-state'
            },
          },
          {
            link: '/configuration/map-view-category',
            title: 'Map Category',
            icon: 'map-outline',
            data: {
              permission: 'view',
              resource: 'configuration-map-view-category'
            },
          },
          {
            link: '/configuration/priority',
            title: 'Priority',
            icon: 'bulb-outline',
            data: {
              permission: 'view',
              resource: 'configuration-priority'
            },
          },
          {
            link: '/configuration/resolution-code',
            title: 'Resolution Code',
            icon: 'pantone-outline',
            data: {
              permission: 'view',
              resource: 'configuration-resolution-code'
            },
          },
          {
            link: '/configuration/sms-template',
            title: 'SMS Template',
            icon: 'message-square-outline',
            data: {
              permission: 'view',
              resource: 'configuration-sms-template'
            },
          },
          {
            link: '/configuration/task-status',
            title: 'Task Status',
            icon: 'speaker-outline',
            data: {
              permission: 'view',
              resource: 'configuration-task-status'
            },
          },
          {
            link: '/configuration/task-type',
            title: 'Task Type',
            icon: 'smartphone-outline',
            data: {
              permission: 'view',
              resource: 'configuration-task-type'
            },
          },
          {
            url: '/configuration/user-note',
            title: 'User Note',
            icon: 'calendar-outline',
            data: {
              permission: 'view',
              resource: 'configuration-user-note'
            },
          },
        ]
      },
      {
        title: 'User and Role',
        data: {
          permission: 'view',
          resource: 'configuration-user-and-role'
        },
        icon: 'person-add-outline',
        children: [
          {
            title: 'User',
            icon: 'person-outline',
            link: '/configuration/user',
            data: {
              permission: 'view',
              resource: 'configuration-user'
            },
          },
          {
            title: 'Role',
            icon: 'options-2-outline',
            link: '/configuration/role',
            data: {
              permission: 'view',
              resource: 'configuration-role'
            },
          },
        ]
      },
      {
        title: 'Automation',
        data: {
          permission: 'view',
          resource: 'configuration-automation'
        },
        icon: 'layers-outline',
        children: [
          {
            link: '/configuration/api-job',
            title: 'Api Jobs',
            icon: 'lock-outline',
            data: {
              permission: 'view',
              resource: 'configuration-api-job'
            },
          },
          {
            link: '/configuration/background-job',
            title: 'Background Jobs',
            icon: 'shield-outline',
            data: {
              permission: 'view',
              resource: 'configuration-background-job'
            },
          },
          {
            title: 'Data State',
            icon: 'layout-outline',
            link: '/configuration/datastate-management',
            data: {
              permission: 'view',
              resource: 'configuration-datastate-management'
            },
          },
          {
            title: 'Task Plan',
            icon: 'file-text-outline',
            link: '/configuration/task-plan',
            data: {
              permission: 'view',
              resource: 'configuration-task-plan'
            },
          },
          {
            link: '/configuration/taskscript-management',
            title: 'Task Script',
            icon: 'archive-outline',
            data: {
              permission: 'view',
              resource: 'configuration-taskscript-management'
            },
          },
        ]
      },
      {
        title: 'General Settings',
        data: {
          permission: 'view',
          resource: 'configuration-general-setting'
        },
        icon: 'settings-2-outline',
        children: [
          {
            title: 'Changelog',
            icon: 'star-outline',
            link: '/configuration/changelog',
            data: {
              permission: 'view',
              resource: 'configuration-changelog'
            },
          },
          {
            link: '/configuration/setting',
            title: 'Setting',
            icon: 'settings-2-outline',
            data: {
              permission: 'view',
              resource: 'configuration-setting'
            },
          },
        ]
      }
    ]
  },
];


export let NAV_MENU_ITEMS: ExtendNbMenuItem[] = [
  {
    // title: 'Dashboard',
    // icon: 'home-outline',
    // allowOpenTab: true,
    // data: {
    //   permission: 'view',
    //   resource: 'configuration-dashboard-group'
    // },
    // children: [
    //   {
    //     title: 'Dashboard',
    //     icon: 'home-outline',
    //     link: '/configuration/dashboard',
    //     data: {
    //       permission: 'view',
    //       resource: 'configuration-dashboard'
    //     },
    //   },
    //   {
    //     title: 'Dashboard',
    //     icon: 'home-outline',
    //     link: '/configuration/dashboard-crm',
    //     data: {
    //       permission: 'view',
    //       resource: 'configuration-dashboard-crm'
    //     },
    //   },
    //   {
    //     title: 'Sale Dashboard',
    //     icon: 'home-outline',
    //     link: '/configuration/dashboard-sale',
    //     data: {
    //       permission: 'view',
    //       resource: 'configuration-dashboard-sale'
    //     },
    //   },
    //   {
    //     title: 'SEO Dashboard',
    //     icon: 'home-outline',
    //     link: '/configuration/dashboard-seo',
    //     data: {
    //       permission: 'view',
    //       resource: 'configuration-dashboard-seo'
    //     },
    //   },
    //   {
    //     title: 'Contact Map',
    //     icon: 'globe-2-outline',
    //     link: '/configuration/dashboard-contact-map',
    //     data: {
    //       permission: 'view',
    //       resource: 'configuration-dashboard-contact-map'
    //     },
    //   },
    // ]
    title: 'Dashboard',
    icon: 'home-outline',
    allowOpenTab: true,
    link: '/configuration/dashboard-crm',
    data: {
      permission: 'view',
      resource: 'configuration-dashboard-crm'
    },
  },
  // {
  //   link: '/configuration/task',
  //   title: 'Task',
  //   icon: 'archive-outline',
  //   data: {
  //     permission: 'view',
  //     resource: 'configuration-task'
  //   },
  // },
  // {
  //   title: (`${environment.titleProfile || ''} Profile`).trim(),
  //   icon: 'book-outline',
  //   link: '/configuration/profile',
  //   data: {
  //     permission: 'view',
  //     resource: 'configuration-profile'
  //   }
  // },
  // {
  //   link: '/configuration/category',
  //   title: 'Category',
  //   icon: 'layers-outline',
  //   data: {
  //     permission: 'view',
  //     resource: 'configuration-category'
  //   }
  // },
  {
    link: '/configuration/contact',
    title: 'Contacts',
    icon: 'phone-call-outline',
    data: {
      permission: 'view',
      resource: 'configuration-nav-contact'
    }
  },
  {
    title: `${environment.titleLead}`,
    icon: 'credit-card-outline',
    link: '/configuration/sale-lead',
    data: {
      permission: 'view',
      resource: 'configuration-nav-sale-lead'
    }
  },
  {
    title: `${environment.titleAccount}s`,
    icon: 'smiling-face-outline',
    link: '/configuration/employee',
    data: {
      permission: 'view',
      resource: 'configuration-sale-account'
    }
  },
  {
    title: `Leave Request`,
    icon: 'briefcase-outline',
    link: '/configuration/leave-request',
    data: {
      permission: 'view',
      resource: 'configuration-leave-request'
    }
  },
  {
    title: 'Leave Setting',
    icon: 'clipboard-outline',
    data: {
      permission: 'view',
      resource: 'configuration-leave-setting'
    },
    children: [
      {
        title: `Leave Type`,
        icon: 'menu-arrow-outline',
        link: '/configuration/leave-type',
        data: {
          permission: 'view',
          resource: 'configuration-leave-type'
        }
      },
      {
        title: `Leave Entitlement`,
        icon: 'shopping-bag-outline',
        link: '/configuration/leave-entitlement',
        data: {
          permission: 'view',
          resource: 'configuration-leave-entitlement'
        }
      },
    ],
  },
  {
    title: `Attendance`,
    icon: 'sun-outline',
    data: {
      permission: 'view',
      resource: 'configuration-nav-attendance'
    },
    children: [
      {
        title: `Attendance`,
        icon: 'clock-outline',
        link: '/configuration/attendance',
        data: {
          permission: 'view',
          resource: 'configuration-attendance'
        }
      },
      {
        title: `Attendance Report`,
        icon: 'pantone-outline',
        link: '/configuration/attendance-report',
        data: {
          permission: 'view',
          resource: 'attendance-report'
        }
      },
      {
        title: `Request Attendance`,
        icon: 'flip-2-outline',
        link: '/configuration/attendance-request',
        data: {
          permission: 'view',
          resource: 'configuration-attendance-request'
        }
      },
    ],
  },
  // {
  //   title: `${environment.titleOpportunity}`,
  //   icon: 'done-all-outline',
  //   link: '/configuration/opportunity',
  //   data: {
  //     permission: 'view',
  //     resource: 'configuration-nav-opportunity'
  //   }
  // },
  // {
  //   title: 'Artist Booking',
  //   icon: 'shopping-cart-outline',
  //   data: {
  //     permission: 'view',
  //     resource: 'configuration-artist-booking'
  //   },
  //   children: [
  //     {
  //       link: '/configuration/enquiry',
  //       title: 'Inquiry',
  //       icon: 'shopping-bag-outline',
  //       data: {
  //         permission: 'view',
  //         resource: 'configuration-enquiry'
  //       },
  //     },
  //     {
  //       link: '/configuration/booking',
  //       title: 'Proposal',
  //       icon: 'shopping-cart-outline',
  //       data: {
  //         permission: 'view',
  //         resource: 'configuration-booking'
  //       },
  //     },
  //     {
  //       title: 'Buyer',
  //       icon: 'pantone-outline',
  //       link: '/configuration/buyer',
  //       data: {
  //         permission: 'view',
  //         resource: 'configuration-buyer'
  //       }
  //     },
  //   ]
  // },
  // {
  //   title: 'Proposals',
  //   icon: 'shopping-bag-outline',
  //   data: {
  //     permission: 'view',
  //     resource: 'configuration-sale-process'
  //   },
  //   children: [
  //     {
  //       link: '/configuration/contact',
  //       title: 'Contacts',
  //       icon: 'phone-call-outline',
  //       data: {
  //         permission: 'view',
  //         resource: 'configuration-contact'
  //       }
  //     },
  //     {
  //       title: `${environment.titleLead}`,
  //       icon: 'credit-card-outline',
  //       link: '/configuration/sale-lead',
  //       data: {
  //         permission: 'view',
  //         resource: 'configuration-sale-lead'
  //       }
  //     },
  //     {
  //       title: `${environment.titleAccount}s`,
  //       icon: 'smiling-face-outline',
  //       link: '/configuration/sale-account',
  //       data: {
  //         permission: 'view',
  //         resource: 'configuration-sale-account'
  //       }
  //     },
  //     {
  //       title: `${environment.titleOpportunity}`,
  //       icon: 'done-all-outline',
  //       link: '/configuration/opportunity',
  //       data: {
  //         permission: 'view',
  //         resource: 'configuration-opportunity'
  //       }
  //     },
  //     {
  //       title: 'Inquiry',
  //       icon: 'shopping-bag-outline',
  //       link: '/configuration/enquiry',
  //       data: {
  //         permission: 'view',
  //         resource: 'configuration-enquiry'
  //       },
  //     },
  //   ]
  // },
  {
    title: 'Email',
    icon: 'flag-outline',
    data: {
      permission: 'view',
      resource: 'configuration-campaign-nav'
    },
    children: [
      {
        link: '/configuration/email-inbox',
        title: 'Email Inbox',
        icon: 'inbox-outline',
        data: {
          permission: 'view',
          resource: 'configuration-email-inbox'
        },
      },
      {
        link: '/configuration/email-history',
        title: 'Email Sent',
        icon: 'email-outline',
        data: {
          permission: 'view',
          resource: 'configuration-email-history'
        },
      },
      {
        link: '/configuration/email-scheduler',
        title: 'Email Scheduler',
        icon: 'at-outline',
        data: {
          permission: 'view',
          resource: 'configuration-email-scheduler'
        },
      },
      {
        link: '/configuration/email-template-user',
        title: 'Email Template',
        icon: 'color-palette-outline',
        data: {
          permission: 'view',
          resource: 'configuration-email-template-end-user',
        },
      },
      // {
      //   link: '/configuration/email-campaign',
      //   title: 'Email Campaign',
      //   icon: 'paper-plane-outline',
      //   data: {
      //     permission: 'view',
      //     resource: 'configuration-email-campaign',
      //   }
      // },
      // {
      //   link: '/configuration/campaign',
      //   title: 'Campaign Report',
      //   icon: 'clock-outline',
      //   data: {
      //     permission: 'view',
      //     resource: 'configuration-campaign'
      //   },
      // },
      // {
      //   link: '/configuration/workflow',
      //   title: 'Workflow',
      //   icon: 'flip-2-outline',
      //   data: {
      //     permission: 'view',
      //     resource: 'configuration-workflow'
      //   },
      // },
    ],
  },
  // {
  //   link: '/configuration/sms-history',
  //   title: 'SMS History',
  //   icon: 'message-square-outline',
  //   data: {
  //     permission: 'view',
  //     resource: 'configuration-sms-history'
  //   },
  // },
  // {
  //   title: 'Report',
  //   icon: 'bar-chart-outline',
  //   data: {
  //     permission: 'view',
  //     resource: 'configuration-report'
  //   },
  //   children: [
  //     {
  //       link: '/configuration/summary-report',
  //       title: 'Profile Summary',
  //       icon: 'pie-chart-outline',
  //       data: {
  //         permission: 'view',
  //         resource: 'configuration-summary-report'
  //       },
  //     },
  //     {
  //       link: '/configuration/activity-log',
  //       title: 'Activity',
  //       icon: 'activity-outline',
  //       data: {
  //         permission: 'view',
  //         resource: 'configuration-activity-log'
  //       },
  //     },
  //     {
  //       link: '/configuration/category-report',
  //       title: 'Category Report',
  //       icon: 'layers-outline',
  //       data: {
  //         permission: 'view',
  //         resource: 'configuration-category-report'
  //       },
  //     },
  //     {
  //       link: '/configuration/diagnostic-history',
  //       title: 'Diagnostic History',
  //       icon: 'trending-up-outline',

  //       data: {
  //         permission: 'view',
  //         resource: 'configuration-diagnostic-history'
  //       },
  //     },
  //     {
  //       link: '/configuration/auto-assignment-report',
  //       title: 'Auto Assignment Report',
  //       icon: 'file-text-outline',

  //       data: {
  //         permission: 'view',
  //         resource: 'configuration-auto-assignment-report'
  //       },
  //     },
  //     {
  //       title: 'Access Logs',
  //       icon: 'monitor-outline',
  //       link: '/configuration/access-log',
  //       data: {
  //         permission: 'view',
  //         resource: 'configuration-access-log'
  //       }
  //     },
  //     {
  //       title: 'Admin Queries',
  //       link: '/configuration/admin-queries',
  //       icon: 'funnel-outline',
  //       data: {
  //         permission: 'view',
  //         resource: 'configuration-admin-queries'
  //       },
  //     },
  //     {
  //       title: 'Datastate Report',
  //       link: '/configuration/data-state-report',
  //       icon: 'pantone',
  //       data: {
  //         permission: 'view',
  //         resource: 'configuration-data-state-report'
  //       },
  //     },
  //     {
  //       link: '/configuration/task-filter',
  //       title: 'Task Filter',
  //       icon: 'funnel-outline',
  //       data: {
  //         permission: 'view',
  //         resource: 'configuration-task-filter'
  //       },
  //     },
  //     {
  //       title: 'Sale Report',
  //       link: '/configuration/sales-report',
  //       icon: 'bar-chart',
  //       data: {
  //         permission: 'view',
  //         resource: 'opportunity-sale-report-button'
  //       },
  //     },
  //   ]
  // },
  {
    title: 'Admin',
    data: {
      permission: 'view',
      resource: 'configuration-admin'
    },
    icon: 'people-outline',
    children: [
      // {
      //   title: 'Front Facing',
      //   data: {
      //     permission: 'view',
      //     resource: 'configuration-wp'
      //   },
      //   icon: 'tv-outline',
      //   children: [
      //     {
      //       link: '/configuration/wp-homepage',
      //       title: 'Home Page',
      //       icon: 'globe-outline',
      //       data: {
      //         permission: 'view',
      //         resource: 'configuration-wp-homepage',
      //       },
      //     },
      //     {
      //       link: '/configuration/front-page-data',
      //       title: 'Front Page Data',
      //       icon: 'globe-outline',
      //       data: {
      //         permission: 'view',
      //         resource: 'configuration-front-page-data',
      //       },
      //     },
      //     {
      //       link: '/configuration/summary-indexing',
      //       title: 'Indexing Summary',
      //       icon: 'pie-chart-outline',
      //       data: {
      //         permission: 'view',
      //         resource: 'configuration-summary-indexing'
      //       },
      //     },
      //     {
      //       link: '/configuration/indexing-tool',
      //       title: 'Indexing Tool',
      //       icon: 'briefcase-outline',
      //       data: {
      //         permission: 'view',
      //         resource: 'configuration-indexing-tool'
      //       },
      //     },
      //     {
      //       link: '/configuration/indexing-tool-setting',
      //       title: 'Indexing Setting',
      //       icon: 'briefcase-outline',
      //       data: {
      //         permission: 'view',
      //         resource: 'configuration-indexing-tool-setting'
      //       },
      //     },

      //   ]
      // },
      {
        title: 'Data Diagnostic',
        data: {
          permission: 'view',
          resource: 'configuration-data-diagnostic'
        },
        icon: 'trending-up-outline',
        children: [
          // {
          //   link: '/configuration/bio-rewrite',
          //   title: 'Bio',
          //   icon: 'book-open-outline',
          //   data: {
          //     permission: 'view',
          //     resource: 'configuration-bio-rewrite',
          //   },
          // },
          // {
          //   link: '/configuration/bunny-upload-tracker',
          //   title: 'Bunny Upload Tracker',
          //   icon: 'monitor-outline',
          //   data: {
          //     permission: 'view',
          //     resource: 'configuration-bunny-upload-tracker'
          //   }
          // },
          // {
          //   link: '/configuration/call-event',
          //   title: 'Call Event',
          //   icon: 'phone-call-outline',
          //   data: {
          //     permission: 'view',
          //     resource: 'configuration-call-event',
          //   },
          // },
          // {
          //   link: '/configuration/callback-mapping',
          //   title: 'Callback Mapping',
          //   icon: 'radio-outline',
          //   data: {
          //     permission: 'view',
          //     resource: 'configuration-callback-mapping'
          //   },
          // },
          // {
          //   link: 'configuration/clickup-history',
          //   title: 'ClickUp History',
          //   icon: 'loader-outline',
          //   data: {
          //     permission: 'view',
          //     resource: 'configuration-clickup-sync',
          //   },
          // },
          // {
          //   link: '/configuration/sale-convert-history',
          //   title: 'Convert History',
          //   icon: 'npm-outline',
          //   data: {
          //     permission: 'view',
          //     resource: 'configuration-sale-convert-history'
          //   },
          // },
          // {
          //   link: '/configuration/custom-view',
          //   title: 'Custom View',
          //   icon: 'eye-outline',
          //   data: {
          //     permission: 'view',
          //     resource: 'configuration-custom-view'
          //   },
          // },
          // {
          //   link: '/configuration/dynamic-content',
          //   title: 'Dynamic Content',
          //   icon: 'keypad-outline',
          //   data: {
          //     permission: 'view',
          //     resource: 'configuration-dynamic-content',
          //   },
          // },
          // {
          //   link: '/configuration/email-attachment',
          //   title: 'Email Attachment',
          //   icon: 'file-outline',
          //   data: {
          //     permission: 'view',
          //     resource: 'configuration-email-attachment',
          //   },
          // },
          // {
          //   link: '/configuration/file-sync-progress',
          //   title: 'File Sync Progress',
          //   icon: 'file-text',
          //   data: {
          //     permission: 'view',
          //     resource: 'configuration-file-sync-progress'
          //   },
          // },
          // {
          //   link: '/configuration/indexing-history',
          //   title: 'Indexing History',
          //   icon: 'book-outline',
          //   data: {
          //     permission: 'view',
          //     resource: 'configuration-indexing-history'
          //   },
          // },
          // {
          //   link: '/configuration/indexing-queue',
          //   title: 'Indexing Queue',
          //   icon: 'briefcase-outline',
          //   data: {
          //     permission: 'view',
          //     resource: 'configuration-indexing-queue'
          //   },
          // },
          // {
          //   link: '/configuration/indexing-progress-queue',
          //   title: 'Indexing Progress',
          //   icon: 'briefcase-outline',
          //   data: {
          //     permission: 'view',
          //     resource: 'configuration-indexing-progress-queue'
          //   },
          // },
          // {
          //   link: '/configuration/location',
          //   title: 'Location',
          //   icon: 'map',
          //   data: {
          //     permission: 'view',
          //     resource: 'configuration-location'
          //   },
          // },
          // {
          //   link: '/configuration/media',
          //   title: 'Media Data',
          //   icon: 'play-circle-outline',
          //   data: {
          //     permission: 'view',
          //     resource: 'configuration-media',
          //   },
          // },
          // {
          //   link: '/configuration/media-resize',
          //   title: 'Media Resize',
          //   icon: 'camera-outline',
          //   data: {
          //     permission: 'view',
          //     resource: 'configuration-media-resize',
          //   },
          // },
          // {
          //   link: '/configuration/onboarding-data',
          //   title: 'Onboarding Data',
          //   icon: 'eye-outline',
          //   data: {
          //     permission: 'view',
          //     resource: 'configuration-onboarding-data',
          //   },
          // },
          // {
          //   link: '/configuration/page-speed',
          //   title: 'Page Speed',
          //   icon: 'wifi-outline',
          //   data: {
          //     permission: 'view',
          //     resource: 'configuration-support-ticket'
          //   },
          // },
          // {
          //   link: '/configuration/photo-process-queue',
          //   title: 'Photo Queue',
          //   icon: 'loader-outline',
          //   data: {
          //     permission: 'view',
          //     resource: 'configuration-support-ticket'
          //   },
          // },
          // {
          //   link: '/configuration/non-artist',
          //   title: 'Proposal Non-Artist',
          //   icon: 'square-outline',
          //   data: {
          //     permission: 'view',
          //     resource: 'configuration-non-artist',
          //   },
          // },
          // {
          //   link: '/configuration/proposal-template',
          //   title: 'Proposal Template',
          //   icon: 'file-text-outline',
          //   data: {
          //     permission: 'view',
          //     resource: 'configuration-proposal-template'
          //   },
          // },
          // {
          //   link: '/configuration/reason',
          //   title: 'Reason',
          //   icon: 'at-outline',
          //   data: {
          //     permission: 'view',
          //     resource: 'configuration-reason'
          //   },
          // },
          // {
          //   link: '/configuration/reminder-history',
          //   title: 'Reminder History',
          //   icon: 'clock-outline',
          //   data: {
          //     permission: 'view',
          //     resource: 'configuration-reminder-history'
          //   },
          // },
          // {
          //   title: 'SEO Data',
          //   icon: 'layout-outline',
          //   link: '/configuration/seo-info',
          //   data: {
          //     permission: 'view',
          //     resource: 'configuration-seo-info'
          //   },
          // },
          {
            link: '/configuration/system-log',
            title: 'System Files',
            icon: 'archive-outline',
            data: {
              permission: 'view',
              resource: 'configuration-system-log'
            },
          },
          {
            link: '/configuration/system-logs',
            title: 'System Logs',
            icon: 'archive',
            data: {
              permission: 'view',
              resource: 'configuration-system-logs'
            },
          },
          {
            link: '/configuration/support-ticket',
            title: 'Support Ticket',
            icon: 'headphones-outline',
            data: {
              permission: 'view',
              resource: 'configuration-support-ticket'
            },
          },
          // {
          //   link: '/configuration/tags',
          //   title: 'Tags',
          //   icon: 'pricetags-outline',
          //   data: {
          //     permission: 'view',
          //     resource: 'configuration-tags'
          //   },
          // },
          {
            link: '/configuration/user-tab',
            title: 'User Tab',
            icon: 'person-outline',
            data: {
              permission: 'view',
              resource: 'configuration-user-tab'
            },
          },
          {
            link: '/configuration/user-upload',
            title: 'User Upload',
            icon: 'upload-outline',
            data: {
              permission: 'view',
              resource: 'configuration-user-upload'
            },
          },
          {
            link: '/configuration/user-token',
            title: 'User Token',
            icon: 'eye-outline',
            data: {
              permission: 'view',
              resource: 'configuration-user-token'
            },
          },
          // {
          //   link: '/configuration/proposal-template',
          //   title: 'Proposal Template',
          //   icon: 'eye-outline',
          //   data: {
          //     permission: 'view',
          //     resource: 'configuration-proposal-template'
          //   },
          // },
          // {
          //   link: '/configuration/campaign-workflow',
          //   title: 'WF - Campaign Workflow',
          //   icon: 'layers',
          //   data: {
          //     permission: 'view',
          //     resource: 'configuration-campaign-workflow'
          //   }
          // },
          // {
          //   link: '/configuration/workflow-detail',
          //   title: 'WF - Detail',
          //   icon: 'file-text-outline',
          //   data: {
          //     permission: 'view',
          //     resource: 'configuration-workflow-detail'
          //   },
          // },
          // {
          //   link: '/configuration/workflow-queue',
          //   title: 'WF Queue',
          //   icon: 'swap-outline',
          //   data: {
          //     permission: 'view',
          //     resource: 'configuration-workflow-queue'
          //   },
          // },
          // {
          //   link: '/configuration/step-in-flow',
          //   title: 'WF - Step In Flow',
          //   icon: 'trending-up-outline',
          //   data: {
          //     permission: 'view',
          //     resource: 'configuration-step-in-flow'
          //   },
          // },
          // {
          //   link: '/configuration/user-action',
          //   title: 'WF - User Action',
          //   icon: 'person-done',
          //   data: {
          //     permission: 'view',
          //     resource: 'configuration-user-action'
          //   }
          // },
          // {
          //   link: '/configuration/workflow-monitoring',
          //   title: 'WF - Monitoring',
          //   icon: 'layers',
          //   data: {
          //     permission: 'view',
          //     resource: 'configuration-workflow'
          //   }
          // },
        ]
      },
      {
        title: 'Configuration',
        data: {
          permission: 'view',
          resource: 'configuration-configurations'
        },
        icon: 'menu-2-outline',
        children: [
          {
            link: '/configuration/buyer-type',
            title: 'Buyer Type',
            icon: 'browser-outline',
            data: {
              permission: 'view',
              resource: 'configuration-buyer-type',
            },
          },
          {
            link: '/configuration/contact-type',
            title: 'Contact Type',
            icon: 'phone-outline',
            data: {
              permission: 'view',
              resource: 'configuration-contact-type',
            },
          },
          {
            link: '/configuration/data-field',
            title: 'Data Fields',
            icon: 'file-text-outline',
            data: {
              permission: 'view',
              resource: 'configuration-data-field'
            },
          },
          {
            link: '/configuration/email-template',
            title: 'Email Template',
            icon: 'color-palette-outline',
            data: {
              permission: 'view',
              resource: 'configuration-email-template',
            },
          },
          {
            link: '/configuration/data-state',
            title: 'Legacy Data State',
            icon: 'browser',
            data: {
              permission: 'view',
              resource: 'configuration-data-state'
            },
          },
          {
            link: '/configuration/map-view-category',
            title: 'Map Category',
            icon: 'map-outline',
            data: {
              permission: 'view',
              resource: 'configuration-map-view-category'
            },
          },
          {
            link: '/configuration/priority',
            title: 'Priority',
            icon: 'bulb-outline',
            data: {
              permission: 'view',
              resource: 'configuration-priority'
            },
          },
          {
            link: '/configuration/resolution-code',
            title: 'Resolution Code',
            icon: 'pantone-outline',
            data: {
              permission: 'view',
              resource: 'configuration-resolution-code'
            },
          },
          {
            link: '/configuration/sms-template',
            title: 'SMS Template',
            icon: 'message-square-outline',
            data: {
              permission: 'view',
              resource: 'configuration-sms-template'
            },
          },
          {
            link: '/configuration/task-status',
            title: 'Task Status',
            icon: 'speaker-outline',
            data: {
              permission: 'view',
              resource: 'configuration-task-status'
            },
          },
          {
            link: '/configuration/task-type',
            title: 'Task Type',
            icon: 'smartphone-outline',
            data: {
              permission: 'view',
              resource: 'configuration-task-type'
            },
          },
        ]
      },
      {
        title: 'User and Role',
        data: {
          permission: 'view',
          resource: 'configuration-user-and-role'
        },
        icon: 'person-add-outline',
        children: [
          {
            title: 'User',
            icon: 'person-outline',
            link: '/configuration/user',
            data: {
              permission: 'view',
              resource: 'configuration-user'
            },
          },
          {
            title: 'Role',
            icon: 'options-2-outline',
            link: '/configuration/role',
            data: {
              permission: 'view',
              resource: 'configuration-role'
            },
          },
          {
            title: 'Inactive Employee',
            icon: 'person-remove-outline',
            link: '/configuration/inactive-employee',
            data: {
              permission: 'view',
              resource: 'configuration-user'
            },
          },
        ]
      },
      {
        title: 'Automation',
        data: {
          permission: 'view',
          resource: 'configuration-automation'
        },
        icon: 'layers-outline',
        children: [
          {
            link: '/configuration/api-job',
            title: 'Api Jobs',
            icon: 'lock-outline',
            data: {
              permission: 'view',
              resource: 'configuration-api-job'
            },
          },
          {
            link: '/configuration/background-job',
            title: 'Background Jobs',
            icon: 'shield-outline',
            data: {
              permission: 'view',
              resource: 'configuration-background-job'
            },
          },
          {
            title: 'Data State',
            icon: 'layout-outline',
            link: '/configuration/datastate-management',
            data: {
              permission: 'view',
              resource: 'configuration-datastate-management'
            },
          },
          {
            title: 'Admin Queries',
            link: '/configuration/admin-queries',
            icon: 'funnel-outline',
            data: {
              permission: 'view',
              resource: 'configuration-admin-queries'
            },
          },
          // {
          //   title: 'Task Plan',
          //   icon: 'file-text-outline',
          //   link: '/configuration/task-plan',
          //   data: {
          //     permission: 'view',
          //     resource: 'configuration-task-plan'
          //   },
          // },
          // {
          //   link: '/configuration/taskscript-management',
          //   title: 'Task Script',
          //   icon: 'archive-outline',
          //   data: {
          //     permission: 'view',
          //     resource: 'configuration-taskscript-management'
          //   },
          // },
        ]
      },
      {
        title: 'General Settings',
        data: {
          permission: 'view',
          resource: 'configuration-general-setting'
        },
        icon: 'settings-2-outline',
        children: [
          {
            title: 'Changelog',
            icon: 'star-outline',
            link: '/configuration/changelog',
            data: {
              permission: 'view',
              resource: 'configuration-changelog'
            },
          },
          {
            link: '/configuration/setting',
            title: 'Setting',
            icon: 'settings-2-outline',
            data: {
              permission: 'view',
              resource: 'configuration-setting'
            },
          },
        ]
      }
    ]
  },
  {
    title: "Work Log",
    icon: 'clock-outline',
    link: '/configuration/work-log',
    data:{
      permission: 'view',
      resource: 'configuration-work-log'
    }
  },
];



// remove on header nav:
/*
{
    link: '/configuration/producer',
    title: 'Producer',
    icon: 'map-outline',
    data: {
      permission: 'view',
      resource: 'configuration-producer'
    }
  },
*/
