<div class="important-note-act">
    <span>
        <b style="font-size: 14px">Important Note</b>
        <br>
        <b>Please read the note before entering your information</b>
        <br>
        <br>
        We consider an "act" to be a unique version of your show or performance.
        <br>
        <b>Example #1:</b> If you are a five-piece rock band that also does a two-piece acoustic performance, then that
        would be two separate acts. If you also have a version of your performance that includes a horn section, then
        that
        would be a third act. Please upload appropriate videos and photos for each act.
        <br>
        <b>Example #2:</b> If you are an acrobat that does multiple routines (aerial, contortion, etc.), then each one
        of
        those would be considered a separate act.
        <br>
        <b>Example #3:</b> If you are a production company, please list each show offering as a separate act, including
        both
        stage performances and atmosphere entertainment.
        <br>
        You can add as many different acts as you need to, and we can always add more later.
    </span>
</div>


<div *ngIf="actDetailsForm && actDetailsForm.length > 0">
    <ng-container [ngTemplateOutlet]="moreAct"
        [ngTemplateOutletContext]="{model: actDetailsForm[0], index: 0, isRemove: false}">
    </ng-container>
    <div *ngFor="let actForm of actDetailsForm; let i = index">
        <ng-container *ngIf="i >= 1" [ngTemplateOutlet]="moreAct"
            [ngTemplateOutletContext]="{model: actForm, index: i, isRemove: true}">
        </ng-container>
    </div>
</div>

<button mat-button mat-raised-button class="st-btn btn-additional" (click)="clickAddAct()">Add another act</button>

<ng-template #moreAct let-model="model" let-index="index" let-isRemove="isRemove">
    <form [formGroup]="model">
        <div style="width: inherit;margin-bottom:10px">
            <mat-expansion-panel class="expandable-block" expanded hideToggle style="border-radius:unset">
                <mat-expansion-panel-header class="custom-expand-header"
                    style="background-color: #307abd;width:inherit">
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <div class="expand-title">
                            Act {{+index+1}} Details
                        </div>
                        <button *ngIf="isRemove" (click)="removeAct(model)" mat-icon-button
                            class="absolute-expand-close">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                </mat-expansion-panel-header>
                <div style="width: -webkit-fill-available;padding-top: 20px;">
                    <div class="contact-expand notify-sec">
                        <div class="form-group">
                            <label class="form-label">Act Name*</label>
                            <input required formControlName="RealName" placeholder="Your Act Name..." type="text"
                                class="contact form-control contact-expand-input">
                            <mat-error
                                *ngIf="model?.controls?.RealName?.errors && (model?.controls?.RealName?.dirty || model?.controls?.RealName?.touched)">
                                {{model?.controls?.RealName['errorMessage']}}
                            </mat-error>
                        </div>
                    </div>
                    <div class="contact-expand notify-sec">
                        <div class="form-group">
                            <label class="form-label">Booking Highlights</label>
                            <p class="help-block-act">
                                What are the best selling points of your act/show? Write as many as you can think of
                                that
                                would help our team sell you to a client.
                            </p>
                            <textarea type="text" formControlName="Reason"
                                class="booking-highlights form-control contact-expand-input text-area-expand"></textarea>
                            <p class="help-block-act">* One highlight per line</p>
                        </div>
                    </div>
                    <div class="contact-expand notify-sec">
                        <div class="form-group">
                            <label class="form-label">Description of act</label>
                            <textarea type="text" formControlName="Bio"
                                class="contact form-control contact-expand-input text-area-expand"></textarea>
                        </div>
                    </div>
                    <div class="contact-expand notify-sec">
                        <div class="form-group">
                            <label class="form-label">Rider</label>
                            <textarea type="text" formControlName="Rider"
                                class="contact form-control contact-expand-input text-area-expand"></textarea>
                            <br>
                            <ngx-dropzone (change)="onSelectRider($event, index)"
                                accept=".xlsx,.xls,.doc,.docx,.ppt,.pptx,.pdf,.txt" [multiple]="false">
                                <ngx-dropzone-label>Click or drop your file here to upload rider information.
                                </ngx-dropzone-label>
                                <ngx-dropzone-preview *ngIf="model.controls?.FileRider?.value" [removable]="true"
                                    (removed)="onRemoveRider(model.controls?.FileRider?.value, index)">
                                    <ngx-dropzone-label>{{ model.controls?.FileRider?.value.name }} ({{
                                        model.controls?.FileRider?.value.type }})
                                    </ngx-dropzone-label>
                                </ngx-dropzone-preview>
                            </ngx-dropzone>
                        </div>
                    </div>
                    <div *ngIf="model?.controls?.PhotoLinks?.value && model?.controls?.PhotoLinks?.value?.length > 0"
                        class="contact-expand notify-sec">
                        <label class="form-label">Suggested photos</label>
                        <mat-grid-list class="contact-expand-input gallery" cols="4" rowHeight="4:3" gutterSize="8">
                            <div *ngFor="let photo of model?.controls?.PhotoLinks?.value ; index as i">
                                <mat-grid-tile *ngIf="photo.MediaID != 0">
                                    <img [src]="photo.Media || 'assets/images/error-photo.jpg'"
                                        style="height: 100%; object-fit: cover;"
                                        (error)="photo.Media = 'assets/images/error-photo.jpg'">
                                </mat-grid-tile>
                            </div>
                        </mat-grid-list>
                    </div>
                    <br>
                    <div class="contact-expand notify-sec">
                        <div class="form-group">
                            <label class="form-label">Upload your photos</label>
                            <p class="help-block-act">
                                * At least 4 photos without your name or identifying information on them, at a minimum
                                resolution of 450x450 pixels.
                            </p>
                            <ngx-dropzone #drop accept="image/png, image/gif, image/jpeg"
                                (change)="onSelectPhoto($event,index)">
                                <ngx-dropzone-label>Click or drop your file here to upload photo.</ngx-dropzone-label>
                                <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview"
                                    *ngFor="let f of model?.controls?.FilePhotos?.value" [removable]="true" [file]="f"
                                    (removed)="onRemovePhoto(f, index)" [removable]="true">
                                    <ngx-dropzone-label></ngx-dropzone-label>
                                </ngx-dropzone-image-preview>
                            </ngx-dropzone>
                        </div>
                    </div>
                    <br>
                    <div class="contact-expand notify-sec">
                        <div *ngIf="model?.controls?.VideoLinks?.value" class="form-group">
                            <label class="form-label">Video Links (preferably HD quality)</label>
                            <ng-container [ngTemplateOutlet]="videoTemp"
                                [ngTemplateOutletContext]="{video: model?.controls?.VideoLinks?.value[0], index: 0, isRemove: false, indexAct: index}">
                            </ng-container>

                            <div *ngFor="let video of model?.controls?.VideoLinks?.value; let i = index">
                                <ng-container *ngIf="i >= 1" [ngTemplateOutlet]="videoTemp"
                                    [ngTemplateOutletContext]="{video: video, index: i, isRemove: true, indexAct: index}">
                                </ng-container>
                            </div>
                            <button mat-button mat-raised-button (click)="addLinkVideo(index)" color="primary"
                                class="st-btn btn-additional">Add
                                another
                                link +</button>
                        </div>
                    </div>
                    <div class="contact-expand notify-sec">
                        <div class="form-group">
                            <label class="form-label">Set List</label>
                            <textarea type="text" formControlName="SetList"
                                class="contact form-control contact-expand-input text-area-expand"></textarea>
                            <p class="help-block-act">* One per line</p>
                        </div>
                    </div>
                    <div class="contact-expand notify-sec">
                        <div class="form-group">
                            <label class="form-label">Previous Clients</label>
                            <textarea type="text" formControlName="PreviousClients"
                                class="contact form-control contact-expand-input text-area-expand"></textarea>
                            <p class="help-block-act">* One per line</p>
                        </div>
                    </div>
                    <div class="contact-expand notify-sec">
                        <div *ngIf="model?.controls?.TestimonialsForm?.value" class="form-group">
                            <label class="form-label">Testimonials</label>
                            <ng-container [ngTemplateOutlet]="testimonialTemp"
                                [ngTemplateOutletContext]="{testimonial: model?.controls?.TestimonialsForm?.value[0], index: 0, isRemove: false, indexAct: index}">
                            </ng-container>
                            <div *ngFor="let testimonial of  model?.controls?.TestimonialsForm?.value; let i = index">
                                <ng-container *ngIf="i >= 1" [ngTemplateOutlet]="testimonialTemp"
                                    [ngTemplateOutletContext]="{testimonial: testimonial, index: i, isRemove: true, indexAct: index}">
                                </ng-container>
                            </div>
                            <button mat-button mat-raised-button (click)="addTestimonial(index)" color="primary"
                                class="st-btn btn-additional">
                                Add testimonials +
                            </button>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </div>
    </form>
</ng-template>


<ng-template #videoTemp let-item="video" let-index="index" let-isRemove="isRemove" let-indexAct="indexAct">
    <br>
    <div *ngIf="item" fxLayout="row" fxLayoutAlign="space-between center">
        <input [(ngModel)]="item.Media" type="text" class="contact form-control"
            placeholder="URL to video Youtube/Facebook/Vimeo/Drive/Dropbox...">
        <button *ngIf="isRemove" mat-button mat-icon-button (click)="removeLinkPhoto(item, indexAct)">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</ng-template>

<ng-template #testimonialTemp let-item="testimonial" let-index="index" let-isRemove="isRemove" let-indexAct="indexAct">
    <br>
    <div *ngIf="item">
        <p class="help-block-act">Client name</p>
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <input [(ngModel)]="item.ClientNames" placeholder="Client name..." type="text" class="contact form-control">
            <button *ngIf="isRemove" (click)="removeTestimonial(item, indexAct)" mat-button mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <p class="help-block-act">Testimonial</p>
        <textarea [style.margin-right]="isRemove ? '40px' : ''" type="text" placeholder="Testimonial..."
            [(ngModel)]="item.Testimonial"
            class="contact form-control contact-expand-input text-area-expand"></textarea>
    </div>
</ng-template>