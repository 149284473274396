import { AfterViewInit, Directive, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AdminTabModeService } from '../components/stand-alone-component/admin-tab-mode/admin-tab-mode.service';
import { ExtendNbMenuItem } from '../components/stand-alone-component/admin-tab-mode/tab-mode.model';
import { Helper } from '../utility/Helper';
import { first, take } from 'rxjs/operators';

@Directive({
  selector: '[appChangeUrlObjects]'
})
export class ChangeUrlObjectsDirective implements OnChanges, AfterViewInit {

  @Input() href: string;
  @Input() id: string;
  @Input() isClose: boolean = false;
  @Input() isClick: boolean = false;
  @Input() isOpenDialog: boolean = true;
  @Input() topic: string;
  @Input() name: string;

  constructor(
    private tabModeService: AdminTabModeService,
    private router: Router) { }

  ngAfterViewInit(): void {
    // if (!this.isClose)
    //   this.changeUrl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const changeId = changes.id;
    if (changeId && changeId.currentValue != changeId.previousValue)
      this.id = changeId.currentValue;

    const nameChanged = changes.name;
    if (nameChanged && nameChanged.currentValue != nameChanged.previousValue) {      
      this.name = nameChanged.currentValue;
    }

    if (!this.isClose && this.id && this.name)
      this.changeUrl();
  }

  @HostListener('click', ['$event'])
  onClick(event) {
    var hrefReplace = this.href;
    if (!hrefReplace) hrefReplace = window.location.pathname;
    this.clickCloseElement(hrefReplace);
    this.clickDetailElement(hrefReplace);
  }

  clickDetailElement(hrefReplace: string) {
    if (hrefReplace && this.isClick) {
      var linkHistory = this.getHistoryUrlDetail();
      linkHistory.push(hrefReplace + window.location.search);
      window.localStorage.setItem("history_url_detail", JSON.stringify(linkHistory));
    }
  }

  clickCloseElement(hrefReplace: string) {
    if (hrefReplace && this.isClose) {
      if (hrefReplace.includes("import-contact")) return; //Cancel checking logic when the page is import contact

      var linkHistory = this.getHistoryUrlDetail();
      if (linkHistory && linkHistory.length > 0) {
        var lastUrl = linkHistory.pop();
        if (lastUrl) hrefReplace = lastUrl;
        if (linkHistory && linkHistory.length > 0)
          window.localStorage.setItem("history_url_detail", JSON.stringify(linkHistory));
        else window.localStorage.removeItem("history_url_detail");
      }
      let displayNameUrl = this.getDisplayName();
      let isAnyExItem: string = displayNameUrl;
      // if (isAnyExItem) {
      //   this.tabModeService.editTab(isAnyExItem, hrefReplace, null, true);
      // }
      Helper.cancelNavigate(hrefReplace);
    };
  }

  getHistoryUrlDetail(): string[] {
    var result: string[] = [];
    try {
      var existedHistoryDetail = window.localStorage.getItem("history_url_detail");
      if (existedHistoryDetail) {
        var arrayLink = JSON.parse(existedHistoryDetail);
        if (arrayLink && arrayLink.length > 0)
          result = arrayLink;
      }
    } catch (ex) { console.log(ex); }

    return result;
  }

  async changeUrl() {
    if (Helper.checkTabMode() && !this.isClose && !this.isClick) {
      var hrefReplace = this.href;
      if (!hrefReplace) hrefReplace = window.location.pathname;
      if (hrefReplace && this.id && this.isOpenDialog && hrefReplace.includes(this.topic)) {
        // find item ExtendNbMenuItem
        let currentUrl = this.router.url;
        let readyToChangeUrl = `${hrefReplace}?id=${this.id}`;
        //let readyToChangeUrl = `${hrefReplace}`;

        //avoid case that already in detail url
        if (currentUrl !== readyToChangeUrl) {
          let displayNameUrl = this.getDisplayName();
          
          let isAnyExItem: string = displayNameUrl;
          if (isAnyExItem) {
            if (readyToChangeUrl.includes(`/${this.id}`)) readyToChangeUrl = hrefReplace;
            await this.tabModeService.createNewTab(hrefReplace, isAnyExItem, 'Link', true, true);
          }
        }
        if (readyToChangeUrl.includes(`/${this.id}`))
          readyToChangeUrl = hrefReplace;
        Helper.cancelNavigate(`${readyToChangeUrl}`);
      }
      // if (hrefReplace && this.id && this.isOpenDialog && this.topic && !hrefReplace.includes(this.topic))
      //   Helper.cancelNavigate(`${hrefReplace}?${this.topic
      //     .replace(/(^|\/|-)(\S)/g, (_, a, b) => a + b.toUpperCase())
      //     .replace(/-/g, "")
      //     }Id=${this.id}`);
      //if (hrefReplace && this.id && !this.isOpenDialog) Helper.cancelNavigate(`${hrefReplace}/${this.id}`);
    }
  }

  getDisplayName() {
    let displayNameUrl = null;
    displayNameUrl = `${this.name.length > 15 ? this.name.substring(0, 14) + '..' : this.name}`;
    return displayNameUrl;
  }
}