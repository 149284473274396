<div mat-dialog-title>
  <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="12px" *ngIf="!isDialog">
    <div>
      <button mat-flat-button color="primary" style="margin-right:12px" (click)="onSave()" [nbSpinner]="loading"
        [disabled]="readonlyContact || loading" nbSpinnerStatus="primary">
        <mat-icon fontSet=" material-icons-outlined">save</mat-icon>Save
      </button>

      <button *ngIf="isAddMoreBtn" mat-raised-button color="primary" style="margin-right:12px"
        (click)="onAddContactBtn(); isChange = true" [attr.disabled]='loading'
        matTooltip="By pressing this button, new contact will be showed">
        <mat-icon>add</mat-icon>More
      </button>

      <button #convertBtn *ngIf="isUpdateAction" mat-flat-button style="margin-right:12px" color="primary"
        [disabled]='disableConvert' [nbSpinner]="loading" (click)="onConvertClick()">
        <mat-icon style="transform: scale(0.9);">swap_horiz
        </mat-icon>Convert
      </button>

    </div>
    <button mat-icon-button aria-label="Close the dialog" style="margin-right:1.6rem" (click)="backClicked()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<mat-dialog-content class="contact-body">
  <div class="col-lg-12">
    <div class="alert alert-warning" role="alert" *ngIf="readonlyContact">
      This contact was created by the another user. You can read-only.
    </div>
    <div class="alert alert-info" role="alert" *ngIf="bypassPermissions">
      This contact was created by the another user. But you can still edit it.
    </div>
  </div>
  <div *ngFor="let formInstance of formContactLst;  index as i; trackBy: trackByFn"
    [style.background-color]="(i+1)%2==0 ? '#f2f9ff' : null" style="margin-bottom: 36px">
    <form [formGroup]="formInstance">
      <div class="contact-card">
        <div fxLayout="row" fxLayoutAlign="end" [ngClass]="isAddMoreBtn? 'contact-header' : null">
          <button *ngIf="isAddMoreBtn && isDupplicatable" mat-icon-button [attr.disabled]='loading'
            (click)="duplicate(formInstance); isChange = true">
            <mat-icon>content_copy</mat-icon>
          </button>
          <div *ngIf='i!= 0'>
            <button *ngIf='contactLst.length>1 && i!== 0' mat-icon-button [attr.disabled]='loading'
              (click)="onDeleteContactBtn(i); isChange = true">
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="12px" style="padding-top: 12px;">
          <div fxFlex="70" class="contact-card-border-left">
            <div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
                <h6>Contact</h6>
                <button *ngIf="(isSetPrimaryable && (!formInstance.controls.primaryContact.value) && isUpdateAction)"
                  mat-stroked-button color="primary" style="line-height: 24px;"
                  (click)="setPrimaryContact(i); isChange = true" [disabled]="loading"
                  matTooltip="When this button clicked, the contact will be set as a primary contact">
                  Set Primary
                </button>
                <div class="badge badge-pill contact-primary-status" *ngIf="formInstance.controls.primaryContact.value == true"
                  [attr.disabled]='loading' matTooltip="This is primary contact" selected
                  (removed)="removePrimaryContact(i); isChange = true">Primary
                </div>
              </div>
              <div [fxLayout.gt-lg]="'row'" [fxLayout.md]="'column'" fxLayoutAlign="start start" fxLayoutGap="12px">
                <mat-form-field [fxFlex]="20" appearance="standard">
                  <mat-label>Saluation</mat-label>
                  <mat-select formControlName="saluation" [disabled]="readonlyContact"
                    (selectionChange)="isChange = true">
                    <mat-option *ngFor="let sal of saluationList" [value]="sal">
                      {{sal}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field appearance="standard">
                  <mat-label>First Name</mat-label>
                  <input matInput autocomplete="off" formControlName="contactName" trim="blur"
                    [readonly]="readonlyContact" (change)="refreshTask.emit()">
                </mat-form-field>

                <mat-form-field appearance="standard">
                  <mat-label>Last Name</mat-label>
                  <input matInput autocomplete="off" formControlName="contactLastName" trim="blur"
                    [readonly]="readonlyContact" (change)="refreshTask.emit()">
                </mat-form-field>
              </div>

              <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="12px">
                <mat-form-field appearance="standard" trim="blur">
                  <mat-label>Job Title</mat-label>
                  <input matInput autocomplete="off" formControlName="jobTitle" [readonly]="readonlyContact">
                </mat-form-field>

                <mat-form-field appearance="standard" trim="blur">
                  <mat-label>Department</mat-label>
                  <input #departmentInput matInput [matAutocomplete]="departmentTypeInput.autocomplete"
                    formControlName="department" [readonly]="readonlyContact">
                  <mat-spinner *ngIf="typeLoading" matSuffix [diameter]="18" style="float: right; margin-left: 8px">
                  </mat-spinner>
                  <app-primas-auto-complete #departmentTypeInput="primasAutoComplete" [searchAPI]="typeDynamicAPI"
                    [input]="departmentInput" [searchProperty]="'content'" [keyProperty]="'content'"
                    [valueProperty]="'content'" (toggleLoading)="getLoadingValueType($event)">
                  </app-primas-auto-complete>
                </mat-form-field>

                <mat-form-field appearance="standard" trim="blur">
                  <mat-label>Contact Company</mat-label>
                  <input matInput formControlName="relationship" (keyup)="onChangesCompany($event)"
                    [matAutocomplete]="autoCompany" [readonly]="readonlyContact">
                  <mat-autocomplete #autoCompany="matAutocomplete">
                    <mat-option *ngFor="let dynamic of filterArray" [value]="dynamic?.content">
                      {{dynamic?.content}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>

              </div>

              <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="12px">
                <mat-form-field [fxFlex]="100/3" appearance="standard">
                  <mat-label>Phone</mat-label>
                  <input matInput autocomplete="off" formControlName="contactPhone" trim="blur"
                    [readonly]="readonlyContact">
                </mat-form-field>
                <mat-form-field [fxFlex]="100/3" appearance="standard">
                  <mat-label>Secondary telephone</mat-label>
                  <input matInput autocomplete="off" formControlName="secondaryPhone" [readonly]="readonlyContact">
                </mat-form-field>
                <mat-form-field [fxFlex]="100/3" appearance="standard">
                  <mat-label>Mobile/Cell Phone</mat-label>
                  <input matInput autocomplete="off" formControlName="cellPhone" [readonly]="readonlyContact">
                </mat-form-field>

              </div>
              <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="12px">

                <mat-form-field appearance="standard">
                  <mat-label>Email</mat-label>
                  <input matInput autocomplete="off" formControlName="contactEmail" [readonly]="readonlyContact"
                    (keyup)="modifyContentNonASCII($event.target.value, formInstance, 'contactEmail')"
                    (change)="modifyContentNonASCII($event.target.value, formInstance, 'contactEmail')">
                  <mat-error
                    *ngIf="formInstance.controls.contactEmail.errors && (formInstance.controls.contactEmail.dirty || formInstance.controls.contactEmail.touched)">
                    {{formInstance.controls.contactEmail['errorMessage']}}</mat-error>
                </mat-form-field>

                <mat-form-field appearance="standard">
                  <mat-label>Secondary Email</mat-label>
                  <input matInput autocomplete="off" formControlName="secondaryEmail" [readonly]="readonlyContact"
                    (keyup)="modifyContentNonASCII($event.target.value, formInstance, 'secondaryEmail')"
                    (change)="modifyContentNonASCII($event.target.value, formInstance, 'secondaryEmail')">
                    <mat-error
                    *ngIf="formInstance.controls.secondaryEmail.errors && (formInstance.controls.secondaryEmail.dirty || formInstance.controls.secondaryEmail.touched)">
                    {{formInstance.controls.secondaryEmail['errorMessage']}}</mat-error>
                </mat-form-field>
              </div>
              <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="12px">
                <mat-form-field appearance="standard" trim="blur">
                  <mat-label>Birthday</mat-label>
                  <input matInput [matDatepicker]="birhdatePicker" (dateChange)="isChange = true"
                    formControlName="birthDate" [readonly]="readonlyContact">
                  <mat-hint>MM/DD/YYYY</mat-hint>
                  <mat-datepicker-toggle matSuffix [for]="birhdatePicker">
                    <mat-icon matDatepickerToggleIcon>calendar_month</mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker #birhdatePicker [disabled]="readonlyContact">
                  </mat-datepicker>
                </mat-form-field>
                <mat-form-field appearance="standard" trim="blur">
                  <mat-label>Source</mat-label>
                  <input #sourceInput matInput [matAutocomplete]="sourceTypeInput.autocomplete" formControlName="source"
                    [readonly]="readonlyContact">

                  <mat-spinner *ngIf="sourceLoading" matSuffix [diameter]="18" style="float: right; margin-left: 8px">
                  </mat-spinner>

                  <app-primas-auto-complete #sourceTypeInput="primasAutoComplete" [searchAPI]="sourceDynamicAPI"
                    [input]="sourceInput" [searchProperty]="'content'" [keyProperty]="'content'"
                    [valueProperty]="'content'" (toggleLoading)="getLoadingValueSource($event)">
                  </app-primas-auto-complete>
                </mat-form-field>
              </div>
              <mat-form-field appearance="standard">
                <mat-label>Contact Type</mat-label>
                <mat-select formControlName="listContactTypeId" required [disabled]="readonlyContact" multiple
                  (selectionChange)="isChange = true">
                  <mat-option *ngFor="let item of arrayContactType" [value]="item.contactTypeId">
                    {{item.contactTypeName}}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="formInstance.controls.listContactTypeId.errors && (formInstance.controls.listContactTypeId.dirty || formInstance.controls.listContactTypeId.touched)">
                  {{formInstance.controls.listContactTypeId['errorMessage']}}</mat-error>
              </mat-form-field>

              <!--Relationship -->
              <app-search-type-objects #relationshipToObj [apiGetSelectedObjects]="apiGetSelectedObjects"
                (selectedObjectsValue)="isChange = true" (detectValueChanged)="entitiesChanged($event)">
              </app-search-type-objects>
              <div>
                <mat-label class="custom-label">Description</mat-label>
                <quill-editor class="content-editor" [modules]="editorOptions"
                  placeholder="Click to add a description..." formControlName="description" style="min-height: 200px;">
                </quill-editor>
              </div>
            </div>
          </div>
          <div fxFlex="30">
            <div>
              <h6>Location</h6>
              <mat-form-field appearance="standard">
                <mat-label>Address 1</mat-label>
                <input matInput autocomplete="off" formControlName="address" [readonly]="readonlyContact">
              </mat-form-field>
              <mat-form-field appearance="standard">
                <mat-label>Address 2</mat-label>
                <input matInput autocomplete="off" formControlName="address2" [readonly]="readonlyContact">
              </mat-form-field>
              <mat-form-field appearance="standard">
                <mat-label>City</mat-label>
                <input matInput autocomplete="off" formControlName="city" trim="blur" [readonly]="readonlyContact">
              </mat-form-field>
              <app-dynamic-content-form style="width: 100%" label="State/Province" [options]="stateOptions"
                [displayKey]="'content'" [control]="formInstance.controls.state">
              </app-dynamic-content-form>
              <mat-form-field appearance="standard">
                <mat-label>Country</mat-label>
                <input type="text" placeholder="Select your Country" matInput [matAutocomplete]="auto"
                  formControlName="country" [readonly]="readonlyContact">
                <mat-error
                  *ngIf="formInstance.controls.country.errors && (formInstance.controls.country.dirty || formInstance.controls.country.touched)">
                  {{formInstance.controls.country['errorMessage']}}</mat-error>
                <mat-autocomplete #auto="matAutocomplete">
                  <ng-container *ngIf="countries != null; else loadingDataCountries">
                    <mat-option *ngFor="let country of contactLst[i].countryObservable | async" [value]="country.value">
                      {{country.value}}
                    </mat-option>
                  </ng-container>
                  <ng-template #loadingDataCountries>
                    <mat-option [nbSpinner]="true"></mat-option>
                  </ng-template>
                </mat-autocomplete>
              </mat-form-field>
              <mat-form-field appearance="standard">
                <mat-label>Postal/Zip code</mat-label>
                <input matInput autocomplete="off" formControlName="zipcode" [readonly]="readonlyContact">
              </mat-form-field>
              <mat-form-field appearance="standard">
                <mat-label>Region</mat-label>
                <input matInput autocomplete="off" formControlName="region" [readonly]="readonlyContact">
              </mat-form-field>
              <mat-form-field appearance="standard">
                <mat-label>Nearest major city</mat-label>
                <input matInput autocomplete="off" formControlName="nearestBigCity" [readonly]="readonlyContact">
              </mat-form-field>
            </div>
          </div>
        </div>

        <div style="margin-top: 24px;">
          <h6>Additional</h6>
          <app-contact-tag [contactTag]="formInstance.get('tags').value"
            (contactTagSelected)="selectTagContact($event, formInstance); isChange = true">
          </app-contact-tag>
          <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="12px">
            <mat-form-field appearance="standard">
              <mat-label>Skype</mat-label>
              <input matInput autocomplete="off" formControlName="skype" [readonly]="readonlyContact">
            </mat-form-field>
            <mat-form-field appearance="standard">
              <mat-label>WhatsApp</mat-label>
              <input matInput autocomplete="off" formControlName="whatsApp" [readonly]="readonlyContact">
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="12px">
            <mat-form-field appearance="standard">
              <mat-label>Facebook</mat-label>
              <input matInput autocomplete="off" formControlName="facebook" [readonly]="readonlyContact">
            </mat-form-field>
            <mat-form-field appearance="standard">
              <mat-label>Tiktok</mat-label>
              <input matInput autocomplete="off" formControlName="tiktok" [readonly]="readonlyContact">
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="12px">
            <mat-form-field appearance="standard">
              <mat-label>Website</mat-label>
              <input matInput autocomplete="off" formControlName="website" [readonly]="readonlyContact">
            </mat-form-field>
            <mat-form-field appearance="standard" *nbIsGranted="['view', 'linked-in-contact']">
              <mat-label>LinkedIn</mat-label>
              <input matInput autocomplete="off" formControlName="linkedIn" [readonly]="readonlyContact">
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>
  </div>


</mat-dialog-content>

<div mat-dialog-actions *ngIf="isDialog">
  <mat-toolbar fxLayout="row" fxLayoutAlign="end center" style="background-color: white;">
    <button *ngIf="isAddMoreBtn" mat-raised-button color="primary" class="float-right"
      (click)="onAddContactBtn(); isChange = true" [attr.disabled]='loading'
      matTooltip="By pressing this button, new contact will be showed">
      <mat-icon>add</mat-icon>More
    </button>
    <button #convertBtn *ngIf="isUpdateAction" mat-raised-button color="primary" class="float-right"
      style="margin-right:5px" [disabled]='disableConvert' [nbSpinner]="loading" (click)="onConvertClick()">
      <mat-icon style="transform: scale(0.9);">swap_horiz
      </mat-icon>Convert
    </button>
    <button mat-raised-button color="primary" class="float-right" (click)="onSave()" [nbSpinner]="loading"
      [disabled]="readonlyContact || loading" nbSpinnerStatus="primary">
      <mat-icon fontSet=" material-icons-outlined">save</mat-icon>Save
    </button>
    <button mat-flat-button aria-label="Close the dialog" (click)="closeDialog()">
      Cancel
    </button>
  </mat-toolbar>
</div>