import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { FileValidator } from 'ngx-material-file-input';
import { environment } from 'src/environments/environment';
import { DateFormatPipe } from '../../pipes/date-time-format.pipe';
import { Helper } from '../../utility/Helper';
import { ImportModalComponent } from '../import-modal/import-modal.component';
import * as XLSX from 'xlsx';
import { PrimasTableComponent } from '../template-management/primas-table/primas-table.component';
import { PagedData } from '../../models/paging/paged-data';
import { T } from '@angular/cdk/keycodes';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { SaleLeadDetailsComponent } from 'src/app/modules/admin/sale-lead-management/sale-lead-details/sale-lead-details.component';
import { SaleAccountDetailsComponent } from 'src/app/modules/admin/sale-account-management/sale-account-details/sale-account-details.component';
import { OpportunityDetailsComponent } from 'src/app/modules/admin/opportunity-management/opportunity-details/opportunity-details.component';
import { ProfileDetailComponent } from 'src/app/modules/admin/profile-management/profile-detail/profile-detail.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
@Component({
  selector: 'app-advanced-import-data',
  templateUrl: './advanced-import-data.component.html',
  styleUrls: ['./advanced-import-data.component.scss']
})
export class AdvancedImportDataComponent implements OnInit, OnDestroy {
  @ViewChild('importRequired', { static: true }) importRequired: TemplateRef<any>;
  @ViewChild('headerFromFile', { static: true }) headerFromFile: TemplateRef<any>;
  @ViewChild('reviewInfo', { static: true }) reviewInfo: TemplateRef<any>;
  @ViewChild('primasTable', { static: true }) primasTable: PrimasTableComponent;
  @ViewChild('stepper', { static: true }) public stepper: MatStepper;
  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild('valueTemplate', { static: true }) value: TemplateRef<any>;

  bulkActionFunction = new EventEmitter<any>();
  readonly maxSize = environment.maxSizeUpload;
  isLoading = false;
  formDoc: FormGroup;
  isComplete = false;
  columns = [];
  systemProperty: string[];
  templateFile: string;
  rows = new Array<any>();
  columnsFile = new Array<any>();
  ColumnMode = ColumnMode;
  onImport = new EventEmitter();
  reviewColumns = [];
  totalRow: number;
  totalSuccess: number;
  totalError: number;
  fileError: any = null;
  systemError = false;
  totalUpdate: number = 0;
  totalNew: number = 0;
  totalReview: number = 0;
  reviewList = [];
  primaryKey: string = 'profileId'; //default primary key on the system
  private readonly destroy$ = new Subject<void>();
  previousEnable: boolean = true;
  disableClose: boolean = false;

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }


  constructor(
    private dialog: MatDialog,
    private frmBuilder: FormBuilder,
    public dialModalRef: MatDialogRef<AdvancedImportDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    datePipe: DateFormatPipe
  ) {
    if (this.data) {
      if (this.data.primaryKey) {
        this.primaryKey = this.data.primaryKey
      }
      if (this.data.importColumn) {
        this.reviewColumns = JSON.parse(JSON.stringify(this.data.importColumn));
        const keyIndex = this.reviewColumns.findIndex(x => { return x.prop == this.primaryKey });
        if (keyIndex > - 1) this.reviewColumns.splice(keyIndex, 1);
      }
      if (this.data.resource) {
        this.data.resource.edit = 'no_permission';
        this.data.resource.remove = 'no_permission';
      }
    }
  }

  ngOnInit() {
    this.columns = [
      {
        name: 'System Property',
        prop: 'systemPropertyName',
        cellTemplate: this.importRequired,
        width: 176,
        resizeable: false,
        canAutoResize: false,
      },
      {
        name: 'Column Header From File',
        prop: 'columnFile',
        cellTemplate: this.headerFromFile,
        width: 176,
        resizeable: false,
        canAutoResize: false,
      },
      {
        name: 'Preview Info',
        prop: 'reviewInfo',
        cellTemplate: this.reviewInfo,
        width: 176,
        resizeable: false,
        canAutoResize: false,
      },

    ];
    this.systemProperty = this.data?.systemProperty;
    this.templateFile = this.data?.templateFile;
    if (this.systemProperty && this.systemProperty.length > 0) {
      this.systemProperty.forEach((x: any) => {
        this.rows.push({
          "systemPropertyName": x.name,
          "systemProperty": x.prop,
          "importRequired": x.importRequired,
          "columnFile": - 1,
          "columnHeader": null,
          "reviewInfo": null
        })
      })
    }
    this.dialModalRef.updatePosition({ right: '0' });
    this.dialModalRef.updateSize('1200px', '100%')
    this.formDoc = this.frmBuilder.group({
      requiredfile: [
        undefined,
        [Validators.required, FileValidator.maxContentSize(this.maxSize)]
      ]
    });
  }
  closeDialog() {
    this.dialModalRef.close();
  }
  onStepChange(event: StepperSelectionEvent) {
    if (event.selectedIndex == 1 && this.formDoc.valid && this.formDoc.controls["requiredfile"].value) {
      this.totalRow = 0;
      this.totalSuccess = 0;
      this.totalError = 0;
      this.fileError = null;
      this.systemError = false;
      this.isComplete = false;
      this.columnsFile = [];
      this.rows.forEach(x => {
        x.columnFile = -1;
        x.reviewInfo = null;
      });
      var reader: FileReader = new FileReader();
      reader.readAsBinaryString(this.formDoc.controls["requiredfile"].value.files[0]);
      reader.onload = (e: any) => {
        /* create workbook */
        const binarystr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary', sheetRows: 2 });

        /* selected the first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
        // ws['!fullref'] = "A1:A3"
        /* save data */

        if (ws) {
          for (var i in ws) {
            if (ws[i].w && ws[i].v) {
              if (Helper.isDateString(ws[i].w)) {
                ws[i].v = ws[i].w;
              }
            }
          }
        }
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 }); // to get 2d array pass 2nd parameter as object {header: 1}
        if (data != null && data.length > 0) {
          let tempColumn = data[0] as [];
          let tempDataColumn = data[1] as [];
          for (let i = 0; i < tempColumn.length; i++) {
            let tempData: any = {
              value: i + 1,
              name: tempColumn[i],
              data: tempDataColumn != null ? tempDataColumn[i] : null,
            }
            // 2022-09-07 tienlm mod start: Improve import mapping
            let formattedName = tempData.name.replace(' ', '').toLowerCase();
            //fill data
            let row = this.rows.find(x =>
              x.systemPropertyName.replace(' ', '').toLowerCase() == formattedName ||
              x.systemPropertyName.toLowerCase() == formattedName ||
              x.systemProperty.toLowerCase() == formattedName
            );
            // 2022-09-07 tienlm mod end
            if (row != null) {
              tempData.selected = true;
              row.columnFile = tempData.value;
              row.columnHeader = tempData.name;
              row.reviewInfo = tempData.data;
              this.rows = [...this.rows];
            }
            setTimeout(() => {
              this.table.recalculate();
              this.table['cd'].markForCheck();
              document.body.style.width = 'auto';
            }, 0);
            this.columnsFile.push(tempData);
          }
        } else {
          //revent file
        }
      };
      setTimeout(() => {
        this.table.recalculate();
        this.table['cd'].markForCheck();
        document.body.style.width = 'auto';
      }, 0);
    }
  }
  finishImport(importResult) {
    this.isComplete = true;
    this.stepper.selected.completed = true;
    this.isLoading = !this.isLoading;
    this.previousEnable = !this.previousEnable;
    this.stepper.next();

    if (importResult) {
      this.totalError = importResult.totalError;
      this.totalRow = importResult.totalRow;
      this.totalSuccess = importResult.totalSuccess;
      this.fileError = importResult.file;
      this.totalNew = importResult.totalNew;
      this.totalUpdate = importResult.totalUpdate;
      this.totalReview = importResult.totalReview;
      this.reviewList = importResult.reviewList ?? [];

      let extendColumn = {};

      for (var i = 0; i < this.reviewList.length; i++) {
        if (this.reviewList[i].extendData) {
          try {
            const extendData = JSON.parse(this.reviewList[i].extendData);
            if (extendData) {
              this.reviewList[i] = Object.assign({}, this.reviewList[i], extendData)
              extendColumn = Object.assign({}, extendData, extendData)
            }
          }
          catch (ex) {
            console.log(ex);
          }
        }
      }
      const pageData = new PagedData();
      pageData.page.size = -1;
      pageData.page.totalElements = this.reviewList?.length;
      pageData.page.totalPages = 1;
      pageData.data = this.reviewList;

      setTimeout(() => {
        this.formatColumn(extendColumn);
        this.primasTable.isLoading = 1;
        this.primasTable.setData(pageData)
        this.primasTable.table.recalculate();
        this.primasTable.table['cd'].markForCheck();
        document.body.style.width = 'auto';
      }, 100);
    } else {
      this.systemError = true;
    }

  }
  onChangeColumn(event, oldValue, rowIndex) {
    if (event.value > -1) {
      let column = this.columnsFile.find(x => x.value == event.value);
      if (column) {
        column.selected = true;
        this.rows[rowIndex].reviewInfo = column.data;
        this.rows[rowIndex].columnHeader = column.name;
      }
    } else {
      this.rows[rowIndex].reviewInfo = null;
    }
    if (oldValue > -1) {
      this.columnsFile.find(x => x.value == oldValue).selected = false;
    }
    this.rows[rowIndex].columnFile = event.value;
    this.rows = [...this.rows];
  }

  importFile() {
    this.isLoading = !this.isLoading;
    const formData = new FormData();
    formData.append('file', this.formDoc.controls["requiredfile"].value.files[0]);
    formData.append('mapping', JSON.stringify(this.rows.map(x => ({
      index: x.columnFile,
      prop: x.systemProperty,
      name: x.systemPropertyName,
      columnHeader: x.columnHeader,
      inputValue: x.columnFile == -2 ? x.reviewInfo?.toString() : '',
    }))))
    this.onImport.emit(formData);
  }

  downloadFileError() {
    Helper.downloadFile(this.fileError);
  }

  inputProperty(rowIndex: any, value: string) {
    if (rowIndex > -1 && value) this.rows[rowIndex].reviewInfo = value;
    this.rows = [...this.rows];
  }
  formatColumn(row: any) {
    for (const key in row) {
      if (Object.prototype.hasOwnProperty.call(row, key)) {
        if (key == this.primaryKey) continue;
        if (key == 'extendData') continue;
        const column = {
          name: this.formatName(key),
          prop: key,
          cellTemplate: this.value,
          sortable: true,
          filter: true,

        };
        this.reviewColumns.push(column);
      }
    }
    for (var i = 0; i < this.reviewColumns.length; i++) {
      this.reviewColumns[i].cellTemplate = this.value;
    }
    const keyIndex = this.reviewColumns.findIndex(x => { return x.prop.toLowerCase() == this.primaryKey.toLowerCase() });
    if (keyIndex > - 1) this.reviewColumns.splice(keyIndex, 1);

    this.primasTable.columnsTable = this.reviewColumns as []
    this.primasTable.clearAllFilter();
  }

  formatName(name: string) {
    return Helper.displayNameProp(name);
  }
  async onClickBulkAction(reviewLst, action: string) {
    const dialogConfirm = this.dialog.open(ConfirmModalComponent, {
      data: {
        message: `Do you wish to ${action.toLowerCase()} ${reviewLst.length} item(s)?`
      }, disableClose: true,
    })

    const result = await dialogConfirm.afterClosed().toPromise();
    if (result) {
      if (this.bulkActionFunction) {
        this.bulkActionFunction.emit({ reviewLst, action });
      }
    }
  }
  onClickRow(row, action) {
    this.onClickBulkAction([row], action);
  }
  refreshData(reset: boolean) {
    this.primasTable.isLoading = 1;
    if (reset) {
      this.primasTable.page.pageNumber = 0;
      this.primasTable.cache = {};
      this.primasTable.rows = [];
      this.primasTable.selected = [];
      //this.primasTable.tableAction = false;
      this.primasTable.table.offset = 0;
      this.primasTable.table.bodyComponent._offset = 0;

    }
    this.primasTable.isLoading = 1;
    if (this.primasTable.page.filter) {
      const data = Helper.filterClientSidePaging(this.primasTable.page.filter, this.reviewList);
      const pageData = new PagedData();
      pageData.page.size = -1;
      pageData.page.totalElements = data.length;
      pageData.page.totalPages = 1;
      pageData.data = data;
      this.primasTable.setData(pageData);
    }
  }
  bulkAction(action: string) {
    const selectedRows =
      this.primasTable.selected && this.primasTable.selected.length > 0
        ? this.primasTable.selected
        : this.primasTable.table.rows;
    this.onClickBulkAction([...selectedRows], action);
  }
  async skipRecord(skipReviewLst) {
    const dialogConfirm = this.dialog.open(ConfirmModalComponent, {
      data: {
        message: `Do you wish to skip ${skipReviewLst.length} item(s)?`
      }, disableClose: true,
    })
    const onClickResult = await dialogConfirm.afterClosed().toPromise();

    if (onClickResult) {
      this.removeRow(skipReviewLst);
    }
  }
  removeRow(skipReviewLst) {
    const rootReviewLst = [...this.primasTable.table.rows];
    if (skipReviewLst) {
      for (var i = 0; i < skipReviewLst.length; i++) {
        const findIdx = rootReviewLst.findIndex(x => { return x == skipReviewLst[i] });
        if (findIdx > -1) rootReviewLst.splice(findIdx, 1);
      }
      this.primasTable.table.rows = rootReviewLst;
      this.primasTable.page.totalElements = rootReviewLst.length;
      this.primasTable.selected = []; //Should be reset the selected record
    }
  }

  onClickToSkipRecord({ row, rowIndex }) {
    this.skipRecord([row]);
  }
  bulkSkipRecord() {
    const selectedRows =
      this.primasTable.selected && this.primasTable.selected.length > 0
        ? this.primasTable.selected
        : this.primasTable.table.rows;
    this.skipRecord([...selectedRows])
  }
  onClickToOpenDialog(row) {
    let component: any;
    let subUrl;
    switch (this.data.typeName) {
      case "LEADS":
        subUrl = 'sale-lead';
        component = SaleLeadDetailsComponent;
        break;
      case "SALEACCOUNT":
        subUrl = 'sale-account'
        component = SaleAccountDetailsComponent;
        break;
      case "OPPORTUNITY":
        subUrl = 'opportunity'
        component = OpportunityDetailsComponent;
        break;
      default:
        subUrl = 'profile'
        component = ProfileDetailComponent;
        break;
    }

    const dialogImport: MatDialogRef<any, any> = this.dialog.open(component, {
      disableClose: true,
      height: '100vh',
      width: '600px',
      panelClass: 'dialog-detail',
      data: {
        importColumn: this.formatKeyPairValue(row),
        advancedImport: true,
        model: { 'profileId': row[this.primaryKey] }
      }
    });

    dialogImport.afterOpened().pipe(takeUntil(this.destroy$))
      .subscribe(() => this.disableClose = true);

    dialogImport.afterClosed().pipe(takeUntil(this.destroy$))
      .subscribe(() => this.disableClose = false);

    if (dialogImport.componentInstance.onAdvancedImport) {
      dialogImport.componentInstance.onAdvancedImport.pipe(takeUntil(this.destroy$)).subscribe((data) => {
        if (data) {
          switch (data) {
            case "Add":
            case "Update":
              this.onClickRow(row, data);
              break;
            case "Skip":
              this.removeRow([row]);
              break;
          }
        }
      });
    }

  }
  formatKeyPairValue(row) {
    const keyPairLst = []
    for (const key in row) {
      if (Object.prototype.hasOwnProperty.call(row, key)) {
        if (key == this.primaryKey) continue;
        if (key == 'extendData') continue;
        var column = {
          name: this.formatName(key),
          prop: key,
          value: row[key],
          sortable: true,
          filter: true,
        };
        keyPairLst.push(column);
      }
    }

    return keyPairLst;

  }

  stepperNext = () => this.stepper.next();
  stepperPrevious = () => this.stepper.previous();
}
