<div class="d-flex flex-wrap" mat-dialog-title style="margin: unset;padding: 1px 10px 1px 28px;">
    <button mat-button color="primary" [disabled]="isLoading || !frmTask.valid" [nbSpinner]="isLoading" debouncedClick
        (throttleClick)="saveData()">
        <mat-icon fontSet=" material-icons-outlined">save</mat-icon>Save
    </button>
    <button mat-button color="primary" (click)="closeDialog()">
        <mat-icon>clear</mat-icon>Cancel
    </button>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="ml-auto">
        <button mat-icon-button *ngIf="task?.clickUpExtend && task?.clickUpExtend?.clickUpId" (click)="openClickUp()"
            matTooltip="View ClickUp site">
            <mat-icon svgIcon="click-up"></mat-icon>
        </button>
        <button mat-icon-button matTooltip="Duplicate task" (click)="duplicateTask()" [nbSpinner]="isLoadingDuplicate"
            *ngIf="action == 1">
            <mat-icon>content_copy</mat-icon>
        </button>
        <div *ngIf="action == actionTypeDefault">
            <button mat-icon-button color="warn" matTooltip="Delete Task" (click)="deleteTask()">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
        <button mat-icon-button aria-label="Close the dialog" (click)="closeDialog()" class="ml-auto">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
<mat-divider></mat-divider>
<mat-dialog-content class="mat-typography">
    <!-- ask automatic task -->
    <div *ngIf="isAutomateTask" class="alert alert-info" role="alert">
        This {{targetType}} has automatic template. Do you wish to use this automatic template?
        <div fxLayout="row" fxLayoutAlign="end center">
            <a mat-button (click)="applyAutomaticTask()">Yes</a>
            <a mat-button color="warn" (click)="turnOffAutomaticTask()">No</a>
        </div>
    </div>

    <form class="container" [formGroup]="frmTask" (ngSubmit)="saveData()"
        style="padding-top: 25px;padding-right: 50px;padding-left: 50px;">
        <div *ngIf="modeForm == modeAddTask.BulkAdd" class="alert alert-warning" role="alert">
            Do you wish to add task for {{totalBulkAdd}} selected item?
        </div>
        <mat-form-field appearance="standard">
            <mat-label>Task type</mat-label>
            <mat-select required formControlName="taskTypeId" [errorStateMatcher]="matcher"
                (selectionChange)="changeTaskType($event)">
                <mat-select-trigger>
                    <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 16 16">
                        <g fill="none" fill-rule="evenodd">
                            <path
                                d="M0 1.777C0 .796.796 0 1.777 0h12.446C15.204 0 16 .796 16 1.777v12.446c0 .981-.796 1.777-1.777 1.777H1.777A1.778 1.778 0 0 1 0 14.223V1.777z"
                                [attr.fill]="taskTypeSelected?.colorCode" />
                            <path
                                d="M4.5 12.5V4.378c0-.485.392-.878.875-.878h5.25c.483 0 .875.393.875.878V12.5L8 8.988 4.5 12.5z"
                                fill="#FFF" />
                        </g>
                    </svg>
                    {{taskTypeSelected.taskTypeName}}
                </mat-select-trigger>
                <mat-option *ngFor="let type of taskType" [value]="type.taskTypeId">
                    <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 16 16">
                        <g fill="none" fill-rule="evenodd">
                            <path
                                d="M0 1.777C0 .796.796 0 1.777 0h12.446C15.204 0 16 .796 16 1.777v12.446c0 .981-.796 1.777-1.777 1.777H1.777A1.778 1.778 0 0 1 0 14.223V1.777z"
                                [attr.fill]="type.colorCode" />
                            <path
                                d="M4.5 12.5V4.378c0-.485.392-.878.875-.878h5.25c.483 0 .875.393.875.878V12.5L8 8.988 4.5 12.5z"
                                fill="#FFF" />
                        </g>
                    </svg>
                    {{type.taskTypeName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="standard">
            <mat-label>Task status</mat-label>
            <mat-select required formControlName="taskStatusId" [errorStateMatcher]="matcher">
                <mat-option *ngFor="let status of taskStatus" [value]="status.taskStatusId">
                    {{status.taskStatusName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="standard">
            <mat-label>Task name</mat-label>
            <input matInput autocomplete="off" required trim="blur" formControlName="taskName"
                [errorStateMatcher]="matcher">
        </mat-form-field>
        <quill-editor class="content-editor" [modules]="editorOptions" placeholder="Click to add description"
            formControlName="taskDescription" style="min-height: 200px;">
        </quill-editor>
        <mat-form-field appearance="standard">
            <mat-label>Task priority</mat-label>
            <mat-select required formControlName="taskPriority" [errorStateMatcher]="matcher"
                (selectionChange)="changeTaskTaskPriority($event)">
                <mat-select-trigger> <img class="user-img" [src]="priorityImg | safeIframe">
                    {{taskPrioritySelected.priorityName}}
                </mat-select-trigger>
                <mat-option *ngFor="let priority of taskPriority" [value]="priority.priorityId">
                    <img class="user-img"
                        [src]="'assets/images/priority/' + priority.priorityId.toString() + '.svg' | safeIframe">
                    {{priority.priorityName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div class="row">
            <div class="col-12">
                <mat-form-field>
                    <mat-label>Due date</mat-label>
                    <input #dueDateInput matInput [matDatepicker]="startPicker.datePicker"
                        formControlName="taskStartDate" [errorStateMatcher]="matcher" [disabled]="!allowChangeDueDate"
                        (dateChange)="startPicker.dateChangeSubject.next($event)">
                    <!-- <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #startPicker [showSeconds]="true">
                        <ng-template>
                            <span>OK</span>
                        </ng-template>
                    </ngx-mat-datetime-picker> -->
                    <!-- nebular -->
                    <!-- <nb-date-timepicker
                    [withSeconds]="false"
                    format="M/dd/yyyy, HH:mm:ss"
                    [step]="15"
                    #startPicker></nb-date-timepicker> -->
                    <!-- primas -->
                    <mat-datepicker-toggle matSuffix [for]="startPicker.datePicker"></mat-datepicker-toggle>
                    <app-primas-custom-date-time-picker #startPicker="PrimasCustomDateTimePicker"
                        (dateChange)="dateChanged($event,'taskStartDate')"
                        (editManualSave)="dateChanged($event,'taskStartDate')"></app-primas-custom-date-time-picker>
                </mat-form-field>
            </div>
            <!-- <div class="col-6">
                <mat-form-field>
                    <mat-label>Finished date</mat-label> -->
                    <!-- [ngxMatDatetimePicker]="endPicker" -->
                    <!-- <input matInput [matDatepicker]="endPicker.datePicker" formControlName="taskEndDate"
                        (dateChange)="endPicker.dateChangeSubject.next($event)" [errorStateMatcher]="matcher"> -->
                    <!-- <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #endPicker [showSeconds]="true">
                        <ng-template>
                            <span>OK</span>
                        </ng-template>
                    </ngx-mat-datetime-picker> -->
                    <!-- <nb-date-timepicker
                    [withSeconds]="false"
                    format="M/dd/yyyy, HH:mm:ss"
                    #endPicker></nb-date-timepicker> -->
                    <!-- <mat-datepicker-toggle matSuffix [for]="endPicker.datePicker"></mat-datepicker-toggle>
                    <app-primas-custom-date-time-picker #endPicker="PrimasCustomDateTimePicker"
                        (dateChange)="dateChanged($event,'taskEndDate')"
                        (editManualSave)="dateChanged($event,'taskEndDate')"></app-primas-custom-date-time-picker>
                </mat-form-field>
            </div> -->
        </div>
        <mat-label><b>Assign to: </b>
            <span *ngIf="task.assigneeTo" style="margin-left: 10px;">
                <span>
                    <img class="user-img" [src]="userChosen.pictureURL ?? 'assets/images/man.png'| safeIframe"
                        alt="user-avatar" style="margin-right: 5px;"
                        (error)="userChosen.pictureURL = 'assets/images/man.png'">
                </span>
                <strong>{{userChosen.firstName}} {{userChosen.lastName}}</strong> -
                ({{userChosen.userName || task.userName}})
                <span>
                    <button mat-icon-button (click)="clearAssignee(); isChange = true">
                        <mat-icon>close</mat-icon>
                    </button>
                </span>
            </span>
        </mat-label>
        <div class="col-12" *ngIf="!task.assigneeTo">
            <app-agent-chosen (assignAgentChange)="getAssignee($event); isChange = true"></app-agent-chosen>
        </div>
        <!-- resolution -->
        <ng-container *ngIf="action == 1">
            <div *ngIf="modeForm == modeAddTask.Basic">
                <mat-form-field appearance="standard">
                    <mat-label>Resolution Code</mat-label>
                    <mat-select formControlName="resolutionCodeId" [errorStateMatcher]="matcher"
                        (selectionChange)="resolutionCodeChanged($event)">
                        <mat-option value="-1">
                            None
                        </mat-option>
                        <mat-option disabled="true">
                            -- Please select --
                        </mat-option>
                        <mat-option *ngFor="let resolutionCode of resolutionCodes"
                            [value]="resolutionCode.resolutionCodeId">
                            {{resolutionCode.resolutionCodeName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <!--2021-12-30 vuonglqn add start-->
            <div *ngIf="modeForm == modeAddTask.Basic">
                <mat-label style="color: #b4b4b4;">Resolution Comment</mat-label>
                <quill-editor class="content-editor" [modules]="editorOptions" placeholder="Click to add resolution"
                    formControlName="resolution" style="min-height: 200px;">
                </quill-editor>
            </div>
            <!--2021-12-30 vuonglqn add end-->
        </ng-container>

    </form>
    <div *ngIf="modeForm == modeAddTask.Basic" class="container"
        style="padding-top: 25px;padding-right: 50px;padding-left: 50px;">
        <div><b>Relationship:</b> </div>
        <div class="float-left">
            <mat-form-field appearance="standard">
                <mat-select [(ngModel)]="relationshipType" (selectionChange)="selectionChangeRelationship()">
                    <mat-option *ngFor="let data of relationshipData" [value]="data.value">
                        {{data.viewValue}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <!-- <div *ngIf="relationshipType === 'profile'; else searchContact">
            <app-profile-chosen (profileSelected)="getProfileSelected($event)"></app-profile-chosen>
        </div> -->
        <div [ngSwitch]="relationshipType">
            <!-- Profile Search -->
            <app-profile-chosen *ngSwitchCase="relationshipType == 'profile'
                ? relationshipType : ''" (profileSelected)="getProfileSelected($event); isChange = true"
                [tooltipProp]="tooltipProp" [apiGet]="apiGetSearchRelationship ? apiGetSearchRelationship : ''"
                [placeholder]="placeholderSearch ? placeholderSearch : ''"
                [funcMapTitleModel]="funcMapTitleSearch ? funcMapTitleSearch : null">
            </app-profile-chosen>


            <app-profile-chosen *ngSwitchCase="relationshipType == 'saleLeads' || relationshipType == 'saleAccount'
            || relationshipType == 'opportunity'
                ? relationshipType : ''" (profileSelected)="getProfileSelected($event); isChange = true"
                [tooltipProp]="tooltipProp" [apiGet]="apiGetSearchRelationship ? apiGetSearchRelationship : ''"
                [placeholder]="placeholderSearch ? placeholderSearch : ''" [isOneLineResult]="true"
                [funcMapTitleModel]="funcMapTitleSearch ? funcMapTitleSearch : null">
            </app-profile-chosen>

            <!-- Real Buyer Search -->
            <app-search-buyer-entity *ngSwitchCase="'buyer'"
                (buyerSelected)="getBuyerSelected($event); isChange = true">
            </app-search-buyer-entity>

            <!-- Contact search -->
            <app-buyer-chosen *ngSwitchCase="'contact'" (contactSelected)="getContactSelected($event); isChange = true">
            </app-buyer-chosen>

        </div>
        <div *ngIf="contactSelected; then contactBlock"></div>
        <div *ngIf="profileSelected; then profileBlock"></div>
        <div *ngIf="buyerSelected; then buyerBlock"></div>

    </div>
</mat-dialog-content>
<ng-template #searchContact>
    <div>
        <app-buyer-chosen (contactSelected)="getContactSelected($event)"></app-buyer-chosen>
    </div>
</ng-template>
<ng-template #contactBlock>
    <div>
        <app-contact-info [contact]="contactSelected" [hideUnlinkBtn]='true'></app-contact-info>
    </div>
</ng-template>
<ng-template #profileBlock>
    <div *ngIf="profileSelected && (profileSelected.typeName == 'SALEPROFILE' || !profileSelected.typeName)">
        <nb-card style="height: 100% !important; padding-bottom: 10px !important">
            <nb-card-header>
                <div class="col-12">
                    <div class="row">
                        <div class="col">
                            <div class="row">
                                <span class="d-flex align-items-center" style="margin-right: 10px;">
                                    <img [src]="displayImage || 'assets/images/5.jpg'"
                                        (error)="displayImage = 'assets/images/5.jpg'" alt="Avatar" class="avatar">
                                </span>
                                <div class="col">
                                    <div [matTooltip]="findItemByKey(tooltipProp, 'displayName') ?? 'Display name'"
                                        matTooltipPosition="left">
                                        <span>
                                            <strong>{{profileSelected?.displayName || 'None'}}</strong>
                                        </span>
                                    </div>
                                    <div [matTooltip]="findItemByKey(tooltipProp, 'realName') ?? 'Real name'"
                                        matTooltipPosition="left" class="text-muted">
                                        <span>
                                            {{profileSelected?.realName || 'None'}}
                                        </span>
                                    </div>
                                </div>
                                <span>
                                    <button mat-icon-button (click)="deletedRelationship(); isChange = true">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

            </nb-card-header>
            <nb-card-body class="card-image">
                <img matTooltip="Profile image" matTooltipPosition="left" [src]="displayImage || 'assets/images/5.jpg'"
                    (error)="displayImage = 'assets/images/5.jpg'" width="100%" [style.height.px]="180"
                    style=" object-fit:cover; object-position: center" alt="Profile Image">
            </nb-card-body>
        </nb-card>
    </div>
    <ng-container *ngIf="profileSelected && profileSelected.typeName == 'LEADS'" [ngTemplateOutlet]="opportunityBlock"
        [ngTemplateOutletContext]="{
            title: profileSelected?.displayName,
            labelTitle: environment.titleLead + ' name'
        }">
    </ng-container>
    <ng-container *ngIf="profileSelected && profileSelected.typeName == 'SALEACCOUNT'"
        [ngTemplateOutlet]="opportunityBlock" [ngTemplateOutletContext]="{
            title: profileSelected?.displayName,
            labelTitle: environment.titleAccount + ' name'
        }">
    </ng-container>
    <ng-container *ngIf="profileSelected && profileSelected.typeName == 'OPPORTUNITY' &&
        profileSelected.saleOpportunity" [ngTemplateOutlet]="opportunityBlock" [ngTemplateOutletContext]="{
            title:  profileSelected.saleOpportunity?.opportunityName,
            labelTitle: environment.titleOpportunity + ' name'
        }">
    </ng-container>
</ng-template>

<ng-template #buyerBlock>
    <div *ngIf="buyerSelected">
        <nb-card style="height: 100% !important; padding-bottom: 10px !important">
            <nb-card-header>
                <div class="col-12">
                    <div class="row">
                        <div class="col">
                            <div class="row">
                                <!-- <span class="d-flex align-items-center" style="margin-right: 10px;">
                                    <img [src]="displayImage || 'assets/images/5.jpg'"
                                        (error)="displayImage = 'assets/images/5.jpg'" alt="Avatar" class="avatar">
                                </span> -->
                                <div class="col">
                                    <div matTooltip="Company name" matTooltipPosition="left">
                                        <span>
                                            <strong>{{buyerSelected?.companyName || 'None'}}</strong>
                                        </span>
                                    </div>
                                    <div matTooltip="Website" matTooltipPosition="left" class="text-muted">
                                        <span>
                                            {{buyerSelected?.companyWebsite || 'None'}}
                                        </span>
                                    </div>
                                </div>
                                <!-- <span>
                                    <button mat-icon-button (click)="deletedRelationship()">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </span> -->
                                <span>
                                    <button mat-icon-button (click)="deletedRelationship(); isChange = true">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

            </nb-card-header>
            <nb-card-body>
                <p matTooltip="Facebook" matTooltipPosition="left">
                    <span class="fab fa-facebook fa-lg social-icon blue-color">
                    </span>
                    <span>
                        {{buyerSelected?.facebook || 'No Information'}}
                    </span>
                </p>
                <!-- insta -->
                <p matTooltip="Instagram" matTooltipPosition="left">
                    <span class="fab fa-instagram fa-lg social-icon blue-color">
                    </span>
                    <span>
                        {{buyerSelected?.instagram || 'No Information'}}
                    </span>
                </p>
                <!-- Twitter -->
                <p matTooltip="Twitter" matTooltipPosition="left">
                    <span class="fab fa-twitter fa-lg social-icon blue-color">
                    </span>
                    <span>
                        {{buyerSelected?.twitter || 'No Information'}}
                    </span>
                </p>
            </nb-card-body>
        </nb-card>
    </div>
</ng-template>

<ng-template #showBlockRelationship let-title="title" let-subTitle="subTitle" let-labelTitle="labelTitle"
    let-labelSubTitle="labelSubTitle" let-labelImg="labelImg">
    <div>
        <nb-card style="height: 100% !important; padding-bottom: 10px !important">
            <nb-card-header>
                <div class="col-12">
                    <div class="row">
                        <div class="col">
                            <div class="row">
                                <span class="d-flex align-items-center" style="margin-right: 10px;">
                                    <img [src]="displayImage || 'assets/images/5.jpg'"
                                        (error)="displayImage = 'assets/images/5.jpg'" alt="Avatar" class="avatar">
                                </span>
                                <div class="col">
                                    <div [matTooltip]="labelTitle" matTooltipPosition="left">
                                        <span>
                                            <strong>{{title || 'None'}}</strong>
                                        </span>
                                    </div>
                                    <div [matTooltip]="labelSubTitle" matTooltipPosition="left" class="text-muted">
                                        <span>
                                            {{subTitle || 'None'}}
                                        </span>
                                    </div>
                                </div>
                                <span>
                                    <button mat-icon-button (click)="deletedRelationship(); isChange = true">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </nb-card-header>
            <nb-card-body class="card-image">
                <img [matTooltip]="labelImg" matTooltipPosition="left" [src]="displayImage || 'assets/images/5.jpg'"
                    (error)="displayImage = 'assets/images/5.jpg'" width="100%" [style.height.px]="180"
                    style=" object-fit:cover; object-position: center" alt="Profile Image">
            </nb-card-body>
        </nb-card>
    </div>
</ng-template>

<ng-template #opportunityBlock let-title="title" let-subTitle="subTitle" let-labelTitle="labelTitle"
    let-tooltip="tooltip">
    <nb-card>
        <nb-card-header fxLayout="row" fxLayoutAlign="space-between center" class="custom-header">
            <div fxLayout="row" fxFlex="1 1 calc(100%-100px)">
                <span class="title-header-card limit-line mr-1" style="--line: 1;" [matTooltip]="labelTitle"
                    matTooltipPosition="left">
                    {{title}}
                </span>
            </div>

            <div fxFlex="100px" fxLayoutAlign="end center">
                <button mat-icon-button (click)="deletedRelationship(); isChange = true">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </nb-card-header>
    </nb-card>

    <ng-template #loadingUser>
        <ngx-skeleton-loader fxFlex count="1" appearance="line" animation="progress"
            [theme]="{ 'margin-bottom': '0px' }">
        </ngx-skeleton-loader>
    </ng-template>
</ng-template>
