<div class="d-flex flex-wrap" mat-dialog-title style="margin: unset; padding: 1px 10px 1px 28px"
  [nbSpinner]="isLoading">
  <!-- <button mat-button color="primary" (click)="saveData()" [disabled]="!frmAttendance.valid">
  </button> -->
  <button mat-button color="primary" (click)="closeDialog()">
    <mat-icon>clear</mat-icon>Cancel
  </button>
  <div fxLayout="row" fxLayoutAlign="end center" class="ml-auto">
    <ng-container *ngIf="!isRequest && hasEditPermission">
      <button mat-stroked-button style="color: #07bb34;" (click)="saveAttendanceRequestAll('approve')">
        <mat-icon>done</mat-icon>Approve All
      </button>
      <button mat-stroked-button style="color: #e100ff; min-width: 110px; margin: 0 10px;" (click)="saveAttendanceRequestAll('reject')">
        <mat-icon>block</mat-icon>Reject All
      </button>
    </ng-container>
    <button mat-icon-button aria-label="Close the dialog" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<mat-divider></mat-divider>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="frmAttendance" class="container" style="padding-top: 25px; padding-right: 50px; padding-left: 50px">
    <div class="row">
      <ng-container *ngIf="action === 0">
      </ng-container>
      <ng-container *ngIf="action === 1">
        <mat-card class="attendance-card">
          <mat-card-header fxLayout="row" fxLayoutAlign="space-between center">
            <mat-card-title style="color: #307abd;" class="d-flex flex-row mt-2">
              Punch In
              <div [ngSwitch]="attendanceModel?.punchinRequestStatus">
                <ng-container *ngSwitchCase="1">
                  <div class="badge badge-pill badge-success mx-3">Approved</div>
                </ng-container>
                <ng-container *ngSwitchCase="2">
                  <div class="badge badge-pill badge-reject mx-3">Rejected</div>
                </ng-container>
                <ng-container *ngSwitchCase="3">
                  <div class="badge badge-pill badge-primary mx-3">Waiting</div>
                </ng-container>
              </div>
            </mat-card-title>
            
            <button mat-stroked-button *ngIf="attendanceModel?.profileId === user?.nameid && isRequest"
              (click)="requestAttendance(true)"
              style="height: 28px; line-height: 0; color: #ffaa00;">
              <mat-icon>cached</mat-icon>Request Change
            </button>
          </mat-card-header>
          <mat-card-content [ngClass]="{disabledNoOfCasesDiv: !hasEditPermission}">
            <div class="col-12">
              <mat-form-field>
                <mat-label>Punch In Date</mat-label>
                <input #dueDateInput matInput [matDatepicker]="punchInPicker.datePicker" [value]="attendanceModel?.punchinDate"
                  readonly (dateChange)="punchInPicker.dateChangeSubject.next($event)">
                <!-- primas -->
                <mat-datepicker-toggle matSuffix [for]="punchInPicker.datePicker" disabled></mat-datepicker-toggle>
                <app-primas-custom-date-time-picker #punchInPicker="PrimasCustomDateTimePicker" [minuteStep]="1"
                  [twelveHoursFormat]="false" (dateChange)="dateChanged($event,'punchinDate')"
                  (editManualSave)="dateChanged($event,'punchinDate')"></app-primas-custom-date-time-picker>
              </mat-form-field>
              <mat-form-field [fxFlex]="20" [ngClass]="{disabledNoOfCasesDiv: true}">
                <mat-label>Time Zone</mat-label>
                <input matInput autocomplete="off" [value]="timeZoneIn">
              </mat-form-field>
            </div>
          </mat-card-content>

          <ng-container *ngIf="attendanceModel?.punchinRequestStatus !== 0">
            <mat-card-header fxLayout="row" fxLayoutAlign="space-between center">
              <mat-card-title style="color: #8dc63f;">
                Request Change Punch In
              </mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <form [formGroup]="frmRequestPunchin" class="container">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <div fxFlex="40">
                    <mat-form-field>
                      <mat-label>Current Time</mat-label>
                      <input #dueDateInput matInput [matDatepicker]="punchInPicker.datePicker"
                        (dateChange)="punchInPicker.dateChangeSubject.next($event)" readonly
                        [value]="displayPunchinCurrent"
                      >
                      <!-- primas -->
                      <mat-datepicker-toggle matSuffix [for]="punchInPicker.datePicker" disabled></mat-datepicker-toggle>
                      <app-primas-custom-date-time-picker
                        #punchInPicker="PrimasCustomDateTimePicker"></app-primas-custom-date-time-picker>
                    </mat-form-field>
                  </div>
                  <div fxFlex="20">
                    <div class="arrow">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                  <div fxFlex="40">
                    <mat-form-field>
                      <mat-label>Request Time</mat-label>
                      <input #dueDateInput matInput [matDatepicker]="requestInPicker.datePicker"
                      (dateChange)="requestInPicker.dateChangeSubject.next($event)"
                      readonly [value]="displayPunchinRequest"
                      >
                      <!-- primas -->
                      <mat-datepicker-toggle matSuffix [for]="requestInPicker.datePicker" disabled></mat-datepicker-toggle>
                      <app-primas-custom-date-time-picker #requestInPicker="PrimasCustomDateTimePicker" [minuteStep]="1"
                        [twelveHoursFormat]="false" (dateChange)="dateChanged($event,'requestDate')"
                        (editManualSave)="dateChanged($event,'requestDate')"></app-primas-custom-date-time-picker>
                    </mat-form-field>
                  </div>
                </div>
                <mat-form-field appearance="standard">
                  <mat-label style="color: #666666">Reason</mat-label>
                  <textarea matInput placeholder="Write note here..." formControlName="requestNote" trim="blur"
                    readonly></textarea>
                </mat-form-field>
                <div class="col-12" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="48px">
                  <ng-container *ngIf="(hasEditPermission && !isRequest) || user?.nameid === attendanceModel?.profile?.ownerId">
                    <button mat-stroked-button *ngIf="attendanceModel?.punchinRequestStatus !== 2" style="color: #e100ff; min-width: 110px;"
                      (click)="saveAttendanceRequest('reject', true)">
                      <mat-icon>block</mat-icon> Reject
                    </button>
                    <button mat-stroked-button *ngIf="attendanceModel?.punchinRequestStatus !== 1" style="color: #07bb34;" 
                      (click)="saveAttendanceRequest('approve', true)">
                      <mat-icon>done</mat-icon> Approve
                    </button>
                  </ng-container>
                </div>
              </form>
            </mat-card-content>
          </ng-container>
        </mat-card>
        <mat-card class="attendance-card" style="margin-top: 20px;">
          <mat-card-header fxLayout="row" fxLayoutAlign="space-between center">
            <mat-card-title style="color: #307abd;" class="d-flex flex-row mt-2">
              Punch Out
              <div [ngSwitch]="attendanceModel?.punchoutRequestStatus">
                <ng-container *ngSwitchCase="1">
                  <div class="badge badge-pill badge-success mx-3">Approved</div>
                </ng-container>
                <ng-container *ngSwitchCase="2">
                  <div class="badge badge-pill badge-reject mx-3">Rejected</div>
                </ng-container>
                <ng-container *ngSwitchCase="3">
                  <div class="badge badge-pill badge-primary mx-3">Waiting</div>
                </ng-container>
              </div>
            </mat-card-title>
            <button mat-stroked-button *ngIf="attendanceModel?.profileId === user?.nameid && isRequest"
              (click)="requestAttendance(false)" [disabled]="!attendanceModel?.attendanceId"
              style="height: 28px; line-height: 0; color: #ffaa00;">
              <mat-icon>cached</mat-icon>Request Change
            </button>
          </mat-card-header>
          <mat-card-content [ngClass]="{disabledNoOfCasesDiv: !hasEditPermission}">
            <div class="col-12" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="12px">
              <mat-form-field>
                <mat-label>Punch Out Date</mat-label>
                <input #dueDateInput matInput [matDatepicker]="punchOutPicker.datePicker" [value]="attendanceModel?.punchoutDate"
                  readonly (dateChange)="punchOutPicker.dateChangeSubject.next($event)">
                <!-- primas -->
                <mat-datepicker-toggle matSuffix [for]="punchOutPicker.datePicker" disabled></mat-datepicker-toggle>
                <app-primas-custom-date-time-picker #punchOutPicker="PrimasCustomDateTimePicker" [minuteStep]="1"
                  [twelveHoursFormat]="false" (dateChange)="dateChanged($event,'punchoutDate')"
                  (editManualSave)="dateChanged($event,'punchoutDate')"></app-primas-custom-date-time-picker>
              </mat-form-field>
              <mat-form-field [fxFlex]="20" [ngClass]="{disabledNoOfCasesDiv: true}">
                <mat-label>Time Zone</mat-label>
                <input matInput autocomplete="off" [value]="timeZoneOut">
              </mat-form-field>
            </div>
          </mat-card-content>

          <ng-container *ngIf="attendanceModel?.punchoutRequestStatus !== 0">
            <mat-card-header fxLayout="row" fxLayoutAlign="space-between center">
              <mat-card-title style="color: #8dc63f;">
                Request Change Punch Out
              </mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <form [formGroup]="frmRequestPunchout" class="container">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <div fxFlex="40">
                    <mat-form-field>
                      <mat-label>Current Time</mat-label>
                      <input #dueDateInput matInput [matDatepicker]="punchOutPicker.datePicker"
                        (dateChange)="punchOutPicker.dateChangeSubject.next($event)" readonly
                        [value]="displayPunchoutCurrent">
                      <!-- primas -->
                      <mat-datepicker-toggle matSuffix [for]="punchOutPicker.datePicker" disabled></mat-datepicker-toggle>
                      <app-primas-custom-date-time-picker
                        #punchOutPicker="PrimasCustomDateTimePicker"></app-primas-custom-date-time-picker>
                    </mat-form-field>
                  </div>
                  <div fxFlex="20">
                    <div class="arrow">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                  <div fxFlex="40">
                    <mat-form-field>
                      <mat-label>Request Time</mat-label>
                      <input #dueDateInput matInput [matDatepicker]="requestOutPicker.datePicker"
                        readonly [value]="displayPunchoutRequest"
                        (dateChange)="requestOutPicker.dateChangeSubject.next($event)"
                      >
                      <!-- primas -->
                      <mat-datepicker-toggle matSuffix [for]="requestOutPicker.datePicker" disabled></mat-datepicker-toggle>
                      <app-primas-custom-date-time-picker #requestOutPicker="PrimasCustomDateTimePicker" [minuteStep]="1"
                        [twelveHoursFormat]="false" (dateChange)="dateChanged($event,'requestDate')"
                        (editManualSave)="dateChanged($event,'requestDate')"></app-primas-custom-date-time-picker>
                    </mat-form-field>
                  </div>
                </div>
                <mat-form-field appearance="standard">
                  <mat-label style="color: #666666">Reason</mat-label>
                  <textarea matInput placeholder="Write note here..." formControlName="requestNote" trim="blur"
                    readonly></textarea>
                </mat-form-field>
                <div class="col-12" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="48px">
                  <ng-container *ngIf="(hasEditPermission && !isRequest) || user?.nameid === attendanceModel?.profile?.ownerId">
                    <button mat-stroked-button *ngIf="attendanceModel?.punchoutRequestStatus !== 2" style="color: #e100ff; min-width: 110px;"
                      (click)="saveAttendanceRequest('reject', false)">
                      <mat-icon>block</mat-icon> Reject
                    </button>
                    <button mat-stroked-button *ngIf="attendanceModel?.punchoutRequestStatus !== 1" style="color: #07bb34;" 
                      (click)="saveAttendanceRequest('approve', false)">
                      <mat-icon>done</mat-icon> Approve
                    </button>
                  </ng-container>
                </div>
              </form>
            </mat-card-content>
          </ng-container>
        </mat-card>
      </ng-container>
    </div>
  </form>
</mat-dialog-content>