<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
    {{this.data.typeMediaType == 2 ? 'Video' : 'Image'}} Gallery
    <button mat-icon-button (click)="getMedia()">
        <mat-icon>refresh</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <div [nbSpinner]="loading" style="min-height:30vh; width:fit-content; min-width: 70vw;">
        <div *ngIf="medias.length > 0" fxLayoutGap="16px grid" fxLayout="row wrap">
            <div *ngFor="let item of medias">
                <div *ngIf="this.data.typeMediaType == 2; else img" class="pointer">
                    <div (click)="selectItem(item)">
                        <iframe
                            [ngClass]="item.media1 == selectedItem?.media1 ? 'target disable-iframe' : 'disable-iframe'"
                            [src]="item.media1 | safeIframe" width="190px" height="190px"></iframe>
                    </div>
                </div>
                <ng-template #img>
                    <img [ngClass]="item.media1 == selectedItem?.media1 ? 'target' : null" [src]="item.media1"
                        loading="lazy" width="190px" height="190px" (click)="selectItem(item)">
                </ng-template>
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false">Cancel</button>
    <button mat-raised-button (click)="clickUpload()"
        [disabled]="data.typeMediaType == 2 ? (obsUploadVideo | async) : false">
        <mat-icon>file_upload</mat-icon>Upload
    </button>
    <button mat-raised-button color="primary" [mat-dialog-close]="selectedItem">Ok</button>
</mat-dialog-actions>

<ng-template #uploadImage>
    <p *ngIf="invalidSize" style="color: red; text-align: center;">Total size each upload < 20MB</p>
            <div ngx-dropzone (change)="onSelect($event)" [accept]="'image/*'">
                <ngx-dropzone-label>Drag your files here or click in this area.</ngx-dropzone-label>
                <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files"
                    [removable]="true" [file]="f" (removed)="onRemove(f)" [removable]="true">
                    <ngx-dropzone-label></ngx-dropzone-label>
                </ngx-dropzone-image-preview>
            </div>
            <mat-dialog-actions align="end">
                <button mat-button (click)="dialogUpload ? dialogUpload.close() : ''">Cancel</button>
                <button mat-raised-button color="primary" (click)="onSaveUpload()"
                    [disabled]="invalidSize || loading">Save
                </button>
            </mat-dialog-actions>
</ng-template>